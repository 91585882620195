import { PublishCommand, PublishCommandInput, PublishCommandOutput, SNSClient } from '@aws-sdk/client-sns';
import { logger } from 'src/analytics/KatalLogger';
import ENVIRONMENT_VARIABLES from 'src/constants/environment-variables';
import { getAWSServiceCredentials } from './auth-token-services';

const REGION = ENVIRONMENT_VARIABLES.env.Region;
const ARN = ENVIRONMENT_VARIABLES.env.SNSNotificationARN;

// If changed, change in slack_notification_handler as well
type EventType = 'info' | 'success' | 'error';

const getSNSClient = async () => {
  const snsClientConfig = new SNSClient({
    region: REGION,
    credentials: await getAWSServiceCredentials()
  });
  return snsClientConfig;
};

export const sendSNSNotification = async (subject: string, message: string, eventType: EventType) => {
  try {
    if (!ARN) return;

    const snsClient = await getSNSClient();
    const commandInput: PublishCommandInput = {
      TopicArn: ARN,
      Subject: subject,
      Message: message,
      MessageAttributes: {
        EventType: {
          DataType: 'String',
          StringValue: eventType
        }
      }
    };
    const publishCommand = new PublishCommand(commandInput);
    const publishCommandOutput: PublishCommandOutput = await snsClient.send(publishCommand);

    logger.info('SNS Notification sent', { messageId: publishCommandOutput.MessageId });
  } catch (error: any) {
    logger.error('Unable to send SNS Notification', error);
  }
};
