import {
  Button,
  Form,
  FormField,
  Input,
  Select,
  SelectProps,
  SpaceBetween,
  SplitPanel,
  StatusIndicator,
  StatusIndicatorProps,
  Toggle
} from '@amzn/awsui-components-react';
import { Formik, FormikHelpers, FormikProps } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { logger, logUserClick } from 'src/analytics/KatalLogger';
import { updateUsefulLifeMappings } from 'src/api/app-sync-services';
import { useAuth } from 'src/app/auth/AuthContextProvider';
import { LoadingSpinner } from 'src/components/common/LoadingSpinner';
import { splitPanelI18nStrings } from 'src/i18n-strings';
import { Status } from 'src/models/AuthContextModels';
import { UsefulLifeMappingEntity } from 'src/models/xPTMappingModels';
import { selectActiveExpenseTypeMappings } from 'src/store/selectors/xPTMapperSelector';
import { fetchExpenseTypeMappings, fetchUsefulLifeDetails } from 'src/store/slices/xPTMapperSlice';
import { RootState } from 'src/store/store';
import { useAppDispatch } from 'src/store/useAppDispatch';
import { sendSNSNotification } from 'src/utils/aws-sns-service';
import { getCurrentUTCTimeInISO } from 'src/utils/date-time-utilities';
import { arrayOfStringsToSelectDropdownOptions } from 'src/utils/generic-utilities';
import { currentBusinessGroupName, currentDataClassificationId } from '../businessGroupSelectors';
import { usefulLifeMappingValidationSchema } from './UsefulLifeMappingConfig';

const initializeUsefulLifeForm = (dataClassificationId: number): UsefulLifeMappingEntity => ({
  useful_life_id: null,
  data_classification_id: dataClassificationId,
  cost_center_code: '',
  expense_type: '',
  useful_life: null,
  is_active: true,
  created_at: '',
  created_by: '',
  updated_at: '',
  updated_by: ''
});

interface UsefulLifeFormProps {
  selectedUsefulLifeMappings: UsefulLifeMappingEntity[];
  closeTheSidePanel: () => void;
}

export const UsefulLifeForm: React.FC<UsefulLifeFormProps> = ({ selectedUsefulLifeMappings, closeTheSidePanel }) => {
  const { userAlias } = useAuth();

  const dispatch = useAppDispatch();
  const businessGroupName = useSelector(currentBusinessGroupName);
  const dataClassificationId = useSelector(currentDataClassificationId);
  const { userCostCentersForCurrentBusinessGroup: userCostCenters } = useSelector((state: RootState) => state.xptAccessAndAuthorizationStore)!;

  const { usefulLifeMappings, usefulLifeStatus, expenseTypeStatus } = useSelector((state: RootState) => state.xPTMappingStore);
  const expenseTypeMappings = useSelector(selectActiveExpenseTypeMappings);

  const { masterCostCenterDropdownValuesForAllBusinessGroups } = useSelector((state: RootState) => state.corpSegmentsStore);

  const [costCenterList, setCostCenterList] = useState<SelectProps.Options>([]);
  const [expenseTypeList, setExpenseTypeList] = useState<SelectProps.Options>([]);

  const [isNewUsefulLife, setIsNewUsefulLife] = useState<boolean>(true);
  const [formInitializing, setFormInitializing] = useState<boolean>(true);
  const [usefulLifeForm, setUsefulLifeForm] = useState<UsefulLifeMappingEntity>(initializeUsefulLifeForm(dataClassificationId));
  const usefulLifeFormRef = useRef<FormikProps<UsefulLifeMappingEntity>>(null);

  const [submissionStatus, setSubmissionStatus] = useState<StatusIndicatorProps.Type>('stopped');
  const [submissionStatusMessage, setSubmissionStatusMessage] = useState<string>('');

  useEffect(() => {
    const costCentersOfCurrentBusinessGroup: string[] = masterCostCenterDropdownValuesForAllBusinessGroups
      .filter((ccForCurrentBG) => ccForCurrentBG.dataClassificationId === dataClassificationId)
      .flatMap((item) => item.costCenters);
    const costCentersApplicableToCurrentUser = costCentersOfCurrentBusinessGroup.filter((cc) => userCostCenters.includes(cc));
    setCostCenterList(arrayOfStringsToSelectDropdownOptions(costCentersApplicableToCurrentUser));
  }, [masterCostCenterDropdownValuesForAllBusinessGroups]);

  useEffect(() => {
    if (expenseTypeStatus === Status.NotInitiated) {
      dispatch(fetchExpenseTypeMappings());
    }
  }, [expenseTypeStatus]);

  useEffect(() => {
    if (expenseTypeStatus === Status.Completed) {
      try {
        let expenseTypesOfCurrentBusinessGroup = expenseTypeMappings
          .filter(
            (expenseType) =>
              expenseType.is_active && expenseType.budget_type === 'CAPEX' && expenseType.data_classification_id === dataClassificationId
          )
          .map((expenseType) => expenseType.expense_type);

        if (!expenseTypesOfCurrentBusinessGroup.includes('N/A')) {
          expenseTypesOfCurrentBusinessGroup.unshift('N/A');
        }

        setExpenseTypeList(arrayOfStringsToSelectDropdownOptions(expenseTypesOfCurrentBusinessGroup));
      } catch (error: any) {
        setExpenseTypeList([]);
      }
    }
  }, [expenseTypeStatus, expenseTypeMappings]);

  useEffect(() => {
    if (dataClassificationId && usefulLifeStatus === Status.Completed) {
      setFormInitializing(true);

      if (selectedUsefulLifeMappings.length > 0) {
        setIsNewUsefulLife(false);
        setUsefulLifeForm(selectedUsefulLifeMappings[0]);
      } else {
        setIsNewUsefulLife(true);
        setUsefulLifeForm(initializeUsefulLifeForm(dataClassificationId));
      }

      setFormInitializing(false);
    }
  }, [usefulLifeStatus, dataClassificationId, selectedUsefulLifeMappings]);

  const handleSubmit = async (formValues: UsefulLifeMappingEntity, formikHelpers: FormikHelpers<UsefulLifeMappingEntity>) => {
    const mutationObject: UsefulLifeMappingEntity = {
      ...formValues,
      data_classification_id: dataClassificationId,
      created_at: isNewUsefulLife ? getCurrentUTCTimeInISO() : formValues.created_at,
      created_by: isNewUsefulLife ? userAlias : formValues.created_by,
      updated_at: getCurrentUTCTimeInISO(),
      updated_by: userAlias
    };

    try {
      formikHelpers.setSubmitting(true);
      setSubmissionStatus('in-progress');
      setSubmissionStatusMessage(`Request in progress...`);

      logUserClick('submit button', 'UsefulLifeMapping', businessGroupName);

      await updateUsefulLifeMappings([mutationObject]);
      setSubmissionStatusMessage(`Useful Life ${isNewUsefulLife ? 'created' : 'updated'} successfully.`);
      setSubmissionStatus('success');
      logger.info(`Useful Life ${isNewUsefulLife ? 'created' : 'updated'} successfully.`);
      setTimeout(() => {
        formikHelpers.setSubmitting(false);
        reloadUsefulLifeTypes();
        closeTheSidePanel();
      }, 1000);

      formikHelpers.setSubmitting(false);
    } catch (error: any) {
      formikHelpers.setSubmitting(false);
      logger.error('Error submitting form', error);
      sendSNSNotification(
        `Useful Life ${isNewUsefulLife ? 'Creation' : 'Update'} Failed`,
        `Failed to ${isNewUsefulLife ? 'create' : 'update'} Useful Life for cost center '${
          mutationObject.cost_center_code
        }' by user ${userAlias} in ${businessGroupName}`,
        'error'
      );
    }
  };

  const reloadUsefulLifeTypes = () => {
    dispatch(fetchUsefulLifeDetails());
  };

  return (
    <SplitPanel hidePreferencesButton header={isNewUsefulLife ? 'Create Useful Life' : 'Update Useful Life'} i18nStrings={splitPanelI18nStrings}>
      {formInitializing && <LoadingSpinner />}
      {!formInitializing && (
        <>
          <Formik<UsefulLifeMappingEntity>
            innerRef={usefulLifeFormRef}
            enableReinitialize={true}
            initialValues={usefulLifeForm}
            validationSchema={usefulLifeMappingValidationSchema(usefulLifeMappings)}
            validateOnChange={true}
            validateOnBlur={true}
            onSubmit={handleSubmit}
          >
            {({ errors, handleBlur, handleSubmit, touched, dirty, isValid, setFieldTouched, values, setFieldValue, isSubmitting }) => {
              return (
                <Form>
                  <SpaceBetween size="m" direction="vertical">
                    <FormField label="Cost Center" errorText={touched.cost_center_code && errors.cost_center_code}>
                      <Select
                        expandToViewport
                        options={costCenterList}
                        selectedOption={{ label: values.cost_center_code, value: values.cost_center_code }}
                        onChange={({ detail }) => {
                          setFieldValue('cost_center_code', detail.selectedOption.value);
                        }}
                        onBlur={() => setFieldTouched('cost_center_code', true, true)}
                        filteringType="auto"
                      />
                    </FormField>

                    <FormField
                      label="Expense Type"
                      description={`Expense Types with 'CAPEX' Budget Types`}
                      errorText={touched.expense_type && errors.expense_type}
                    >
                      <Select
                        expandToViewport
                        options={expenseTypeList}
                        selectedOption={{ label: values.expense_type, value: values.expense_type }}
                        onChange={({ detail }) => {
                          setFieldValue('expense_type', detail.selectedOption.value);
                        }}
                        onBlur={() => setFieldTouched('expense_type', true, true)}
                        filteringType="auto"
                      />
                    </FormField>

                    <FormField label="Useful Life" errorText={touched.useful_life && errors.useful_life}>
                      <Input
                        type="number"
                        inputMode="numeric"
                        value={values.useful_life ? `${values.useful_life}` : ''}
                        onChange={({ detail }) => {
                          setFieldValue('useful_life', detail.value);
                        }}
                        onBlur={() => {
                          setFieldTouched('useful_life', true, true);
                        }}
                      />
                    </FormField>

                    <FormField label="Status" errorText={touched.is_active && errors.is_active}>
                      <Toggle
                        checked={values.is_active}
                        onChange={({ detail }) => setFieldValue('is_active', detail.checked)}
                        onBlur={() => setFieldTouched('is_active', true, true)}
                      >
                        {values.is_active ? 'Active' : 'Inactive'}
                      </Toggle>
                    </FormField>

                    <Button variant="primary" disabled={!dirty || isSubmitting || !isValid} onClick={() => handleSubmit()} loading={isSubmitting}>
                      {isNewUsefulLife ? 'Create Useful Life' : 'Update Useful Life'}
                    </Button>

                    {submissionStatus !== 'stopped' && <StatusIndicator type={submissionStatus}>{submissionStatusMessage}</StatusIndicator>}
                  </SpaceBetween>
                </Form>
              );
            }}
          </Formik>
        </>
      )}
    </SplitPanel>
  );
};
