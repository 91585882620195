import { Box, HelpPanel, SpaceBetween } from '@amzn/awsui-components-react';
import React from 'react';

export const XptActualsReportInfoPanel: React.FC = () => {
  return (
    <HelpPanel header={<h2>Actuals Report</h2>}>
      <SpaceBetween size="l">
        <Box variant="p">
          The Actuals Report provides a comprehensive view of your actuals data, allowing for in-depth analysis and customization to meet your
          specific needs.
        </Box>

        <SpaceBetween size="xs">
          <Box variant="h4">Key Features</Box>
          <ul>
            <li>Customizable views for tailored analysis</li>
            <li>View persistence for efficient recurring use</li>
            <li>Data export functionality for external processing</li>
            <li>Advanced pivot capabilities for multi-dimensional analysis</li>
          </ul>
        </SpaceBetween>

        <SpaceBetween size="s">
          <Box variant="h4">Using the Pivot Feature</Box>
          <Box variant="p">
            The pivot feature enables you to analyze actuals data at a monthly level, grouped by Purchase Order (PO) number. Follow these steps to
            utilize this powerful tool:
          </Box>
          <ol>
            <li>Locate and expand the "Columns" section in the right-side panel</li>
            <li>Activate the "Pivot Mode" option</li>
            <li>In the columns list, right-click on "PO #" and select "Group by PO#"</li>
            <li>To save this view for future use, click "Save current view" in the top right corner of the report</li>
          </ol>
        </SpaceBetween>
      </SpaceBetween>
    </HelpPanel>
  );
};
