import {
  CellClassParams,
  ColDef,
  ColGroupDef,
  ExcelStyle,
  ICellRendererParams,
  IRichCellEditorParams,
  ValueFormatterParams,
  ValueParserParams
} from 'ag-grid-community';
import { eAgGridThemes } from 'src/constants/generic-constants';
import { POTaggingColumnsInfo } from 'src/models/POTaggingModel';
import { CellDataType, cellValueParser, poTaggingCurrencyFormatter } from 'src/utils/ag-grid-utils';
import { customLineItemIdComparator } from '../business-group/forecast-template/forecast-utils/ForecastTemplateUtils';

/**
 * Gets the header information for PO tagging data.
 *
 * @returns {POTaggingColumnsInfo} - The header information for PO tagging data.
 */
export const getPOTaggingHeaderInfo = (): POTaggingColumnsInfo => {
  return {
    idField: 'actuals_item_id',
    poTaggingFileHeader: [
      { field: 'actuals_item_id', headerName: 'Actual Item ID', dataType: 'number', isNullable: false },
      { field: 'xpt_line_item_id', headerName: 'Line Item ID' },
      { field: 'po_number', headerName: 'PO #' },
      { field: 'po_line_number', headerName: 'PO Line #', dataType: 'number' },
      { field: 'entered_amount_usd', headerName: 'Monthly Actual Spent (USD)', dataType: 'number' },
      { field: 'ytd_amt', headerName: 'YTD Actual Spent (USD)', isNullable: true, dataType: 'number' },
      { field: 'po_line_approved_amt_usd', headerName: 'PO Line Approved Amount (USD)', dataType: 'number' },
      { field: 'po_header_approved_amt_usd', headerName: 'PO Header Approved Amount (USD)', dataType: 'number' },
      { field: 'po_need_by_date', headerName: 'PO Need By Date' },
      { field: 'po_requestor', headerName: 'PO Requestor' },
      { field: 'po_line_description', headerName: 'Line Description' },
      { field: 'status', headerName: 'Status' },
      { field: 'period_name', headerName: 'Month', isNullable: false },
      { field: 'cost_center_code', headerName: 'Cost Center Code', isNullable: false },
      { field: 'cost_center_description', headerName: 'Cost Center Description' },
      { field: 'account_code', headerName: 'Account Code' },
      { field: 'account_description', headerName: 'Account Description' },
      { field: 'product_code', headerName: 'Product Code' },
      { field: 'product_description', headerName: 'Product Description' },
      { field: 'project_code', headerName: 'Project Code' },
      { field: 'project_description', headerName: 'Project Description' },
      { field: 'channel_code', headerName: 'Channel Code' },
      { field: 'channel_description', headerName: 'Channel Description' },
      { field: 'company_code', headerName: 'Company Code' },
      { field: 'company_description', headerName: 'Company Description' },
      { field: 'location_code', headerName: 'Location Code' },
      { field: 'location_description', headerName: 'Location Description' },
      { field: 'je_category', headerName: 'JE Category' }
    ],
    filterFields: ['period_name', 'cost_center_code'],
    idFields: ['actuals_item_id', 'xpt_line_item_id']
  };
};


/**
 * Generates column definitions for ActualsTaggingEntity for AgGrid
 * @param lineItemsIds - List of line item IDs for the dropdown in xpt_line_item_id column
 * @returns {Promise<ColDef[]>}
 */
export const poTaggingColumnGenerator = async (lineItemsIds: number[], themeClassName: eAgGridThemes): Promise<ColDef[]> => {
  const columnDefs: ColDef[] = [
    {
      headerName: 'Actual Item ID',
      field: 'actuals_item_id',
      width: 130,
      minWidth: 130,
      hide: true
    },
    {
      headerName: 'Line Item ID',
      wrapHeaderText: true,
      field: 'xpt_line_item_id',
      cellClass: ['number-field'],
      headerClass: ['required-cell'],
      editable: true,
      cellDataType: CellDataType.NUMBER,
      comparator: (valueA: string, valueB: string, nodeA: any, nodeB: any, isDescending: boolean) => {
        return customLineItemIdComparator(valueA, valueB, nodeA, nodeB);
      },
      width: 155,
      minWidth: 155,
      cellEditor: 'agRichSelectCellEditor',
      cellEditorParams: {
        values: lineItemsIds,
        valueListMaxHeight: 300,
        allowTyping: true,
        searchType: 'matchAny',
        filterList: true,
        highlightMatch: true
      } as IRichCellEditorParams,
      cellEditorPopup: true,
      cellEditorPopupPosition: 'over',
      hide: false,
      lockPinned: true,
      pinned: 'left',
      lockVisible: true,
      cellClassRules: {
        'bold-text': (params: CellClassParams) => params.node.footer || false,
        'editable-cell': (params: CellClassParams) => !params.node.footer && themeClassName === eAgGridThemes.Quartz,
        'editable-cell-dark-theme': (params: CellClassParams) => !params.node.footer && themeClassName !== eAgGridThemes.Quartz
      },
      cellRenderer: (params: ICellRendererParams) => {
        if (params.node.footer) {
          return 'Total';
        }
        return params.value;
      }
    },
    {
      headerName: 'PO #',
      field: 'po_number',
      hide: false,
      width: 130,
      minWidth: 130,
      lockPinned: true,
      pinned: 'left',
      lockVisible: true,
      sort: 'asc', // Default sort direction
      sortIndex: 0 // Sort order priority
    },
    {
      headerName: 'PO Line #',
      wrapHeaderText: true,
      field: 'po_line_number',
      hide: false,
      width: 130,
      minWidth: 130,
      sort: 'asc', // Default sort direction
      sortIndex: 1 // Sort order priority
    },
    {
      headerName: 'Cost Center Code',
      wrapHeaderText: true,
      field: 'cost_center_code',
      cellClass: ['text-field'],
      hide: true,
      width: 120,
      minWidth: 120
    },
    {
      headerName: 'Cost Center Description',
      wrapHeaderText: true,
      field: 'cost_center_description',
      cellClass: ['text-field'],
      hide: true,
      width: 170,
      minWidth: 170
    },
    {
      headerName: 'PO Amount',
      marryChildren: true,
      children: [
        {
          headerName: 'Monthly Actual Spent (USD)',
          wrapHeaderText: true,
          field: 'entered_amount_usd',
          valueParser: (params: ValueParserParams) => cellValueParser(params.newValue),
          valueFormatter: (params: ValueFormatterParams) => poTaggingCurrencyFormatter(params.value),
          hide: false,
          columnGroupShow: 'closed',
          width: 170,
          minWidth: 170,
          aggFunc: 'sum',
          cellClassRules: {
            'bold-text': (params: CellClassParams) => params.node.footer
          }
        },
        {
          headerName: 'Monthly Actual Spent (USD)',
          wrapHeaderText: true,
          field: 'entered_amount_usd',
          valueParser: (params: ValueParserParams) => cellValueParser(params.newValue),
          valueFormatter: (params: ValueFormatterParams) => poTaggingCurrencyFormatter(params.value),
          hide: false,
          columnGroupShow: 'open',
          width: 170,
          minWidth: 170,
          aggFunc: 'sum',
          cellClassRules: {
            'bold-text': (params: CellClassParams) => params.node.footer
          }
        },
        {
          headerName: 'YTD Actual Spent (USD)',
          wrapHeaderText: true,
          field: 'ytd_amt',
          valueParser: (params: ValueParserParams) => cellValueParser(params.newValue),
          valueFormatter: (params: ValueFormatterParams) => poTaggingCurrencyFormatter(params.value),
          hide: false,
          columnGroupShow: 'open',
          width: 150,
          minWidth: 150,
          aggFunc: 'sum',
          cellClassRules: {
            'bold-text': (params: CellClassParams) => params.node.footer
          }
        },
        {
          headerName: 'PO Line Approved Amount (USD)',
          wrapHeaderText: true,
          field: 'po_line_approved_amt_usd',
          valueParser: (params: ValueParserParams) => cellValueParser(params.newValue),
          valueFormatter: (params: ValueFormatterParams) => poTaggingCurrencyFormatter(params.value),
          hide: false,
          columnGroupShow: 'open',
          width: 150,
          minWidth: 150,
          cellClassRules: {
            'bold-text': (params: CellClassParams) => params.node.footer
          }
        },
        {
          headerName: 'PO Header Approved Amount (USD)',
          wrapHeaderText: true,
          field: 'po_header_approved_amt_usd',
          valueParser: (params: ValueParserParams) => cellValueParser(params.newValue),
          valueFormatter: (params: ValueFormatterParams) => poTaggingCurrencyFormatter(params.value),
          hide: false,
          columnGroupShow: 'open',
          width: 150,
          minWidth: 150,
          cellClassRules: {
            'bold-text': (params: CellClassParams) => params.node.footer
          }
        },
        {
          headerName: 'PO Need By Date',
          wrapHeaderText: true,
          field: 'po_need_by_date',
          hide: false,
          columnGroupShow: 'open',
          width: 130,
          minWidth: 130
        },
        {
          headerName: 'PO Requestor',
          wrapHeaderText: true,
          field: 'po_requestor',
          hide: false,
          columnGroupShow: 'open',
          width: 130,
          minWidth: 130
        }
      ] as ColDef[]
    } as ColGroupDef,
    {
      headerName: 'Line Description',
      wrapHeaderText: true,
      field: 'po_line_description',
      hide: false,
      width: 150,
      suppressSizeToFit: true
    },
    {
      headerName: 'Month',
      field: 'period_name',
      hide: true,
      width: 120,
      minWidth: 120
    },
    {
      headerName: 'Status',
      field: 'status',
      hide: false,
      width: 120,
      minWidth: 120
    },
    {
      headerName: 'Corp Segments',
      wrapHeaderText: true,
      marryChildren: true,
      children: [
        {
          headerName: 'Account Code',
          wrapHeaderText: true,
          field: 'account_code',
          cellClass: ['text-field'],
          hide: false,
          columnGroupShow: 'closed',
          width: 120,
          minWidth: 120
        },
        {
          headerName: 'Account Description',
          wrapHeaderText: true,
          field: 'account_description',
          cellClass: ['text-field'],
          hide: false,
          columnGroupShow: 'closed',
          width: 170,
          minWidth: 170
        },
        {
          headerName: 'Account Code',
          wrapHeaderText: true,
          field: 'account_code',
          cellClass: ['text-field'],
          hide: false,
          columnGroupShow: 'open',
          width: 120,
          minWidth: 120
        },
        {
          headerName: 'Account Description',
          wrapHeaderText: true,
          field: 'account_description',
          cellClass: ['text-field'],
          hide: false,
          columnGroupShow: 'open',
          width: 170,
          minWidth: 170
        },
        {
          headerName: 'Product Code',
          wrapHeaderText: true,
          field: 'product_code',
          cellClass: ['text-field'],
          hide: false,
          columnGroupShow: 'open',
          width: 120,
          minWidth: 120
        },
        {
          headerName: 'Product Description',
          wrapHeaderText: true,
          field: 'product_description',
          cellClass: ['text-field'],
          hide: false,
          columnGroupShow: 'open',
          width: 170,
          minWidth: 170
        },
        {
          headerName: 'Project Code',
          wrapHeaderText: true,
          field: 'project_code',
          cellClass: ['text-field'],
          hide: false,
          columnGroupShow: 'open',
          width: 120,
          minWidth: 120
        },
        {
          headerName: 'Project Description',
          wrapHeaderText: true,
          field: 'project_description',
          cellClass: ['text-field'],
          hide: false,
          columnGroupShow: 'open',
          width: 170,
          minWidth: 170
        },
        {
          headerName: 'Channel Code',
          wrapHeaderText: true,
          field: 'channel_code',
          cellClass: ['text-field'],
          hide: false,
          columnGroupShow: 'open',
          width: 120,
          minWidth: 120
        },
        {
          headerName: 'Channel Description',
          wrapHeaderText: true,
          field: 'channel_description',
          cellClass: ['text-field'],
          hide: false,
          columnGroupShow: 'open',
          width: 170,
          minWidth: 170
        },
        {
          headerName: 'Company Code',
          wrapHeaderText: true,
          field: 'company_code',
          cellClass: ['text-field'],
          hide: false,
          columnGroupShow: 'open',
          width: 120,
          minWidth: 120
        },
        {
          headerName: 'Company Description',
          wrapHeaderText: true,
          field: 'company_description',
          cellClass: ['text-field'],
          hide: false,
          columnGroupShow: 'open',
          width: 170,
          minWidth: 170
        },
        {
          headerName: 'Location Code',
          wrapHeaderText: true,
          field: 'location_code',
          cellClass: ['text-field'],
          hide: false,
          columnGroupShow: 'open',
          width: 120,
          minWidth: 120
        },
        {
          headerName: 'Location Description',
          wrapHeaderText: true,
          field: 'location_description',
          cellClass: ['text-field'],
          hide: false,
          columnGroupShow: 'open',
          width: 170,
          minWidth: 170
        }
      ] as ColDef[]
    } as ColGroupDef,
    {
      headerName: 'JE Category',
      wrapHeaderText: true,
      field: 'je_category',
      cellClass: ['text-field'],
      hide: false
    },
    {
      headerName: 'Is Active',
      field: 'is_active',
      hide: true
    },
    {
      headerName: 'Created At',
      field: 'created_at',
      hide: true
    },
    {
      headerName: 'Created By',
      field: 'created_by',
      hide: true
    },
    {
      headerName: 'Updated At',
      field: 'updated_at',
      hide: true
    },
    {
      headerName: 'Updated By',
      field: 'updated_by',
      hide: true
    }
  ];

  return columnDefs;
};

export const poTaggingExcelStyles = [
  {
    id: 'editable-cell',
    interior: {
      color: '#FFFFED',
      pattern: 'Solid'
    }
  },
  {
    id: 'nan-cell',
    interior: {
      color: 'lightcoral' // Light coral color
    }
  },
  {
    id: 'text-field',
    dataType: 'String'
  },
  {
    id: 'number-field',
    dataType: 'Number'
  },
  {
    id: 'required-cell',
    dataType: 'String',
    font: {
      bold: true
    }
  }
] as ExcelStyle[];
