import { Box, Header, HelpPanel, SpaceBetween } from '@amzn/awsui-components-react';
import React from 'react';

export const BudgetOwnerTransferInfoPanel: React.FC = () => {
  return (
    <HelpPanel header={<Header variant="h2">Budget Owner Transfer</Header>}>
      <SpaceBetween size="l">
        <Box variant="p">
          This page is accessible only to users with the <strong>Finance Admin</strong> or <strong>Finance Owner</strong> roles.
        </Box>

        <Box variant="p">
          This feature allows administrators to transfer the ownership of budgets from one user to another. If budgets exist at multiple cost centers,
          the administrator has the option to transfer budgets at one or multiple cost centers.
        </Box>

        <SpaceBetween size="xs">
          <h3>Key Benefits</h3>
          <ul>
            <li>Maintain continuity during personnel changes</li>
            <li>Streamline access management processes</li>
            <li>Facilitate smooth transitions between team members</li>
          </ul>
        </SpaceBetween>

        <SpaceBetween size="xs">
          <h3>When to Use</h3>
          <p>Consider utilizing this feature in the following scenarios:</p>
          <ul>
            <li>When an employee departs from the company</li>
            <li>When a team member transitions to a new role or department</li>
            <li>During the reorganization of Cost Center responsibilities</li>
          </ul>
        </SpaceBetween>
      </SpaceBetween>
    </HelpPanel>
  );
};
