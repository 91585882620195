import { Box, Container, FormField, Multiselect, Select, SelectProps, SpaceBetween } from '@amzn/awsui-components-react';
import { isEqual } from 'lodash';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SELECT_ALL_OPTION, STAGES } from 'src/constants/generic-constants';
import { selectForecastPlanningCyclesOfCurrentBG } from 'src/features/planning-cycle/planningCycleSelector';
import { fromPlanningCycleSelectOption, toPlanningCycleSelectOption } from 'src/features/planning-cycle/planningCycleUtils';
import { getLocalStorageItem, setLocalStorageItem } from 'src/hooks/useLocalStorage';
import { CorpSegmentFilterSelection, ForecastTemplateCorpSegmentDropdowns } from 'src/models/ForecastModels';
import { AppDispatch, RootState } from 'src/store/store';
import { getMultiSelectPlaceHolderValue } from 'src/utils/generic-utilities';
import { currentBusinessGroupName, currentBusinessGroupShortDesc } from '../businessGroupSelectors';
import { validateCorpSegmentSelections } from './forecast-utils/ForecastTemplateUtils';
import { setCurrentCorpSegmentDropDownSelections, setCurrentPlanningCycle } from './redux/forecastTemplateSlice';
import { clearForecastTemplateCompleteData, fetchForecastTemplateCompleteData } from './redux/forecastTemplateThunks';
import { logUserClick } from 'src/analytics/KatalLogger';
import {
  forecastFilterPlanningCycleKey,
  getForecastPlanningCycleToBeSelected,
  getLocalStorageKeyForCorpSegments
} from './forecast-utils/ForecastTemplateFilterUtils';
import ENVIRONMENT_VARIABLES from 'src/constants/environment-variables';

const ForecastTemplateFilterSelection: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();

  const businessGroupName = useSelector(currentBusinessGroupName);
  const businessGroupShortDesc = useSelector(currentBusinessGroupShortDesc);
  const forecastPlanningCycles = useSelector(selectForecastPlanningCyclesOfCurrentBG);
  const {
    currentPlanningCycle,
    currentCorpSegmentDropDownSelections,
    forecastTemplateCorpSegmentDropdowns,
    forecastTemplateDataLoading,
    forecastDataSubmitting
  } = useSelector((state: RootState) => state.forecastTemplateStore);

  useEffect(() => {
    const storedValue = getLocalStorageItem<SelectProps.Option | null>(forecastFilterPlanningCycleKey(businessGroupShortDesc));

    const plToBeSelected = getForecastPlanningCycleToBeSelected(storedValue, forecastPlanningCycles);
    setLocalStorageItem(forecastFilterPlanningCycleKey(businessGroupShortDesc), toPlanningCycleSelectOption(plToBeSelected));
    dispatch(setCurrentPlanningCycle(plToBeSelected));

    return () => {
      dispatch(setCurrentPlanningCycle(null));
    };
  }, [businessGroupShortDesc, forecastPlanningCycles, forecastFilterPlanningCycleKey, dispatch]);

  useEffect(() => {
    const loadPlanningCycleDataFromS3 = async () => {
      if (currentPlanningCycle) {
        await dispatch(clearForecastTemplateCompleteData()).unwrap();
        dispatch(fetchForecastTemplateCompleteData(currentPlanningCycle));
      }
    };
    loadPlanningCycleDataFromS3();
  }, [currentPlanningCycle, dispatch]);

  useEffect(() => {
    if (currentPlanningCycle?.scenario_seq_id && forecastTemplateCorpSegmentDropdowns.length > 0) {
      const storedCorpSegmentSelections = getLocalStorageItem<CorpSegmentFilterSelection>(
        getLocalStorageKeyForCorpSegments(currentPlanningCycle.scenario_seq_id, businessGroupShortDesc)
      );

      if (storedCorpSegmentSelections) {
        const validatedSelections = validateCorpSegmentSelections(storedCorpSegmentSelections, forecastTemplateCorpSegmentDropdowns);
        dispatch(setCurrentCorpSegmentDropDownSelections(validatedSelections));
      } else {
        const newCorpSegmentSelections: CorpSegmentFilterSelection = {};
        forecastTemplateCorpSegmentDropdowns.forEach((segment) => {
          newCorpSegmentSelections[segment.displayName] = segment.fieldDropdownOptions;
        });
        setLocalStorageItem(
          getLocalStorageKeyForCorpSegments(currentPlanningCycle.scenario_seq_id!, businessGroupShortDesc),
          newCorpSegmentSelections
        );
        dispatch(setCurrentCorpSegmentDropDownSelections(newCorpSegmentSelections));
      }
    }

    return () => {
      dispatch(setCurrentCorpSegmentDropDownSelections(null));
    };

    // Note: currentPlanningCycle is intentionally omitted from the dependency array.
    // Including it would cause this effect to run immediately after changing the planning cycle,
    // potentially using outdated forecastTemplateCorpSegmentDropdowns data.
    // The effect will run when forecastTemplateCorpSegmentDropdowns is updated, which should happen after the planning cycle change.
  }, [forecastTemplateCorpSegmentDropdowns, dispatch]);

  const onPlanningCycleChange = (selectedOption: SelectProps.Option) => {
    setLocalStorageItem(forecastFilterPlanningCycleKey(businessGroupShortDesc), selectedOption);
    const selectedPlanningCycle = fromPlanningCycleSelectOption(selectedOption, forecastPlanningCycles);
    dispatch(setCurrentPlanningCycle(selectedPlanningCycle));
    logUserClick('Planning Cycle Filter Changed', `Forecast Input`, businessGroupName);
  };

  const selectedCorpSegmentOption = (filter: ForecastTemplateCorpSegmentDropdowns): SelectProps.Options => {
    return currentCorpSegmentDropDownSelections?.[filter.displayName] || [];
  };

  const handleCorpSegmentChange = (corpSegmentDisplayName: string, selectedOptions: SelectProps.Options) => {
    const newCorpSegmentSelections: CorpSegmentFilterSelection = {
      ...currentCorpSegmentDropDownSelections,
      [corpSegmentDisplayName]: selectedOptions
    };

    if (currentPlanningCycle?.scenario_seq_id && !isEqual(currentCorpSegmentDropDownSelections?.[corpSegmentDisplayName], selectedOptions)) {
      setLocalStorageItem(getLocalStorageKeyForCorpSegments(currentPlanningCycle.scenario_seq_id, businessGroupShortDesc), newCorpSegmentSelections);
      dispatch(setCurrentCorpSegmentDropDownSelections(newCorpSegmentSelections));
    }
    logUserClick(`Corp Segment Changed: ${corpSegmentDisplayName}`, 'Forecast Input', businessGroupName);
  };

  return (
    <Container disableHeaderPaddings disableContentPaddings className="transform">
      <Box padding={'m'}>
        <SpaceBetween size="m" direction="horizontal">
          <FormField
            label={`Planning Cycle ${ENVIRONMENT_VARIABLES.env.Stage === STAGES.DEV ? `- ${currentPlanningCycle?.scenario_seq_id}` : ``}`}
            className="width-20-rem"
          >
            <Select
              expandToViewport
              filteringType="auto"
              placeholder="Planning Cycles"
              options={forecastPlanningCycles.map(toPlanningCycleSelectOption)}
              selectedOption={toPlanningCycleSelectOption(currentPlanningCycle)}
              onChange={({ detail }) => onPlanningCycleChange(detail.selectedOption)}
              empty={'No active planning cycles'}
            />
          </FormField>

          {forecastTemplateCorpSegmentDropdowns
            .filter((forecastTemplateCorpSegmentDropdown) => forecastTemplateCorpSegmentDropdown.isRequired)
            .map((forecastTemplateCorpSegmentDropdown) => {
              const isMultiSelect = forecastTemplateCorpSegmentDropdown.isMultiSelect;
              return (
                <FormField
                  label={forecastTemplateCorpSegmentDropdown.displayName}
                  className="width-20-rem"
                  key={forecastTemplateCorpSegmentDropdown.key}
                >
                  {isMultiSelect ? (
                    <Multiselect
                      placeholder={getMultiSelectPlaceHolderValue(
                        selectedCorpSegmentOption(forecastTemplateCorpSegmentDropdown),
                        forecastTemplateCorpSegmentDropdown.displayName
                      )}
                      filteringType="auto"
                      options={[{ ...SELECT_ALL_OPTION, options: forecastTemplateCorpSegmentDropdown.fieldDropdownOptions }]}
                      selectedOptions={selectedCorpSegmentOption(forecastTemplateCorpSegmentDropdown)}
                      onChange={({ detail }) => handleCorpSegmentChange(forecastTemplateCorpSegmentDropdown.displayName, detail.selectedOptions)}
                      disabled={forecastTemplateDataLoading || forecastDataSubmitting}
                      virtualScroll={forecastTemplateCorpSegmentDropdown.fieldDropdownOptions.length > 500}
                      hideTokens
                      expandToViewport
                      empty="No data"
                    />
                  ) : (
                    <Select
                      placeholder={getMultiSelectPlaceHolderValue(
                        selectedCorpSegmentOption(forecastTemplateCorpSegmentDropdown),
                        forecastTemplateCorpSegmentDropdown.displayName
                      )}
                      options={forecastTemplateCorpSegmentDropdown.fieldDropdownOptions}
                      selectedOption={selectedCorpSegmentOption(forecastTemplateCorpSegmentDropdown)[0]}
                      onChange={({ detail }) => handleCorpSegmentChange(forecastTemplateCorpSegmentDropdown.displayName, [detail.selectedOption])}
                      disabled={forecastTemplateDataLoading || forecastDataSubmitting}
                      virtualScroll={forecastTemplateCorpSegmentDropdown.fieldDropdownOptions.length > 500}
                      expandToViewport
                      filteringType="auto"
                      empty="No data"
                    />
                  )}
                </FormField>
              );
            })}
        </SpaceBetween>
      </Box>
    </Container>
  );
};

export default ForecastTemplateFilterSelection;
