import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { logger } from 'src/analytics/KatalLogger';
import { LoadingSpinner } from 'src/components/common/LoadingSpinner';
import {
  fetchCurrentUserAuthorization,
  fetchUserAccessAndAuthorization
} from 'src/features/business-group/access-authorization/redux/userAuthorizationSlice';
import { fetchBusinessGroups } from 'src/features/business-group/businessGroupSlice';
import { fetchForecastPlanningCycles, fetchPlanningCycles, fetchScenarios, fetchSnapshots } from 'src/features/planning-cycle/planningCycleSlice';
import { fetchBusinessSegments, fetchCorpSegments, fetchCostCenterDropdownValuesOfAllBusinessGroups } from 'src/store/slices/corpSegmentSlice';
import { AppDispatch } from '../store/store';
import { useFlashMessage } from './AppBannerContext';
import { fetchXptMetadata } from './AppMetadataSlice';
import { useAuth } from './auth/AuthContextProvider';

interface AppInitializerProps {
  children: React.ReactNode;
}

const AppInitializer: React.FC<AppInitializerProps> = ({ children }) => {
  const { userAlias, isDev, isInitiallyDev } = useAuth();
  const [isInitialized, setIsInitialized] = useState<boolean>(false);
  const dispatch = useDispatch<AppDispatch>();
  const { addMessage } = useFlashMessage();

  useEffect(() => {
    const initializeApp = async () => {
      try {
        await Promise.all([dispatch(fetchXptMetadata())]);

        // Stage 1: Fetch users list with access, business groups
        const stage1Results = await Promise.all([
          dispatch(fetchUserAccessAndAuthorization({ userAlias, isInitiallyDev })),
          dispatch(fetchBusinessGroups())
        ]);

        // Check if any of the stage 1 actions were rejected
        const stage1Rejected = stage1Results.find((result) => result.meta.requestStatus === 'rejected');
        if (stage1Rejected) {
          throw new Error('Error during Stage 1 initialization.');
        }

        // Stage 2: Fetch corp segments, business segments (depends on stage 2)
        const stage2Results = await Promise.all([dispatch(fetchCorpSegments()), dispatch(fetchBusinessSegments())]);
        // Check if any of the stage 2 actions were rejected
        const stage2Rejected = stage2Results.find((result) => result.meta.requestStatus === 'rejected');
        if (stage2Rejected) {
          throw new Error('Error during Stage 2 initialization.');
        }

        // Stage 3: Fetch cost center dropdown values (depends on stage 2)
        const stage3Results = await Promise.all([dispatch(fetchCostCenterDropdownValuesOfAllBusinessGroups())]);

        // Check if any of the stage 3 actions were rejected
        const stage3Rejected = stage3Results.find((result) => result.meta.requestStatus === 'rejected');
        if (stage3Rejected) {
          throw new Error('Error during Stage 3 initialization.');
        }

        // Stage 4: Fetch current user authorization (depends on stage 3)
        const stage4Results = await Promise.all([dispatch(fetchCurrentUserAuthorization({ userAlias, isDev }))]);

        // Check if any of the stage 4 actions were rejected
        const stage4Rejected = stage4Results.find((result) => result.meta.requestStatus === 'rejected');
        if (stage4Rejected) {
          throw new Error('Error during Stage 4 initialization.');
        }

        await Promise.all([
          dispatch(fetchScenarios()),
          dispatch(fetchSnapshots()),
          dispatch(fetchPlanningCycles()),
          dispatch(fetchForecastPlanningCycles())
        ]);
      } catch (error: any) {
        logger.error('Failed to initialize the application:', error);
        addMessage({
          type: 'error',
          content:
            'We encountered an issue while initializing key application services. As a result, some features may not be available. Please try refreshing the page or contact support if the issue persists.'
        });
      } finally {
        setIsInitialized(true);
      }
    };

    initializeApp();
  }, [dispatch]);

  if (!isInitialized) {
    return <LoadingSpinner />;
  }

  return <>{children}</>;
};

export default AppInitializer;
