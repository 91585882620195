import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import { LoadingSpinner } from 'src/components/common/LoadingSpinner';
import { FINANCE_ADMIN } from 'src/constants/generic-constants';
import { Status } from 'src/models/AuthContextModels';
import { RootState } from 'src/store/store';
import { useAuth } from '../../app/auth/AuthContextProvider';

// Used for accessing "Admin Console" -> "Finance Admin Tools" in Admin Console.
export const XptFinanceAdminWrapper: React.FC = () => {
  const { currentUserAccessDetails, currentUserAccessLoadingStatus } = useSelector((state: RootState) => state.xptAccessAndAuthorizationStore);

  if (currentUserAccessLoadingStatus === Status.NotInitiated || currentUserAccessLoadingStatus === Status.Loading) {
    return <LoadingSpinner />;
  }

  // Check if user is a Finance Admin for at least 1 Business Group.
  const isFinanceAdmin = currentUserAccessDetails?.user_data_classifications.some((classification) => classification.user_type === FINANCE_ADMIN);

  // Navigate away if the user isn't a FINANCE_ADMIN for at least 1 business group.
  if (!isFinanceAdmin) {
    return <Navigate to="/" replace />;
  }

  // If the user is a FINANCE_ADMIN for at least 1 business group, render the child routes
  return <Outlet />;
};

// Used for accessing "Developer Tools" in Admin Console.
export const XPTDevOnlyWrapper: React.FC = () => {
  const { isDev } = useAuth();

  return isDev ? <Outlet /> : <Navigate to="/" replace />;
};
