import { Button, Flashbar, Header, Link, SpaceBetween } from '@amzn/awsui-components-react';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { XptAppLayout } from 'src/components/common/xpt-app-layout/XptAppLayout';
import XptTable from 'src/components/common/xpt-table/XptTable';
import { XPTBreadcrumbs } from 'src/components/common/XptBreadcrumb';
import { useFlashbar } from 'src/hooks/useFlashbar';
import { useAppDispatch } from 'src/store/useAppDispatch';

import { useAuth } from 'src/app/auth/AuthContextProvider';
import BusinessGroupSideNavigation from 'src/features/xpt-layout/XptBusinessGroupSideNavigation';
import { Status } from 'src/models/AuthContextModels';
import { UserAccessEntity } from 'src/models/XptUsersModel';
import { RootState } from 'src/store/store';
import { businessGroupBaseBreadcrumbs, currentBusinessGroupName, currentDataClassificationId } from '../businessGroupSelectors';
import { getAccessAuthBreadCrumbs, getUserAccessTableDefinitions, UserAccessTableConfig } from './AccessAuthorizationConfig';
import { AccessAuthorizationInfoPanel } from './AccessAuthorizationInfoPanel';
import UserAccessModificationForm from './AccessModificationForm';
import { selectXptUsers } from './redux/userAuthorizationSelectors';
import { fetchUserAccessAndAuthorization } from './redux/userAuthorizationSlice';
import { logUserClick } from 'src/analytics/KatalLogger';

export const AccessAuthorization: React.FC = () => {
  const appLayout = useRef<any>();
  const dispatch = useAppDispatch();
  const { flashbarItems, displayFlashMessage } = useFlashbar();
  const { userAlias, isInitiallyDev } = useAuth();

  const xptUsers = useSelector(selectXptUsers);
  const xptUsersLoadingStatus = useSelector((state: RootState) => state.xptAccessAndAuthorizationStore.xptUsersLoadingStatus);

  const businessGroupName = useSelector(currentBusinessGroupName);
  const businessGroupBaseBreadcrumb = useSelector(businessGroupBaseBreadcrumbs);
  const dataClassificationId = useSelector(currentDataClassificationId);

  const [filteredUserAccess, setFilteredUserAccess] = useState<UserAccessEntity[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<UserAccessEntity[]>([]);
  const [isSplitPanelOpen, setIsSplitPanelOpen] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<UserAccessEntity[]>([]);

  const [toolsOpen, setToolsOpen] = useState<boolean>(false);

  const reloadAccessAndAuth = () => {
    logUserClick(`Refreshed access and authorization`, 'Access & Authorization', businessGroupName);
    dispatch(fetchUserAccessAndAuthorization({ userAlias, isInitiallyDev }))
      .unwrap()
      .then(() => {
        setSelectedUsers([]);
      })
      .catch(() => {
        displayFlashMessage('Unable to load user data', 'error', false);
      });
  };

  useEffect(() => {
    if (xptUsersLoadingStatus === Status.Completed) {
      const filteredDetails = xptUsers.filter((xptUser) => xptUser.data_classification_id === dataClassificationId);
      setFilteredUserAccess(filteredDetails);
    }
  }, [xptUsersLoadingStatus, xptUsers, dataClassificationId]);

  // Handle "Add User" button click
  const addUser = () => {
    setSelectedUser([]);
    setIsSplitPanelOpen(true);
  };

  // Handle user selection to open in Split Panel
  useEffect(() => {
    if (selectedUsers.length === 1) {
      setSelectedUser(selectedUsers);
      setIsSplitPanelOpen(true);
    } else {
      setIsSplitPanelOpen(false);
    }
  }, [selectedUsers]);

  // Clear selected user when split panel is closed
  useEffect(() => {
    if (!isSplitPanelOpen) {
      setSelectedUser([]);
    }
  }, [isSplitPanelOpen]);

  const userAccessFormSubmitResponse = (isNewUser: boolean, userAlias: string, status: Status) => {
    if (status === Status.Completed) {
      displayFlashMessage(`${userAlias} ${isNewUser ? 'created' : 'updated'} successfully`, 'success', true);
      setIsSplitPanelOpen(false);
      reloadAccessAndAuth();
    } else {
      displayFlashMessage('Unable to update records', 'error', true);
    }
  };

  const AccessAuthorizationTableHeader = () => (
    <Header
      actions={
        <SpaceBetween size="l" direction="horizontal">
          <Button iconName="refresh" onClick={reloadAccessAndAuth}></Button>
          <Button iconName="add-plus" onClick={addUser}>{`Add User`}</Button>
        </SpaceBetween>
      }
      info={
        <Link variant="info" onFollow={() => setToolsOpen(true)}>
          Info
        </Link>
      }
    >
      {'Access & Authorization'}
    </Header>
  );

  return (
    <XptAppLayout
      ref={appLayout}
      headerSelector="#h"
      navigation={<BusinessGroupSideNavigation />}
      breadcrumbs={<XPTBreadcrumbs items={getAccessAuthBreadCrumbs(businessGroupBaseBreadcrumb, businessGroupName)} />}
      notifications={<Flashbar items={flashbarItems} stackItems />}
      stickyNotifications={true}
      maxContentWidth={Number.MAX_VALUE}
      contentType={'table'}
      content={
        <div className="xpt-app-layout-content">
          <XptTable
            xptTableVariant="full-page"
            loadingStatus={xptUsersLoadingStatus === Status.Loading}
            entityName={'Access & Authorization'}
            xptTableHeader={<AccessAuthorizationTableHeader />}
            allItems={filteredUserAccess}
            allColumns={UserAccessTableConfig}
            columnDefinitions={getUserAccessTableDefinitions()}
            selectionType="single"
            selectedItems={selectedUsers}
            setSelectedItems={async (items) => setSelectedUsers(items)}
            page={'Access & Authorization'}
            mainPage={businessGroupName}
          />
        </div>
      }
      splitPanelSize={320}
      onSplitPanelResize={() => {}}
      splitPanelPreferences={{ position: 'side' }}
      onSplitPanelPreferencesChange={() => {}}
      onSplitPanelToggle={({ detail }) => {
        setIsSplitPanelOpen(detail.open);
      }}
      splitPanelOpen={isSplitPanelOpen}
      splitPanel={<UserAccessModificationForm selectedUser={selectedUser} userAccessFormSubmitResponse={userAccessFormSubmitResponse} />}
      tools={<AccessAuthorizationInfoPanel />}
      toolsOpen={toolsOpen}
      onToolsChange={({ detail }) => setToolsOpen(detail.open)}
    />
  );
};
