import { HelpPanel, SpaceBetween, Box, Link, Alert } from '@amzn/awsui-components-react';
import React from 'react';

export const SnapshotInfoPanel: React.FC = () => {
  return (
    <HelpPanel header={<h2>Snapshot Management</h2>}>
      <SpaceBetween size="l">
        <Box variant="p">
          This page is accessible only to users with the <strong>Finance Admin</strong> or <strong>Finance Owner</strong> roles.
        </Box>

        <Box variant="p">
          The Snapshot feature captures the current state of the selected Planning Cycle, preserving all available data at the moment of creation.
        </Box>

        <SpaceBetween size="m">
          <h3>Creating a Snapshot</h3>
          <Box variant="p">When creating a snapshot:</Box>
          <ul>
            <li>
              <strong>Snapshot Name:</strong> It's recommended to retain the default name. If modification is necessary, consider appending a suffix.
            </li>
            <li>
              Use <strong>Note</strong> field to add any relevant additional details or context for the snapshot.
            </li>
          </ul>
        </SpaceBetween>

        <Alert type="info" header="Naming Convention">
          Maintaining a consistent naming convention helps in easy identification and management of snapshots.
        </Alert>

        <SpaceBetween size="m">
          <h3>Accessing Snapshot Data</h3>
          <Box variant="p">You can view and analyze snapshot data in the following sections:</Box>
          <ul>
            <li>
              <strong>Snapshot Data:</strong> You can view the Snapshot data in the <strong>Reports</strong> &rarr; <strong>Forecast</strong> section.
            </li>
            <li>
              <strong>Data Comparison:</strong> For comparative analysis, go to the <strong>Reports</strong> &rarr; <strong>Variance</strong> section.
            </li>
          </ul>
        </SpaceBetween>

        <Box variant="p"></Box>
      </SpaceBetween>
    </HelpPanel>
  );
};
