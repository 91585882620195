import { Alert } from '@amzn/awsui-components-react';
import { ColDef } from 'ag-grid-community';
import { useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useSelector } from 'react-redux';
import { useAuth } from 'src/app/auth/AuthContextProvider';
import { ErrorFallback } from 'src/components/common/ErrorFallback';
import { LoadingSpinner } from 'src/components/common/LoadingSpinner';
import { Status } from 'src/models/AuthContextModels';
import { ForecastGridRowData } from 'src/models/ForecastModels';
import { selectActiveExpenseTypesForCurrentGroup } from 'src/store/selectors/xPTMapperSelector';
import { RootState } from 'src/store/store';
import { dateTimeComparator } from 'src/utils/date-time-utilities';
import { isEmptyObject } from 'src/utils/generic-utilities';
import { currentBusinessGroup, currentDataClassificationId } from '../businessGroupSelectors';
import { useForecastTemplateContext } from './ForecastTemplateContext';
import { ForecastTemplateGridTable } from './ForecastTemplateGridTable';
import { generateColumnDefinitions } from './forecast-utils/ForecastColumnGenerator';
import { convertToLabelArrays, filterRowDataWithAllMatches } from './forecast-utils/ForecastTemplateUtils';
import useLockStatus from './hooks/useLockStatus';

const ForecastTemplateGrid: React.FC = () => {
  const { userAlias } = useAuth();
  const { clearSpecificFlashMessage, notificationMessage } = useForecastTemplateContext();
  const themeClassName = useSelector((state: RootState) => state.xptAppMetadataStore.themeClassName);

  const dataClassificationId = useSelector(currentDataClassificationId);

  const { userAccessForCurrentBusinessGroup, xptUsers, userCostCentersForCurrentBusinessGroup } = useSelector(
    (state: RootState) => state.xptAccessAndAuthorizationStore
  );
  const xptUsersOfCurrentDataClassification = xptUsers.filter((user) => user.is_active && user.data_classification_id === dataClassificationId);

  const isFinanceAdminOrFinanceOwner = userAccessForCurrentBusinessGroup?.isFinanceAdminOrFinanceOwner || false;
  const isBudgetLeader = userAccessForCurrentBusinessGroup?.isBudgetLeader || false;

  const businessGroup = useSelector(currentBusinessGroup);
  const currentPlanningCycle = useSelector((state: RootState) => state.forecastTemplateStore.currentPlanningCycle);
  const currentCorpSegmentDropDownSelections = useSelector((state: RootState) => state.forecastTemplateStore.currentCorpSegmentDropDownSelections);

  const { isCycleLocked } = useLockStatus(currentPlanningCycle, isFinanceAdminOrFinanceOwner);

  const masterCorpSegmentDropdowns = useSelector((state: RootState) => state.corpSegmentsStore.masterCorpSegmentDropdownValues);
  const masterBusinessSegments = useSelector((state: RootState) => state.corpSegmentsStore.masterBusinessSegments);
  const { accountBudgetTypeMapping } = useSelector((state: RootState) => state.xPTMappingStore);
  const expenseTypesForCurrentGroup = useSelector(selectActiveExpenseTypesForCurrentGroup);

  const forecastTemplateCompleteData = useSelector((state: RootState) => state.forecastTemplateStore.forecastTemplateCompleteData);
  const forecastTemplateDataLoading = useSelector((state: RootState) => state.forecastTemplateStore.forecastTemplateDataLoading);

  const [forecastGridInitialized, setForecastGridInitialized] = useState<Status>(Status.NotInitiated);
  const [forecastTemplateGridData, setForecastTemplateGridData] = useState<ForecastGridRowData[]>([]);
  const [forecastTemplateColDef, setForecastTemplateColDef] = useState<ColDef[]>([]);

  useEffect(() => {
    setForecastTemplateGridData([]);
    setForecastTemplateColDef([]);
    setForecastGridInitialized(Status.NotInitiated);
  }, [forecastTemplateCompleteData]);

  // Update grid data when currentCorpSegmentDropDownSelections change
  useEffect(() => {
    if (forecastTemplateDataLoading) {
      setForecastGridInitialized(Status.NotInitiated);
      return;
    }

    if (!currentCorpSegmentDropDownSelections || isEmptyObject(currentCorpSegmentDropDownSelections)) {
      setForecastTemplateGridData([]);
      setForecastTemplateColDef([]);
      setForecastGridInitialized(Status.NotInitiated);
      return;
    }

    if (businessGroup && currentPlanningCycle && currentCorpSegmentDropDownSelections && !isEmptyObject(currentCorpSegmentDropDownSelections)) {
      try {
        setForecastGridInitialized(Status.Loading);
        const filtersToApply = convertToLabelArrays(currentCorpSegmentDropDownSelections || {});
        const filteredData: ForecastGridRowData[] = filterRowDataWithAllMatches(forecastTemplateCompleteData, filtersToApply);
        const sortedFilteredData = filteredData.sort((a, b) => dateTimeComparator(a.updated_at, b.updated_at, false));
        setForecastTemplateGridData(sortedFilteredData);
        initializeColumnDefinition();
      } catch (error: any) {
        console.error('Error loading the forecast data:', error);
        setForecastGridInitialized(Status.Failed);
      }
    }
  }, [
    themeClassName,
    businessGroup,
    currentPlanningCycle,
    forecastTemplateDataLoading,
    masterCorpSegmentDropdowns,
    masterBusinessSegments,
    currentCorpSegmentDropDownSelections
  ]);

  const initializeColumnDefinition = async () => {
    if (businessGroup && currentPlanningCycle && userAccessForCurrentBusinessGroup) {
      try {
        // const expenseTypesForCurrentGroup = getExpenseTypesOfCurrentBusinessGroup(activeExpenseTypeMappings, dataClassificationId);

        const colDefs = await generateColumnDefinitions(
          userAlias,
          currentPlanningCycle,
          businessGroup,
          isCycleLocked,
          isFinanceAdminOrFinanceOwner,
          isBudgetLeader,
          masterCorpSegmentDropdowns,
          masterBusinessSegments,
          expenseTypesForCurrentGroup,
          accountBudgetTypeMapping,
          xptUsersOfCurrentDataClassification,
          userAccessForCurrentBusinessGroup,
          themeClassName
        );
        setForecastTemplateColDef(colDefs);
        setForecastGridInitialized(Status.Completed);
      } catch (error) {
        console.error('Error generating column definitions:', error);
        setForecastGridInitialized(Status.Failed);
      }
    }
  };

  const errorMessage = `Forecast Template data grid initialization failed. Please try again or reach out to support`;
  const errorFalBackComponent = <Alert type="error">{errorMessage}</Alert>;

  return (
    <>
      {forecastGridInitialized === Status.Failed && errorFalBackComponent}
      {forecastGridInitialized === Status.Loading && <LoadingSpinner />}
      {forecastGridInitialized === Status.Completed && (
        <ErrorBoundary FallbackComponent={() => ErrorFallback(errorMessage, '')}>
          <ForecastTemplateGridTable forecastGridData={forecastTemplateGridData} forecastColDef={forecastTemplateColDef} />
        </ErrorBoundary>
      )}
    </>
  );
};

export default ForecastTemplateGrid;
