import { BreadcrumbGroupProps, StatusIndicator, TableProps } from '@amzn/awsui-components-react';
import { eEntityStatus } from 'src/constants/generic-constants';
import { BudgetOwnershipTransferHistory } from 'src/models/BudgetOwnerTransferModel';
import { DynamicColumnDetails } from 'src/models/XptTableModel';
import { formatUTCAsLocalReadable } from 'src/utils/date-time-utilities';
import { getStatusIndicatorColorOverride, getStatusIndicatorType } from 'src/utils/generic-utilities';
import CostCentersCell from './CostCentersCell';

export const geBOTransferBreadcrumbItems = (businessGroupBaseBreadcrumbs: BreadcrumbGroupProps.Item[], currentBusinessGroupName?: string) => {
  if (!currentBusinessGroupName) {
    return businessGroupBaseBreadcrumbs;
  }

  return [
    ...businessGroupBaseBreadcrumbs,
    {
      text: 'Budget Owner Transfer',
      href: `/${currentBusinessGroupName}/budget-owner-transfer`
    }
  ];
};

export const BUDGET_OWNER_TRANSFER_CONFIG: DynamicColumnDetails = {
  columnInfo: {
    entityName: `Transfer History`,
    trackBy: 'log_id',
    sortingColumn: 'last_updated_at',
    isDescending: true
  },
  data: [
    {
      fieldName: 'performed_by',
      displayName: 'Performed By',
      canBeHiddenFromProperties: false,
      isShownByDefault: true,
      isFilterable: true,
      metadata: {
        type: 'string'
      }
    },
    {
      fieldName: 'from_alias',
      displayName: 'From Alias',
      canBeHiddenFromProperties: false,
      isShownByDefault: true,
      isFilterable: true,
      metadata: {
        type: 'string'
      }
    },
    {
      fieldName: 'to_alias',
      displayName: 'To Alias',
      canBeHiddenFromProperties: false,
      isShownByDefault: true,
      isFilterable: true,
      metadata: {
        type: 'string'
      }
    },
    {
      fieldName: 'cost_centers',
      displayName: 'Cost Centers',
      canBeHiddenFromProperties: true,
      isShownByDefault: true,
      isFilterable: true,
      metadata: {
        type: 'string'
      }
    },
    {
      fieldName: 'number_of_forecast_inputs_updated',
      displayName: 'Effected Records',
      canBeHiddenFromProperties: true,
      isShownByDefault: true,
      isFilterable: true,
      metadata: {
        type: 'number'
      }
    },
    {
      fieldName: 'status',
      displayName: 'Status',
      canBeHiddenFromProperties: false,
      isShownByDefault: true,
      isFilterable: true,
      metadata: {
        type: 'string'
      }
    },
    {
      fieldName: 'last_updated_at',
      displayName: 'Updated At',
      canBeHiddenFromProperties: false,
      isShownByDefault: true,
      isFilterable: true,
      metadata: {
        type: 'string'
      }
    }
  ]
};

export const getBudgetOwnerTransferDefinitions = (): TableProps.ColumnDefinition<BudgetOwnershipTransferHistory>[] => [
  {
    id: 'log_id',
    header: 'Id',
    cell: (item: BudgetOwnershipTransferHistory) => item.log_id,
    sortingField: 'log_id'
  },
  {
    id: 'performed_by',
    header: 'Performed By',
    cell: (item: BudgetOwnershipTransferHistory) => item.performed_by,
    sortingField: 'performed_by',
    isRowHeader: true
  },
  {
    id: 'from_alias',
    header: 'From Alias',
    cell: (item: BudgetOwnershipTransferHistory) => item.from_alias,
    sortingField: 'from_alias'
  },
  {
    id: 'to_alias',
    header: 'To Alias',
    cell: (item: BudgetOwnershipTransferHistory) => item.to_alias,
    sortingField: 'to_alias'
  },
  {
    id: 'cost_centers',
    header: 'Cost Centers',
    // cell: (item: BudgetOwnershipTransferHistory) => (item.cost_centers ? item.cost_centers.join(', ') : ''),
    cell: (item: BudgetOwnershipTransferHistory) => <CostCentersCell costCenters={item.cost_centers} />,
    sortingField: 'cost_centers'
  },
  {
    id: 'number_of_forecast_inputs_updated',
    header: 'Effected Records',
    cell: (item: BudgetOwnershipTransferHistory) => (item.number_of_forecast_inputs_updated !== null ? item.number_of_forecast_inputs_updated : '-'),
    sortingField: 'number_of_forecast_inputs_updated'
  },
  {
    id: 'status',
    header: 'Status',
    cell: (item: BudgetOwnershipTransferHistory) => {
      if (!item.status) {
        return <div>-</div>;
      }
      return (
        <StatusIndicator
          type={getStatusIndicatorType(item.status as eEntityStatus)}
          colorOverride={getStatusIndicatorColorOverride(item.status as eEntityStatus)}
        >
          {item.status}
        </StatusIndicator>
      );
    },
    sortingField: 'status'
  },
  {
    id: 'last_updated_at',
    header: 'Updated At',
    cell: (item: BudgetOwnershipTransferHistory) => formatUTCAsLocalReadable(item.last_updated_at),
    sortingField: 'last_updated_at'
  }
];

export const BUDGET_OWNER_MESSAGES = {
  UNABLE_TO_LOAD_AUDIT_LOG: 'Unable to load audit log data',
  UNABLE_TO_LOAD_BUDGET_OWNERS: 'Unable to load current budget owners',
  BUDGET_OWNER_TRANSFER_IN_PROGRESS: 'Budget Owner Transfer in progress',
  BUDGET_OWNER_TRANSFER_SUCCESSFUL: (noOfRecordsUpdated: number) =>
    `Budget Owner Transfer successful. ${noOfRecordsUpdated} record${noOfRecordsUpdated > 1 ? 's' : ''} updated.`,
  BUDGET_OWNER_TRANSFER_FAILED: 'Budget Owner Transfer failed',
  NO_DATA_TO_TRANSFER: 'No data found to transfer.'
};
