import { Box, Button, Container, FileUpload, Flashbar, Form, FormField, Header, SpaceBetween, StatusIndicator } from '@amzn/awsui-components-react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { logger, logUserClick } from 'src/analytics/KatalLogger';
import { getAuditLogs } from 'src/api/app-sync-services';
import { useAuth } from 'src/app/auth/AuthContextProvider';
import CodeViewModal from 'src/components/common/CodeViewModal';
import { XptAppLayout } from 'src/components/common/xpt-app-layout/XptAppLayout';
import XptTable from 'src/components/common/xpt-table/XptTable';
import { XPTBreadcrumbs } from 'src/components/common/XptBreadcrumb';
import { AuditType } from 'src/constants/generic-constants';
import { useFlashbar } from 'src/hooks/useFlashbar';
import { fileUploadI18nStrings } from 'src/i18n-strings/file-upload';
import { AuditLogEntity } from 'src/models/AppContextModels';
import { CAPEXDataValidationStatus, CAPEXEntity, CAPEXFileEntity } from 'src/models/CapexModels';
import { ValidationStatusEntity } from 'src/models/XptGenericModels';
import { dateTimeComparator, getCurrentUTCTimeInISO } from 'src/utils/date-time-utilities';
import { readExcelFileWithHeader } from 'src/utils/file-utils';
import { generateUniqueId, getHeaderCounter, parseAWSJSONToJsonObject } from 'src/utils/generic-utilities';
import AdminConsoleSideNavigation from '../AdminConsoleSideNavigation';
import { AUDIT_TABLE_CONFIG, getAuditTableDefinitions } from '../dev-console/audit-log/AuditLogConfig';
import {
  CAPEX_SHEET_NAME,
  CAPEXValidationMessages,
  downloadSampleTemplate,
  getCapexBreadcrumbItems,
  getCAPEXFileHeaderInfo,
  INITIAL_CAPEX_VALIDATION_STATUS,
  submitCapexData
} from './CapexConfig';
import { transformCapexFileHeader, validateCapexDataIsUnique, validateCapexHeaders } from './CapexDataValidation';
import { CapexInfoPanel } from './CapexInfoPanel';
import { sendSNSNotification } from 'src/utils/aws-sns-service';

const AcceptedFileFormForExcel = {
  ConstraintMessage: 'Upload Excel (.xlsx) file only.',
  FileFormats: '.xlsx,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
};

export const CapexFileUpload: React.FC = () => {
  const appLayout = useRef<any>();
  const capexFileUploadHistoryName = 'Capex Upload History';

  const { userAlias } = useAuth();
  const [uploadedFile, setUploadedFile] = useState<File[]>([]);
  const [uploadedFileModifiedData, setUploadedFileModifiedData] = useState<CAPEXFileEntity[]>([]);
  const [isUploadedFileValid, setIsUploadedFileValid] = useState<boolean>(false);
  const [fileValidationStatus, setFileValidationStatus] = useState<CAPEXDataValidationStatus>(INITIAL_CAPEX_VALIDATION_STATUS);
  const [auditLogs, setAuditLogs] = useState<AuditLogEntity[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedAdditionalInfo, setSelectedAdditionalInfo] = useState<any>(null);
  const { flashbarItems, displayFlashMessage, clearSpecificFlashMessage } = useFlashbar();

  useEffect(() => {
    resetFileAndValidationStatus();
  }, []);

  useEffect(() => {
    if (uploadedFile.length > 0) {
      handleOnFileUpload();
    }
  }, [uploadedFile]);

  const resetFileAndValidationStatus = () => {
    setFileValidationStatus(INITIAL_CAPEX_VALIDATION_STATUS);
    setUploadedFileModifiedData([]);
    setIsUploadedFileValid(false);
  };

  const handleOnFileUpload = async () => {
    if (!uploadedFile.length) return;

    logger.debug(`File upload initiated for Capex by ${userAlias}`);
    try {
      const { headerRow, fileData } = await readExcelFileWithHeader(uploadedFile[0], CAPEX_SHEET_NAME);
      logger.info(`User ${userAlias} uploaded a file with total records: ${fileData.length}`);
      validateFileData(headerRow, fileData);
    } catch (error: any) {
      handleFileReadError(error);
    }
  };

  const handleFileReadError = (error: any) => {
    logger.error('Error while reading the file:', error);
    setUploadedFile([]);
    setFileValidationStatus({
      ...INITIAL_CAPEX_VALIDATION_STATUS,
      HeadersMatching: {
        colorOverride: 'red',
        validationStatus: 'error',
        validationMessage: 'Error reading the file',
        validationDefaultMessage: 'Error reading the file',
        validationErrorDetails: [error.message]
      }
    });
  };

  const validateFileData = async (headerRow: string[], fileData: any[]) => {
    resetFileAndValidationStatus();
    try {
      const { fileHeaders } = getCAPEXFileHeaderInfo();
      const expectedHeaders = fileHeaders.map((column) => column.headerName);
      const headerValidation = await validateHeadersStep(headerRow, expectedHeaders);

      if (headerValidation.validationStatus === 'error') return;

      const transformHeaderValidation = await transformCapexFileHeader(fileData);

      handleTransformHeaderValidation(transformHeaderValidation);

      if (transformHeaderValidation.transformedData) {
        setUploadedFileModifiedData(transformHeaderValidation.transformedData);
        const finalValidation = await validateCapexDataIsUnique(transformHeaderValidation.transformedData);
        setFileValidationStatus((prevState) => ({
          ...prevState,
          UniqueKeyValidation: finalValidation
        }));
      }
    } catch (error: any) {
      handleValidationError(error);
    }
  };

  const handleTransformHeaderValidation = (validationResult: any) => {
    if (validationResult.validationStatus === 'error') {
      setFileValidationStatus((prevState) => ({
        ...prevState,
        HeadersMatching: {
          colorOverride: 'green',
          validationStatus: 'success',
          validationMessage: CAPEXValidationMessages.HEADER_VALIDATION_SUCCESS,
          validationDefaultMessage: '',
          validationErrorDetails: []
        },
        DataValidity: validationResult
      }));
    } else {
      setFileValidationStatus((prevState) => ({
        ...prevState,
        HeadersMatching: {
          colorOverride: 'green',
          validationStatus: 'success',
          validationMessage: CAPEXValidationMessages.HEADER_VALIDATION_SUCCESS,
          validationDefaultMessage: '',
          validationErrorDetails: []
        },
        DataValidity: validationResult
      }));
    }
  };

  const handleValidationError = (error: any) => {
    logger.error('Error while validating the file data:', error);
    setFileValidationStatus({
      ...INITIAL_CAPEX_VALIDATION_STATUS,
      HeadersMatching: {
        colorOverride: 'red',
        validationStatus: 'error',
        validationMessage: 'Error validating the file data',
        validationDefaultMessage: 'Error validating the file data',
        validationErrorDetails: [error.message]
      }
    });
  };

  const validateHeadersStep = async (headerRow: string[], expectedHeaders: string[]): Promise<ValidationStatusEntity> => {
    const headerValidation = await validateCapexHeaders(headerRow, expectedHeaders);

    if (headerValidation.validationStatus === 'error') {
      setIsUploadedFileValid(false);
      setFileValidationStatus((prevState) => ({
        ...prevState,
        HeadersMatching: {
          ...headerValidation,
          validationStatus: 'error'
        },
        DataValidity: {
          ...prevState.DataValidity,
          validationStatus: 'error',
          validationMessage: CAPEXValidationMessages.SKIPPED_DUE_TO_HEADER_FAILURE
        },
        UniqueKeyValidation: {
          ...prevState.UniqueKeyValidation,
          validationStatus: 'error',
          validationMessage: CAPEXValidationMessages.SKIPPED_DUE_TO_HEADER_FAILURE
        }
      }));
    } else {
      setIsUploadedFileValid(true);
      setFileValidationStatus((prevState) => ({
        ...prevState,
        HeadersMatching: headerValidation
      }));
    }

    return headerValidation;
  };

  const isAllValidationsPassed = () => {
    return Object.values(fileValidationStatus).every((status) => status.validationStatus === 'success');
  };

  const handleSubmit = async () => {
    const messageId = generateUniqueId();
    displayFlashMessage('Submitting Capex data', 'info', true, messageId);

    try {
      const capexDataToUpload = uploadedFileModifiedData.map(
        (row) =>
          ({
            ...row,
            created_at: getCurrentUTCTimeInISO(),
            created_by: userAlias
          } as CAPEXEntity)
      );
      await submitCapexData(capexDataToUpload, userAlias);

      sendSNSNotification(
        'Capex Data Submission',
        `Capex data submitted successfully by ${userAlias}. Total records: ${capexDataToUpload.length} uploaded.`,
        'error'
      );

      clearSpecificFlashMessage(messageId);
      displayFlashMessage('Capex data submitted successful', 'success', true);

      logger.info(`Capex data submitted successfully by ${userAlias}`);
      logUserClick('CapexFileUpload', 'CapEx File Upload', 'Admin Console');

      loadAuditData();
      resetAfterSubmit();
    } catch (error: any) {
      logger.error('Error during the capex data submission process:', error);
      displayFlashMessage('Error during the capex data submission process', 'error', true);
      sendSNSNotification('Capex Data Submission', `Error during the capex data submission process: ${error.message}`, 'error');
    }
  };

  const resetAfterSubmit = () => {
    setTimeout(() => {
      resetFileAndValidationStatus();
      setUploadedFile([]);
    }, 3000);
  };

  useEffect(() => {
    loadAuditData();
  }, []);

  // Use useCallback to memoize the function and prevent unnecessary re-renders
  const loadAuditData = useCallback(async () => {
    try {
      setIsLoading(true);
      const auditLogResponse = await getAuditLogs();
      const filteredAudit = auditLogResponse.filter((log) => log.page_name === AuditType.Capex);
      const auditLogsSorted = filteredAudit.sort((a, b) => dateTimeComparator(a.created_at, b.created_at, false));
      setAuditLogs(auditLogsSorted);
    } catch (error: any) {
      logger.error('Unable to load audit log data', error);
      setAuditLogs([]);
      displayFlashMessage(`Unable to load audit log data`, 'error', true);
    } finally {
      setIsLoading(false);
    }
  }, [getAuditLogs, dateTimeComparator, logger, displayFlashMessage]);

  const AuditTableHeader = () => {
    return (
      <Header actions={<Button iconName="refresh" onClick={loadAuditData}></Button>} counter={getHeaderCounter([], auditLogs)}>
        {capexFileUploadHistoryName}
      </Header>
    );
  };

  // Handler for the "Show Details" button click
  const handleAdditionalInfoClick = (auditLogId: number): void => {
    try {
      const selectedLog = auditLogs.find((log) => log.audit_log_id === auditLogId);
      if (selectedLog) {
        const parsedInfo = parseAWSJSONToJsonObject(selectedLog.additional_info);
        setSelectedAdditionalInfo(parsedInfo);
        setIsModalVisible(true);
      }
    } catch (error: any) {
      displayFlashMessage(`Unable to load additional info`, 'error', true);
    }
  };

  // Handler for modal close
  const handleModalClose = (): void => {
    setSelectedAdditionalInfo(null);
    setIsModalVisible(false);
  };

  return (
    <XptAppLayout
      ref={appLayout}
      navigation={<AdminConsoleSideNavigation />}
      tools={<CapexInfoPanel />}
      breadcrumbs={<XPTBreadcrumbs items={getCapexBreadcrumbItems()} />}
      notifications={<Flashbar items={[]} stackItems />}
      stickyNotifications={true}
      maxContentWidth={Number.MAX_VALUE}
      contentType="default"
      content={
        <div className="xpt-app-layout-content">
          <>
            <form onSubmit={(event) => event.preventDefault()}>
              <Form
                header={
                  <Header variant="h2" actions={<Button onClick={downloadSampleTemplate}>Download Template</Button>}>
                    {`CapEx File Upload`}
                  </Header>
                }
              >
                <SpaceBetween size="m" direction="vertical">
                  <Container
                    header={
                      <Header
                        actions={
                          <Button variant="primary" onClick={handleSubmit}>
                            {`Upload data`}
                          </Button>
                        }
                      ></Header>
                    }
                  >
                    <FormField label="Upload CapEx Data">
                      <FileUpload
                        i18nStrings={fileUploadI18nStrings()}
                        onChange={({ detail }) => setUploadedFile(detail.value)}
                        value={uploadedFile}
                        accept={AcceptedFileFormForExcel.FileFormats}
                        constraintText={AcceptedFileFormForExcel.ConstraintMessage}
                        ariaRequired
                        multiple={false}
                        showFileLastModified={true}
                        showFileSize={true}
                        showFileThumbnail={true}
                        tokenLimit={1}
                        invalid={!isUploadedFileValid}
                      />
                    </FormField>

                    <Box padding={{ top: 's' }}>
                      <SpaceBetween size="m" direction="vertical">
                        {Object.keys(fileValidationStatus).map((key, index) => {
                          const validationStatus = fileValidationStatus[key as keyof CAPEXDataValidationStatus];
                          return (
                            <StatusIndicator key={index} type={validationStatus.validationStatus} colorOverride={validationStatus.colorOverride}>
                              {validationStatus.validationMessage}
                            </StatusIndicator>
                          );
                        })}
                      </SpaceBetween>
                    </Box>
                  </Container>
                </SpaceBetween>
              </Form>
            </form>
          </>
          <Box margin={{ top: 'm' }}>
            <XptTable
              xptTableVariant={'container'}
              loadingStatus={isLoading}
              entityName={capexFileUploadHistoryName}
              xptTableHeader={<AuditTableHeader />}
              allItems={auditLogs}
              allColumns={AUDIT_TABLE_CONFIG}
              columnDefinitions={getAuditTableDefinitions({ handleAdditionalInfoClick })}
              itemsPerPage={10}
              selectedItems={[]}
              setSelectedItems={async (items) => {}}
              page="Audit Table Log"
              mainPage={`Developer Console`}
            />
            <CodeViewModal
              visible={isModalVisible}
              onClose={handleModalClose}
              header="Additional Information"
              content={selectedAdditionalInfo}
              contentType="json"
            />
          </Box>
        </div>
      }
    />
  );
};
