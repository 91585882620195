import { Button, Flashbar, Header, Link, SpaceBetween, TableProps } from '@amzn/awsui-components-react';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { LoadingSpinner } from 'src/components/common/LoadingSpinner';
import { XptAppLayout } from 'src/components/common/xpt-app-layout/XptAppLayout';
import XptTable from 'src/components/common/xpt-table/XptTable';
import { XPTBreadcrumbs } from 'src/components/common/XptBreadcrumb';
import { SPLIT_PANEL_DEFAULT_SIZE, SPLIT_PANEL_MAX_SIZE } from 'src/constants/generic-constants';
import BusinessGroupSideNavigation from 'src/features/xpt-layout/XptBusinessGroupSideNavigation';
import { useFlashbar } from 'src/hooks/useFlashbar';
import { Status } from 'src/models/AuthContextModels';
import { UsefulLifeMappingEntity } from 'src/models/xPTMappingModels';
import { fetchUsefulLifeDetails } from 'src/store/slices/xPTMapperSlice';
import { RootState } from 'src/store/store';
import { useAppDispatch } from 'src/store/useAppDispatch';
import { deepClone } from 'src/utils/ag-grid-utils';
import { getHeaderCounter } from 'src/utils/generic-utilities';
import { businessGroupBaseBreadcrumbs, currentBusinessGroupName, currentDataClassificationId } from '../businessGroupSelectors';
import { UsefulLifeForm } from './UsefulLifeForm';
import { getUsefulLifeAllColumns, getUsefulLifeBreadcrumbItems, getUsefulLifeColumnDefs } from './UsefulLifeMappingConfig';
import { UsefulLifeMappingsInfoPanel } from './UsefulLifeMappingsInfoPanel';
import { logUserClick } from 'src/analytics/KatalLogger';

export const UsefulLifeMapping: React.FC = () => {
  const ENTITY_NAME = `Useful Life Mappings`;
  const appLayout = useRef<any>();
  const [toolsOpen, setToolsOpen] = useState(false);
  const [isSplitPanelOpen, setIsSplitPanelOpen] = useState<boolean>(false);
  const [splitPanelSize, setSplitPanelSize] = useState<number>(SPLIT_PANEL_DEFAULT_SIZE);

  const dispatch = useAppDispatch();
  const { userCostCentersForCurrentBusinessGroup: userCostCenters } = useSelector((state: RootState) => state.xptAccessAndAuthorizationStore)!;
  const { usefulLifeMappings, usefulLifeStatus } = useSelector((state: RootState) => state.xPTMappingStore);
  const dataClassificationId = useSelector(currentDataClassificationId);
  const businessGroupName = useSelector(currentBusinessGroupName);
  const businessGroupBaseBreadcrumb = useSelector(businessGroupBaseBreadcrumbs);

  const [currentBusinessGroupUsefulLifeMappings, setCurrentBusinessGroupUsefulLifeMappings] = useState<UsefulLifeMappingEntity[]>([]);
  const [selectedUsefulLifeMappings, setSelectedUsefulLifeMappings] = useState<UsefulLifeMappingEntity[]>([]);
  const [columnDefinition, setColumnDefinition] = useState<TableProps.ColumnDefinition<UsefulLifeMappingEntity>[]>(getUsefulLifeColumnDefs());

  const { flashbarItems, displayFlashMessage, clearSpecificFlashMessage } = useFlashbar();

  useEffect(() => {
    if (usefulLifeStatus === Status.NotInitiated) {
      dispatch(fetchUsefulLifeDetails());
    }

    if (usefulLifeStatus === Status.Failed) {
      displayFlashMessage('Unable to load useful life mappings.', 'error', true);
    }

    if (usefulLifeStatus === Status.Completed) {
      try {
        setSelectedUsefulLifeMappings([]);
        setIsSplitPanelOpen(false);
        const mutableUsefulLife = deepClone(usefulLifeMappings);
        const usefulLifeMapping = mutableUsefulLife
          .filter(
            (usefulLifeMapping) =>
              usefulLifeMapping.data_classification_id === dataClassificationId && userCostCenters.includes(usefulLifeMapping.cost_center_code)
          )
          .sort((a, b) => a.cost_center_code.localeCompare(b.cost_center_code));
        setCurrentBusinessGroupUsefulLifeMappings(usefulLifeMapping);
      } catch (error: any) {
        setCurrentBusinessGroupUsefulLifeMappings([]);
        displayFlashMessage('Unable to load useful life mappings.', 'error', true);
      }
    }
  }, [usefulLifeStatus, usefulLifeMappings]);

  const reloadExpenseTypes = () => {
    logUserClick('Refreshed useful life mapping', ENTITY_NAME, businessGroupName);
    dispatch(fetchUsefulLifeDetails());
  };

  const addUsefulLife = () => {
    setSelectedUsefulLifeMappings([]);
    setIsSplitPanelOpen(true);
    logUserClick('add button', ENTITY_NAME, businessGroupName);
  };

  useEffect(() => {
    if (selectedUsefulLifeMappings.length === 1) {
      setSelectedUsefulLifeMappings(selectedUsefulLifeMappings);
      setIsSplitPanelOpen(true);
    }
  }, [selectedUsefulLifeMappings]);

  useEffect(() => {
    if (!isSplitPanelOpen) {
      setSelectedUsefulLifeMappings([]);
    }
  }, [isSplitPanelOpen]);

  const UsefulLifeTableHeader = () => (
    <Header
      actions={
        <SpaceBetween size="l" direction="horizontal">
          <Button iconName="refresh" onClick={reloadExpenseTypes}></Button>
          <Button iconName="add-plus" onClick={addUsefulLife}>{`Add Useful Life`}</Button>
        </SpaceBetween>
      }
      counter={getHeaderCounter(selectedUsefulLifeMappings, currentBusinessGroupUsefulLifeMappings)}
      info={
        <Link variant="info" onFollow={() => setToolsOpen(true)}>
          Info
        </Link>
      }
    >
      {ENTITY_NAME}
    </Header>
  );

  return (
    <XptAppLayout
      ref={appLayout}
      headerSelector="#h"
      navigation={<BusinessGroupSideNavigation />}
      tools={<UsefulLifeMappingsInfoPanel />}
      toolsOpen={toolsOpen}
      onToolsChange={({ detail }) => setToolsOpen(detail.open)}
      breadcrumbs={<XPTBreadcrumbs items={getUsefulLifeBreadcrumbItems(businessGroupBaseBreadcrumb, businessGroupName)} />}
      notifications={<Flashbar items={flashbarItems} stackItems />}
      stickyNotifications={true}
      maxContentWidth={Number.MAX_VALUE}
      contentType="default"
      content={
        <div className="xpt-app-layout-content">
          <XptTable
            xptTableVariant="full-page"
            xptTableHeader={<UsefulLifeTableHeader />}
            loadingStatus={usefulLifeStatus === Status.Loading}
            entityName={ENTITY_NAME}
            allColumns={getUsefulLifeAllColumns(ENTITY_NAME)}
            columnDefinitions={columnDefinition}
            allItems={currentBusinessGroupUsefulLifeMappings}
            selectionType="single"
            itemsPerPage={100}
            onRowClickSelect={true}
            selectedItems={selectedUsefulLifeMappings}
            setSelectedItems={async (selectedItems) => setSelectedUsefulLifeMappings(selectedItems)}
            page={ENTITY_NAME}
            mainPage={businessGroupName}
          />
        </div>
      }
      splitPanelSize={splitPanelSize}
      onSplitPanelResize={({ detail }) => setSplitPanelSize(detail.size > SPLIT_PANEL_MAX_SIZE ? SPLIT_PANEL_MAX_SIZE : detail.size)}
      splitPanelPreferences={{ position: 'side' }}
      onSplitPanelPreferencesChange={() => {}}
      onSplitPanelToggle={({ detail }) => {
        setIsSplitPanelOpen(detail.open);
      }}
      splitPanelOpen={isSplitPanelOpen}
      splitPanel={<UsefulLifeForm selectedUsefulLifeMappings={selectedUsefulLifeMappings} closeTheSidePanel={() => setIsSplitPanelOpen(false)} />}
    />
  );
};
