import { FlashbarProps } from '@amzn/awsui-components-react';
import { createContext, useContext } from 'react';

export type ForecastTemplateContextType = {
  notificationMessage: (content: string, flashBarType: FlashbarProps.Type, isDismissible: boolean, messageId?: string) => void;
  clearSpecificFlashMessage: (messageId: string) => void;
  clearAllMessages: () => void;
};

export const ForecastTemplateContext = createContext<ForecastTemplateContextType>({
  notificationMessage: () => {},
  clearSpecificFlashMessage: (messageId: string) => {},
  clearAllMessages: () => {}
});

export const useForecastTemplateContext = () => useContext(ForecastTemplateContext);
