import moment from 'moment-timezone';
import { DateRelativeRangePicker } from 'src/models/AppContextModels';

export const FORECAST_TEMPLATE_MONTH_FORMAT = 'YYYYMM01';
export const FORECAST_TEMPLATE_MONTH_DISPLAY_FORMAT = 'MMM-YYYY';
export const PLANNING_CYCLE_MONTH_FORMAT = 'YYYY-MM-DD';

export const PO_TAGGING_MONTH_FORMAT = 'MMM-YY';

export const getMoment = () => moment();

export const getCurrentTime = () => moment(PLANNING_CYCLE_MONTH_FORMAT);

export const getCurrentDate = () => moment().format(PLANNING_CYCLE_MONTH_FORMAT);

export const getTimeDifference = (input: moment.Moment, unitOfTime: moment.unitOfTime.Diff = 'milliseconds'): number => {
  return moment().diff(input, unitOfTime);
};

export const getCurrentTimeInUserLocalReadableFor = () => formatUTCAsLocalReadable(getCurrentUTCTimeInISO());

/**
 * Returns the current UTC time in ISO format.
 */
export const getCurrentUTCTimeInISO = () => moment.utc().toISOString();

/**
 * Accepts UTC Time and returns the converted user's local time in a readable format.
 * Example: July 29th 2022, 12:28:11 AM PDT
 * @param {string} utcTimestampInISO - input UTC timestamp value to format.
 */
export const formatUTCAsLocalReadable = (utcTimestampInISO: string) => {
  if (!utcTimestampInISO) {
    return '';
  }
  // const localTime = moment.utc(utcTimestampInISO).local().format('MMM Do YYYY, hh:mm A z');
  // return localTime;
  // Note: This uses the browser's local time zone automatically.
  const localTimeWithTimeZone = moment.utc(utcTimestampInISO).local().format('MMM Do YYYY, hh:mm A') + ' ' + moment.tz(moment.tz.guess()).zoneAbbr();

  return localTimeWithTimeZone;
};

// convert pacific time to UTC time
export const convertPacificTimeToUTC = (pstTimestamp: string) => {
  if (!pstTimestamp) {
    return '';
  }

  const pacificTime = moment(pstTimestamp).tz('America/Los_Angeles');
  return moment.utc(pacificTime).toISOString();
};

/**
 * Splits a UTC datetime string into date and time strings of user timezone.
 * @param utcTimestampInISO - The UTC datetime string to split.
 * @returns An object containing separate date and time strings.
 */
export const convertToUserLocalAndSplitDateTime = (utcTimestampInISO: string): { date: string; time: string } => {
  const localDateTime = moment.utc(utcTimestampInISO).local();

  // Extract date and time in the desired format
  const date = localDateTime.format('YYYY-MM-DD');
  const time = localDateTime.format('HH:mm');
  return { date, time };
};

/**
 * Returns a Month & Year in a readable format (mandatory).
 * Example: Jan 2022 | Dec 2023
 * @param {string} utcTimestampInISO - Input UTC Timestamp to format.
 */
export const getReadableFormatOfMonthYear = (utcTimestampInISO: string) => {
  if (!utcTimestampInISO) {
    return '';
  }
  const inputValueInMoment = moment(utcTimestampInISO);
  return inputValueInMoment.format('MMM YYYY');
};

/**
 * Returns a Month & Year in a readable format (mandatory).
 * Example: Jan 1st 2022 | Dec 2nd 2023
 * @param {string} utcTimestampInISO - Input UTC Timestamp to format.
 */
export const getReadableFormatOfMonthDateYear = (utcTimestampInISO: string) => {
  if (!utcTimestampInISO) {
    return '';
  }
  return moment.utc(utcTimestampInISO).local().format('MMM Do YYYY');
};

/**
 * Compares two date times for sorting.
 * @param {string} dateTime1 - First date time.
 * @param {string} dateTime2 - Second date time.
 * @param {boolean} ascending - If true, sorts in ascending order; otherwise, sorts in descending order.
 * @returns {number} - Result of comparison.
 */
export const dateTimeComparator = (dateTime1: string, dateTime2: string, ascending: boolean = true) => {
  const date1 = moment(dateTime1);
  const date2 = moment(dateTime2);
  return ascending ? (date1.isBefore(date2) ? -1 : 1) : date1.isBefore(date2) ? 1 : -1;
};

export const isGivenDateTimeIsValid = (input: string) => {
  return moment(input).isValid();
};

export const isDateWithinRange = (dateStr: string, startStr: string, endStr: string): boolean => {
  const date = moment(dateStr);
  const start = moment(startStr);
  const end = moment(endStr);
  return date.isBetween(start, end, undefined, '[]');
};

/**
 * Checks if a given date is in the past compared to the current date and time.
 *
 * @param date - The date string to be checked.
 * @returns true if the date is in the past, otherwise false.
 */
export const isDateInThePast = (inputDateTimeValue: string) => {
  const currentMoment = moment();
  return moment(inputDateTimeValue).isBefore(currentMoment);
};

/**
 * Checks if a given date is in the future compared to the current date and time.
 *
 * @param date - The date string to be checked.
 * @returns true if the date is in the future, otherwise false.
 */
export const isDateInFuture = (date: any) => {
  const currentMonthDate = moment();
  return moment(date).isAfter(currentMonthDate);
};

/**
 * Checks if the start date is before the end date.
 * @param startDate - The start date.
 * @param endDate - The end date.
 * @returns True if the start date is before the end date, otherwise false.
 */
export const isStartDateBeforeThanEndDate = (startDate: any, endDate: any) => {
  return moment(startDate).isBefore(moment(endDate));
};

/**
 * Checks if the given date is the start or end date of the month.
 * @param date - The date to check.
 * @returns True if the date is the start or end date of the month, otherwise false.
 */
export const isStartOfTheMonth = (date: any): boolean => {
  const momentDate = moment(date);
  return momentDate.date() === 1;
};

export const isEndOfTheMonth = (date: any): boolean => {
  const momentDate = moment(date);
  return momentDate.date() === momentDate.daysInMonth();
};

export const getPacificTimeOffset = () => {
  return moment.tz(moment.utc(), 'America/Los_Angeles').utcOffset();
};

const getTimeMessage = (duration: moment.Duration, suffix: string) => {
  let message = '';

  switch (true) {
    case duration.asDays() >= 1:
      const days = Math.floor(duration.asDays());
      message = `${days} day${days > 1 ? 's' : ''} ${suffix}`;
      break;
    case duration.asHours() >= 1:
      const hours = Math.floor(duration.asHours());
      message = `${hours} hour${hours > 1 ? 's' : ''} ${suffix}`;
      break;
    case duration.asMinutes() >= 1:
      const minutes = Math.floor(duration.asMinutes());
      message = `${minutes} minute${minutes > 1 ? 's' : ''} ${suffix}`;
      break;
    case duration.asSeconds() >= 0:
      const seconds = Math.floor(duration.asSeconds());
      message = `${seconds} second${seconds > 1 ? 's' : ''} ${suffix}`;
      break;
    default:
      message = 'just now';
      break;
  }

  return message;
};

export const getRemainingTimeDetails = (futureDate: string, suffix: string) => {
  const currentDate = moment();
  const duration = moment.duration(moment(futureDate).diff(currentDate));
  return getTimeMessage(duration, suffix);
};

export const getElapsedTimeDetails = (pastDate: string) => {
  const currentDate = moment();
  const duration = moment.duration(currentDate.diff(moment(pastDate)));
  return getTimeMessage(duration, 'ago');
};

// Checks if the param date time is after the current moment
export const isAfterCurrentTime = (inputDateTime: string): boolean => {
  const userTimeZone = moment.tz.guess();
  return moment(inputDateTime).tz(userTimeZone).isAfter(moment.tz(userTimeZone));
};

/**
 * Generates a list of months between the start and end dates in the format 'YYYYMM01'.
 *
 * @param {string} startMonth - The start date in 'YYYY-MM-DD' format.
 * @param {string} endMonth - The end date in 'YYYY-MM-DD' format.
 * @returns {string[]} - An array of month strings in the 'YYYYMM01' format.
 *
 * @example
 * // Returns ['20230101', '20230201', '20230301', '20230401', '20230501', '20230601', '20230701', '20230801']
 * generateMonthList('2023-01-01', '2023-08-31');
 */
export const generateMonthList = (startMonth: string, endMonth: string): string[] => {
  const startDate = moment(startMonth, PLANNING_CYCLE_MONTH_FORMAT);
  const endDate = moment(endMonth, PLANNING_CYCLE_MONTH_FORMAT);
  const monthList: string[] = [];
  while (startDate.isSameOrBefore(endDate, 'month')) {
    monthList.push(startDate.format(FORECAST_TEMPLATE_MONTH_FORMAT));
    startDate.add(1, 'month');
  }
  return monthList;
};

/**
 * Generates a list of months between the start and end dates in the format 'MMM-YYYY'.
 *
 * @param {string} startMonth - The start date in 'YYYY-MM-DD' format.
 * @param {string} endMonth - The end date in 'YYYY-MM-DD' format.
 * @returns {string[]} - An array of month strings in the 'MMM-YYYY' format.
 *
 * @example
 * // Returns ['Jan-2023', 'Feb-2023', 'Mar-2023', 'Apr-2023', 'May-2023', 'Jun-2023', 'Jul-2023', 'Aug-2023']
 * generateMonthList('2023-01-01', '2023-08-31');
 */
export const generateMonthListForExcelImport = (startMonth: string, endMonth: string): string[] => {
  const startDate = moment(startMonth, PLANNING_CYCLE_MONTH_FORMAT);
  const endDate = moment(endMonth, PLANNING_CYCLE_MONTH_FORMAT);
  const monthList: string[] = [];
  while (startDate.isSameOrBefore(endDate, 'month')) {
    monthList.push(startDate.format(FORECAST_TEMPLATE_MONTH_DISPLAY_FORMAT));
    startDate.add(1, 'month');
  }
  return monthList;
};

/**
 * Extracts the year from a date string in the 'YYYYMM01' format.
 *
 * @param {string} input - The date string in the 'YYYYMM01' format.
 * @returns {string} - The year as a string.
 *
 * @example
 * // Returns '2023'
 * getYearFromMonthYear('20230101');
 */
export const getYearFromMonthYear = (input: string): string => {
  return moment(input, FORECAST_TEMPLATE_MONTH_FORMAT).year().toString();
};

/**
 * Extracts the year from a date string in the 'YYYY-MM-DD' format.
 *
 * @param {string} input - The date string in the 'YYYY-MM-DD' format.
 * @returns {number} - The year as a number.
 *
 * @example
 * // Returns 2023
 * getYearFromDate('2023-01-01');
 */
export const getYearFromDate = (input: string): number => {
  return moment(input, PLANNING_CYCLE_MONTH_FORMAT).year();
};

/**
 * Determines the quarter of the year from a date string in the 'YYYYMM01' format.
 *
 * @param {string} dateString - The date string in the 'YYYYMM01' format.
 * @returns {string} - The quarter of the year as 'Q1', 'Q2', 'Q3', or 'Q4'.
 *
 * @example
 * // Returns 'Q2'
 * getQuarterFromMonth('20230601');
 */
export const getQuarterFromMonth = (dateString: string): string => {
  const month: number = moment(dateString, FORECAST_TEMPLATE_MONTH_FORMAT).month() + 1;
  const quarter: number = Math.ceil(month / 3);
  return `Q${quarter}`;
};

/**
 * Converts a date string from 'YYYYMM01' format to 'MMM-YYYY' format.
 *
 * @param {string} monthYear - The date string in 'YYYYMM01' format.
 * @returns {string} - The converted date string in 'MMM-YYYY' format.
 *
 * @example
 * // Returns 'Feb-2023'
 * convertMonthFormatToDisplay('20230201');
 */
export const convertMonthFormatToDisplay = (monthYear: string): string => {
  return moment(monthYear, FORECAST_TEMPLATE_MONTH_FORMAT).format(FORECAST_TEMPLATE_MONTH_DISPLAY_FORMAT);
};

type SortOrder = 'asc' | 'desc';

/**
 * Sorts an array of date strings in 'MMM-YY' format.
 *
 * @param {string[]} dates - An array of date strings to sort.
 * @param {SortOrder} [order='desc'] - The order in which to sort the dates, either 'asc' for ascending or 'desc' for descending.
 * @returns {string[]} The sorted array of date strings.
 *
 * @example
 * const dates: string[] = ["APR-24", "AUG-23", "DEC-23", "FEB-24", "JAN-24", "JUL-23"];
 * const sortedDates: string[] = sortDates(dates);
 * console.log(sortedDates); // ["JUL-23", "AUG-23", "DEC-23", "JAN-24", "FEB-24", "APR-24"]
 *
 * const sortedDatesAsc: string[] = sortDates(dates, 'asc');
 * console.log(sortedDatesAsc); // ["AUG-23", "JUL-23", "DEC-23", "JAN-24", "FEB-24", "APR-24"]
 */
export const sortDatesWithFormat_MMM_YY = (dates: string[], order: SortOrder = 'desc'): string[] => {
  return dates.sort((a, b) => {
    const dateA = moment(a, PO_TAGGING_MONTH_FORMAT);
    const dateB = moment(b, PO_TAGGING_MONTH_FORMAT);
    if (order === 'asc') {
      return dateA.valueOf() - dateB.valueOf();
    } else {
      return dateB.valueOf() - dateA.valueOf();
    }
  });
};

/**
 * Gets the year based on an optional offset value.
 * If the offset is not provided, it returns the current year.
 * If a negative value is passed, it returns the previous year(s).
 * If a positive value is passed, it returns the future year(s).
 *
 * @param {number} [offset=0] - The optional offset value. Default is 0.
 * @returns {number} - The calculated year.
 */
export const getYearWithOffset = (offset: number = 0): number => {
  return moment().add(offset, 'years').year();
};

/**
 * Gets the current year, month, and date.
 *
 * @returns {object} - An object with the year, month, and date properties.
 */
export const getCurrentYearMonthDate = (): { year: number; month: number; date: number } => {
  const momentDate = moment();
  return {
    year: momentDate.year(),
    month: momentDate.month() + 1, // Moment.js months are zero-indexed
    date: momentDate.date()
  };
};

/**
 * Formats a date string based on the given input and output formats.
 * @param dateStr - The date string to format.
 * @param inputFormat - The format of the input date string.
 * @param outputFormat - The desired format of the output date string.
 * @returns The formatted date string.
 */
export const formatDateString = (dateStr: string, inputFormat: string, outputFormat: string): string => {
  return moment(dateStr, inputFormat).format(outputFormat);
};

// Define the ISO8601 format
const ISO8601Format = 'YYYY-MM-DDTHH:mm:ssZ';

export const convertDateRelativeRangePicker = (relativeRange: DateRelativeRangePicker) => {
  let startDate = moment.tz(moment.tz.guess());
  const endDate = moment.tz(moment.tz.guess());

  // Calculate the start date based on the relative range
  if (relativeRange.type === 'relative') {
    const now = moment.tz(moment.tz.guess()); // Get current time in local timezone
    startDate = now.subtract(relativeRange.amount, relativeRange.unit);
  } else {
    throw new Error('Unsupported range type');
  }

  // Define the end date as the end of the current day

  // Format the dates in the desired format
  return {
    start: startDate.format(ISO8601Format),
    end: endDate.format(ISO8601Format)
  };
};
