export enum Status {
  NotInitiated = 'NotInitiated',
  Validating = 'Validating',
  Loading = 'Loading',
  Completed = 'Completed',
  Failed = 'Failed',
  Expired = 'Expired'
}

export interface UserAuthContext {
  userAlias: string;
  userDisplayName: string;
  userGivenName: string;
  userEmail: string;
  isDev: boolean;
  isInitiallyDev: boolean;
  isUserRoleModified: boolean;
  userAuthenticationStatus: Status;
}
