import { Alert, AppLayout, ContentLayout, Flashbar, Header, Link, SpaceBetween } from '@amzn/awsui-components-react';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useSelector } from 'react-redux';
import { logger } from 'src/analytics/KatalLogger';
import { ErrorFallback } from 'src/components/common/ErrorFallback';
import { LoadingSpinner } from 'src/components/common/LoadingSpinner';
import { XPTBreadcrumbs } from 'src/components/common/XptBreadcrumb';
import { fetchForecastPlanningCycles, fetchPlanningCycles } from 'src/features/planning-cycle/planningCycleSlice';
import BusinessGroupSideNavigation from 'src/features/xpt-layout/XptBusinessGroupSideNavigation';
import { useFlashbar } from 'src/hooks/useFlashbar';
import { Status } from 'src/models/AuthContextModels';
import { fetchAccountBudgetTypeMappings, fetchExpenseTypeMappings } from 'src/store/slices/xPTMapperSlice';
import { useAppDispatch } from 'src/store/useAppDispatch';
import {
  businessGroupBaseBreadcrumbs,
  currentBusinessGroup,
  currentBusinessGroupName,
  currentBusinessGroupShortDesc,
  currentDataClassificationId
} from '../businessGroupSelectors';
import { getForecastTemplateBreadcrumbItems } from './forecast-utils/ForecastTemplateUtils';
import { ForecastTemplateContext } from './ForecastTemplateContext';
import ForecastTemplateFilterSelection from './ForecastTemplateFilterSelection';
import ForecastTemplateGrid from './ForecastTemplateGrid';
import { ForecastTemplateInfoPanel } from './ForecastTemplateInfoPanel';
import ForecastTemplateStatusIndicator from './ForecastTemplateStatusIndicator';
import { fetchLineItemIdsArchived } from './redux/forecastTemplateSlice';

const ForecastTemplateMainPage: React.FC = () => {
  const appLayout = useRef<any>();
  const [toolsOpen, setToolsOpen] = useState(false);
  const dispatch = useAppDispatch();

  // Selectors for state management
  const businessGroup = useSelector(currentBusinessGroup);
  const businessGroupName = useSelector(currentBusinessGroupName);
  const businessGroupShortDesc = useSelector(currentBusinessGroupShortDesc);
  const dataClassificationId = useSelector(currentDataClassificationId);
  const businessGroupBaseBreadcrumb = useSelector(businessGroupBaseBreadcrumbs);

  const { flashbarItems, displayFlashMessage, clearSpecificFlashMessage, clearAllMessages } = useFlashbar();

  const [forecastInitializationStatus, setForecastInitializationStatus] = useState<Status>(Status.NotInitiated);

  const initializeForecastTemplate = useCallback(async () => {
    if (!businessGroup || !dataClassificationId || !businessGroupShortDesc) {
      setForecastInitializationStatus(Status.NotInitiated);
      return;
    }

    setForecastInitializationStatus(Status.Loading);

    try {
      logger.info(`Initializing Forecast Template for ${businessGroupShortDesc}`);
      const responses = await Promise.all([
        dispatch(fetchLineItemIdsArchived({ businessGroupShortDesc, dataClassificationId })),
        dispatch(fetchPlanningCycles()),
        dispatch(fetchForecastPlanningCycles()),
        dispatch(fetchExpenseTypeMappings()),
        dispatch(fetchAccountBudgetTypeMappings())
      ]);

      // Check for rejected requests and log details
      const errors = responses.filter((result) => result.meta.requestStatus === 'rejected');
      if (errors.length > 0) {
        logger.error('Errors during Forecast Template initialization:', errors);
        throw new Error('One or more actions failed during forecast initialization.');
      }

      setForecastInitializationStatus(Status.Completed);
    } catch (error: any) {
      setForecastInitializationStatus(Status.Failed);
      logger.error('Unable to initialize Forecast Template', error);
    }
  }, [dispatch, businessGroup, dataClassificationId, businessGroupShortDesc]);

  useEffect(() => {
    initializeForecastTemplate();
  }, [initializeForecastTemplate]);

  const breadcrumbsItems = useMemo(
    () => getForecastTemplateBreadcrumbItems(businessGroupBaseBreadcrumb, businessGroupName),
    [businessGroupBaseBreadcrumb, businessGroupName]
  );

  const contextValue = useMemo(
    () => ({
      notificationMessage: displayFlashMessage,
      clearSpecificFlashMessage,
      clearAllMessages
    }),
    [displayFlashMessage, clearSpecificFlashMessage, clearAllMessages]
  );

  if (!businessGroup) {
    return <LoadingSpinner />;
  }

  const errorMessage = 'Please try refreshing the page or contact support if the issue persists.';
  const errorAlert = () => (
    <Alert type="error" header="Failed to initialize Forecast Template.">
      {errorMessage}
    </Alert>
  );

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <ForecastTemplateContext.Provider value={contextValue}>
        <AppLayout
          ref={appLayout}
          headerSelector="#h"
          navigation={<BusinessGroupSideNavigation />}
          tools={<ForecastTemplateInfoPanel />}
          toolsOpen={toolsOpen}
          onToolsChange={({ detail }) => setToolsOpen(detail.open)}
          breadcrumbs={<XPTBreadcrumbs items={breadcrumbsItems} />}
          notifications={<Flashbar stackItems items={flashbarItems} />}
          maxContentWidth={Number.MAX_VALUE}
          contentType="default"
          content={
            <ContentLayout
              header={
                <Header
                  info={
                    <Link variant="info" onFollow={() => setToolsOpen(true)}>
                      Info
                    </Link>
                  }
                  actions={
                    <SpaceBetween size="xxxs" direction="horizontal">
                      <ForecastTemplateStatusIndicator />
                    </SpaceBetween>
                  }
                >
                  {`Forecast Input`}
                </Header>
              }
            >
              {forecastInitializationStatus === Status.Loading && <LoadingSpinner />}
              {forecastInitializationStatus === Status.Failed && errorAlert()}
              {forecastInitializationStatus === Status.Completed && (
                <SpaceBetween size="xs" direction="vertical">
                  <ForecastTemplateFilterSelection />
                  <ForecastTemplateGrid />
                </SpaceBetween>
              )}
            </ContentLayout>
          }
        />
      </ForecastTemplateContext.Provider>
    </ErrorBoundary>
  );
};

export default ForecastTemplateMainPage;
