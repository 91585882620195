import { createSelector } from '@reduxjs/toolkit';
import { COST_CENTER_SEGMENT_NAME } from 'src/constants/corp-segment-constants';
import { eLocalStorageKeys, FINANCE_ADMIN } from 'src/constants/generic-constants';
import { BusinessGroupEntity } from 'src/models/AppContextModels';
import { CostCenterValuesPerBusinessGroup } from 'src/models/ForecastModels';
import {
  CorpSegmentAccess,
  CurrentUserAccessDetails,
  UserAccessEntity,
  UserAccessForCurrentBusinessGroup,
  UserDataClassifications
} from 'src/models/XptUsersModel';
import { RootState } from 'src/store/store';

// Selector to get xptUsers from state
export const selectXptUsers = (state: RootState) => state.xptAccessAndAuthorizationStore.xptUsers;

// Selector to get all users (now using selectXptUsers instead of selectUserAuthorization)
export const selectAllUsers = createSelector(selectXptUsers, (xptUsers) => xptUsers);

// Selector to get users by alias (handles multiple users with the same alias)
export const selectUsersByAlias = (userAlias: string) =>
  createSelector(selectAllUsers, (users) => users.filter((user) => user.user_alias === userAlias && user.is_active));
