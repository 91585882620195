import { DateRangePicker, DateRangePickerProps } from '@amzn/awsui-components-react';
import React, { useEffect, useState } from 'react';
import { dateRangeI18n } from 'src/i18n-strings';
import { DateAbsoluteRangePicker, DateRelativeRangePicker } from 'src/models/AppContextModels';
import { convertDateRelativeRangePicker } from 'src/utils/date-time-utilities';

const DEFAULT_RELATIVE_OPTION: DateRangePickerProps.Value = { key: 'previous-1-year', amount: 1, unit: 'year', type: 'relative' };
const DEFAULT_RELATIVE_OPTIONS = [
  { key: 'previous-24-hours', amount: 24, unit: 'hour', type: 'relative' },
  { key: 'previous-7-days', amount: 7, unit: 'day', type: 'relative' },
  { key: 'previous-30-days', amount: 30, unit: 'day', type: 'relative' },
  { key: 'previous-90-days', amount: 90, unit: 'day', type: 'relative' },
  { key: 'previous-1-year', amount: 1, unit: 'year', type: 'relative' }
] as DateRangePickerProps.RelativeOption[];

interface DateTimeRangePickerProps {
  onDateChange?: (startDate: string | null, endDate: string | null) => void; // New prop
}

export const DateTimeRangePicker: React.FC<DateTimeRangePickerProps> = ({ onDateChange }) => {
  const [dateTimeRangeValue, setDateTimeRangeValue] = React.useState<DateRangePickerProps.Value | null>(DEFAULT_RELATIVE_OPTION);

  const [startDateTime, setStartDateTime] = useState<string | null>(null);
  const [endDateTime, setEndDateTime] = useState<string | null>(null);

  useEffect(() => {
    if (dateTimeRangeValue) {
      const dateTimeRangeType: 'relative' | 'absolute' = dateTimeRangeValue.type;
      if (dateTimeRangeType === 'relative') {
        const relativeValue: DateRelativeRangePicker = dateTimeRangeValue as DateRelativeRangePicker;
        const { start, end } = convertDateRelativeRangePicker(relativeValue);
        setStartDateTime(start);
        setEndDateTime(end);
      } else {
        const absoluteValue: DateAbsoluteRangePicker = dateTimeRangeValue as DateAbsoluteRangePicker;
        setStartDateTime(absoluteValue.startDate);
        setEndDateTime(absoluteValue.endDate);
      }
    }
  }, [dateTimeRangeValue]);

  // Call the onDateChange prop when startDateTime or endDateTime changes
  useEffect(() => {
    if (onDateChange) {
      onDateChange(startDateTime, endDateTime);
    }
  }, [startDateTime, endDateTime, onDateChange]);

  return (
    <>
      <DateRangePicker
        expandToViewport
        i18nStrings={dateRangeI18n()}
        value={dateTimeRangeValue}
        onChange={({ detail }) => setDateTimeRangeValue(detail.value)}
        relativeOptions={DEFAULT_RELATIVE_OPTIONS}
        isValidRange={() => ({ valid: true })}
        placeholder={'Select a date & time range'}
        rangeSelectorMode={'default'}
        showClearButton={true}
        dateOnly={false}
        absoluteFormat="long-localized"
      />
    </>
  );
};
