import { AttributeEditor, Box, Button, Input, Modal, SpaceBetween } from '@amzn/awsui-components-react';
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { updateResourcesData } from 'src/api/app-sync-services';
import { Resources } from 'src/models/AppContextModels';
import { useAuth } from 'src/app/auth/AuthContextProvider';
import { getCurrentUTCTimeInISO } from 'src/utils/date-time-utilities';

interface ResourcesFormProps {
  showModal: boolean;
  onCancel: () => void;
  onConfirm: (updatedResources: Resources[]) => void;
  currentResources: Resources[];
}

interface Item {
  key: string;
  value: string;
}

interface ItemError {
  key?: string;
  value?: string;
}

// Yup schema for URL validation
const urlSchema = Yup.string().url('Value must be a valid URL.');

export const ResourcesForm: React.FC<ResourcesFormProps> = ({ showModal, onCancel, onConfirm, currentResources }) => {
  const userAuthDetails = useAuth();
  const [items, setItems] = useState<Item[]>([]);
  const [errors, setErrors] = useState<ItemError[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [message, setMessage] = useState<string | null>(null);

  // Reset message and initialize items and errors when the modal is shown
  useEffect(() => {
    if (showModal) {
      setMessage(null);
      const currentResourceItems = currentResources.map((currentResource) => ({
        key: currentResource.resource_name,
        value: currentResource.resource_link
      }));
      setItems(currentResourceItems);
      setErrors(currentResourceItems.map(() => ({})));
    }
  }, [showModal, currentResources]);

  // Handle input changes and validate
  const handleInputChange = (index: number, field: 'key' | 'value', value: string) => {
    const newItems = [...items];
    newItems[index][field] = value;
    setItems(newItems);

    // Validate the input
    const newErrors = [...errors];
    if (field === 'key' && value.length > 200) {
      newErrors[index][field] = 'Key cannot exceed 200 characters.';
    } else if (field === 'value') {
      urlSchema
        .validate(value)
        .then(() => {
          newErrors[index][field] = undefined;
        })
        .catch((err) => {
          newErrors[index][field] = err.message;
        });
    } else {
      newErrors[index][field] = undefined;
    }
    setErrors(newErrors);
  };

  // Validate all items
  const validateItems = async () => {
    const newErrors = await Promise.all(
      items.map(async (item) => {
        const itemErrors: ItemError = {};
        if (!item.key.trim()) {
          itemErrors.key = 'name cannot be empty.';
        }
        if (!item.value.trim()) {
          itemErrors.value = 'link cannot be empty.';
        }
        try {
          await urlSchema.validate(item.value);
        } catch (err) {
          itemErrors.value = (err as Yup.ValidationError).message;
        }
        return itemErrors;
      })
    );
    setErrors(newErrors);
    const isValid = newErrors.every((error) => Object.keys(error).length === 0);
    return isValid;
  };

  // Handle cancel action
  const handleCancel = () => {
    onCancel();
  };

  // Handle submit action
  const handleSubmit = async () => {
    if (!(await validateItems())) {
      setMessage('Please fix the errors before submitting.');
      return;
    }

    setIsSubmitting(true);
    setMessage('Submitting...');
    const updatedResources = items.map((item) => ({
      resource_name: item.key,
      resource_link: item.value,
      item_metadata: {
        created_at: getCurrentUTCTimeInISO(),
        created_by: userAuthDetails.userAlias,
        is_active: true,
        updated_at: getCurrentUTCTimeInISO(),
        updated_by: userAuthDetails.userAlias
      }
    }));

    try {
      await updateResourcesData(updatedResources);
      setMessage('Resources updated successfully.');
      onConfirm(updatedResources);
    } catch (error: any) {
      setMessage(`Failed to update resources: ${error.message}`);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal
      onDismiss={onCancel}
      visible={showModal}
      size="large"
      footer={
        <Box>
          {message && (
            <Box float="left" margin={{ top: 's' }} color="text-status-info">
              {message}
            </Box>
          )}
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <Button variant="link" onClick={handleCancel} disabled={isSubmitting}>
                Cancel
              </Button>
              <Button variant="primary" onClick={handleSubmit} disabled={isSubmitting}>
                {isSubmitting ? 'Submitting...' : 'Submit'}
              </Button>
            </SpaceBetween>
          </Box>
        </Box>
      }
      header="Resources"
    >
      <AttributeEditor
        onAddButtonClick={() => {
          setItems([...items, { key: '', value: '' }]);
          setErrors([...errors, {}]);
        }}
        onRemoveButtonClick={({ detail: { itemIndex } }) => {
          const newItems = [...items];
          const newErrors = [...errors];
          newItems.splice(itemIndex, 1);
          newErrors.splice(itemIndex, 1);
          setItems(newItems);
          setErrors(newErrors);
        }}
        items={items}
        addButtonText="Add new resource"
        removeButtonText="Remove"
        definition={[
          {
            label: 'Resource name',
            control: (item, index) => (
              <>
                <Input value={item.key} onChange={({ detail }) => handleInputChange(index, 'key', detail.value)} placeholder="xPT Resource Name" />
                {errors[index]?.key && <Box color="text-status-error">{errors[index].key}</Box>}
              </>
            )
          },
          {
            label: 'Resource link',
            control: (item, index) => (
              <>
                <Input
                  value={item.value}
                  onChange={({ detail }) => handleInputChange(index, 'value', detail.value)}
                  placeholder="https://example.amazon.com/"
                />
                {errors[index]?.value && <Box color="text-status-error">{errors[index].value}</Box>}
              </>
            )
          }
        ]}
        empty="No items"
      />
    </Modal>
  );
};
