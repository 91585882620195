import { COST_CENTER_SEGMENT_NAME } from 'src/constants/corp-segment-constants';
import { BUDGET_LEADER, BUDGET_OWNER, eLocalStorageKeys, FINANCE_ADMIN, FINANCE_OWNER } from 'src/constants/generic-constants';
import { BusinessGroupEntity } from 'src/models/AppContextModels';
import { CostCenterValuesPerBusinessGroup } from 'src/models/ForecastModels';
import {
  CorpSegmentAccess,
  CurrentUserAccessDetails,
  UserAccessEntity,
  UserAccessForCurrentBusinessGroup,
  UserDataClassifications
} from 'src/models/XptUsersModel';

export const getCurrentUserAccessDetails = (
  userAlias: string,
  isDev: boolean,
  currentUsersPermissionFromAllBusinessGroups: UserAccessEntity[],
  activeBusinessGroups: BusinessGroupEntity[],
  costCenterForAllBusinessGroups: CostCenterValuesPerBusinessGroup[]
): { currentUserAccessDetails: CurrentUserAccessDetails | null; currentUserNotFound: boolean } => {
  // Modification of user access can only happen at developer.
  const userAccessFromLocalStorage: string | null = localStorage.getItem(eLocalStorageKeys.UserAccessDetails);

  if (userAccessFromLocalStorage) {
    const userAccessFromLocalStorageParsed = JSON.parse(userAccessFromLocalStorage) as unknown as CurrentUserAccessDetails;
    return {
      currentUserAccessDetails: {
        user_alias: userAlias,
        user_access_modified: true,
        user_data_classifications: userAccessFromLocalStorageParsed.user_data_classifications
      },
      currentUserNotFound: false
    };
  }

  if (isDev) {
    const userDataClassifications: UserDataClassifications[] = activeBusinessGroups.map((bg) => {
      const businessGroupCostCenters = costCenterForAllBusinessGroups.find(
        (ccPerBusinessGroup) => ccPerBusinessGroup.dataClassificationId === bg.data_classification.data_classification_id
      );

      const costCenterSegment: CorpSegmentAccess = {
        segment_name: COST_CENTER_SEGMENT_NAME,
        segment_value: businessGroupCostCenters ? businessGroupCostCenters.costCenters : []
      };

      const userDataClassification: UserDataClassifications = {
        data_classification_id: bg.data_classification.data_classification_id!,
        user_type: FINANCE_ADMIN,
        corp_segment_access: [costCenterSegment]
      };
      return userDataClassification;
    });

    return {
      currentUserAccessDetails: {
        user_alias: userAlias,
        user_access_modified: false,
        user_data_classifications: userDataClassifications
      },
      currentUserNotFound: false
    };
  }

  if (currentUsersPermissionFromAllBusinessGroups.length === 0) {
    return { currentUserAccessDetails: null, currentUserNotFound: true };
  }

  const userDataClassifications: UserDataClassifications[] = currentUsersPermissionFromAllBusinessGroups.map((user) => ({
    data_classification_id: user.data_classification_id!,
    user_type: user.user_type!,
    corp_segment_access: user.corp_segment_access
  }));

  return {
    currentUserAccessDetails: {
      user_alias: userAlias,
      user_access_modified: false,
      user_data_classifications: userDataClassifications
    },
    currentUserNotFound: false
  };
};

export const getUserCostCenters = (userAccessForCurrentBusinessGroup: UserAccessForCurrentBusinessGroup): string[] => {
  const userCCs = userAccessForCurrentBusinessGroup.corp_segment_access
    .filter((segment) => segment.segment_name === COST_CENTER_SEGMENT_NAME)
    .flatMap((segment) => segment.segment_value);

  return userCCs;
};

export const getUserCostCentersFromUserAccessEntitiesList = (userAlias: string, xptUsersOfCurrentDataClassification: UserAccessEntity[]) => {
  const userAccess = xptUsersOfCurrentDataClassification.find((user) => user.user_alias === userAlias);

  if (!userAccess) {
    return [];
  }

  const givenUsersCCs = userAccess.corp_segment_access
    .filter((segment) => segment.segment_name === COST_CENTER_SEGMENT_NAME)
    .flatMap((segment) => segment.segment_value);

  return givenUsersCCs;
};

export const currentUserToUserAccessForCurrentBusinessGroup = (currentUserAccessDataClassification: UserDataClassifications, userAlias: string) => {
  // User is FINANCE_ADMIN or FINANCE_OWNER in the current Business Group.
  const isFinanceAdminOrFinanceOwner =
    currentUserAccessDataClassification.user_type === FINANCE_ADMIN || currentUserAccessDataClassification.user_type === FINANCE_OWNER;

  const isFinanceAdmin = currentUserAccessDataClassification.user_type === FINANCE_ADMIN;
  const isFinanceOwner = currentUserAccessDataClassification.user_type === FINANCE_OWNER;
  const isBudgetLeader = currentUserAccessDataClassification.user_type === BUDGET_LEADER;
  const isBudgetOwner = currentUserAccessDataClassification.user_type === BUDGET_OWNER;

  const userAccessForCurrentBusinessGroup: UserAccessForCurrentBusinessGroup = {
    user_alias: userAlias,
    data_classification_id: currentUserAccessDataClassification.data_classification_id,
    user_type: currentUserAccessDataClassification.user_type,
    corp_segment_access: currentUserAccessDataClassification.corp_segment_access,
    isFinanceAdminOrFinanceOwner,
    isFinanceAdmin,
    isFinanceOwner,
    isBudgetLeader,
    isBudgetOwner
  };

  return userAccessForCurrentBusinessGroup;
};
