import { AdvancedFilterModel, ColumnState, FilterModel, GridApi, GridState, RowGroupState } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { RefObject, useCallback, useState } from 'react';

interface GridStateCache {
  gridState: GridState | null;
  columnState: ColumnState[];
  columnGroupState: {
    groupId: string;
    open: boolean;
  }[];
  filterState: FilterModel;
  advancedFilterState: AdvancedFilterModel | null;
  isPivotMode: boolean; // Added isPivotMode
}

export const useGridStateV2 = (gridApiRef: RefObject<AgGridReact>, key: string) => {
  const [isPivotMode, setIsPivotMode] = useState(false); // Local state for pivot mode

  const atLeastOneStateAvailable = useCallback(() => {
    return !!localStorage.getItem(key);
  }, [key]);

  const saveGridState = useCallback(() => {
    const gridApi: GridApi | undefined = gridApiRef?.current?.api;
    if (!gridApi) return;

    try {
      const gridState: GridStateCache = {
        gridState: gridApi.getState(),
        columnState: gridApi.getColumnState(),
        columnGroupState: gridApi.getColumnGroupState(),
        filterState: gridApi.getFilterModel(),
        advancedFilterState: gridApi.getAdvancedFilterModel(),
        isPivotMode: gridApi.isPivotMode() // Save current pivot mode
      };

      localStorage.setItem(key, JSON.stringify(gridState));
    } catch (error) {
      console.error('Failed to save grid state:', error);
    }
  }, [gridApiRef, key]);

  const restoreGridState = useCallback(() => {
    const gridRef: AgGridReact<any> | null = gridApiRef?.current;
    const gridApi: GridApi | undefined = gridRef?.api;
    if (!gridRef || !gridApi) return;

    try {
      const stateJSON = localStorage.getItem(key);
      if (stateJSON) {
        const gridStateCache: GridStateCache = JSON.parse(stateJSON);

        gridRef.setState(gridStateCache.gridState);
        gridApi.applyColumnState({ state: gridStateCache.columnState, applyOrder: true });

        // Restore column widths explicitly
        gridStateCache.columnState.forEach((state) => {
          if (state.width) {
            gridApi.setColumnWidths([{ key: state.colId, newWidth: state.width }], true);
          }
        });

        gridApi.setColumnGroupState(gridStateCache.columnGroupState);
        gridApi.setFilterModel(gridStateCache.filterState);
        gridApi.setAdvancedFilterModel(gridStateCache.advancedFilterState);
        gridApi.setRowGroupColumns(gridStateCache.gridState?.rowGroup?.groupColIds ?? []);

        // Set local pivot mode state
        setIsPivotMode(gridStateCache.isPivotMode);
      }
    } catch (error) {
      console.error('Failed to restore grid state:', error);
    }
  }, [gridApiRef, key]);

  const clearGridState = useCallback(() => {
    localStorage.removeItem(key);
    setIsPivotMode(false); // Reset local pivot mode state
  }, [key]);

  return { atLeastOneStateAvailable, saveGridState, restoreGridState, clearGridState, isPivotMode };
};
