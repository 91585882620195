import {
  CloudWatchClient,
  CloudWatchClientConfig,
  GetDashboardCommand,
  GetDashboardCommandInput,
  GetDashboardCommandOutput
} from '@aws-sdk/client-cloudwatch';
import ENVIRONMENT_VARIABLES from 'src/constants/environment-variables';
import { getAWSServiceCredentials } from './auth-token-services';

const getCloudWatchClient = async () => {
  const cloudWatchClientConfig: CloudWatchClientConfig = {
    region: ENVIRONMENT_VARIABLES.env.Region,
    credentials: await getAWSServiceCredentials()
  };
  const client = new CloudWatchClient(cloudWatchClientConfig);
  return client;
};

export const getCWDashboard = async (dashboardName: string): Promise<GetDashboardCommandOutput> => {
  const cloudWatchClient = await getCloudWatchClient();
  const getDashboardCommandInput: GetDashboardCommandInput = {
    DashboardName: dashboardName
  };
  const getDashboardCommand = new GetDashboardCommand(getDashboardCommandInput);
  const dashboard = await cloudWatchClient.send(getDashboardCommand);
  return dashboard;
};
