import { eEntityStatus } from 'src/constants/generic-constants';
import { BaselineScenario } from './PlanningCycleModel';

export interface DropdownModel {
  label: string;
  value: string;
}

export interface DropdownGroupModel {
  label: string;
  options: DropdownModel[];
}

export type TimeUnit = 'second' | 'minute' | 'hour' | 'day' | 'week' | 'month' | 'year';

export interface DateRelativeRangePicker {
  key?: string;
  type: 'relative';
  amount: number;
  unit: TimeUnit;
}

export interface DateAbsoluteRangePicker {
  type: 'absolute';
  startDate: string;
  endDate: string;
}

export interface BusinessGroupEntity {
  data_classification: DataClassification;
  corp_segments: CorpSegmentsEntity[] | [];
  business_segments: BusinessSegmentsEntity[] | [];
  status: eEntityStatus;
  item_metadata: ItemMetadata;
}

export interface BusinessGroupEntityInput extends BusinessGroupEntity {
  s3_bucket_name: string;
  s3_region: string;
  corp_segment_bucket: string;
}

export type CorpSegmentDefaultDataType = 'dropdown';
export type BusinessSegmentDataType = 'text' | 'number' | 'dropdown';

export const BusinessSegmentDataTypes: { label: BusinessSegmentDataType; value: BusinessSegmentDataType }[] = [
  { label: 'text', value: 'text' },
  { label: 'number', value: 'number' },
  { label: 'dropdown', value: 'dropdown' }
];

export interface BusinessSegmentsEntity {
  business_segment_id: number | null;
  is_id_column: boolean;
  business_segment_name: string;
  business_segment_name_key: string | null;
  business_segment_data_type: BusinessSegmentDataType;
  business_segment_values_s3_path: string | null;
}

export interface CorpSegmentsEntity {
  corp_segment_data_type: CorpSegmentDefaultDataType;
  corp_segment_default_value: SegmentHierarchy;
  corp_segment_id: number;
  corp_segment_name: string;
  corp_segment_name_key: string;
  corp_segment_required: boolean;
  corp_segment_values_s3_path: string;
}

export interface CorpSegmentsValueEntity extends CorpSegmentsEntity {
  corp_segment_values_s3_data_fetched: SegmentHierarchy[];
}

export interface DataClassification {
  data_classification_id: number | null;
  data_classification_name: string;
  data_classification_short_description: string;
  data_classification_description: string;
}

export interface MasterSegment {
  segment_id: number;
  segment_name: string;
  segment_name_key: string;
  segment_category: string;
  description: string;
  segment_data_type: BusinessSegmentDataType;
  segment_hierarchy_s3_path: string | null; // Use the more general type here for flexibility
}

export interface MasterCorpSegments extends MasterSegment {
  // Corp Segment - specific properties would go here if needed in the future
  segment_hierarchy_s3_path: string; // Overriding to make non-nullable if all corp segments must have a path
  segment_hierarchy_data_from_s3: SegmentHierarchy[];
}

export interface MasterBusinessSegments extends MasterSegment {
  // Business Segment - specific properties would go here if needed in the future
  business_segment_dropdown_list: string[];
}

export interface SegmentHierarchy {
  segment_hierarchy: string[];
  segment_description: string;
}

export interface BusinessSegmentsRaw {
  expense_type_options: string;
}

export interface ItemMetadata {
  created_at: string;
  created_by: string;
  is_active: boolean;
  updated_at: string;
  updated_by: string;
}

export interface ContactDetails {
  contact_alias: string;
  contact_name: string;
}

export interface ContactInformation {
  contact_id: number | null;
  group_name: string;
  primary_contact: ContactDetails;
  other_contacts: ContactDetails[];
  item_metadata: ItemMetadata;
}

export interface Resources {
  resource_name: string;
  resource_link: string;
  item_metadata: ItemMetadata;
}

export interface AnnouncementDataClassification {
  data_classification_id: number;
  data_classification_name: string;
}

export interface Announcement {
  announcement_id: number | null;
  data_classification: AnnouncementDataClassification[];
  announcement_date: string;
  announcement_message: string;
  announcement_title: string;
  item_metadata: ItemMetadata;
}

export interface UserMetricsRaw {
  metrics_name: string;
  metrics_payload: string; // this is an AWS JSON in string. have parse twice to get the formatted and parsed details.
  created_at: string;
  created_by: string;
}

export interface MonthlyUniqueUsers {
  metrics_name: string;
  metrics_payload: MonthlyUniqueUsersPayloadEntity[] | [];
  created_at: string;
  created_by: string;
}
export interface MonthlyUniqueUsersPayloadEntity {
  log_date: string;
  distinct_users_count: number;
  user_list: string[] | null;
}

export interface AuditLogEntity {
  audit_log_id: number;
  data_classification_id?: number | null;
  page_name: string;
  operation_type: string;
  status: string;
  record_count?: number | null;
  additional_info?: any;
  created_at: string;
  created_by: string;
}

export interface AuditTableDefinitionsProps {
  handleAdditionalInfoClick: (auditLogId: number) => void;
}

export interface AppRedirectInfo {
  from: string;
  redirectFromHomePage: boolean;
  fromAppName?: string;
  fromAppId?: string;
}
