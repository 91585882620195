import { SideNavigation, SideNavigationProps } from '@amzn/awsui-components-react';
import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from 'src/app/auth/AuthContextProvider';
import { FINANCE_ADMIN } from 'src/constants/generic-constants';
import { RootState } from 'src/store/store';
import { findActiveHref } from 'src/utils/navigation-utilities';

const SECTION_DIVIDER: SideNavigationProps.Divider = { type: 'divider' };

// Navigation items accessible by both isDev and isFinanceAdmin users
const financeAdminSideNavItems: SideNavigationProps.Item[] = [
  {
    type: 'section-group',
    title: 'Finance Admin Tools',
    items: [
      { type: 'link', text: 'Planning Cycles', href: '/admin-console/planning-cycle' },
      { type: 'link', text: 'CapEx File Upload', href: '/admin-console/capex' },
      { type: 'link', text: 'Project Mapping', href: '/admin-console/project' }
    ]
  }
];

// Navigation items accessible only by isDev users
const developerNavItems: SideNavigationProps.Item[] = [
  {
    type: 'section-group',
    title: 'Developer Tools',
    items: [
      { type: 'link', text: 'Business Groups', href: '/admin-console/onboarding' },
      { type: 'link', text: 'Audit Log', href: '/admin-console/audit-log' },
      {
        type: 'section',
        text: 'Application Monitor',
        items: [
          { type: 'link', text: 'User Visits', href: '/admin-console/user-visits' },
          { type: 'link', text: 'API Stats', href: '/admin-console/api-stats' }
        ]
      }
    ]
  }
];

/**
 * AdminConsoleSideNavigation component
 * Renders side navigation for the Admin Console based on user's roles (isDev, isFinanceAdmin).
 * Uses AWSUI SideNavigation component and responds to route changes.
 */
const AdminConsoleSideNavigation: React.FC = () => {
  // Get authentication info (isDev flag) and user authorization (isFinanceAdmin flag)
  const { isDev } = useAuth();
  const { currentUserAccessDetails, currentUserAccessLoadingStatus } = useSelector((state: RootState) => state.xptAccessAndAuthorizationStore);
  // Check if user is a Finance Admin for at least 1 Business Group.
  const isFinanceAdmin = currentUserAccessDetails?.user_data_classifications.some((classification) => classification.user_type === FINANCE_ADMIN);

  const { pathname } = useLocation();
  const navigate = useNavigate();

  // Initialize the side navigation items array
  let sideNavigationItems: SideNavigationProps.Item[] = [];

  // If the user is a developer, show both Finance Admin and Developer Tools
  if (isDev) {
    sideNavigationItems = [...financeAdminSideNavItems, SECTION_DIVIDER, ...developerNavItems];
  } else {
    // If the user is not a developer, handle Finance Admin access
    if (isFinanceAdmin) {
      // Add Finance Admin tools if the user is a Finance Admin
      sideNavigationItems = [...sideNavigationItems, ...financeAdminSideNavItems];
    }
  }

  // Determine the currently active link based on the current URL pathname
  const activeHref = findActiveHref(pathname, sideNavigationItems);

  return (
    <SideNavigation
      data-testid="admin-console-side-navigation"
      activeHref={activeHref}
      onFollow={(event) => {
        // Prevent navigation if the link is internal and navigate via React Router
        if (!event.detail.external) {
          event.preventDefault();
          navigate(event.detail.href);
        }
      }}
      items={sideNavigationItems} // Set the dynamic side navigation items
    />
  );
};

export default AdminConsoleSideNavigation;
