import { Box, Link } from '@amzn/awsui-components-react';
import React from 'react';
import { logUserClick } from 'src/analytics/KatalLogger';
import { PHONE_TOOL_URL, USER_IMG_BASE_URL } from 'src/constants/generic-constants';

// UserImage Component
interface UserImageProps {
  alias: string;
  size?: number;
}

const UserImage: React.FC<UserImageProps> = React.memo(({ alias, size = 20 }) => {
  const imageUrl = `${USER_IMG_BASE_URL}${alias}`;

  return (
    <img
      src={imageUrl}
      alt={`User: ${alias}`}
      style={{
        width: `${size}px`,
        height: `${size}px`,
        borderRadius: '50%',
        marginRight: '8px'
      }}
    />
  );
});

UserImage.displayName = 'UserImage';

// UserLink Component
interface UserLinkProps {
  alias: string;
  displayName: string;
  variant: 'small' | 'medium';
  page: string;
  mainPage: string;
}

const UserLink: React.FC<UserLinkProps> = ({ alias, displayName, variant, page, mainPage }) => {
  const userPhoneToolLink = `${PHONE_TOOL_URL}${alias}`;

  if (variant === 'medium') {
    return (
      <Box>
        <Link
          fontSize="heading-m"
          target="_blank"
          variant="secondary"
          href={userPhoneToolLink}
          onClick={() => logUserClick(`Clicked alias link`, page, mainPage)}
        >
          {displayName}
        </Link>
      </Box>
    );
  }

  return (
    <Link target="_blank" variant="secondary" href={userPhoneToolLink}>
      {displayName}
    </Link>
  );
};

// Main UserDisplay Component
interface UserDisplayProps {
  userAlias: string;
  variant: 'small' | 'medium';
  userName?: string;
  width?: string;
  imgSize?: number;
  page: string;
  mainPage: string;
}

export const UserDisplay: React.FC<UserDisplayProps> = ({ userAlias, userName, width = '30rem', imgSize = 20, variant, page, mainPage }) => {
  const displayName = userName || userAlias;

  return (
    <div style={{ width, display: 'flex', alignItems: 'center' }}>
      <UserImage alias={userAlias} size={imgSize} />
      <UserLink alias={userAlias} displayName={displayName} variant={variant} page={page} mainPage={mainPage} />
    </div>
  );
};
