import { Button, Header, SpaceBetween } from '@amzn/awsui-components-react';
import React, { useEffect, useState } from 'react';
import { DateTimeRangePicker } from 'src/components/common/DateTimeRangePicker';
import XptTable from 'src/components/common/xpt-table/XptTable';
import { getHeaderCounter } from 'src/utils/generic-utilities';
import { LogEntry } from '../models/KPIModels';
import { fetchCloudWatchLogData } from '../services/cloudwatchLogsService';
import { getLogsHeader, getLogsTableConfig } from './UserVisitsConfig';

interface UserVisitsTableProps {
  widgetPropertyQuery: string;
}

export const UserVisitsTable: React.FC<UserVisitsTableProps> = ({ widgetPropertyQuery }) => {
  const entityName = 'User Visits';
  const [loading, setLoading] = useState<boolean>(false);
  const [logData, setLogData] = useState<LogEntry[]>([]);

  const [startDateTime, setStartDateTime] = useState<number | null>(null);
  const [endDateTime, setEndDateTime] = useState<number | null>(null);

  useEffect(() => {
    fetchLoadData();
  }, [widgetPropertyQuery, startDateTime, endDateTime]);

  const fetchLoadData = async () => {
    if (startDateTime && endDateTime) {
      setLoading(true);
      try {
        const data = await fetchCloudWatchLogData(widgetPropertyQuery, startDateTime, endDateTime);
        setLogData(data);
      } catch (err) {
        console.error('Error fetching log data:', err);
      } finally {
        setLoading(false);
      }
    }
  };

  const userVisitsTableHeader = () => {
    return (
      <>
        <Header
          variant="h1"
          actions={
            <SpaceBetween size="m" direction="horizontal">
              <Button onClick={fetchLoadData} iconName="refresh" />
              <DateTimeRangePicker onDateChange={onDateChange} />
            </SpaceBetween>
          }
          description="Latest visits from each unique user."
          counter={getHeaderCounter([], logData)}
        >
          {`${entityName}`}
        </Header>
      </>
    );
  };

  const onDateChange = (startDate: string | null, endDate: string | null) => {
    setStartDateTime(startDate ? new Date(startDate).getTime() : null);
    setEndDateTime(endDate ? new Date(endDate).getTime() : null);
  };

  return (
    <>
      <XptTable
        xptTableVariant="container"
        loadingStatus={loading}
        entityName={entityName}
        xptTableHeader={userVisitsTableHeader()}
        allItems={logData}
        allColumns={getLogsTableConfig(entityName)}
        columnDefinitions={getLogsHeader()}
        selectedItems={[]}
        setSelectedItems={async (items) => {}}
        page={entityName}
        mainPage={`Developer Console`}
      />
    </>
  );
};
