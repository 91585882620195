import { SelectProps } from '@amzn/awsui-components-react';
import { PlanningCycleEntity } from 'src/models/PlanningCycleModel';

export const localStoragePrefix = `forecast_filters`;
export const forecastFilterPlanningCycleKey = (businessGroupShortDesc: string) => `${localStoragePrefix}_${businessGroupShortDesc}_pl_seq_id`;
export const getLocalStorageKeyForCorpSegments = (plSeqId: number, businessGroupShortDesc: string) =>
  `${localStoragePrefix}_${businessGroupShortDesc}_corp_segments_${plSeqId}`;

/**
 * Determines the forecast planning cycle to be selected based on stored value and available cycles.
 *
 * @param {SelectProps.Option | null} storedValue - The previously selected planning cycle stored in localStorage.
 * @param {PlanningCycleEntity[]} forecastPlanningCycles - The current list of valid planning cycles.
 * @returns {PlanningCycleEntity | null} The planning cycle to be selected, or null if no valid cycles are available.
 */
export const getForecastPlanningCycleToBeSelected = (
  storedValue: SelectProps.Option | null,
  forecastPlanningCycles: PlanningCycleEntity[]
): PlanningCycleEntity | null => {
  // If there are no forecast planning cycles, return null
  if (!forecastPlanningCycles.length) {
    return null;
  }

  // If there's no stored value or it's null, return the first available cycle
  if (!storedValue || storedValue.value === null) {
    return forecastPlanningCycles[0];
  }

  // Try to find a matching planning cycle based on the stored value
  const selectedPlanningCycle = forecastPlanningCycles.find((cycle) => `${cycle.scenario_seq_id}` === storedValue.value);

  // Return the matching cycle if found, otherwise return the first available cycle
  // This ensures we always return a valid cycle when available
  return selectedPlanningCycle || forecastPlanningCycles[0];
};
