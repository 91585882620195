import { Box, HelpPanel, SpaceBetween } from '@amzn/awsui-components-react';
import React from 'react';

export const XptForecastReportInfoPanel: React.FC = () => {
  return (
    <>
      <HelpPanel header={<h2>Forecast Report</h2>}>
        <SpaceBetween size="l">
          <Box variant="p">This report allows users to view the forecast data of a specific planning cycle.</Box>
          <ul>
            <li>
              <Box variant="p">Select a Planning Cycle from the dropdown list</Box>
            </li>
            <li>
              <Box variant="p">
                To customize your view, you can expand or collapse Row Groups as well as add or remove Columns and Filters on the right side of the
                table
              </Box>
            </li>
            <li>
              <Box variant="p">To save your customized view, click the Save current view button</Box>
            </li>
            <li>
              <Box variant="p">To download the report, click the Export to Excel button</Box>
            </li>
          </ul>
        </SpaceBetween>
      </HelpPanel>
    </>
  );
};
