import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { Amplify, Auth } from 'aws-amplify';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { configureCloudwatchRUM } from 'src/analytics/CloudWatchRumClient';
import { configureKatalLogger, entryLog } from 'src/analytics/KatalLogger';
import { DEVELOPER_LDAP_GROUP, eLocalStorageKeys } from 'src/constants/generic-constants';
import { Status, UserAuthContext } from 'src/models/AuthContextModels';
import { configureAgGridLicense } from 'src/utils/ag-grid-license-config';
import { getAmplifyAuthConfig, getApiConfig, getAppSyncConfig } from 'src/utils/amplify-config';
import { LoadingSpinner } from '../../components/common/LoadingSpinner';

// Define the shape of your context including the new functions
interface AuthContextValue extends UserAuthContext {
  updateUserAuthDetails: (updates: Partial<UserAuthContext>, fromModifyAuthContext: boolean) => void;
  resetToDefaultAuthDetails: () => void;
}

export const authInitialState: UserAuthContext = {
  userAlias: '',
  userDisplayName: '',
  userGivenName: '',
  userEmail: '',
  isDev: false,
  isInitiallyDev: false,
  isUserRoleModified: false,
  userAuthenticationStatus: Status.NotInitiated
};

const initialAuthContextValue: AuthContextValue = {
  ...authInitialState,
  updateUserAuthDetails: () => {},
  resetToDefaultAuthDetails: () => {}
};

const AuthContextDetails = createContext<AuthContextValue>(initialAuthContextValue);

export const useAuth = () => useContext(AuthContextDetails);

// Provider component that wraps your app and makes auth object
export const AuthContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { userCognitoAuthData, updateUserAuthDetails, resetToDefaultAuthDetails } = useAuthProvider();
  const [isInitializationComplete, setInitializationComplete] = useState(false);

  useEffect(() => {
    const configureLoggerAndServices = async () => {
      if (userCognitoAuthData?.userAuthenticationStatus === Status.Completed) {
        await configureKatalLogger(userCognitoAuthData.userAlias, userCognitoAuthData.userDisplayName, userCognitoAuthData.isDev);

        await entryLog();
        await configureAgGridLicense();

        configureCloudwatchRUM(userCognitoAuthData.userAlias);
        setInitializationComplete(true);
      }
    };

    configureLoggerAndServices();
  }, [userCognitoAuthData]);

  if (userCognitoAuthData?.userAuthenticationStatus !== Status.Completed || !isInitializationComplete) {
    return <LoadingSpinner />;
  }

  return (
    <AuthContextDetails.Provider value={{ ...userCognitoAuthData, updateUserAuthDetails, resetToDefaultAuthDetails }}>
      {children}
    </AuthContextDetails.Provider>
  );
};

const useAuthProvider = () => {
  const [userCognitoAuthData, setUserCognitoAuthData] = useState<UserAuthContext>({
    ...authInitialState,
    userAuthenticationStatus: Status.Loading
  });

  useEffect(() => {
    authenticateAndSetUserDetails();
  }, []);

  const authenticateAndSetUserDetails = () => {
    Auth.configure(getAmplifyAuthConfig());

    const configureAppSync = async () => {
      try {
        const awsAppSync = await getAppSyncConfig();
        Amplify.configure(awsAppSync.Appsync);

        const apiConfig = getApiConfig();
        Amplify.configure(apiConfig);
      } catch (error: any) {
        console.error('Unable to fetch Config', error);
      }
    };

    const signInWithAmazonFederate = async () => {
      try {
        await Auth.federatedSignIn({ customProvider: 'AmazonFederate' });
        const session = await Auth.currentSession();
        setUserCognitoAuthData(getSessionDetails(session));
      } catch (error: any) {
        console.error('Unable to sign in with AmazonFederate', error);
        setUserCognitoAuthData({
          ...userCognitoAuthData,
          userAuthenticationStatus: Status.Loading
        });
      }
    };

    Auth.currentAuthenticatedUser()
      .then(async () => {
        await configureAppSync();
        const session = await Auth.currentSession();
        setUserCognitoAuthData(getSessionDetails(session));
      })
      .catch(() => {
        signInWithAmazonFederate();
      });
  };

  /**
   * Updates the user authentication details and saves them to local storage.
   * If called from ModifyAuthContext, it sets isUserRoleModified to true.
   */
  const updateUserAuthDetails = (updates: Partial<UserAuthContext>, fromModifyAuthContext: boolean) => {
    setUserCognitoAuthData((prevState) => {
      const updatedState = { ...prevState, ...updates };
      if (fromModifyAuthContext) {
        updatedState.isUserRoleModified = true;
      }
      localStorage.setItem(eLocalStorageKeys.UserAuthDetails, JSON.stringify(updatedState));
      return updatedState;
    });
  };

  // Reset to default auth details
  const resetToDefaultAuthDetails = () => {
    authenticateAndSetUserDetails(); // Re-authenticate or set to initial state
    localStorage.removeItem(eLocalStorageKeys.UserAuthDetails); // Clear saved details
  };

  /**
   * Retrieves and formats the LDAP groups from the credentials payload.
   * @param credentials The AWS credentials object containing the ID token payload.
   * @returns A properly formatted string array of LDAP groups.
   */
  const formatUserLDAPs = (credentials: CognitoUserSession): string[] => {
    // if user is not part of any ldap, we receive it as "[]"
    const ldapGroups = credentials.getIdToken().payload['custom:LDAP_GROUPS'];
    // Check if ldapGroups is a string and try to parse it as JSON
    if (typeof ldapGroups === 'string') {
      try {
        const parsedLdapGroups = JSON.parse(ldapGroups);
        return Array.isArray(parsedLdapGroups) ? parsedLdapGroups : [];
      } catch (error) {
        console.error('Failed to parse ldapGroups string: ', error);
        return [];
      }
    }

    // If ldapGroups is already an array, return it; otherwise, return an empty array
    return Array.isArray(ldapGroups) ? ldapGroups : [];
  };

  const getSessionDetails = (credentials: CognitoUserSession) => {
    const userLDAPs: string[] = formatUserLDAPs(credentials);

    // Always start with extracting the default session details from the credentials
    const defaultSessionDetails: UserAuthContext = {
      userAlias: credentials.getIdToken().payload['identities'][0].userId,
      userDisplayName: credentials.getIdToken().payload['custom:DISPLAY_NAME'],
      userGivenName: credentials.getIdToken().payload['custom:GIVEN_NAME'],
      userEmail: credentials.getIdToken().payload['custom:EMAIL'],
      isInitiallyDev: userLDAPs.includes(DEVELOPER_LDAP_GROUP),
      isDev: userLDAPs.includes(DEVELOPER_LDAP_GROUP),
      isUserRoleModified: false,
      userAuthenticationStatus: Status.Completed
    };

    // Check localStorage for any developer modifications
    const savedAuthDetails = localStorage.getItem(eLocalStorageKeys.UserAuthDetails);
    if (savedAuthDetails && JSON.parse(savedAuthDetails)?.isUserRoleModified) {
      console.debug('Identified as custom modified. so returning from local storage');
      const parsedDetails: UserAuthContext = JSON.parse(savedAuthDetails);
      return parsedDetails; // Return the modified session details
    }

    console.debug('Identified as non modified. so returning from cognito');
    return defaultSessionDetails;
  };

  return { userCognitoAuthData, updateUserAuthDetails, resetToDefaultAuthDetails };
};
