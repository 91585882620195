import { BreadcrumbGroupProps, FlashbarProps, SelectProps } from '@amzn/awsui-components-react';
import moment from 'moment-timezone';
import { logger } from 'src/analytics/KatalLogger';
import { CorpSegmentNames } from 'src/constants/corp-segment-constants';
import { customCorpSegmentSort } from 'src/features/business-group/forecast-template/forecast-utils/CorpSegmentsUtils';
import { ForecastGridFixedFields } from 'src/features/business-group/forecast-template/forecast-utils/ForecastGridConstants';
import { BusinessGroupEntity } from 'src/models/AppContextModels';
import {
  ForecastMonth,
  PlanningCycleInfo,
  XptReportRowDataStructured,
  XptVarianceReportGridData,
  XptVarianceReportRowData
} from 'src/models/XptReportingModels';
import { roundToPrecision } from 'src/utils/ag-grid-utils';
import { getFileFromS3URI } from 'src/utils/aws-s3-services';
import { flattenAndConcatenateSegments } from '../XptReportingUtils';

// Breadcrumb items generator with error handling
export const getXptVarianceReportsBreadcrumbItems = (
  businessGroupBaseBreadcrumbs: BreadcrumbGroupProps.Item[],
  currentBusinessGroupName?: string
): BreadcrumbGroupProps.Item[] => {
  try {
    if (!currentBusinessGroupName) {
      return businessGroupBaseBreadcrumbs;
    }

    return [
      ...businessGroupBaseBreadcrumbs,
      {
        text: 'Variance Report',
        href: `/${currentBusinessGroupName}/variance-report`
      }
    ];
  } catch (error: any) {
    logger.error('Error generating breadcrumb items:', error);
    throw new Error('Failed to generate breadcrumb items');
  }
};

// Helper function to get and sort required corp segments
export const getSortedRequiredCorpSegments = (businessGroup: BusinessGroupEntity): string[] => {
  return businessGroup.corp_segments
    .filter((corp) => corp.corp_segment_required)
    .sort(customCorpSegmentSort)
    .map((corp) => corp.corp_segment_name);
};

// Helper function to flatten segment data
export const flattenSegments = (segments: any[], headers: string[] = []): { [key: string]: any } => {
  return Object.assign(
    {},
    ...segments.map((segment) => {
      const filteredSegment: { [key: string]: any } = {};
      headers.forEach((header) => {
        if (segment[header] !== undefined) {
          filteredSegment[header] = segment[header];
        }
      });
      return filteredSegment;
    })
  );
};

export const EMPTY_OPTION: SelectProps.Option | null = null;

export enum ROLL_UP_PERIOD {
  YEARLY = 'YEARLY',
  QUARTERLY = 'QUARTERLY'
}

export const RollUpPeriodOptions: SelectProps.Options = [
  { label: 'Yearly', value: ROLL_UP_PERIOD.YEARLY },
  { label: 'Quarterly', value: ROLL_UP_PERIOD.QUARTERLY }
];

export enum FORECAST_MONTH_FORMAT {
  FORECAST_S3_DATE_FORMAT = 'YYYYMMDD',
  PLANNING_CYCLE_DATE_FORMAT = 'YYYY-MM-DD',
  YEAR = 'YYYY'
}

export const fetchCycleData = async (
  planningCycleInfo: PlanningCycleInfo,
  notificationMessage: (content: string, flashBarType: FlashbarProps.Type, isDismissible: boolean) => void,
  selectedRollupPeriod: ROLL_UP_PERIOD,
  currentUserAlias: string,
  isBudgetOwner: boolean
): Promise<XptReportRowDataStructured[] | []> => {
  try {
    // Fetch data from S3
    const forecastDataFromS3: XptReportRowDataStructured[] = (await getFileFromS3URI(
      planningCycleInfo.path
    )) as unknown as XptReportRowDataStructured[];

    // Id user is a budget owner, then filter rows applicable to that user only.
    const filteredBasedOnBudgetOwner = isBudgetOwner
      ? forecastDataFromS3.filter((row) => currentUserAlias === row[ForecastGridFixedFields.BudgetOwner.value])
      : forecastDataFromS3;

    // console.debug(
    //   `forecastDataFromS3 for ${planningCycleInfo.planningCycleSelected.scenario_year} - ${forecastDataFromS3.length} with isBudgetOwner - ${isBudgetOwner} - After filteredBasedOnBudgetOwner  - ${filteredBasedOnBudgetOwner.length}`
    // );

    // Aggregate the data
    const aggregatedData: XptReportRowDataStructured[] = aggregateForecastDataUtil(filteredBasedOnBudgetOwner, selectedRollupPeriod);

    return aggregatedData;
  } catch (error: any) {
    logger.error(`Error fetching data for planning cycle: ${planningCycleInfo.planningCycleSelected.scenario_year}`, error);
    notificationMessage(`Failed to fetch data for planning cycle: ${planningCycleInfo.planningCycleSelected.scenario_year}`, 'error', true);
    return [];
  }
};

export const aggregateForecastDataUtil = (data: XptReportRowDataStructured[], rollUpPeriod: ROLL_UP_PERIOD): XptReportRowDataStructured[] => {
  return data.map((row) => {
    const aggregatedForecastMonths: Record<string, number | null> = {};

    // Aggregate forecast months
    row.forecast_months.forEach((monthData) => {
      Object.entries(monthData).forEach(([dateStr, value]) => {
        const key = formatDateKey(dateStr, rollUpPeriod);
        if (value !== null) {
          // Aggregate non-null values
          aggregatedForecastMonths[key] = (aggregatedForecastMonths[key] || 0) + value;
        } else if (!(key in aggregatedForecastMonths)) {
          // Initialize with null if all values are null
          aggregatedForecastMonths[key] = null;
        }
      });
    });

    // Convert aggregated results back to forecast_months format
    const aggregatedForecastMonthsArray = Object.entries(aggregatedForecastMonths).map(([key, value]) => ({ [key]: value }));

    // Return a new row with the aggregated forecast months
    return {
      ...row,
      forecast_months: aggregatedForecastMonthsArray
    };
  });
};

export const formatDateKey = (dateStr: string, rollUpPeriod: ROLL_UP_PERIOD): string => {
  const date = moment(dateStr, FORECAST_MONTH_FORMAT.FORECAST_S3_DATE_FORMAT);

  if (rollUpPeriod === ROLL_UP_PERIOD.YEARLY) {
    return date.format(FORECAST_MONTH_FORMAT.YEAR); // Year format YYYY
  } else if (rollUpPeriod === ROLL_UP_PERIOD.QUARTERLY) {
    const quarter = Math.floor(date.month() / 3) + 1; // Calculate quarter
    return `${date.year()}_Q${quarter}`; // Format as YYYY_QN
  }
  return '';
};

export const filterAndFlattenVarianceReportData = (
  varianceReport: XptVarianceReportRowData[],
  optionalCorpSegmentsHeader: string[],
  userCostCentersForCurrentBusinessGroup: string[]
): XptVarianceReportGridData[] => {
  try {
    const flattenedVarianceReport: XptVarianceReportGridData[] = varianceReport?.map((item) => {
      const corpSegmentsFlattened = flattenAndConcatenateSegments(item.corp_segments, optionalCorpSegmentsHeader);

      const busSegmentsFlattened = Object.assign({}, ...item.bus_segments);
      const forecastMonthsFlattened = Object.assign({}, ...item.forecast_months);

      const { bus_segments, corp_segments, forecast_months, ...rest } = item;
      return {
        ...rest,
        ...corpSegmentsFlattened,
        ...busSegmentsFlattened,
        ...forecastMonthsFlattened
      };
    });

    // Filter data based on user cost centers
    const filteredVarianceReport = flattenedVarianceReport.filter((row, index) => {
      const rowCCWithDescription: string = row[CorpSegmentNames.COST_CENTER];
      const rowCCWithoutDescription = rowCCWithDescription.split(' - ')[0];
      return userCostCentersForCurrentBusinessGroup.includes(rowCCWithoutDescription);
    });

    return filteredVarianceReport;
  } catch (error: any) {
    logger.error('Error flattening forecast row data structured:', error);
    throw new Error('Error flattening forecast row data structured');
  }
};

/**
 * Generates a variance report between two sets of aggregated data.
 *
 * This function compares `currentData` and `comparisonData` based on the `xpt_line_item_seq_id`.
 * Variances are calculated for all matching periods in `forecast_months`. The resulting array includes:
 * - Entries from both datasets, with variances calculated where possible.
 * - If an entry exists only in one dataset, it is included with `null` values for the variance fields.
 *
 * Rules for variance calculations:
 * - Variances are only calculated when both values are non-null.
 * - If either value is `null`, the variance and percentage variance are `null`.
 * - The percentage variance (`${period}_variance_percent`) is `null` if any involved value is `null`.
 * - For `0 - 0`, the result is `0`.
 *
 * @param {XptReportRowDataStructured[]} currentData - Aggregated data for the current planning cycle.
 * @param {XptReportRowDataStructured[]} comparisonData - Aggregated data for the comparison planning cycle.
 * @returns {XptVarianceReportRowData[]} - Variance report as an array of XptVarianceReportRowData.
 */
export const generateVarianceReport = (
  currentData: XptReportRowDataStructured[],
  comparisonData: XptReportRowDataStructured[]
): XptVarianceReportRowData[] => {
  const result: XptVarianceReportRowData[] = [];

  // Convert data arrays into maps for quick lookup by xpt_line_item_seq_id
  const currentMap = new Map(currentData.map((item) => [item.xpt_line_item_seq_id, item]));
  const comparisonMap = new Map(comparisonData.map((item) => [item.xpt_line_item_seq_id, item]));

  // Get all unique xpt_line_item_seq_ids
  const currentIds = new Set(currentData.map((item) => item.xpt_line_item_seq_id));
  const comparisonIds = new Set(comparisonData.map((item) => item.xpt_line_item_seq_id));

  const allIds = new Set([...currentIds, ...comparisonIds]);

  allIds.forEach((id) => {
    const currentItem = currentMap.get(id) || ({} as XptReportRowDataStructured);
    const comparisonItem = comparisonMap.get(id) || ({} as XptReportRowDataStructured);

    // Initialize the structure for the variance report entry
    const reportEntry: XptVarianceReportRowData = {
      xpt_line_item_seq_id: id,
      xpt_line_item_id: currentItem?.xpt_line_item_id || comparisonItem?.xpt_line_item_id || '',
      corp_segments: currentItem?.corp_segments || comparisonItem?.corp_segments || [],
      bus_segments: currentItem?.bus_segments || comparisonItem?.bus_segments || [],
      forecast_months: []
    };

    // Convert forecast months into an easily comparable format
    const currentForecastMap = createForecastMap(currentItem?.forecast_months || []);
    const comparisonForecastMap = createForecastMap(comparisonItem?.forecast_months || []);

    // Combine all periods
    const allPeriods = new Set([...Object.keys(currentForecastMap), ...Object.keys(comparisonForecastMap)]);

    // Aggregate data for forecast_months and calculate variances
    allPeriods.forEach((period) => {
      // Ensure that both current and comparison values are present
      const currentVal = currentForecastMap[period] !== undefined ? currentForecastMap[period] : null;
      const comparisonVal = comparisonForecastMap[period] !== undefined ? comparisonForecastMap[period] : null;

      const forecastEntry = createForecastEntry(period, currentVal, comparisonVal);
      reportEntry.forecast_months.push(forecastEntry);
    });

    result.push(reportEntry);
  });

  return result;
};

/**
 * Creates a forecast entry with variance calculations for a given period.
 *
 * @param {string} period - The forecast period (e.g., '2028_Q1' or '2028').
 * @param {number | null} currentVal - The value from the current data set.
 * @param {number | null} comparisonVal - The value from the comparison data set.
 * @returns {ForecastMonth} - An object containing the forecast data and variances for the period.
 */
export const createForecastEntry = (period: string, currentVal: number | null, comparisonVal: number | null): ForecastMonth => {
  return {
    [`${period}_current`]: currentVal !== null ? currentVal : null,
    [`${period}_comparison`]: comparisonVal !== null ? comparisonVal : null
  };
};

/**
 * Calculates the variance and percentage variance between two values.
 *
 * @param {number | null} currentVal - The value from the current data set.
 * @param {number | null} comparisonVal - The value from the comparison data set.
 * @returns {{ variance: number | null; variancePercentage: number | null }} - An object containing the variance and percentage variance.
 */
export const calculateVariance = (
  currentVal: number | null,
  comparisonVal: number | null
): { variance: number | null; variancePercentage: number | null } => {
  const currentValue = currentVal ?? 0;
  const comparisonValue = comparisonVal ?? 0;
  const variance = comparisonValue - currentValue;

  const calculatePercentage = (): number => {
    if (currentValue === 0 && comparisonValue === 0) return 0;
    if (comparisonValue === 0) return 0;
    return roundToPrecision((variance / comparisonValue) * 100, 2);
  };

  return {
    variance,
    variancePercentage: calculatePercentage()
  };
};

/**
 * Creates a map from forecast_months for easy lookup.
 *
 * @param {ForecastMonth[]} forecastMonths - The forecast months array from XptReportRowDataStructured.
 * @returns {Record<string, number | null>} - A map of forecast months.
 */
export const createForecastMap = (forecastMonths: ForecastMonth[]): Record<string, number | null> => {
  const forecastMap: Record<string, number | null> = {};
  forecastMonths.forEach((month) => {
    Object.entries(month).forEach(([key, value]) => {
      if (key && value !== undefined) {
        // Avoid adding undefined keys or values
        forecastMap[key] = value;
      }
    });
  });
  return forecastMap;
};
