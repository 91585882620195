import { createSelector } from 'reselect';
import { RootState } from '../store';
import { MasterCorpSegments } from 'src/models/AppContextModels';
import { CorpSegmentNames } from 'src/constants/corp-segment-constants';

const getCorpSegmentsStore = (state: RootState) => state.corpSegmentsStore;

export const selectMasterCorpSegments = createSelector(getCorpSegmentsStore, (corpSegmentsStore) => corpSegmentsStore.masterCorpSegments);

export const selectMasterCorpSegmentStatus = createSelector(getCorpSegmentsStore, (corpSegmentsStore) => corpSegmentsStore.masterCorpSegmentsStatus);

export const selectMasterBusinessSegments = createSelector(getCorpSegmentsStore, (corpSegmentsStore) => corpSegmentsStore.masterBusinessSegments);

export const selectMasterProjectCorpSegment = createSelector(selectMasterCorpSegments, (masterCorpSegments: MasterCorpSegments[]) =>
  masterCorpSegments.find((corpSegment: MasterCorpSegments) => corpSegment.segment_name === CorpSegmentNames.PROJECT)
);
