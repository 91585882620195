export const COST_CENTER_SEGMENT_NAME = 'cost_center';

export enum CorpSegmentNames {
  COST_CENTER = 'Cost Center',
  COST_CENTER_DESCRIPTION = 'Cost Center Description',
  PRODUCT_LINE = 'Product Line',
  PROJECT = 'Project',
  ACCOUNT = 'Account',
  ACCOUNT_DESCRIPTION = 'Account Description',
  CHANNEL = 'Channel',
  LOCATION = 'Location',
  COMPANY = 'Company'
}

export enum BusinessSegmentNames {
  EXPENSE_TYPE = 'Expense Type',
  COMMODITY = 'Commodity'
}

export enum SegmentCategoryType {
  CORP = 'CORP',
  BUSINESS = 'BUSINESS'
}

export enum BusinessSegmentDataType {
  TEXT = 'text',
  NUMBER = 'number',
  DROPDOWN = 'dropdown'
}

// To check if a segment is mandatory.
export const isMandatoryCorpSegment = (corpSegmentName: CorpSegmentNames): boolean => {
  return corpSegmentName === CorpSegmentNames.COST_CENTER;
};

// Adding the definition of the sequence of corp segments to display on the Onboarding page, ensuring consistent ordering regardless of the backend response.
export const CORP_SEGMENTS_SEQUENCE: CorpSegmentNames[] = [
  CorpSegmentNames.COST_CENTER,
  CorpSegmentNames.PRODUCT_LINE,
  CorpSegmentNames.ACCOUNT,
  CorpSegmentNames.PROJECT,
  CorpSegmentNames.CHANNEL,
  CorpSegmentNames.LOCATION,
  CorpSegmentNames.COMPANY
];
