import { StatusIndicator, TableProps } from '@amzn/awsui-components-react';
import { Link } from 'react-router-dom';
import { DynamicColumnDetails } from 'src/models/XptTableModel';
import { eEntityStatus } from 'src/constants/generic-constants';
import { formatUTCAsLocalReadable } from 'src/utils/date-time-utilities';
import { getStatusIndicatorColorOverride, getStatusIndicatorType } from 'src/utils/generic-utilities';
import React from 'react';
import { FilterSet } from 'src/components/common/xpt-table/XptTableUseFilterSets';
import { OnboardingTableEntity } from 'src/models/OnboardingModel';

export const ONBOARDING_DATA_CLASSIFICATION_TABLE_CONFIG: DynamicColumnDetails = {
  columnInfo: {
    entityName: 'Business groups',
    trackBy: 'data_classification_id',
    sortingColumn: 'updated_at',
    isDescending: true
  },
  data: [
    {
      fieldName: 'data_classification_name',
      displayName: 'Business group',
      canBeHiddenFromProperties: false,
      isShownByDefault: true,
      isFilterable: true,
      metadata: {
        type: 'string'
      }
    },
    {
      fieldName: 'data_classification_description',
      displayName: 'Description',
      canBeHiddenFromProperties: false,
      isShownByDefault: true,
      isFilterable: true,
      metadata: {
        type: 'string'
      }
    },
    {
      fieldName: 'is_active',
      displayName: 'Status',
      canBeHiddenFromProperties: false,
      isShownByDefault: true,
      isFilterable: true,
      metadata: {
        type: 'string'
      }
    },
    {
      fieldName: 'created_at',
      displayName: 'Onboard date',
      canBeHiddenFromProperties: false,
      isShownByDefault: true,
      isFilterable: false,
      metadata: {
        type: 'dateTime',
        dateFormat: 'MM-DD-YYYY'
      }
    },
    {
      fieldName: 'updated_at',
      displayName: 'Last update',
      canBeHiddenFromProperties: false,
      isShownByDefault: true,
      isFilterable: false,
      metadata: {
        type: 'dateTime',
        dateFormat: 'MM-DD-YYYY'
      }
    },
    {
      fieldName: 'updated_by',
      displayName: 'Updated by',
      canBeHiddenFromProperties: false,
      isShownByDefault: true,
      isFilterable: true,
      metadata: {
        type: 'string'
      }
    }
  ]
};

export const ONBOARDING_DATA_CLASSIFICATION_TABLE_DEFINITIONS: TableProps.ColumnDefinition<OnboardingTableEntity>[] = [
  {
    id: 'data_classification_id',
    header: 'Id',
    cell: (item: OnboardingTableEntity) => item.data_classification_id,
    sortingField: 'data_classification_id'
  },
  {
    id: 'data_classification_name',
    header: 'Business group',
    cell: (item: OnboardingTableEntity) => (
      <Link to={'/admin-console/onboarding/' + item.data_classification_id}>{item.data_classification_name}</Link>
    ),
    sortingField: 'data_classification_name',
    isRowHeader: true
  },
  {
    id: 'data_classification_description',
    header: 'Description',
    cell: (item: OnboardingTableEntity) => item.data_classification_description,
    sortingField: 'data_classification_description'
  },
  {
    id: 'is_active',
    header: 'Status',
    cell: (item: OnboardingTableEntity) => (
      <StatusIndicator
        type={getStatusIndicatorType(item.is_active as eEntityStatus)}
        colorOverride={getStatusIndicatorColorOverride(item.is_active as eEntityStatus)}
      >
        {item.is_active}
      </StatusIndicator>
    ),
    sortingField: 'is_active',
    minWidth: '100px'
  },
  {
    id: 'created_at',
    header: 'Onboard date',
    cell: (item: OnboardingTableEntity) => formatUTCAsLocalReadable(item.created_at),
    sortingField: 'created_at'
  },
  {
    id: 'updated_at',
    header: 'Last update',
    cell: (item: OnboardingTableEntity) => formatUTCAsLocalReadable(item.updated_at),
    sortingField: 'updated_at'
  },
  {
    id: 'updated_by',
    header: 'Updated by',
    cell: (item: OnboardingTableEntity) => item.updated_by,
    sortingField: 'updated_by'
  }
];

export const defaultFilterSetsOfBusinessGroup: FilterSet[] = [
  {
    name: 'Active business groups only',
    query: {
      operation: 'and',
      tokens: [{ propertyKey: 'is_active', operator: '=', value: 'Active' }]
    }
  },
  {
    name: 'Active & Inactive business groups',
    query: {
      operation: 'or',
      tokens: [
        { propertyKey: 'is_active', operator: '=', value: 'Active' },
        { propertyKey: 'is_active', operator: '=', value: 'Inactive' }
      ]
    }
  },
  {
    name: 'Inactive business groups only',
    query: {
      operation: 'and',
      tokens: [{ propertyKey: 'is_active', operator: '=', value: 'Inactive' }]
    }
  }
];
