import { Alert, Box, BoxProps, ColumnLayout, Container, Header, Icon, SpaceBetween, Tabs } from '@amzn/awsui-components-react';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { logger, logUserClick } from 'src/analytics/KatalLogger';
import { selectForecastPlanningCyclesOfAllBG } from 'src/features/planning-cycle/planningCycleSelector';
import { fetchForecastPlanningCycles } from 'src/features/planning-cycle/planningCycleSlice';
import {
  calculateLockDates,
  getActualsDateRange,
  getForecastDateRange,
  planningCycleWindowText
} from 'src/features/planning-cycle/planningCycleUtils';
import { Status } from 'src/models/AuthContextModels';
import { PlanningCycleHomePageWidgetModel } from 'src/models/PlanningCycleModel';
import { AppDispatch, RootState } from 'src/store/store';
import { compareNullableNumbers } from 'src/utils/generic-utilities';

export const PlanningCycleHomePageWidget: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [activeTabId, setActiveTabId] = useState<string>('');
  const { planningCyclesLoadingStatus, scenarios } = useSelector((state: RootState) => state.planningCycleStore);
  const { userAccessForCurrentBusinessGroup } = useSelector((state: RootState) => state.xptAccessAndAuthorizationStore);
  const forecastPlanningCycles = useSelector(selectForecastPlanningCyclesOfAllBG);
  const { forecastPlanningCyclesStatus } = useSelector((state: RootState) => state.planningCycleStore);

  useEffect(() => {
    dispatch(fetchForecastPlanningCycles());
  }, [dispatch]);

  const tabs = useMemo(() => {
    if (planningCyclesLoadingStatus !== Status.Completed) return [];
    return scenarios.map((scenario) => ({
      label: scenario.scenario_name,
      id: `${scenario.scenario_id}`,
      content: null // We'll render content based on active tab
    }));
  }, [planningCyclesLoadingStatus, scenarios]);

  useEffect(() => {
    if (tabs.length > 0 && !activeTabId) {
      setActiveTabId(tabs[0].id);
    }
  }, [tabs]);

  const planningCycleContent = useMemo(() => {
    if (forecastPlanningCyclesStatus !== Status.Completed || !activeTabId) return [];

    try {
      return forecastPlanningCycles
        .filter((cycle) => cycle.scenario.scenario_id === Number(activeTabId))
        .map((cycle) => {
          const isFinanceAdminOrFinanceOwner = userAccessForCurrentBusinessGroup?.isFinanceAdminOrFinanceOwner || false;
          const { isLocked, buttonText, statusType } = calculateLockDates(cycle, isFinanceAdminOrFinanceOwner);

          return {
            dataClassificationId: cycle.data_classification.data_classification_id,
            businessGroupName: cycle.data_classification.data_classification_name,
            scenarioName: cycle.scenario.planning_cycle_name,
            forecastDateRange: getForecastDateRange(cycle),
            actualsDateRange: getActualsDateRange(cycle),
            isLocked: isLocked,
            statusType: statusType,
            planningCycleLockStatusMessage: buttonText,
            planningCycleWindow: planningCycleWindowText(cycle, isFinanceAdminOrFinanceOwner)
          } as PlanningCycleHomePageWidgetModel;
        });
    } catch (error: any) {
      logger.error('Error updating planning cycle content:', error);
      return [];
    }
  }, [forecastPlanningCyclesStatus, forecastPlanningCycles, activeTabId, userAccessForCurrentBusinessGroup]);

  const handleTabChange = (selectedTabId: string) => {
    logUserClick(`Changed Planning cycle tab`, 'Planning cycle widget', 'Landing Page');
    setActiveTabId(selectedTabId);
  };

  const renderTabContent = () => {
    if (!activeTabId) return <Alert type="error">Planning Cycle not available</Alert>;

    if (planningCycleContent.length === 0) {
      return <Alert type="warning">No planning cycles available for this scenario</Alert>;
    }

    return (
      <>
        {planningCycleContent
          .sort((a, b) => compareNullableNumbers(a.dataClassificationId, b.dataClassificationId, 'asc'))
          .map((cycle, index) => {
            const textColor: BoxProps.Color = `text-status-${cycle.statusType || 'info'}`;

            return (
              <React.Fragment key={index}>
                <Box margin={{ left: 's' }}>
                  <Box fontWeight="bold" variant="h3" margin={{ top: 's' }}>
                    {cycle.businessGroupName}
                  </Box>
                  <CycleInfoRow label="Scenario" value={cycle.scenarioName} />
                  <CycleInfoRow label="Forecast Date Range" value={cycle.forecastDateRange} />
                  <CycleInfoRow label="Actuals Date Range" value={cycle.actualsDateRange} />
                  <CycleInfoRow
                    label="Status"
                    value={
                      <SpaceBetween size="s" direction="horizontal" alignItems="center">
                        <Icon name={cycle.isLocked ? 'lock-private' : 'unlocked'} />
                        <Box color={textColor}>{cycle.planningCycleLockStatusMessage}</Box>
                      </SpaceBetween>
                    }
                  />
                  <CycleInfoRow label="Planning Cycle Window" value={cycle.planningCycleWindow} />
                </Box>
              </React.Fragment>
            );
          })}
      </>
    );
  };

  return (
    <Container header={<Header>Planning Cycle Deadlines</Header>}>
      <Tabs tabs={tabs} activeTabId={activeTabId} onChange={({ detail }) => handleTabChange(detail.activeTabId)} />
      {renderTabContent()}
    </Container>
  );
};

interface CycleInfoRowProps {
  label: string;
  value: React.ReactNode;
}

const CycleInfoRow: React.FC<CycleInfoRowProps> = ({ label, value }) => (
  <>
    <Box padding={{ top: 'xxs', bottom: 'xxs' }}>
      <ColumnLayout columns={2}>
        <Box>{label}</Box>
        <Box>{value}</Box>
      </ColumnLayout>
    </Box>
    <div className="horizontal-bar" />
  </>
);
