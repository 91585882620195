import {
  Announcement,
  AuditLogEntity,
  BusinessGroupEntity,
  BusinessGroupEntityInput,
  ContactInformation,
  MasterSegment,
  Resources,
  UserMetricsRaw
} from 'src/models/AppContextModels';

import * as mutations from 'src/graphql/mutations';
import * as queries from 'src/graphql/queries';

import { BudgetOwners, BudgetOwnershipTransferHistory, BudgetOwnershipTransferMutation } from 'src/models/BudgetOwnerTransferModel';
import { CapexActualsDetailsMutation } from 'src/models/CapexModels';
import { ForecastInputDetails } from 'src/models/ForecastModels';
import { ForecastPlanningCycleIds, MasterScenarios, PlanningCycleEntity, PlanningCycleSnapshot } from 'src/models/PlanningCycleModel';
import { POActualsDetailsMutation, POFilterDropdowns, PoTaggingLineItemDetails, PoTaggingLineItemDetailsUnParsed } from 'src/models/POTaggingModel';
import { AccountBudgetTypeMapping, ExpenseTypeMappingEntity, ProjectDetailsInput, UsefulLifeMappingEntity } from 'src/models/xPTMappingModels';
import { UserAccessEntity } from 'src/models/XptUsersModel';
import { fetchGraphQLRequest } from './graphql-api';
import { XptMetadata } from 'src/models/XptGenericModels';

export const getUserAccessAuthorization = async (): Promise<UserAccessEntity[]> => {
  try {
    const data = await fetchGraphQLRequest<{ listUserAccess: UserAccessEntity[] }>({
      query: queries.listUserAccess,
      variables: {},
      requestName: 'listUserAccess',
      requestType: 'query'
    });
    return data.listUserAccess;
  } catch (error: any) {
    throw error;
  }
};

export const getXptMetadata = async (): Promise<XptMetadata[]> => {
  try {
    const data = await fetchGraphQLRequest<{ listMetadata: XptMetadata[] }>({
      query: queries.listMetadata,
      variables: {},
      requestName: 'listMetadata',
      requestType: 'query'
    });
    return data.listMetadata;
  } catch (error: any) {
    throw error;
  }
};

/**
 * Retrieves master segments data from the API.
 * @returns A Promise resolving to an array of MasterSegments.
 */
export const getMasterSegments = async (): Promise<MasterSegment[]> => {
  try {
    const data = await fetchGraphQLRequest<{ listMasterSegments: MasterSegment[] }>({
      query: queries.listMasterSegments,
      variables: {},
      requestName: 'listMasterSegments',
      requestType: 'query'
    });
    return data.listMasterSegments;
  } catch (error: any) {
    throw error;
  }
};

/**
 * Retrieves all business groups from the API.
 * @returns A Promise resolving to an array of business groups.
 */
export const getAllBusinessGroups = async (): Promise<BusinessGroupEntity[]> => {
  try {
    const data = await fetchGraphQLRequest<{ listBusinessGroups: BusinessGroupEntity[] }>({
      query: queries.listBusinessGroups,
      variables: {},
      requestName: 'listBusinessGroups',
      requestType: 'query'
    });
    return data.listBusinessGroups;
  } catch (error: any) {
    throw error;
  }
};

/**
 * Retrieves scenarios - listScenarios
 * @returns A Promise resolving to the list of scenarios.
 */
export const getScenarios = async (): Promise<MasterScenarios[]> => {
  try {
    const data = await fetchGraphQLRequest<{ listScenarios: MasterScenarios[] }>({
      query: queries.listScenarios,
      variables: {},
      requestName: 'listScenarios',
      requestType: 'query'
    });
    return data.listScenarios;
  } catch (error: any) {
    throw error;
  }
};

/**
 * Retrieves all Planning Cycles
 */
export const getAllPlanningCycles = async (): Promise<PlanningCycleEntity[]> => {
  try {
    const data = await fetchGraphQLRequest<{ listPlanningCycles: PlanningCycleEntity[] }>({
      query: queries.listPlanningCycles,
      variables: {},
      requestName: 'listPlanningCycles',
      requestType: 'query'
    });
    return data.listPlanningCycles;
  } catch (error: any) {
    throw error;
  }
};

export const getAllPlanningCycleSnapshots = async (): Promise<PlanningCycleSnapshot[]> => {
  try {
    const data = await fetchGraphQLRequest<{ listScenarioSnapshots: PlanningCycleSnapshot[] }>({
      query: queries.listScenarioSnapshots,
      variables: {},
      requestName: 'listScenarioSnapshots',
      requestType: 'query'
    });
    return data.listScenarioSnapshots;
  } catch (error: any) {
    throw error;
  }
};

export const getContactDetails = async (): Promise<ContactInformation[]> => {
  try {
    const data = await fetchGraphQLRequest<{ listContactDetails: ContactInformation[] }>({
      query: queries.listContactDetails,
      variables: {},
      requestName: 'listContactDetails',
      requestType: 'query'
    });
    return data.listContactDetails || [];
  } catch (error: any) {
    throw error;
  }
};

export const getResources = async (): Promise<Resources[]> => {
  try {
    const data = await fetchGraphQLRequest<{ listResourcesDetails: Resources[] }>({
      query: queries.listResourcesDetails,
      variables: {},
      requestName: 'listResourcesDetails',
      requestType: 'query'
    });
    return data.listResourcesDetails || [];
  } catch (error: any) {
    throw error;
  }
};

export const getAnnouncements = async (): Promise<Announcement[]> => {
  try {
    const data = await fetchGraphQLRequest<{ listAnnouncementDetails: any[] }>({
      query: queries.listAnnouncementDetails,
      variables: {},
      requestName: 'listAnnouncementDetails',
      requestType: 'query'
    });
    return data.listAnnouncementDetails;
  } catch (error: any) {
    throw new Error(`Failed to get announcements: ${error.message}`);
  }
};

export const getUserMetrics = async (): Promise<UserMetricsRaw[]> => {
  try {
    const data = await fetchGraphQLRequest<{ listUserMetricsDetails: UserMetricsRaw[] }>({
      query: queries.listUserMetricsDetails,
      variables: {},
      requestName: 'listUserMetricsDetails',
      requestType: 'query'
    });
    return data.listUserMetricsDetails || [];
  } catch (error: any) {
    throw error;
  }
};

export const getUsefulLifeDetails = async (): Promise<UsefulLifeMappingEntity[]> => {
  try {
    const data = await fetchGraphQLRequest<{ listUsefulLifeDetails: UsefulLifeMappingEntity[] }>({
      query: queries.listUsefulLifeDetails,
      variables: {},
      requestName: 'listUsefulLifeDetails',
      requestType: 'query'
    });
    return data.listUsefulLifeDetails || [];
  } catch (error: any) {
    throw error;
  }
};

export const getExpenseTypeMappings = async (): Promise<ExpenseTypeMappingEntity[]> => {
  try {
    const data = await fetchGraphQLRequest<{ listExpenseTypeDetails: ExpenseTypeMappingEntity[] }>({
      query: queries.listExpenseTypeDetails,
      variables: {},
      requestName: 'listExpenseTypeDetails',
      requestType: 'query'
    });
    return data.listExpenseTypeDetails || [];
  } catch (error: any) {
    throw error;
  }
};

export const getAuditLogs = async (): Promise<AuditLogEntity[]> => {
  try {
    const data = await fetchGraphQLRequest<{ listAuditLogDetails: AuditLogEntity[] }>({
      query: queries.listAuditLogDetails,
      variables: {},
      requestName: 'listAuditLogDetails',
      requestType: 'query'
    });
    return data.listAuditLogDetails || [];
  } catch (error: any) {
    throw error;
  }
};

export const getCurrentBudgetOwners = async (): Promise<BudgetOwners[]> => {
  try {
    const data = await fetchGraphQLRequest<{ listBudgetOwners: BudgetOwners[] }>({
      query: queries.listBudgetOwners,
      variables: {},
      requestName: 'listBudgetOwners',
      requestType: 'query'
    });
    return data.listBudgetOwners || [];
  } catch (error: any) {
    throw error;
  }
};

export const getBudgetOwnerTransferHistory = async (): Promise<BudgetOwnershipTransferHistory[]> => {
  try {
    const data = await fetchGraphQLRequest<{ listTransferBudgetOwnershipLog: BudgetOwnershipTransferHistory[] }>({
      query: queries.listTransferBudgetOwnershipLog,
      variables: {},
      requestName: 'listTransferBudgetOwnershipLog',
      requestType: 'query'
    });
    return data.listTransferBudgetOwnershipLog || [];
  } catch (error: any) {
    throw error;
  }
};

// PO Filter Dropdowns
export const getPOTaggingMonthsAndCC = async (dataClassificationId: number): Promise<POFilterDropdowns> => {
  try {
    const data = await fetchGraphQLRequest<{ listActualsFilterData: POFilterDropdowns }>({
      query: queries.listActualsFilterData,
      variables: { data_classification_id: dataClassificationId },
      requestName: 'listActualsFilterData',
      requestType: 'query'
    });
    return data.listActualsFilterData || [];
  } catch (error: any) {
    throw error;
  }
};

// Line Item Ids
export const getLineItemIds = async (dataClassificationId: number): Promise<PoTaggingLineItemDetailsUnParsed[]> => {
  try {
    const data = await fetchGraphQLRequest<{ listLineItemIds: PoTaggingLineItemDetailsUnParsed[] }>({
      query: queries.listLineItemIds,
      variables: { data_classification_id: dataClassificationId },
      requestName: 'listLineItemIds',
      requestType: 'query'
    });
    return data.listLineItemIds || [];
  } catch (error: any) {
    throw error;
  }
};

export const getAccountBudgetTypeMappings = async (): Promise<AccountBudgetTypeMapping[]> => {
  try {
    const data = await fetchGraphQLRequest<{ listBudgetTypeDetails: AccountBudgetTypeMapping[] }>({
      query: queries.listBudgetTypeDetails,
      variables: {},
      requestName: 'listBudgetTypeDetails',
      requestType: 'query'
    });
    return data.listBudgetTypeDetails || [];
  } catch (error: any) {
    throw error;
  }
};

export const getForecastingPlanningCycles = async (): Promise<ForecastPlanningCycleIds[]> => {
  try {
    const data = await fetchGraphQLRequest<{ listForecastPlanningCycles: ForecastPlanningCycleIds[] }>({
      query: queries.listForecastPlanningCycles,
      variables: {},
      requestName: 'listForecastPlanningCycles',
      requestType: 'query'
    });
    return data.listForecastPlanningCycles || [];
  } catch (error: any) {
    throw error;
  }
};

// Mutations

export const createOrUpdateUserAccessMutation = async (mutationData: UserAccessEntity): Promise<any> => {
  return fetchGraphQLRequest<any>({
    query: mutations.createOrUpdateUserAccess,
    variables: { input: mutationData },
    requestName: 'createOrUpdateUserAccess',
    requestType: 'mutation'
  });
};

/**
 * Planning Cycle Form Submission
 */
export const planningCycleFormMutation = async (planningCycleForm: PlanningCycleEntity[]): Promise<any> => {
  return fetchGraphQLRequest<any>({
    query: mutations.createPlanningCycles,
    variables: { planningCycleJson: planningCycleForm },
    requestName: 'createPlanningCycle',
    requestType: 'mutation'
  });
};

/**
 * Submit Snapshot Create Mutation
 */
export const createScenarioSnapshotFormMutation = async (scenarioSnapshotFormValues: PlanningCycleSnapshot): Promise<any> => {
  return fetchGraphQLRequest<any>({
    query: mutations.createScenarioSnapshot,
    variables: { scenarioSnapshotJson: scenarioSnapshotFormValues },
    requestName: 'createScenarioSnapshot',
    requestType: 'mutation'
  });
};

/**
 * Submit Business Group changes from Onboarding module
 */
export const upsertBusinessGroupMutation = async (onboardingSubmitModel: BusinessGroupEntityInput): Promise<any> => {
  return fetchGraphQLRequest<any>({
    query: mutations.createBusinessGroup,
    variables: { dataClassificationMDJson: onboardingSubmitModel },
    requestName: 'createBusinessGroup',
    requestType: 'mutation'
  });
};

/**
 * Update Forecast Template Data
 */
export const updateForecastDetails = async (mutationData: ForecastInputDetails): Promise<any> => {
  return fetchGraphQLRequest<any>({
    query: mutations.updateForecastDetails,
    variables: { forecastDetailsJson: mutationData },
    requestName: 'updateForecastDetails',
    requestType: 'mutation'
  });
};

/**
 * Create Export Forecast Template Data
 */
export const createExportForecastDetails = async (mutationData: ForecastInputDetails): Promise<any> => {
  return fetchGraphQLRequest<any>({
    query: mutations.createExportForecastDetails,
    variables: { input: mutationData },
    requestName: 'createExportForecastDetails',
    requestType: 'mutation'
  });
};

/**
 * Update Home Page - Resources
 */
export const updateResourcesData = async (mutationData: Resources[]): Promise<any> => {
  return fetchGraphQLRequest<any>({
    query: mutations.createOrUpdateResourcesDetails,
    variables: { input: mutationData },
    requestName: 'createOrUpdateResourcesDetails',
    requestType: 'mutation'
  });
};

export const updatePOTaggingData = async (mutationData: POActualsDetailsMutation): Promise<any> => {
  return fetchGraphQLRequest<any>({
    query: mutations.updatePOActualsDetails,
    variables: { input: mutationData },
    requestName: 'updatePOActualsDetails',
    requestType: 'mutation'
  });
};

export const updateUsefulLifeMappings = async (mutationData: UsefulLifeMappingEntity[]): Promise<any> => {
  return fetchGraphQLRequest<any>({
    query: mutations.createOrUpdateUsefulLifeDetails,
    variables: { input: mutationData },
    requestName: 'createOrUpdateUsefulLifeDetails',
    requestType: 'mutation'
  });
};

export const updateCapex = async (mutationData: CapexActualsDetailsMutation): Promise<any> => {
  return fetchGraphQLRequest<any>({
    query: mutations.createOrUpdateCapexActualsDetails,
    variables: { input: mutationData },
    requestName: 'createOrUpdateCapexActualsDetails',
    requestType: 'mutation'
  });
};

export const updateExpenseType = async (mutationData: ExpenseTypeMappingEntity[]): Promise<any> => {
  return fetchGraphQLRequest<any>({
    query: mutations.createOrUpdateExpenseTypeDetails,
    variables: { input: mutationData },
    requestName: 'createOrUpdateExpenseTypeDetails',
    requestType: 'mutation'
  });
};

export const updateBudgetOwnerTransfer = async (mutationData: BudgetOwnershipTransferMutation): Promise<any> => {
  return fetchGraphQLRequest<any>({
    query: mutations.updateBudgetOwnership,
    variables: { input: mutationData },
    requestName: 'updateBudgetOwnership',
    requestType: 'mutation'
  });
};

export const updateAnnouncement = async (mutationData: Announcement[]): Promise<any> => {
  return fetchGraphQLRequest<any>({
    query: mutations.createOrUpdateAnnouncementDetails,
    variables: { input: mutationData },
    requestName: 'createOrUpdateAnnouncementDetails',
    requestType: 'mutation'
  });
};

export const updateContacts = async (mutationData: ContactInformation[]): Promise<any> => {
  return fetchGraphQLRequest<any>({
    query: mutations.createOrUpdateContactDetails,
    variables: { input: mutationData },
    requestName: 'createOrUpdateContactDetails',
    requestType: 'mutation'
  });
};

export const updateProjectMapping = async (mutationData: ProjectDetailsInput): Promise<any> => {
  return fetchGraphQLRequest<any>({
    query: mutations.updateAndExportProjectDetails,
    variables: { input: mutationData },
    requestName: 'updateAndExportProjectDetails',
    requestType: 'mutation'
  });
};
