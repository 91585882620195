import { BreadcrumbGroupProps } from '@amzn/awsui-components-react';
import { createSelector } from '@reduxjs/toolkit';
import { OnboardingTableEntity } from '../../models/OnboardingModel';
import { RootState } from '../../store/store';

// Selector to get all business groups from the state.
export const selectAllBusinessGroups = (state: RootState) => state.businessGroupStore.businessGroups;
export const currentBusinessGroup = (state: RootState) => state.businessGroupStore.currentBusinessGroup;

export const currentDataClassificationId = createSelector(
  [currentBusinessGroup],
  (currentBusinessGroup) => currentBusinessGroup?.data_classification.data_classification_id!
);

export const currentBusinessGroupName = createSelector(
  [currentBusinessGroup],
  (currentBusinessGroup) => currentBusinessGroup?.data_classification.data_classification_name!
);

export const currentBusinessGroupShortDesc = createSelector(
  [currentBusinessGroup],
  (currentBusinessGroup) => currentBusinessGroup?.data_classification.data_classification_short_description!
);

export const selectActiveBusinessGroups = createSelector([selectAllBusinessGroups], (businessGroups) =>
  businessGroups.filter((bg) => bg.item_metadata.is_active)
);

export const getActiveDataClassifications = createSelector([selectAllBusinessGroups], (businessGroups) =>
  businessGroups.filter((businessGroup) => businessGroup.item_metadata.is_active).map((businessGroup) => businessGroup.data_classification)
);

// businessGroupName is unique and can be used to directly identify a business group
export const selectBusinessGroupByName = (businessGroupName: string) =>
  createSelector([selectAllBusinessGroups], (businessGroups) =>
    businessGroups.find((bg) => bg.data_classification.data_classification_name === businessGroupName)
  );

// Selector to transform business groups into a flattened structure for table display.
export const selectFlattenedBusinessGroupsForTable = createSelector([selectAllBusinessGroups], (businessGroups): OnboardingTableEntity[] =>
  businessGroups.map(
    (businessGroup) =>
      ({
        data_classification_id: businessGroup.data_classification.data_classification_id,
        data_classification_name: businessGroup.data_classification.data_classification_name,
        data_classification_description: businessGroup.data_classification.data_classification_description,
        onboarding_status: businessGroup.status,
        is_active: businessGroup.item_metadata.is_active ? 'Active' : 'Inactive',
        created_by: businessGroup.item_metadata.created_by,
        created_at: businessGroup.item_metadata.created_at,
        updated_by: businessGroup.item_metadata.updated_by,
        updated_at: businessGroup.item_metadata.updated_at
      } as OnboardingTableEntity)
  )
);

// Factory function returning a selector to check if a business group name is valid by searching through all business groups.
export const isValidBusinessGroupName = (businessGroupName: string) =>
  createSelector([selectAllBusinessGroups], (businessGroups) =>
    businessGroups.some((bg) => bg.data_classification.data_classification_name === businessGroupName)
  );

export const businessGroupBaseBreadcrumbs = createSelector([currentBusinessGroup], (currentBusinessGroup): BreadcrumbGroupProps.Item[] => {
  // Check if the currentBusinessGroup is defined to safely access its properties
  if (currentBusinessGroup && currentBusinessGroup.data_classification) {
    return [
      {
        text: currentBusinessGroup.data_classification.data_classification_name,
        href: `/${currentBusinessGroup.data_classification.data_classification_name}/forecast-input`
      }
    ];
  }
  // Fallback to just the root breadcrumb if no current business group is selected
  return [
    {
      text: 'Xpt',
      href: '/'
    }
  ];
});
