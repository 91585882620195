import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getAccountBudgetTypeMappings, getExpenseTypeMappings, getUsefulLifeDetails } from 'src/api/app-sync-services';
import { Status } from 'src/models/AuthContextModels';
import { AccountBudgetTypeMapping, ExpenseTypeMappingEntity, UsefulLifeMappingEntity } from 'src/models/xPTMappingModels';

interface xPTMapperState {
  expenseTypeStatus: Status;
  expenseTypeMappings: ExpenseTypeMappingEntity[];

  accountBudgetTypeMappingStatus: Status;
  accountBudgetTypeMapping: AccountBudgetTypeMapping[];

  usefulLifeStatus: Status;
  usefulLifeMappings: UsefulLifeMappingEntity[];
}

const initialState: xPTMapperState = {
  expenseTypeStatus: Status.NotInitiated,
  expenseTypeMappings: [],

  accountBudgetTypeMappingStatus: Status.NotInitiated,
  accountBudgetTypeMapping: [],

  usefulLifeStatus: Status.NotInitiated,
  usefulLifeMappings: []
};

export const fetchExpenseTypeMappings = createAsyncThunk('xPTMapper/fetchExpenseTypeMappings', async () => {
  const response = await getExpenseTypeMappings();
  return response;
});

export const fetchAccountBudgetTypeMappings = createAsyncThunk('xPTMapper/fetchAccountBudgetTypeMappings', async () => {
  const response = await getAccountBudgetTypeMappings();
  return response;
});

export const fetchUsefulLifeDetails = createAsyncThunk('xPTMapper/fetchUsefulLifeDetails', async () => {
  const response = await getUsefulLifeDetails();
  return response;
});

// Create the slice
const xPTMapperSlice = createSlice({
  name: 'xPTMapper',
  initialState,
  reducers: {
    // Define any synchronous actions here if needed
  },
  extraReducers: (builder) => {
    // Handle fetchExpenseTypes lifecycle actions
    builder.addCase(fetchExpenseTypeMappings.pending, (state) => {
      state.expenseTypeStatus = Status.Loading;
    });
    builder.addCase(fetchExpenseTypeMappings.fulfilled, (state, action: PayloadAction<ExpenseTypeMappingEntity[]>) => {
      state.expenseTypeMappings = action.payload;
      state.expenseTypeStatus = Status.Completed;
    });
    builder.addCase(fetchExpenseTypeMappings.rejected, (state) => {
      state.expenseTypeStatus = Status.Failed;
    });

    // Handle fetchAccountBudgetTypeMappings lifecycle actions
    builder.addCase(fetchAccountBudgetTypeMappings.pending, (state) => {
      state.accountBudgetTypeMappingStatus = Status.Loading;
    });
    builder.addCase(fetchAccountBudgetTypeMappings.fulfilled, (state, action: PayloadAction<AccountBudgetTypeMapping[]>) => {
      state.accountBudgetTypeMappingStatus = Status.Completed;
      state.accountBudgetTypeMapping = action.payload;
    });
    builder.addCase(fetchAccountBudgetTypeMappings.rejected, (state) => {
      state.accountBudgetTypeMappingStatus = Status.Failed;
    });

    // Handle fetchUsefulLifeDetails lifecycle actions
    builder.addCase(fetchUsefulLifeDetails.pending, (state) => {
      state.usefulLifeStatus = Status.Loading;
    });
    builder.addCase(fetchUsefulLifeDetails.fulfilled, (state, action: PayloadAction<UsefulLifeMappingEntity[]>) => {
      state.usefulLifeMappings = action.payload;
      state.usefulLifeStatus = Status.Completed;
    });
    builder.addCase(fetchUsefulLifeDetails.rejected, (state) => {
      state.usefulLifeStatus = Status.Failed;
    });
  }
});

// Export the reducer
export default xPTMapperSlice.reducer;
