import {
  Box,
  Container,
  ContentLayout,
  Flashbar,
  FlashbarProps,
  FormField,
  Header,
  Link,
  Select,
  SelectProps,
  SpaceBetween
} from '@amzn/awsui-components-react';
import { ColDef } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { logger, logUserClick } from 'src/analytics/KatalLogger';
import { XptAppLayout } from 'src/components/common/xpt-app-layout/XptAppLayout';
import { XPTBreadcrumbs } from 'src/components/common/XptBreadcrumb';
import { useFlashbar } from 'src/hooks/useFlashbar';
import { getLocalStorageItem, setLocalStorageItem } from 'src/hooks/useLocalStorage';
import { Status } from 'src/models/AuthContextModels';
import { PlanningCycleEntity } from 'src/models/PlanningCycleModel';
import { XptForecastReportGridRowData, XptReportExportFileDetails, XptReportRowDataStructured } from 'src/models/XptReportingModels';
import { AppDispatch, RootState } from 'src/store/store';
import { getFileFromS3URI } from 'src/utils/aws-s3-services';
import { useAuth } from '../../../app/auth/AuthContextProvider';
import { businessGroupBaseBreadcrumbs, currentBusinessGroupName } from '../../business-group/businessGroupSelectors';
import { getForecastTemplateHeaderInfo } from '../../business-group/forecast-template/forecast-utils/ForecastTemplateUtils';
import { selectAllPlanningCyclesForCurrentGroup, selectScenarioSnapshotPerBusinessGroup } from '../../planning-cycle/planningCycleSelector';
import BusinessGroupSideNavigation from '../../xpt-layout/XptBusinessGroupSideNavigation';
import { generateForecastReportColumnDefinitions } from '../XptReportColumnGenerator';
import { ReportGrid } from '../XptReportGrid';
import { ReportTypes, XptReportGridFixedFields } from '../XptReportGridConstants';
import {
  filterAndFlattenForecastReportData,
  getPlanningCyclesDropdownOptions,
  getSelectedPlanningCycle,
  getXptReportFileName,
  getXptReportsBreadcrumbItems
} from '../XptReportingUtils';
import { XptForecastReportInfoPanel } from './XptForecastReportInfoPanel';

const EMPTY_OPTION: SelectProps.Option | null = null;
export const XptForecastReport: React.FC = () => {
  const appLayout = useRef<any>();
  const { userAlias } = useAuth();

  const dispatch = useDispatch<AppDispatch>();
  const { userAccessForCurrentBusinessGroup, userCostCentersForCurrentBusinessGroup, userAccessForCurrentBusinessGroupStatus } = useSelector(
    (state: RootState) => state.xptAccessAndAuthorizationStore
  )!;
  const isFinanceAdminOrFinanceOwner = userAccessForCurrentBusinessGroup?.isFinanceAdminOrFinanceOwner || false;
  const isBudgetOwner = userAccessForCurrentBusinessGroup?.isBudgetOwner || false;

  const currentBusinessGroup = useSelector((state: RootState) => state.businessGroupStore.currentBusinessGroup);
  const businessGroupName = useSelector(currentBusinessGroupName);
  const data_classification_id = currentBusinessGroup?.data_classification.data_classification_id;
  const dataClassificationShortDesc: string | undefined = currentBusinessGroup?.data_classification.data_classification_short_description;
  const gridStateKey = `UniqueGridStateKey-XptForecastReport-${dataClassificationShortDesc}-v1`;
  const gridRef = useRef<AgGridReact>(null);

  const businessGroupBaseBreadcrumb = useSelector(businessGroupBaseBreadcrumbs);
  const { flashbarItems, displayFlashMessage, clearSpecificFlashMessage, clearAllMessages } = useFlashbar();

  const scenarios = useSelector((state: RootState) => state.planningCycleStore.scenarios);
  const planningCycles = useSelector(selectAllPlanningCyclesForCurrentGroup);
  const scenarioSnapshots = useSelector(selectScenarioSnapshotPerBusinessGroup);

  const [planningCycleOptions, setPlanningCycleOptions] = useState<SelectProps.OptionGroup[]>([]);
  const [planningCycleOptionsFlattened, setPlanningCycleOptionsFlattened] = useState<SelectProps.Options>([]);

  const [localStorageKey, setLocalStorageKey] = useState<string | null>(null);
  const [selectedPlanningCycle, setSelectedPlanningCycleState] = useState<SelectProps.Option | null>(EMPTY_OPTION);

  const [columnDefinitions, setColumnDefinitions] = useState<ColDef[]>([]);
  const [xptReportData, setXptReportData] = useState<XptForecastReportGridRowData[]>([]);
  const [isGeneratingReport, setIsGeneratingReport] = useState(false);

  const [exportFileDetails, setExportFileDetails] = useState<XptReportExportFileDetails>();
  const [toolsOpen, setToolsOpen] = useState(false);

  const notificationMessage = (content: string, flashBarType: FlashbarProps.Type, isDismissible: boolean, messageId?: string) => {
    displayFlashMessage(content, flashBarType, isDismissible, messageId);
  };

  // Once Planning Cycle & Snapshots are available, will create Dropdown options for Planning Cycle
  useEffect(() => {
    // Determine applicable scenario snapshots based on user role
    // Only Finance Admin or Finance Owner can see snapshots
    const applicableScenarioSnapshots = isFinanceAdminOrFinanceOwner ? scenarioSnapshots : [];
    const planningCycleDropdownOptions: SelectProps.OptionGroup[] = getPlanningCyclesDropdownOptions(
      scenarios,
      planningCycles,
      applicableScenarioSnapshots
    );
    setPlanningCycleOptions(planningCycleDropdownOptions);

    // Create a flattened array of all options for easier processing
    const flattened = planningCycleDropdownOptions.flatMap((option) => option.options);
    setPlanningCycleOptionsFlattened(flattened);
  }, [scenarios, planningCycles, scenarioSnapshots]);

  useEffect(() => {
    if (dataClassificationShortDesc) {
      const key = `xpt-Reports-SelectedPlanningCycle-${dataClassificationShortDesc}`;
      setLocalStorageKey(key);
      const localStorageItem = getLocalStorageItem<SelectProps.Option>(key);
      if (localStorageItem && localStorageItem?.value) {
        const validOptions = planningCycleOptionsFlattened.map((pl) => pl.label);
        if (validOptions.includes(localStorageItem?.label)) {
          setSelectedPlanningCycleState(localStorageItem);
        } else {
          setSelectedPlanningCycleState(planningCycleOptionsFlattened[0] || null);
        }
      } else if (planningCycleOptionsFlattened.length > 0) {
        setSelectedPlanningCycleState(planningCycleOptionsFlattened[0]);
      }
    }
  }, [dataClassificationShortDesc, planningCycleOptionsFlattened, dispatch]);

  // Persisting Planning Cycle selection
  useEffect(() => {
    if (localStorageKey) {
      setLocalStorageItem(localStorageKey, selectedPlanningCycle);
    }
  }, [selectedPlanningCycle, localStorageKey]);

  useEffect(() => {
    if (dataClassificationShortDesc && selectedPlanningCycle) {
      const { fileName, sheetName } = getXptReportFileName(dataClassificationShortDesc, selectedPlanningCycle?.label || '', ReportTypes.FORECAST);
      setExportFileDetails({ fileName, sheetName });
    }
  }, [dataClassificationShortDesc, selectedPlanningCycle]);

  const handlePlanningCycleChange = (detail: SelectProps.ChangeDetail) => {
    logUserClick(`Planning Cycle filter change`, 'Forecast Report', businessGroupName);
    setSelectedPlanningCycleState(detail.selectedOption);
  };

  useEffect(() => {
    if (userAccessForCurrentBusinessGroupStatus === Status.Completed) initializeReport();
  }, [userCostCentersForCurrentBusinessGroup, userAccessForCurrentBusinessGroupStatus, selectedPlanningCycle]);

  const initializeReport = () => {
    if (!selectedPlanningCycle || !data_classification_id || !dataClassificationShortDesc) return;

    try {
      const selectedPlanningCycleData = getSelectedPlanningCycle(
        selectedPlanningCycle,
        data_classification_id,
        dataClassificationShortDesc,
        scenarioSnapshots,
        planningCycles
      );

      if (selectedPlanningCycleData) {
        const { path, planningCycleSelected } = selectedPlanningCycleData;
        generateReport(path, planningCycleSelected);
      }
    } catch (error: any) {
      logger.error('Error generating forecast report s3 paths:', error);
    }
  };

  const generateReport = async (planningCycleS3Path: string | undefined, planningCycleSelected: PlanningCycleEntity | undefined) => {
    try {
      if (userCostCentersForCurrentBusinessGroup && planningCycleSelected && currentBusinessGroup && planningCycleS3Path) {
        logger.info(`Generating Forecast report for ${planningCycleSelected?.planning_cycle_year}`);

        setIsGeneratingReport(true);
        const forecastHeaderInfo = getForecastTemplateHeaderInfo(currentBusinessGroup, planningCycleSelected);
        const optionalCorpSegmentsHeader = forecastHeaderInfo.corpSegmentOptionalFields;
        const forecastRowDataResponseFromS3 = (await getFileFromS3URI(planningCycleS3Path)) as unknown as XptReportRowDataStructured[];
        const authorizedForecastReportData: XptForecastReportGridRowData[] = filterAndFlattenForecastReportData(
          forecastRowDataResponseFromS3,
          optionalCorpSegmentsHeader,
          planningCycleSelected,
          userCostCentersForCurrentBusinessGroup,
          userAlias,
          isBudgetOwner
        );
        setXptReportData(authorizedForecastReportData);

        const colDefs = await generateForecastReportColumnDefinitions(userAlias, planningCycleSelected, currentBusinessGroup);
        setColumnDefinitions(colDefs);

        logger.info(`Forecast report generated for ${planningCycleSelected?.planning_cycle_year}`);
        setIsGeneratingReport(false);
      }
    } catch (error: any) {
      logger.error('Error generating forecast report:', error);
      displayFlashMessage(`Unable to generate the Forecast Report.`, 'error', true);
      setXptReportData([]);
      setColumnDefinitions([]);
      setIsGeneratingReport(false);
    }
  };

  return (
    <>
      <XptAppLayout
        ref={appLayout}
        headerSelector="#h"
        navigation={<BusinessGroupSideNavigation />}
        tools={<XptForecastReportInfoPanel />}
        toolsOpen={toolsOpen}
        onToolsChange={({ detail }) => setToolsOpen(detail.open)}
        breadcrumbs={<XPTBreadcrumbs items={getXptReportsBreadcrumbItems(businessGroupBaseBreadcrumb, businessGroupName)} />}
        notifications={<Flashbar stackItems items={flashbarItems} />}
        stickyNotifications={true}
        maxContentWidth={Number.MAX_VALUE}
        contentType="default"
        content={
          <ContentLayout
            disableOverlap
            header={
              <Header
                info={
                  <Link variant="info" onFollow={() => setToolsOpen(true)}>
                    Info
                  </Link>
                }
              >{`Forecast Report`}</Header>
            }
          >
            <Container disableHeaderPaddings disableContentPaddings>
              <Box padding={{ top: 's', right: 's', bottom: 'm', left: 's' }}>
                <SpaceBetween size="m" direction="horizontal">
                  <FormField label="Planning Cycle" className="width-25-rem">
                    <Select
                      options={planningCycleOptions}
                      selectedOption={selectedPlanningCycle}
                      onChange={({ detail }) => handlePlanningCycleChange(detail)}
                    />
                  </FormField>
                </SpaceBetween>
              </Box>
            </Container>
            <Box padding={{ top: 's' }}>
              <ReportGrid
                gridRef={gridRef}
                columnDefs={columnDefinitions}
                rowData={xptReportData}
                uniqueIdField={XptReportGridFixedFields.XptLineItemSeqId.value}
                isLoading={isGeneratingReport}
                localStorageKey={gridStateKey}
                refreshReport={initializeReport}
                exportFileDetails={exportFileDetails}
                notificationMessage={notificationMessage}
                showPivotModeOptionInPanel={true}
                enablePivotMode={false}
                page="Forecast Report"
                mainPage={businessGroupName}
              />
            </Box>
          </ContentLayout>
        }
      />
    </>
  );
};
