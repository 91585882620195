import { Button, Flashbar, Header, SpaceBetween } from '@amzn/awsui-components-react';
import moment from 'moment-timezone';
import React, { useEffect, useRef } from 'react';
import { useQuery } from 'react-query';
import { XptAppLayout } from 'src/components/common/xpt-app-layout/XptAppLayout';
import XptTable from 'src/components/common/xpt-table/XptTable';
import { XPTBreadcrumbs } from 'src/components/common/XptBreadcrumb';
import AdminConsoleSideNavigation from 'src/features/admin-console/AdminConsoleSideNavigation';
import { useFlashbar } from 'src/hooks/useFlashbar';
import { retrieveParameterFromSSM } from 'src/utils/ssm-parameter';
import { APIStatisticsEntity } from '../models/KPIModels';
import { fetchCloudWatchLogData } from '../services/cloudwatchLogsService';
import { geAPIStatsBreadcrumbItems, getAPIStatsHeader, getAPIStatsTableConfig } from './APIStatsConfig';

const CW_QUERY_SSM_KEY = '/xpt-fe/cwq/api-stats';

const getAPIStatsQuery = async (): Promise<string> => {
  try {
    const result = await retrieveParameterFromSSM(CW_QUERY_SSM_KEY);
    return result;
  } catch (error) {
    throw new Error(`Error retrieving API Stats Query: ${error}`);
  }
};

export const APIStats: React.FC = () => {
  const entityName = 'API Stats';

  const appLayout = useRef<any>();
  const { flashbarItems, displayFlashMessage } = useFlashbar();

  const startDateTime = moment().subtract(1, 'year').tz('UTC').endOf('day').valueOf();
  const endDateTime = moment().tz('UTC').endOf('day').valueOf();

  const {
    data: widgetPropertyQuery,
    isFetching: fetchingQuery,
    error: errorWhileFetchingQuery,
    refetch: reFetchQueryFromSSM
  } = useQuery<string, Error>(
    ['cwQueryForAPIStats'],
    async () => {
      return await getAPIStatsQuery();
    },
    {
      cacheTime: Infinity,
      staleTime: Infinity
    }
  );

  const {
    data: apiStatsData,
    isFetching: fetchingAPIStats,
    error,
    refetch
  } = useQuery<APIStatisticsEntity[], Error>(
    ['apiStats', startDateTime, endDateTime, widgetPropertyQuery],
    async () => {
      if (widgetPropertyQuery) {
        return fetchCloudWatchLogData(widgetPropertyQuery, startDateTime, endDateTime);
      } else {
        return [];
      }
    },
    {
      enabled: !!widgetPropertyQuery, // Only fetch data when widgetPropertyQuery is available
      cacheTime: Infinity,
      staleTime: Infinity
    }
  );

  useEffect(() => {
    if (errorWhileFetchingQuery) {
      displayFlashMessage('Error fetching API Stats query', 'error', true);
    }

    if (error) {
      displayFlashMessage('Error fetching API Stats', 'error', true);
    }
  }, [error, errorWhileFetchingQuery, displayFlashMessage]);

  return (
    <XptAppLayout
      ref={appLayout}
      headerSelector="#h"
      navigation={<AdminConsoleSideNavigation />}
      breadcrumbs={<XPTBreadcrumbs items={geAPIStatsBreadcrumbItems()} />}
      notifications={<Flashbar items={flashbarItems} stackItems />}
      stickyNotifications={true}
      maxContentWidth={Number.MAX_VALUE}
      toolsHide={true}
      contentType="dashboard"
      content={
        <XptTable
          xptTableVariant="container"
          loadingStatus={fetchingQuery || fetchingAPIStats}
          entityName={entityName}
          xptTableHeader={
            <Header
              variant="h1"
              actions={
                <SpaceBetween size="m" direction="horizontal">
                  <Button
                    onClick={() => {
                      reFetchQueryFromSSM();
                      refetch();
                    }}
                    iconName="refresh"
                  />
                </SpaceBetween>
              }
              description="API Statistics along with success rate."
            >
              {entityName}
            </Header>
          }
          stickyColumns={{ first: 1, last: 0 }}
          allItems={apiStatsData || []}
          allColumns={getAPIStatsTableConfig(entityName)}
          columnDefinitions={getAPIStatsHeader()}
          selectedItems={[]}
          setSelectedItems={async (items) => {}}
          page="API Stats"
          mainPage={`Developer Console`}
        />
      }
    />
  );
};
