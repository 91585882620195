import { BreadcrumbGroupProps, StatusIndicator, TableProps } from '@amzn/awsui-components-react';
import { ExpenseTypeMappingEntity } from 'src/models/xPTMappingModels';
import { DynamicColumnDetails } from 'src/models/XptTableModel';
import { formatUTCAsLocalReadable, dateTimeComparator } from 'src/utils/date-time-utilities';
import * as Yup from 'yup';

export const getExpenseTypeBreadcrumbItems = (
  businessGroupBaseBreadcrumbs: BreadcrumbGroupProps.Item[],
  currentBusinessGroupName?: string
): BreadcrumbGroupProps.Item[] => {
  if (!currentBusinessGroupName) {
    return businessGroupBaseBreadcrumbs;
  }

  return [
    ...businessGroupBaseBreadcrumbs,
    {
      text: 'Expense Type Mappings',
      href: `/${currentBusinessGroupName}/expense-type`
    }
  ];
};

export const getAllColumns = (entityName: string): DynamicColumnDetails => {
  return {
    columnInfo: {
      entityName: entityName,
      trackBy: 'expense_type_id',
      sortingColumn: 'updated_at',
      isDescending: true
    },
    data: [
      {
        fieldName: 'expense_type_id',
        displayName: 'Expense Type Id',
        canBeHiddenFromProperties: true,
        isShownByDefault: false,
        isFilterable: false,
        metadata: {
          type: 'number'
        }
      },
      {
        fieldName: 'data_classification_id',
        displayName: 'Data Classification Id',
        canBeHiddenFromProperties: true,
        isShownByDefault: false,
        isFilterable: false,
        metadata: {
          type: 'number'
        }
      },
      {
        fieldName: 'budget_type',
        displayName: 'Budget Type',
        canBeHiddenFromProperties: false,
        isShownByDefault: true,
        isFilterable: true,
        metadata: {
          type: 'string'
        }
      },
      {
        fieldName: 'expense_type',
        displayName: 'Expense Type',
        canBeHiddenFromProperties: false,
        isShownByDefault: true,
        isFilterable: true,
        metadata: {
          type: 'string'
        }
      },
      {
        fieldName: 'description',
        displayName: 'Description',
        canBeHiddenFromProperties: false,
        isShownByDefault: true,
        isFilterable: true,
        metadata: {
          type: 'string'
        }
      },
      // {
      //   fieldName: 'is_active',
      //   displayName: 'Active',
      //   canBeHiddenFromProperties: true,
      //   isShownByDefault: true,
      //   isFilterable: true,
      //   metadata: {
      //     type: 'boolean'
      //   }
      // },
      {
        fieldName: 'updated_by',
        displayName: 'Updated By',
        canBeHiddenFromProperties: true,
        isShownByDefault: true,
        isFilterable: true,
        metadata: {
          type: 'string'
        }
      },
      {
        fieldName: 'updated_at',
        displayName: 'Updated At',
        canBeHiddenFromProperties: true,
        isShownByDefault: true,
        isFilterable: true,
        metadata: {
          type: 'dateTime'
        }
      }
    ]
  };
};

export const getExpenseTypeColumnDefs = (): TableProps.ColumnDefinition<ExpenseTypeMappingEntity>[] => [
  {
    id: 'expense_type_id',
    header: 'Expense Type Id',
    cell: (item: ExpenseTypeMappingEntity) => item.expense_type_id
  },
  {
    id: 'data_classification_id',
    header: 'Data Classification Id',
    cell: (item: ExpenseTypeMappingEntity) => item.data_classification_id
  },
  {
    id: 'budget_type',
    header: 'Budget Type',
    cell: (item: ExpenseTypeMappingEntity) => item.budget_type,
    isRowHeader: true,
    minWidth: 100,
    sortingField: 'budget_type'
  },
  {
    id: 'expense_type',
    header: 'Expense Type',
    cell: (item: ExpenseTypeMappingEntity) => item.expense_type,
    minWidth: 300,
    maxWidth: 400,
    sortingField: 'expense_type'
  },
  {
    id: 'description',
    header: 'Description',
    cell: (item: ExpenseTypeMappingEntity) => item.description,
    minWidth: 200,
    sortingField: 'description'
  },
  // {
  //   id: 'is_active',
  //   header: 'Active',
  //   cell: (item: ExpenseTypeMappingEntity) => (
  //     <StatusIndicator colorOverride={item.is_active ? 'green' : 'red'} type={item.is_active ? 'success' : 'stopped'}>
  //       {item.is_active ? 'Active' : 'Inactive'}
  //     </StatusIndicator>
  //   ),
  //   minWidth: 100,
  //   sortingField: 'is_active'
  // },
  {
    id: 'updated_by',
    header: 'Updated By',
    cell: (item: ExpenseTypeMappingEntity) => item.updated_by,
    sortingField: 'updated_by'
  },
  {
    id: 'updated_at',
    header: 'Updated At',
    cell: (item: ExpenseTypeMappingEntity) => formatUTCAsLocalReadable(item.updated_at),
    sortingField: 'updated_at'
  }
];

export const getSortedUniqueExpenseAndBudgetTypesArray = (expenseTypeMappings: ExpenseTypeMappingEntity[]) => {
  const { expenseTypes, budgetTypes } = getSortedUniqueExpenseAndBudgetTypes(expenseTypeMappings);

  const expenseTypesArray = expenseTypes.map((type) => ({ value: type }));
  const budgetTypesArray = budgetTypes.map((type) => ({ value: type }));

  return { expenseTypes: expenseTypesArray, budgetTypes: budgetTypesArray };
};

export const getSortedUniqueExpenseAndBudgetTypes = (expenseTypeMappings: ExpenseTypeMappingEntity[]) => {
  const expenseTypes = new Set<string>();
  const budgetTypes = new Set<string>();

  expenseTypeMappings.forEach((mapping) => {
    expenseTypes.add(mapping.expense_type);
    budgetTypes.add(mapping.budget_type);
  });

  return {
    expenseTypes: Array.from(expenseTypes).sort(),
    budgetTypes: Array.from(budgetTypes).sort()
  };
};

// Modified schema to include uniqueness validation
export const expenseTypeSchema = (expenseTypeMappings: ExpenseTypeMappingEntity[]) =>
  Yup.object()
    .shape({
      expense_type_id: Yup.number().nullable(),
      data_classification_id: Yup.number().nullable().required('Data Classification ID is required'),
      budget_type: Yup.string().required('Budget Type is required'),
      expense_type: Yup.string().required('Expense Type is required'),
      description: Yup.string().nullable().max(200, 'Max 200 characters allowed'),
      is_active: Yup.boolean().required('Is Active is required')
    })
    .test('unique-combination', function (value) {
      const { data_classification_id, budget_type, expense_type } = value;

      const existingRecord = expenseTypeMappings
        .filter((item) => item.expense_type_id !== value.expense_type_id)
        .find(
          (item) => item.data_classification_id === data_classification_id && item.budget_type === budget_type && item.expense_type === expense_type
        );

      if (existingRecord) {
        return this.createError({
          path: 'expense_type', // The field to assign the error to
          message: 'Combination of Budget Type, and Expense Type already exists'
        });
      }

      return true;
    });
