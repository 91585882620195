import {
  Autosuggest,
  Button,
  Form,
  FormField,
  Input,
  SpaceBetween,
  SplitPanel,
  StatusIndicator,
  StatusIndicatorProps,
  Toggle
} from '@amzn/awsui-components-react';
import { Formik, FormikHelpers, FormikProps } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { logger, logUserClick } from 'src/analytics/KatalLogger';
import { updateExpenseType } from 'src/api/app-sync-services';
import { useAuth } from 'src/app/auth/AuthContextProvider';
import { LoadingSpinner } from 'src/components/common/LoadingSpinner';
import { splitPanelI18nStrings } from 'src/i18n-strings';
import { Status } from 'src/models/AuthContextModels';
import { BudgetTypeArray, ExpenseTypeArray, ExpenseTypeMappingEntity } from 'src/models/xPTMappingModels';
import { selectActiveExpenseTypeMappings } from 'src/store/selectors/xPTMapperSelector';
import { fetchExpenseTypeMappings } from 'src/store/slices/xPTMapperSlice';
import { RootState } from 'src/store/store';
import { useAppDispatch } from 'src/store/useAppDispatch';
import { sendSNSNotification } from 'src/utils/aws-sns-service';
import { getCurrentUTCTimeInISO } from 'src/utils/date-time-utilities';
import { currentBusinessGroupName, currentDataClassificationId } from '../businessGroupSelectors';
import { expenseTypeSchema } from './ExpenseTypeMappingConfig';

interface ExpenseTypeFormProps {
  expenseTypesAutoSuggestList: ExpenseTypeArray;
  budgetTypeAutoSuggestList: BudgetTypeArray;
  selectedExpenseTypeMappings: ExpenseTypeMappingEntity[];
  closeTheSidePanel: () => void;
}

const initializeExpenseTypeForm = (dataClassificationId: number): ExpenseTypeMappingEntity => {
  return {
    expense_type_id: null,
    data_classification_id: dataClassificationId,
    expense_type: '',
    budget_type: '',
    description: '',
    is_active: true,
    created_at: getCurrentUTCTimeInISO(),
    created_by: '',
    updated_at: getCurrentUTCTimeInISO(),
    updated_by: ''
  } as ExpenseTypeMappingEntity;
};

export const ExpenseTypeForm: React.FC<ExpenseTypeFormProps> = ({
  expenseTypesAutoSuggestList,
  budgetTypeAutoSuggestList,
  selectedExpenseTypeMappings,
  closeTheSidePanel
}) => {
  const { userAlias } = useAuth();
  const dispatch = useAppDispatch();
  const dataClassificationId = useSelector(currentDataClassificationId);
  const businessGroupName = useSelector(currentBusinessGroupName);
  const { expenseTypeStatus } = useSelector((state: RootState) => state.xPTMappingStore);
  const expenseTypeMappings = useSelector(selectActiveExpenseTypeMappings);

  const [isNewExpenseType, setIsNewExpenseType] = useState<boolean>(true);
  const [formInitializing, setFormInitializing] = useState<boolean>(true);
  const [expenseTypeForm, setExpenseTypeForm] = useState<ExpenseTypeMappingEntity>(initializeExpenseTypeForm(dataClassificationId));
  const expenseTypeFormRef = useRef<FormikProps<ExpenseTypeMappingEntity>>(null);

  const [submissionStatus, setSubmissionStatus] = useState<StatusIndicatorProps.Type>('stopped');
  const [submissionStatusMessage, setSubmissionStatusMessage] = useState<string>('');

  useEffect(() => {
    if (dataClassificationId && expenseTypeStatus === Status.Completed) {
      setFormInitializing(true);
      setSubmissionStatusMessage(``);
      setSubmissionStatus('stopped');

      if (selectedExpenseTypeMappings.length > 0) {
        setIsNewExpenseType(false);
        setExpenseTypeForm(selectedExpenseTypeMappings[0]);
      } else {
        setIsNewExpenseType(true);
        setExpenseTypeForm(initializeExpenseTypeForm(dataClassificationId));
      }

      setFormInitializing(false);
    }
  }, [expenseTypeStatus, dataClassificationId, selectedExpenseTypeMappings]);

  const handleSubmit = async (formValues: ExpenseTypeMappingEntity, formikHelpers: FormikHelpers<ExpenseTypeMappingEntity>) => {
    const mutationObject: ExpenseTypeMappingEntity = {
      ...formValues,
      created_at: isNewExpenseType ? getCurrentUTCTimeInISO() : formValues.created_at,
      created_by: isNewExpenseType ? userAlias : formValues.created_by,
      updated_at: getCurrentUTCTimeInISO(),
      updated_by: userAlias
    };

    try {
      formikHelpers.setSubmitting(true);
      setSubmissionStatus('in-progress');
      setSubmissionStatusMessage(`Request in progress...`);

      logUserClick(`submit expense type mappings`, 'Expense Type Mappings', businessGroupName);
      await updateExpenseType([mutationObject]);
      setSubmissionStatusMessage(`Expense Type ${isNewExpenseType ? 'created' : 'updated'} successfully.`);
      setSubmissionStatus('success');

      sendSNSNotification(
        `Expense Type ${isNewExpenseType ? 'Created' : 'Updated'}`,
        `Expense Type '${mutationObject.expense_type}' was ${isNewExpenseType ? 'created' : 'updated'} by ${userAlias} in ${businessGroupName}`,
        'success'
      );

      setTimeout(() => {
        formikHelpers.setSubmitting(false);
        reloadExpenseTypes();
        closeTheSidePanel();
      }, 1000);
    } catch (error: any) {
      logger.error('Unable to submit Expense Type request.', error);
      setSubmissionStatusMessage(`Request failed, please try again.`);
      setSubmissionStatus('error');

      sendSNSNotification(
        `Expense Type ${isNewExpenseType ? 'Creation' : 'Update'} Failed`,
        `Expense Type '${mutationObject.expense_type}' failed to ${isNewExpenseType ? 'create' : 'update'} by ${userAlias} in ${businessGroupName}`,
        'error'
      );

      formikHelpers.setSubmitting(false);
    }
  };

  const reloadExpenseTypes = () => {
    dispatch(fetchExpenseTypeMappings());
  };

  return (
    <SplitPanel hidePreferencesButton header={isNewExpenseType ? 'Create Expense Type' : 'Update Expense Type'} i18nStrings={splitPanelI18nStrings}>
      {formInitializing && <LoadingSpinner />}
      {!formInitializing && (
        <>
          <Formik<ExpenseTypeMappingEntity>
            innerRef={expenseTypeFormRef}
            enableReinitialize={true}
            initialValues={expenseTypeForm}
            validationSchema={expenseTypeSchema(expenseTypeMappings)}
            validateOnChange={true}
            validateOnBlur={true}
            onSubmit={handleSubmit}
          >
            {({ errors, handleBlur, handleSubmit, touched, dirty, isValid, setFieldTouched, values, setFieldValue, isSubmitting }) => {
              return (
                <Form>
                  <SpaceBetween size="m" direction="vertical">
                    <FormField label="Budget Type" errorText={touched.budget_type && errors.budget_type}>
                      <Autosuggest
                        name="budget_type"
                        value={values.budget_type}
                        onChange={({ detail }) => {
                          setFieldTouched('expense_type', true, true);
                          setFieldValue('budget_type', detail.value);
                        }}
                        onBlur={() => {
                          setFieldTouched('budget_type', true, true);
                        }}
                        enteredTextLabel={(value) => `Use: "${value}"`}
                        options={budgetTypeAutoSuggestList}
                      />
                    </FormField>

                    <FormField label="Expense Type" errorText={touched.expense_type && errors.expense_type}>
                      <Autosuggest
                        name="expense_type"
                        value={values.expense_type}
                        onChange={({ detail }) => {
                          setFieldValue('expense_type', detail.value);
                        }}
                        onBlur={() => {
                          setFieldTouched('expense_type', true, true);
                          setFieldTouched('budget_type', true, true);
                        }}
                        options={expenseTypesAutoSuggestList}
                        enteredTextLabel={(value) => `Use: "${value}"`}
                      />
                    </FormField>

                    <FormField label="Description" errorText={touched.description && errors.description}>
                      <Input
                        value={values.description || ''}
                        onChange={({ detail }) => {
                          setFieldValue('description', detail.value);
                        }}
                        onBlur={() => {
                          setFieldTouched('description', true, true);
                          setFieldTouched('expense_type', true, true);
                          setFieldTouched('budget_type', true, true);
                        }}
                        disableBrowserAutocorrect
                      />
                    </FormField>

                    <FormField label="Status" errorText={touched.is_active && errors.is_active}>
                      <Toggle
                        checked={values.is_active}
                        onChange={({ detail }) => setFieldValue('is_active', detail.checked)}
                        onBlur={() => setFieldTouched('is_active', true, true)}
                      >
                        {values.is_active ? 'Active' : 'Inactive'}
                      </Toggle>
                    </FormField>

                    <Button variant="primary" disabled={!dirty || isSubmitting || !isValid} onClick={() => handleSubmit()} loading={isSubmitting}>
                      {isNewExpenseType ? 'Create Expense Type' : 'Update Expense Type'}
                    </Button>

                    {submissionStatus !== 'stopped' && <StatusIndicator type={submissionStatus}>{submissionStatusMessage}</StatusIndicator>}
                  </SpaceBetween>
                </Form>
              );
            }}
          </Formik>
        </>
      )}
    </SplitPanel>
  );
};
