import { Box, StatusIndicator, StatusIndicatorProps } from '@amzn/awsui-components-react';
import React from 'react';
import { Status } from 'src/models/AuthContextModels';

interface XptGridOverlayProps {
  forecastRowsLoadingStatus: Status;
  forecastLoadingErrorMessage: string;
}

// Default settings for the component
const Defaults = {
  DefaultErrorMessage: 'No data',
  DefaultErrorStatus: 'info' as StatusIndicatorProps.Type
};

/**
 * Determines the error message and status based on loading status.
 * @param forecastRowsLoadingStatus - The current loading status of forecast rows
 * @param forecastLoadingErrorMessage - The error message to display if loading fails
 * @returns { [string, StatusIndicatorProps.Type] } - A tuple containing the error message and status
 */
const useErrorMessageAndStatus = (forecastRowsLoadingStatus: Status, forecastLoadingErrorMessage: string): [string, StatusIndicatorProps.Type] => {
  switch (forecastRowsLoadingStatus) {
    case Status.Loading:
      return ['Loading data', 'loading'];
    case Status.Failed:
      return [forecastLoadingErrorMessage, 'error'];
    case Status.Completed:
      return ['No data', 'info'];
    default:
      return [Defaults.DefaultErrorMessage, Defaults.DefaultErrorStatus];
  }
};

/**
 * Component to display a status indicator overlay based on the data loading state
 */
const XptGridOverlay: React.FC<XptGridOverlayProps> = ({ forecastRowsLoadingStatus, forecastLoadingErrorMessage }) => {
  const [errorMessage, errorStatus] = useErrorMessageAndStatus(forecastRowsLoadingStatus, forecastLoadingErrorMessage);

  return (
    <Box padding={'m'} textAlign="center">
      <StatusIndicator type={errorStatus}>{errorMessage}</StatusIndicator>
    </Box>
  );
};

export default XptGridOverlay;
