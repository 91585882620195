import { Box, HelpPanel, SpaceBetween } from '@amzn/awsui-components-react';
import React from 'react';

export const XptVarianceReportInfoPanel: React.FC = () => {
  return (
    <>
      <HelpPanel header={<h2>Variance Report</h2>}>
        <SpaceBetween size="l">
          <Box variant="p">This report allows users to compare two scenarios and view the variance.</Box>
          <ul>
            <li>
              <Box variant="p">Select Scenario 1, Scenario 2, Rollup Period and Period from the dropdown list to generate the report</Box>
            </li>
            <li>
              <Box variant="p">Like the Forecast report, you can customize your view, save your customized view and export the report to Excel</Box>
            </li>
          </ul>
        </SpaceBetween>
      </HelpPanel>
    </>
  );
};
