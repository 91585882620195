import { useEffect, useState } from 'react';
import { logger } from 'src/analytics/KatalLogger';

/**
 * Retrieves an item from local storage.
 * @param key - The key of the item to retrieve.
 * @returns The parsed item from local storage or null if not found or parsing fails.
 */
export const getLocalStorageItem = <T,>(key: string): T | null => {
  try {
    const item = localStorage.getItem(key);
    return item ? JSON.parse(item) : null;
  } catch (err: any) {
    logger.error(`Error parsing local storage item "${key}":`, err);
    return null;
  }
};

/**
 * Sets an item in local storage.
 * @param key - The key under which the item is stored.
 * @param value - The value to store.
 */
export const setLocalStorageItem = <T,>(key: string, value: T): void => {
  try {
    localStorage.setItem(key, JSON.stringify(value));
  } catch (err: any) {
    logger.error(`Error setting local storage item "${key}":`, err);
  }
};

/**
 * Removes an item from local storage.
 * @param key - The key of the item to remove.
 */
export const removeLocalStorageItem = (key: string): void => {
  try {
    localStorage.removeItem(key);
  } catch (err: any) {
    logger.error(`Error removing local storage item "${key}":`, err);
  }
};

/**
 * A generic hook for persisting state in local storage.
 * @param localStorageKey - The key under which the state is stored in local storage.
 * @param defaultValue - The default value for the state if it doesn't exist in local storage.
 * @returns A tuple with the current state and a function to update it.
 */
export const usePersistedState = <T,>(localStorageKey: string, defaultValue: T): [T, React.Dispatch<React.SetStateAction<T>>] => {
  const [value, setValue] = useState<T>(() => {
    const storedValue = getLocalStorageItem<T>(localStorageKey);
    return storedValue !== null ? storedValue : defaultValue;
  });

  useEffect(() => {
    setLocalStorageItem(localStorageKey, value);
  }, [value, localStorageKey]);

  return [value, setValue];
};
