import { Button, Header, SpaceBetween, StatusIndicator } from '@amzn/awsui-components-react';
import React, { useEffect, useState } from 'react';
import { logger } from 'src/analytics/KatalLogger';
import { getContactDetails } from 'src/api/app-sync-services';
import { LoadingSpinner } from 'src/components/common/LoadingSpinner';
import XptWidgetTable from 'src/components/common/xpt-table/XptWidgetTable';
import { ContactInformation } from 'src/models/AppContextModels';
import { WidgetContainer } from '../WidgetContainer';
import { Contacts_Header_Config } from './ContactUtils';
import { useAuth } from 'src/app/auth/AuthContextProvider';
import ContactsForm from './ContactsForm';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/store';

interface ContactsWidgetProps {
  dataClassificationId?: number;
}

const ContactsWidget: React.FC<ContactsWidgetProps> = ({ dataClassificationId }) => {
  const { isDev } = useAuth();
  const { userAccessForCurrentBusinessGroup } = useSelector((state: RootState) => state.xptAccessAndAuthorizationStore);
  const isFinanceAdminOrFinanceOwner = userAccessForCurrentBusinessGroup?.isFinanceAdminOrFinanceOwner || false;

  const [contacts, setContacts] = useState<ContactInformation[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [showModal, setShowModal] = useState<boolean>(false);

  useEffect(() => {
    loadContacts();
  }, [dataClassificationId]);

  const loadContacts = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const fetchedContacts = await getContactDetails();
      setContacts(fetchedContacts);
    } catch (err: any) {
      setContacts([]);
      setError('Failed to load resources');
      logger.error('Failed to load contacts widget data', err);
    } finally {
      setIsLoading(false);
    }
  };

  const onCancelCreateContact = () => {
    setShowModal(false);
  };

  const onConfirmingCreateContact = () => {
    setShowModal(false);
    loadContacts();
  };

  const widgetHeader = (
    <Header
      variant="h3"
      actions={
        <SpaceBetween size="s" direction="horizontal">
          {(isDev || isFinanceAdminOrFinanceOwner) && <Button onClick={() => setShowModal(true)}>Edit</Button>}
        </SpaceBetween>
      }
    >
      Contacts
    </Header>
  );

  return (
    <>
      <ContactsForm showModal={showModal} onCancel={onCancelCreateContact} onConfirm={onConfirmingCreateContact} contacts={contacts} />
      <WidgetContainer header={widgetHeader}>
        {isLoading ? (
          <LoadingSpinner />
        ) : error ? (
          <StatusIndicator type="error">{error}</StatusIndicator>
        ) : (
          <>
            <XptWidgetTable
              variant="embedded"
              loadingStatus={false}
              allItems={contacts}
              columnDefinitions={Contacts_Header_Config()}
              hasPagination={false}
              itemsPerPage={5}
            />
          </>
        )}
      </WidgetContainer>
    </>
  );
};

export default ContactsWidget;
