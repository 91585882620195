import { useCollection } from '@amzn/awsui-collection-hooks';
import { CollectionPreferencesProps, Pagination, PropertyFilter, PropertyFilterProps, Table, TableProps } from '@amzn/awsui-components-react';
import React from 'react';
import { paginationAriaLabels, propertyFilterI18nStrings } from 'src/i18n-strings';
import { BLANK_SEARCH_AND, TableEmptyState, TableNoMatchState, XptTablePreferences, getMatchesCountText } from '../TableCommons';
import {
  extractFieldNamesForDefaultVisibleContent,
  generateFilteringProperties,
  generateVisibleContentOptions,
  xptTableSortingComparator
} from './XptTableConfig';
import { DynamicColumnDetails } from '../../../models/XptTableModel';
import { logUserClick } from 'src/analytics/KatalLogger';

interface XptTableProps {
  xptTableVariant: TableProps.Variant;
  loadingStatus: boolean;
  entityName: string;
  xptTableHeader: React.ReactNode;
  allItems: any[];
  allColumns: DynamicColumnDetails;
  columnDefinitions: TableProps.ColumnDefinition<any>[];
  itemsPerPage?: number;
  selectionType?: TableProps.SelectionType;
  onRowClickSelect?: boolean;
  selectedItems: any[];
  setSelectedItems: (selectedItems: any[]) => {};
  handleSubmit?: TableProps.SubmitEditFunction<any, unknown>;
  page: string;
  mainPage: string;
  stickyColumns?: any;
}

const XptTable: React.FC<XptTableProps> = ({
  xptTableVariant,
  loadingStatus,
  entityName,
  xptTableHeader,
  allItems,
  allColumns,
  columnDefinitions,
  itemsPerPage,
  selectionType,
  onRowClickSelect = true,
  selectedItems,
  setSelectedItems,
  handleSubmit,
  page,
  mainPage,
  stickyColumns
}) => {
  const [tableRowData, setTableRowData] = React.useState<any[]>([]);
  const [tableColumnDefinitions, setTableColumnDefinitions] = React.useState<TableProps.ColumnDefinition<any>[]>([]);

  const [tableDefaultPreferences, setTableDefaultPreferences] = React.useState<CollectionPreferencesProps.Preferences>({});
  const [tableVisibleCOntentOptions, setTableVisibleContentOptions] = React.useState<CollectionPreferencesProps.VisibleContentOptionsGroup[]>([]);
  const [filteringProperties, setFilteringProperties] = React.useState<PropertyFilterProps.FilteringProperty[]>([]);
  const [query, setQuery] = React.useState(BLANK_SEARCH_AND);

  React.useEffect(() => {
    document.body.style.lineHeight = 'normal';
  }, []);

  React.useEffect(() => {
    actions.setPropertyFiltering(query);
  }, [query]);

  // generating Table column definitions from allColumns
  React.useEffect(() => {
    setTableColumnDefinitions(columnDefinitions || []);
    setTableRowData(allItems || []);
  }, [columnDefinitions, allItems]);

  // generating Table default preferences from allColumns
  React.useEffect(() => {
    if (allColumns) {
      const defaultPreferences = {
        pageSize: itemsPerPage,
        visibleContent: extractFieldNamesForDefaultVisibleContent(allColumns),
        wrapLines: false,
        stripedRows: false,
        custom: false
      } as CollectionPreferencesProps.Preferences;
      setTableDefaultPreferences(defaultPreferences);
      setTableVisibleContentOptions(generateVisibleContentOptions(allColumns));
    }
  }, [allColumns, itemsPerPage]);

  const onClearFilter = () => {
    setQuery(BLANK_SEARCH_AND);
  };

  // generating filtering properties from allColumns
  React.useEffect(() => {
    if (allColumns) {
      const properties = generateFilteringProperties(allColumns);
      setFilteringProperties(properties);
    }
  }, [allColumns]);

  const { items, actions, filteredItemsCount, collectionProps, paginationProps, propertyFilterProps } = useCollection(tableRowData, {
    propertyFiltering: {
      filteringProperties,
      empty: <TableEmptyState resourceName={``} />,
      noMatch: <TableNoMatchState onClearFilter={onClearFilter} />
    },
    ...(tableDefaultPreferences.pageSize && {
      pagination: {
        pageSize: tableDefaultPreferences.pageSize
      }
    }),
    sorting: {
      defaultState: {
        sortingColumn: columnDefinitions?.find((col) => col.id == allColumns.columnInfo.sortingColumn) || columnDefinitions[0],
        isDescending: allColumns?.columnInfo?.isDescending
      }
    }
  });

  return (
    <>
      <Table
        variant={xptTableVariant}
        header={xptTableHeader}
        loading={loadingStatus}
        loadingText="Loading"
        stickyHeader={true}
        columnDefinitions={tableColumnDefinitions}
        items={items}
        selectionType={selectionType}
        selectedItems={selectedItems}
        onSelectionChange={({ detail }) => setSelectedItems(detail.selectedItems)}
        onRowClick={({ detail }) => onRowClickSelect && setSelectedItems([detail.item])}
        pagination={
          tableDefaultPreferences.pageSize ? (
            <Pagination {...paginationProps} ariaLabels={paginationAriaLabels(paginationProps.pagesCount)} />
          ) : undefined
        }
        {...collectionProps}
        trackBy={allColumns?.columnInfo?.trackBy}
        stickyColumns={stickyColumns}
        enableKeyboardNavigation
        filter={
          <PropertyFilter
            {...propertyFilterProps}
            expandToViewport={true}
            i18nStrings={propertyFilterI18nStrings(entityName)}
            countText={getMatchesCountText(filteredItemsCount!)}
            query={query}
            onChange={(event) => {
              event.detail.tokens?.length != 0 && logUserClick('table search', page, mainPage);
              setQuery(event.detail.tokens?.length === 0 ? BLANK_SEARCH_AND : event.detail);
            }}
          />
        }
        visibleColumns={tableDefaultPreferences.visibleContent}
        resizableColumns={tableDefaultPreferences.custom}
        wrapLines={tableDefaultPreferences.wrapLines}
        stripedRows={tableDefaultPreferences.stripedRows}
        contentDensity={tableDefaultPreferences.contentDensity}
        preferences={
          <XptTablePreferences
            preferences={tableDefaultPreferences}
            setPreferences={setTableDefaultPreferences}
            visibleContentOptions={tableVisibleCOntentOptions}
          />
        }
        submitEdit={handleSubmit}
      />
    </>
  );
};

export default XptTable;
