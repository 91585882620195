import { Alert, Box, SpaceBetween } from '@amzn/awsui-components-react';
import React from 'react';
import { ForecastTemplateColumns } from 'src/models/ForecastModels';
import { PlanningCycleEntity } from 'src/models/PlanningCycleModel';
import { replaceWithDisplayNames } from './forecast-utils/ForecastTemplateUtils';

interface ForecastTemplateUploadGuidelinesProps {
  selectedPlanningCycle: PlanningCycleEntity;
  forecastTemplateColumns: ForecastTemplateColumns;
}

const ForecastTemplateUploadGuidelines: React.FC<ForecastTemplateUploadGuidelinesProps> = ({ forecastTemplateColumns }) => {
  const mandatoryFields: string[] = forecastTemplateColumns?.mandatoryFields ? replaceWithDisplayNames(forecastTemplateColumns.mandatoryFields) : [];

  return (
    <SpaceBetween size="xs" direction="vertical">
      <Box variant="h4">Excel upload guidelines</Box>
      <Alert type="warning">Do not rename column headers in the template or file upload may fail</Alert> 
      <Box variant="p">To edit existing rows, only modify the yellow-shaded cells in the monthly input section.</Box>
      <Box variant="p">
        To add new segment combinations, insert new rows in Excel and fill out all <strong>bolded column header</strong> fields in the Excel template:
      </Box>
      <ul style={{ marginTop: '8px', marginBottom: '8px' }}>
        {mandatoryFields.map((field, index) => (
          <li key={index}>{field}</li>
        ))}
      </ul>
    </SpaceBetween>
  );
};

export default ForecastTemplateUploadGuidelines;
