import { CloudWatchLogsClient, GetQueryResultsCommand, StartQueryCommand } from '@aws-sdk/client-cloudwatch-logs';
import { logger } from 'src/analytics/KatalLogger';
import ENVIRONMENT_VARIABLES from 'src/constants/environment-variables';
import { getAWSServiceCredentials } from 'src/utils/auth-token-services';

// NOTE: This is a POC and not in use currently. Please ignore this section during code review as it will be utilized shortly.
export const fetchCloudWatchLogData = async (query: string, startTime: number, endTime: number): Promise<any[]> => {
  const credentials = await getAWSServiceCredentials();
  const client = new CloudWatchLogsClient({ region: ENVIRONMENT_VARIABLES.env.Region, credentials });

  logger.info('Fetching cw logs from Start ' + new Date(startTime) + ' to End ' + new Date(endTime));
  try {
    const stageName = `${ENVIRONMENT_VARIABLES.env.Stage === 'dev' ? 'alpha' : ENVIRONMENT_VARIABLES.env.Stage}`;
    const xPT_Log_Group_Name = `xpt-fe-website-logs-${stageName}`;

    const queryToPassAsParam = query.replace(`SOURCE '${xPT_Log_Group_Name}' | `, '');
    // logger.info('Fetching cw logs with query ' + queryToPassAsParam);

    // Start the query
    const startQueryCommand = new StartQueryCommand({
      logGroupName: xPT_Log_Group_Name,
      startTime,
      endTime,
      queryString: queryToPassAsParam
    });

    const startQueryResponse = await client.send(startQueryCommand);
    const queryId = startQueryResponse.queryId;

    if (!queryId) {
      logger.error('Failed to start query.');
      throw new Error('Failed to start query');
    }

    // Poll for results
    let queryStatus = 'Running';
    let queryResults;
    while (queryStatus === 'Running') {
      await new Promise((resolve) => setTimeout(resolve, 1000)); // Wait for 1 second before checking again

      const getResultsCommand = new GetQueryResultsCommand({ queryId });
      const getResultsResponse = await client.send(getResultsCommand);
      queryStatus = getResultsResponse.status || 'Running';
      queryResults = getResultsResponse.results;
    }

    if (!queryResults) {
      logger.error('No results returned from query');
      throw new Error('No results returned from query');
    }

    // Process and return the results
    return queryResults.map((result) => {
      const entry: any = {};
      result.forEach((field) => {
        if (field.field && field.value) {
          entry[field.field] = field.value;
        }
      });
      return entry as any;
    });
  } catch (error: any) {
    logger.error('Error fetching cw log data:', error);
    throw error;
  }
};
