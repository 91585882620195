import { Button, Flashbar, FlashbarProps, Header, SpaceBetween } from '@amzn/awsui-components-react';
import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { XPTBreadcrumbs } from 'src/components/common/XptBreadcrumb';
import { XptAppLayout } from 'src/components/common/xpt-app-layout/XptAppLayout';
import XptTableWithSavedFilters from 'src/components/common/xpt-table/XptTableWithSavedFilters';
import { selectFlattenedBusinessGroupsForTable } from 'src/features/business-group/businessGroupSelectors';
import { fetchBusinessGroups } from 'src/features/business-group/businessGroupSlice';
import { useFlashbar } from 'src/hooks/useFlashbar';
import { useAppDispatch } from 'src/store/useAppDispatch';
import { getHeaderCounter } from 'src/utils/generic-utilities';
import OnboardingForm from './OnboardingForm';
import { getOnboardingBreadcrumbItems } from './OnboardingFormUtils';
import { OnboardingHelpPanel } from './OnboardingInfoPanel';
import {
  ONBOARDING_DATA_CLASSIFICATION_TABLE_CONFIG,
  ONBOARDING_DATA_CLASSIFICATION_TABLE_DEFINITIONS,
  defaultFilterSetsOfBusinessGroup
} from './OnboardingTableConfig';
import { OnboardingTableEntity } from 'src/models/OnboardingModel';
import AdminConsoleSideNavigation from '../../AdminConsoleSideNavigation';

export type OnboardingContextType = {
  reloadBusinessGroups: () => void;
  notificationMessage: (content: string, flashBarType: FlashbarProps.Type, isDismissible: boolean, messageId?: string) => void;
  clearSpecificFlashMessage: (messageId: string) => void;
  loadingModules: boolean;
  setLoadingModules: (loadingModules: boolean) => void;
};

export const OnboardingContext = createContext<OnboardingContextType>({
  reloadBusinessGroups: () => {},
  notificationMessage: () => {},
  clearSpecificFlashMessage: (messageId: string) => {},
  loadingModules: false,
  setLoadingModules: (loadingModules: boolean) => {}
});

export const useOnboardingContext = () => useContext(OnboardingContext);

const OnboardingHomePage: React.FC = () => {
  const appLayout = useRef<any>();
  const navigate = useNavigate();
  const { id: paramDataClassificationId } = useParams();

  const [loadingModules, setLoadingModules] = React.useState<boolean>(true);
  const { flashbarItems, displayFlashMessage, clearSpecificFlashMessage } = useFlashbar();

  const [onboardingTableRowEntity, setOnboardingTableRowEntity] = useState<OnboardingTableEntity[]>([]);
  const [selectedOnboardingRow, setSelectedOnboardingRow] = useState<OnboardingTableEntity[]>([]);

  const dispatch = useAppDispatch();
  const flattenedBusinessGroups = useSelector(selectFlattenedBusinessGroupsForTable);

  useEffect(() => {
    setLoadingModules(true);
    setOnboardingTableRowEntity(flattenedBusinessGroups);
    setLoadingModules(false);
  }, [flattenedBusinessGroups]);

  const reloadBusinessGroups = async () => {
    setLoadingModules(true);
    await dispatch(fetchBusinessGroups());
    setLoadingModules(false);
  };

  const navigateToOnboardingForm = () => {
    navigate('/admin-console/onboarding/' + selectedOnboardingRow[0].data_classification_id);
  };

  const navigateToNewOnboardingForm = () => {
    navigate('/admin-console/onboarding/new');
  };

  const OnboardingTableHeader = () => {
    return (
      <Header
        actions={
          <SpaceBetween size="m" direction="horizontal">
            <Button iconName="refresh" onClick={reloadBusinessGroups}></Button>
            <Button iconName="edit" onClick={navigateToOnboardingForm} variant="normal" disabled={selectedOnboardingRow.length === 0}>
              {'Edit'}
            </Button>
            <Button iconName="add-plus" variant="primary" onClick={navigateToNewOnboardingForm}>
              {'Create business group'}
            </Button>
          </SpaceBetween>
        }
        counter={getHeaderCounter(selectedOnboardingRow, onboardingTableRowEntity)}
      >
        {'Business Groups'}
      </Header>
    );
  };

  return (
    <>
      <XptAppLayout
        ref={appLayout}
        headerSelector="#h"
        navigation={<AdminConsoleSideNavigation />}
        tools={<OnboardingHelpPanel />}
        breadcrumbs={<XPTBreadcrumbs items={getOnboardingBreadcrumbItems(paramDataClassificationId)} />}
        notifications={<Flashbar items={flashbarItems} stackItems />}
        stickyNotifications={true}
        maxContentWidth={Number.MAX_VALUE}
        contentType="table"
        content={
          <div className="xpt-app-layout-content">
            <OnboardingContext.Provider
              value={{
                loadingModules,
                setLoadingModules,
                reloadBusinessGroups,
                notificationMessage: displayFlashMessage,
                clearSpecificFlashMessage
              }}
            >
              {!paramDataClassificationId && (
                <XptTableWithSavedFilters
                  xptTableVariant={'full-page'}
                  loadingStatus={loadingModules}
                  entityName={ONBOARDING_DATA_CLASSIFICATION_TABLE_CONFIG.columnInfo.entityName}
                  xptTableHeader={<OnboardingTableHeader />}
                  allItems={onboardingTableRowEntity}
                  allColumns={ONBOARDING_DATA_CLASSIFICATION_TABLE_CONFIG}
                  columnDefinitions={ONBOARDING_DATA_CLASSIFICATION_TABLE_DEFINITIONS}
                  itemsPerPage={20}
                  selectionType="single"
                  selectedItems={selectedOnboardingRow}
                  setSelectedItems={async (items) => setSelectedOnboardingRow(items)}
                  filterSets={defaultFilterSetsOfBusinessGroup}
                  defaultFilterSets={defaultFilterSetsOfBusinessGroup[0]}
                />
              )}
              {paramDataClassificationId && <OnboardingForm />}
            </OnboardingContext.Provider>
          </div>
        }
      />
    </>
  );
};

export default OnboardingHomePage;
