import { Flashbar } from '@amzn/awsui-components-react';
import React, { useRef } from 'react';
import { XptAppLayout } from 'src/components/common/xpt-app-layout/XptAppLayout';
import { XPTBreadcrumbs } from 'src/components/common/XptBreadcrumb';
import { useFlashbar } from 'src/hooks/useFlashbar';
import AdminConsoleSideNavigation from '../../AdminConsoleSideNavigation';
import { geAuditLogBreadcrumbItems } from './AuditLogConfig';
import { AuditLogTable } from './AuditLogTable';
import { AuditType } from 'src/constants/generic-constants';

export const AuditLog: React.FC = () => {
  const appLayout = useRef<any>();

  return (
    <>
      <XptAppLayout
        ref={appLayout}
        headerSelector="#h"
        navigation={<AdminConsoleSideNavigation />}
        breadcrumbs={<XPTBreadcrumbs items={geAuditLogBreadcrumbItems()} />}
        notifications={<Flashbar items={[]} stackItems />}
        stickyNotifications={true}
        maxContentWidth={Number.MAX_VALUE}
        toolsHide={true}
        contentType="table"
        content={
          <div className="xpt-app-layout-content">
            <AuditLogTable showAuditFor={AuditType.All} itemsPerPage={200} headerName={'Audit Log'} />
          </div>
        }
      />
    </>
  );
};
