import { TopNavigation, TopNavigationProps } from '@amzn/awsui-components-react';
import { applyMode, Mode } from '@amzn/awsui-global-styles';
import { faMoon, faSun } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toggleTheme } from 'src/app/AppMetadataSlice';
import { checkTokenExpiration } from 'src/app/auth/TokenExpiry';
import { APP_NAME_SHORT, eAgGridThemes, FINANCE_ADMIN } from 'src/constants/generic-constants';
import { topNavI18nStrings } from 'src/i18n-strings/top-nav-bar-i18nStrings';
import DFP_HEADER_LOGO from 'src/images/DFP_HEADER_LOGO.png';
import { RootState } from 'src/store/store';
import { useAuth } from '../../app/auth/AuthContextProvider';
import { ModifyUserAccess } from './ModifyUserAccess';

const Visual_Mode_Options = [
  {
    id: 'dark',
    text: 'Dark',
    iconSvg: <FontAwesomeIcon icon={faMoon} />
  },
  {
    id: 'light',
    text: 'Light',
    iconSvg: <FontAwesomeIcon icon={faSun} />
  }
];

interface TokenExpirationTimes {
  idToken: string;
  accessToken: string;
}

export const XptTopNavigation: React.FC = () => {
  const { isInitiallyDev, isUserRoleModified, userDisplayName, userEmail } = useAuth();
  const isDarkModeEnabled = useSelector((state: RootState) => state.xptAppMetadataStore.themeClassName === eAgGridThemes.Quartz_Dark);
  const { currentUserAccessDetails } = useSelector((state: RootState) => state.xptAccessAndAuthorizationStore);
  const [expirationTimes, setExpirationTimes] = useState<TokenExpirationTimes | null>(null);

  // Check if user is a Finance Admin for at least 1 Business Group.
  const isFinanceAdmin = currentUserAccessDetails?.user_data_classifications.some((classification) => classification.user_type === FINANCE_ADMIN);

  const [displayAuthModifierModal, setDisplayAuthModifierModal] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    applyMode(isDarkModeEnabled ? Mode.Dark : Mode.Light);
  }, [isDarkModeEnabled]);

  const nonAdminUtilityItems = (): any[] => {
    return [];
  };

  const adminUtilityItems = (): any[] => {
    if (isFinanceAdmin) {
      return [
        {
          id: 'admin-dev-console',
          text: 'Finance Admin / Dev Console'
        }
      ];
    }
    return [];
  };

  useEffect(() => {
    const fetchExpirationTime = async () => {
      const times = await checkTokenExpiration();
      setExpirationTimes(times);
    };

    fetchExpirationTime();
    // const intervalId = setInterval(fetchExpirationTime, 1000);

    // return () => clearInterval(intervalId);
  }, []);

  const devTopNavBarUtility: TopNavigationProps.Utility[] = [
    // { type: 'button', text: `ID Token: ${expirationTimes?.idToken || 'Loading...'}` },
    // { type: 'button', text: `Access Token: ${expirationTimes?.accessToken || 'Loading...'}` },
    {
      type: 'button',
      text: `${isUserRoleModified ? 'Access Modified' : 'Modify Your Access'}`,
      iconName: 'security',
      variant: isUserRoleModified ? 'primary-button' : 'link',
      onFollow: () => {
        setDisplayAuthModifierModal(true);
      },
      onClick: () => {
        setDisplayAuthModifierModal(true);
      }
    }
  ];

  const onCancel = () => {
    setDisplayAuthModifierModal(false);
  };

  const onConfirm = () => {
    setDisplayAuthModifierModal(false);
    window.location.reload();
  };

  const topNavBarUtility: TopNavigationProps.Utility[] = [
    {
      type: 'menu-dropdown',
      iconSvg: isDarkModeEnabled ? <FontAwesomeIcon icon={faMoon} /> : <FontAwesomeIcon icon={faSun} />,
      description: 'Mode',
      onItemClick: (event) => onChangeVisualMode(event.detail.id === 'dark'),
      items: Visual_Mode_Options
    },
    {
      type: 'menu-dropdown',
      text: userDisplayName,
      description: userEmail,
      iconName: 'user-profile',
      items: nonAdminUtilityItems().concat(adminUtilityItems()),
      onItemClick: ({ detail }) => handleUtilityItemClick(detail)
    }
  ];

  const handleUtilityItemClick = (detail: any) => {
    if (detail.id === 'admin-dev-console') {
      navigate({ pathname: '/admin-console/planning-cycle' });
    }
  };

  const onChangeVisualMode = (useDarkMode: boolean) => {
    dispatch(toggleTheme(useDarkMode));
  };

  const appTitleClicked = (e: any) => {
    e.preventDefault();
    navigate({ pathname: '/' });
  };

  return (
    <>
      <ModifyUserAccess showModal={displayAuthModifierModal} onCancel={onCancel} onConfirm={onConfirm} />
      <TopNavigation
        className="app-primary-top-navigation"
        i18nStrings={topNavI18nStrings}
        identity={{
          href: '#',
          title: `${APP_NAME_SHORT}`,
          onFollow: appTitleClicked,
          logo: {
            src: DFP_HEADER_LOGO,
            alt: 'xPT Logo'
          }
        }}
        utilities={isInitiallyDev ? devTopNavBarUtility.concat(topNavBarUtility) : topNavBarUtility}
      />
    </>
  );
};
