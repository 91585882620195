// Environment variables object
const ENVIRONMENT_VARIABLES = Object.freeze({
  env: {
    Region: 'us-west-2',
    Stage: 'alpha',
    CloudFrontProtocol: 'https',
    CloudFrontDomain: 'djfk7skzpdvb9.cloudfront.net',
    CognitoUserPoolId: 'us-west-2_f8fHVcAnR',
    CognitoIdentityPoolId: 'us-west-2:5c0c091b-0c1b-4e2a-bd93-0c7d17789458',
    AuthenticationType: 'AMAZON_COGNITO_USER_POOLS',
    CognitoUserPoolWebClientId: '15ki88r5sgcc6hkgslhso3q35m',
    CognitoDomain: 'xpt-application-alpha.auth.us-west-2.amazoncognito.com',
    ApplicationDomain: 'https://alpha.xpt.device.finance.amazon.dev',
    AwsAccountId: '631849255022',
    SNSNotificationARN: 'arn:aws:sns:us-west-2:631849255022:xPT-Slack-Notification-alpha'
  }
});

export default ENVIRONMENT_VARIABLES;
