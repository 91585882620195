import { POTaggingColumnsInfo, POTaggingDataValidationStatus, POTaggingEntity, POTaggingFileDataEntity } from 'src/models/POTaggingModel';
import { ValidationErrorDetail, ValidationStatusEntity } from 'src/models/XptGenericModels';
import { getCurrentUTCTimeInISO } from 'src/utils/date-time-utilities';
import { getPOTaggingHeaderInfo } from './POTaggingColumnGenerator';

export const POTaggingValidationMessages = {
  HEADER_VALIDATION_DEFAULT_MESSAGE: "The file is not valid. The headers don't match.",
  HEADER_VALIDATION_FAILED: "The file is not valid. The headers don't match.",
  HEADER_VALIDATION_SUCCESS: 'The headers are valid.',
  HEADER_VALIDATION_ERROR: 'There was an error validating the headers.',

  SKIPPED_DUE_TO_HEADER_FAILURE: 'Skipped due to a header validation failure.',

  NO_MODIFIED_ROWS: 'There are no modified rows to validate.',

  VALIDATION_ERROR: 'There was a validation error.',

  INVALID_FILTER_DATA_DEFAULT: 'Ensure the month and cost center match the selected values in the filters.',
  INVALID_FILTER_DATA_IN_PROGRESS: 'Validating the month and cost center.',
  INVALID_FILTER_DATA_SUCCESS: 'The month and cost center are valid.',
  INVALID_FILTER_DATA_FAILED: 'Only the selected values for the month and cost center are allowed.',
  INVALID_FILTER_DATA_ERROR: 'There was an error validating the month and cost center.',

  ACTUAL_ID_COMBINATION_DEFAULT: 'The Actual Item ID is invalid. Download the latest Excel file and re-upload.',
  ACTUAL_ID_COMBINATION_IN_PROGRESS: 'Validating the tagging data.',
  ACTUAL_ID_COMBINATION_SUCCESS: 'The tagging data is valid.',
  ACTUAL_ID_COMBINATION_FAILED: 'Only the selected values for the tagging data are allowed.',
  ACTUAL_ID_COMBINATION_ERROR: 'There was an error validating the tagging data.',

  LINE_ITEM_ID_DEFAULT: 'The Line Item ID should be one of the values in the dropdown list.',
  LINE_ITEM_ID_IN_PROGRESS: 'Validating the line item IDs.',
  LINE_ITEM_ID_SUCCESS: 'The line item IDs are valid.',
  LINE_ITEM_ID_FAILED: 'Only the selected values for the line item IDs are allowed.',
  LINE_ITEM_ID_ERROR: 'There was an error validating the line item IDs.',

  UN_AUTH_MODIFICATION_DEFAULT: 'Only the Line Item ID column is allowed to be modified.',
  UN_AUTH_MODIFICATION_IN_PROGRESS: 'Validating unauthorized modifications.',
  UN_AUTH_MODIFICATION_SUCCESS: 'The modifications are valid.',
  UN_AUTH_MODIFICATION_FAILED: 'Read-only fields were updated.',
  UN_AUTH_MODIFICATION_ERROR: 'There was an error validating the line item IDs.',

  PO_TAGGING_UPLOAD_FAILED: 'The PO tagging upload failed.',
  PO_TAGGING_UPLOAD_SUCCESS: 'The PO tagging upload was successful.'
};

export const VALIDATION_NOT_INITIATED: ValidationStatusEntity = {
  colorOverride: 'grey',
  validationStatus: 'pending',
  validationMessage: 'Not initiated',
  validationDefaultMessage: '',
  validationErrorDetails: []
};

export const VALIDATION_STARTED: ValidationStatusEntity = {
  colorOverride: 'grey',
  validationStatus: 'loading',
  validationMessage: 'Validating',
  validationDefaultMessage: '',
  validationErrorDetails: []
};

export const INITIAL_PO_VALIDATION_STATUS: POTaggingDataValidationStatus = {
  HeadersMatching: { ...VALIDATION_NOT_INITIATED, validationMessage: 'Header validation' },
  FilterFieldValidation: { ...VALIDATION_NOT_INITIATED, validationMessage: 'Mandatory field validation' },
  ActualIdValidation: { ...VALIDATION_NOT_INITIATED, validationMessage: 'Non editable field validation' },
  LineItemIdValidation: { ...VALIDATION_NOT_INITIATED, validationMessage: 'Line Item Id field validation' },
  UnAuthorizedModifications: { ...VALIDATION_NOT_INITIATED, validationMessage: 'Unauthorized validation' }
};

export const INITIAL_SUBMIT_STATUS: ValidationStatusEntity = {
  colorOverride: 'grey',
  validationStatus: 'pending',
  validationMessage: 'Not Initiated',
  validationDefaultMessage: '',
  validationErrorDetails: []
};

// Helper function to convert value based on data type
const convertValue = (value: any, dataType: string): any => {
  switch (dataType) {
    case 'number':
      const parsedValue = Number(value);
      if (isNaN(parsedValue)) {
        throw new Error(`Expected a number, but received ${value}`);
      }
      return parsedValue;
    case 'string':
      return `${value}`;
    default:
      return value;
  }
};

/**
 * Transforms the uploaded PO tagging data based on column information.
 * Converts the data values to appropriate types and checks for nullability.
 *
 * @param {POTaggingColumnsInfo} columnInfo - The column information including header names, data types, and nullability.
 * @param {any[]} fileData - The raw file data to be transformed.
 * @returns {POTaggingFileDataEntity[]} - The transformed PO tagging data entities.
 * @throws {Error} - Throws an error if a non-nullable field contains a null or undefined value, or if a value cannot be converted to the specified data type.
 */
export const transformUploadedPOTaggingData = (columnInfo: POTaggingColumnsInfo, fileData: any[]): POTaggingFileDataEntity[] => {
  // Map header names to column information for quick lookup
  const headerMap = columnInfo.poTaggingFileHeader.reduce((acc, col) => {
    acc[col.headerName] = col;
    return acc;
  }, {} as { [key: string]: { field: string; dataType?: string; isNullable?: boolean } });

  return fileData.map((row) => {
    const transformedRow = {} as POTaggingFileDataEntity;

    for (const [headerName, value] of Object.entries(row)) {
      const columnInfo = headerMap[headerName];
      if (columnInfo) {
        const { field, dataType = 'string', isNullable = true } = columnInfo;

        if (value === null || value === undefined) {
          if (!isNullable) {
            throw new Error(`Field ${field} is not nullable, but received null or undefined value`);
          }
          (transformedRow as any)[field] = null;
        } else {
          try {
            (transformedRow as any)[field] = convertValue(value, dataType);
          } catch (error: any) {
            throw new Error(`Error processing field ${field}: ${error.message}`);
          }
        }
      }
    }

    return transformedRow;
  });
};

/**
 * Identifies modified rows based on xpt_line_item_id.
 * For fields not in POTaggingFileDataEntity, use values from POTaggingEntity except updated_at & updated_by.
 * @param {POTaggingFileDataEntity[]} poTaggingFileData - The PO tagging file data to be validated.
 * @param {POTaggingEntity[]} poTaggingCompleteData - The complete PO tagging data to validate against.
 * @param {string} user - The user performing the update.
 * @returns {POTaggingEntity[]} - The modified rows.
 */
export const getModifiedRows = (poTaggingFileData: POTaggingFileDataEntity[], poTaggingCompleteData: POTaggingEntity[], user: string) => {
  const modifiedRows: POTaggingEntity[] = [];

  poTaggingFileData.forEach((fileRow) => {
    const completeRow = poTaggingCompleteData.find((item) => item.actuals_item_id === fileRow.actuals_item_id);

    if (completeRow) {
      let fileValue = fileRow.xpt_line_item_id;
      let templateValue = completeRow.xpt_line_item_id;

      if (`${fileValue}` !== `${templateValue}`) {
        // console.debug(`File row: ${JSON.stringify(fileRow)}`);
        // console.debug(`Complete row: ${JSON.stringify(completeRow)}`);
        const modifiedRow: POTaggingEntity = {
          ...completeRow,
          xpt_line_item_id: fileValue,
          updated_at: getCurrentUTCTimeInISO(),
          updated_by: user
        };
        modifiedRows.push(modifiedRow);
      }
    }
  });

  return modifiedRows;
};

export const getActualItemIdMessagePart = (row: any): string => {
  const columnInfo = getPOTaggingHeaderInfo();
  const actualsItemId = row[columnInfo.idField];
  return actualsItemId ? `(Actual Item ID: ${actualsItemId})` : '';
};

/**
 * Validates that all entries in the poTaggingFileData have period_name as selectedMonth and cost_center_code as selectedCostCenter.
 * @param {POTaggingFileDataEntity[]} poTaggingFileData - The PO tagging data to be validated.
 * @param {string} selectedMonth - The selected month to be validated against period_name in the format "MMM-YY".
 * @param {string} selectedCostCenter - The selected cost center to be validated against cost_center_code.
 * @returns {Promise<ValidationStatusEntity>} - The validation status of the data.
 */
export const poTaggingFilterDataValidations = async (
  poTaggingFileData: POTaggingFileDataEntity[],
  selectedMonth: string,
  selectedCostCenter: string
): Promise<ValidationStatusEntity> => {
  const validationErrorDetails: ValidationErrorDetail[] = [];

  poTaggingFileData.forEach((row, rowIndex) => {
    if (row.period_name !== selectedMonth) {
      validationErrorDetails.push({
        rowIndex: rowIndex + 1,
        message: `Expected period_name to be '${selectedMonth}', but found '${row.period_name}' ${getActualItemIdMessagePart(row)}`
      });
    }
    if (row.cost_center_code !== selectedCostCenter) {
      validationErrorDetails.push({
        rowIndex: rowIndex + 1,
        message: `Expected cost_center_code to be '${selectedCostCenter}', but found '${row.cost_center_code}' ${getActualItemIdMessagePart(row)}`
      });
    }
  });

  if (validationErrorDetails.length > 0) {
    console.debug(`Data validation failed.`, JSON.stringify(validationErrorDetails, null, 2));
    return {
      colorOverride: 'red',
      validationMessage: POTaggingValidationMessages.INVALID_FILTER_DATA_ERROR,
      validationStatus: 'error',
      validationDefaultMessage: POTaggingValidationMessages.INVALID_FILTER_DATA_DEFAULT,
      validationErrorDetails
    };
  }

  return {
    colorOverride: 'green',
    validationMessage: POTaggingValidationMessages.INVALID_FILTER_DATA_SUCCESS,
    validationStatus: 'success',
    validationDefaultMessage: POTaggingValidationMessages.INVALID_FILTER_DATA_DEFAULT,
    validationErrorDetails: []
  };
};

/**
 * Validates that the combination of actuals_item_id and cost_center_code in poTaggingFileData matches the combination in poTaggingCompleteData.
 * @param {POTaggingFileDataEntity[]} poTaggingFileData - The PO tagging file data to be validated.
 * @param {POTaggingEntity[]} poTaggingCompleteData - The complete PO tagging data to validate against.
 * @returns {Promise<ValidationStatusEntity>} - The validation status of the data.
 */
export const actualIdValidation = async (
  poTaggingFileData: POTaggingFileDataEntity[],
  poTaggingCompleteData: POTaggingEntity[]
): Promise<ValidationStatusEntity> => {
  const validationErrorDetails: ValidationErrorDetail[] = [];

  // Create a set of valid combinations from poTaggingCompleteData for quick lookup
  const validCombinations = new Set(poTaggingCompleteData.map((item) => `${item.actuals_item_id}_${item.cost_center_code}`));

  poTaggingFileData.forEach((row, rowIndex) => {
    const combination = `${row.actuals_item_id}_${row.cost_center_code}`;
    if (!validCombinations.has(combination)) {
      validationErrorDetails.push({
        rowIndex: rowIndex + 1,
        message: `Combination of actuals_item_id '${row.actuals_item_id}' and cost_center_code '${
          row.cost_center_code
        }' does not match any valid combination ${getActualItemIdMessagePart(row)}`
      });
    }
  });

  if (validationErrorDetails.length > 0) {
    console.debug(`Actual ID validation failed.`, JSON.stringify(validationErrorDetails, null, 2));
    return {
      colorOverride: 'red',
      validationMessage: POTaggingValidationMessages.ACTUAL_ID_COMBINATION_FAILED,
      validationStatus: 'error',
      validationDefaultMessage: POTaggingValidationMessages.ACTUAL_ID_COMBINATION_DEFAULT,
      validationErrorDetails
    };
  }

  return {
    colorOverride: 'green',
    validationMessage: POTaggingValidationMessages.ACTUAL_ID_COMBINATION_SUCCESS,
    validationStatus: 'success',
    validationDefaultMessage: POTaggingValidationMessages.ACTUAL_ID_COMBINATION_SUCCESS,
    validationErrorDetails: []
  };
};

/**
 * Validates that xpt_line_item_id in poTaggingFileData can be null, an empty string, or a valid ID from validLineItemIds.
 * @param {POTaggingFileDataEntity[]} poTaggingFileData - The PO tagging file data to be validated.
 * @param {number[]} validLineItemIds - The valid line item IDs.
 * @returns {Promise<ValidationStatusEntity>} - The validation status of the data.
 */
export const lineItemIdValuation = async (
  poTaggingFileData: POTaggingFileDataEntity[],
  validLineItemIds: number[]
): Promise<ValidationStatusEntity> => {
  const validationErrorDetails: ValidationErrorDetail[] = [];

  poTaggingFileData.forEach((row, rowIndex) => {
    const lineItemId: string | null = row.xpt_line_item_id ? `${row.xpt_line_item_id}` : null;

    if (lineItemId !== null && lineItemId !== '') {
      const numericLineItemId = Number(lineItemId);

      if (isNaN(numericLineItemId) || !validLineItemIds.includes(numericLineItemId)) {
        validationErrorDetails.push({
          rowIndex: rowIndex + 1,
          message: `xpt_line_item_id '${lineItemId}' is not valid  ${getActualItemIdMessagePart(row)}`
        });
      }
    }
  });

  if (validationErrorDetails.length > 0) {
    console.debug(`Line item ID validation failed.`, JSON.stringify(validationErrorDetails, null, 2));
    return {
      colorOverride: 'red',
      validationMessage: 'Line item ID validation failed',
      validationStatus: 'error',
      validationDefaultMessage: POTaggingValidationMessages.LINE_ITEM_ID_DEFAULT,
      validationErrorDetails
    };
  }

  return {
    colorOverride: 'green',
    validationMessage: POTaggingValidationMessages.LINE_ITEM_ID_SUCCESS,
    validationStatus: 'success',
    validationDefaultMessage: POTaggingValidationMessages.LINE_ITEM_ID_SUCCESS,
    validationErrorDetails: []
  };
};

/**
 * Validates that only xpt_line_item_id has been modified in the poTaggingFileData compared to poTaggingCompleteData.
 * @param {POTaggingFileDataEntity[]} poTaggingFileData - The PO tagging file data to be validated.
 * @param {POTaggingEntity[]} poTaggingCompleteData - The complete PO tagging data to validate against.
 * @returns {Promise<ValidationStatusEntity>} - The validation status of the data.
 */
export const validateUnauthorizedModifications = async (
  poTaggingFileData: POTaggingFileDataEntity[],
  poTaggingCompleteData: POTaggingEntity[]
): Promise<ValidationStatusEntity> => {
  const validationErrorDetails: ValidationErrorDetail[] = [];

  const poTaggingColumnInfo = getPOTaggingHeaderInfo();
  const headersInfo = poTaggingColumnInfo.poTaggingFileHeader;

  poTaggingFileData.forEach((row, rowIndex) => {
    const completeRow = poTaggingCompleteData.find((item) => item.actuals_item_id === row.actuals_item_id);
    if (completeRow) {
      (Object.keys(headersInfo) as (keyof POTaggingFileDataEntity)[]).forEach((key) => {
        const keyInfo = headersInfo.find((header) => header.field === key);

        if (keyInfo?.dataType === 'number') {
          const rowValue = +convertValue(row[key], keyInfo.dataType);
          const completeValue = +convertValue(completeRow[key], keyInfo.dataType);

          if (key !== 'xpt_line_item_id' && rowValue.toPrecision(2) !== completeValue.toPrecision(2)) {
            validationErrorDetails.push({
              rowIndex: rowIndex + 1,
              message: `Field '${key}' - ${row[key]} should not be modified - Original ${completeRow[key]} ${getActualItemIdMessagePart(row)}`
            });
          }
        } else {
          if (key !== 'xpt_line_item_id' && row[key] !== completeRow[key]) {
            validationErrorDetails.push({
              rowIndex: rowIndex + 1,
              message: `Field '${key}' - ${row[key]} should not be modified - Original ${completeRow[key]}  ${getActualItemIdMessagePart(row)}`
            });
          }
        }
      });
    } else {
      validationErrorDetails.push({
        rowIndex: rowIndex + 1,
        message: `No matching actuals_item_id found in the complete data  ${getActualItemIdMessagePart(row)}`
      });
    }
  });

  if (validationErrorDetails.length > 0) {
    console.debug(`Un authorized modifications rows validation failed.`, JSON.stringify(validationErrorDetails, null, 2));
    return {
      colorOverride: 'red',
      validationMessage: POTaggingValidationMessages.UN_AUTH_MODIFICATION_FAILED,
      validationStatus: 'error',
      validationDefaultMessage: POTaggingValidationMessages.UN_AUTH_MODIFICATION_DEFAULT,
      validationErrorDetails
    };
  }

  return {
    colorOverride: 'green',
    validationMessage: POTaggingValidationMessages.UN_AUTH_MODIFICATION_SUCCESS,
    validationStatus: 'success',
    validationDefaultMessage: POTaggingValidationMessages.UN_AUTH_MODIFICATION_SUCCESS,
    validationErrorDetails: []
  };
};
