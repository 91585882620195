import { CollectionPreferencesProps, PropertyFilterProps, TableProps } from '@amzn/awsui-components-react';
import moment from 'moment-timezone';
import { ColumnDataType, DataEntity, DynamicColumnDetails } from '../../../models/XptTableModel';

export const DEFAULT_PAGE_SIZE_IS_20 = 20;
export const BLANK_SEARCH_AND = {
  tokens: [],
  operation: 'and'
} as PropertyFilterProps.Query;

export function extractFieldNamesForDefaultVisibleContent(dynamicColumnDetails: DynamicColumnDetails): string[] {
  return dynamicColumnDetails.data.filter((item: DataEntity) => item.isShownByDefault).map((dataEntity: DataEntity) => dataEntity.fieldName);
}

export function generateVisibleContentOptions(
  dynamicColumnDetails: DynamicColumnDetails | undefined
): CollectionPreferencesProps.VisibleContentOptionsGroup[] {
  if (dynamicColumnDetails) {
    const groups: CollectionPreferencesProps.VisibleContentOptionsGroup[] = [
      {
        label: 'Properties',
        options: dynamicColumnDetails?.data.map((dataEntity: DataEntity) => ({
          id: dataEntity.fieldName,
          label: dataEntity.displayName,
          editable: dataEntity.canBeHiddenFromProperties || false
        }))
      }
    ];
    return groups;
  }

  return [];
}

export const xptTableSortingComparator = (a: any, b: any, columnDataType: ColumnDataType, sortingColumnName: string) => {
  if (columnDataType === 'date' || columnDataType === 'dateTime') {
    return moment(a[sortingColumnName]).isBefore(b[sortingColumnName]) ? -1 : 1;
  } else {
    return a[sortingColumnName].localeCompare(b[sortingColumnName]);
  }
};

export const DefaultDateFormat = 'YYYY-MM-DD';
export const DefaultDateTimeFormat = 'YYYY-MM-DD HH:mm:ss';

export function generateFilteringProperties(dynamicColumnDetails: DynamicColumnDetails): any[] {
  const filteringProperties: any[] = dynamicColumnDetails.data
    .filter((items: DataEntity) => items.isFilterable)
    .map((dataEntity: DataEntity) => {
      const dataType: ColumnDataType = dataEntity.metadata.type;
      let operators: any[] = [];

      if (dataType === 'string') {
        operators = [':', '!:', '=', '!='];
      } else if (dataType === 'number') {
        operators = ['=', '!=', '<', '<=', '>', '>='];
      } else if (dataType === 'date') {
        operators = [':', '!:', '=', '!='];
      } else if (dataType === 'dateTime') {
        operators = [':', '!:', '=', '!='];
      } else {
        operators = [':', '!:', '=', '!='];
      }

      return {
        key: dataEntity.fieldName,
        propertyLabel: dataEntity.displayName,
        groupValuesLabel: `${dataEntity.displayName} values`,
        operators
      } as any;
    });

  return filteringProperties;
}
