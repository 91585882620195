/**
 * Sorts the keys of an object.
 *
 * @param object - The object to be sorted.
 * @returns A new object with sorted keys.
 */
export const sortObjectKeys = (object: Object): Object => {
  return Object.fromEntries(Object.entries(object).sort());
};

/**
 * Compares two objects by sorting their keys and then stringifying them.
 * All values are converted to strings before comparison to support for file uploads.
 *
 * @param obj1 - The first object to compare.
 * @param obj2 - The second object to compare.
 * @returns True if the sorted, stringified versions of the objects are equal, false otherwise.
 */
export const compareObjects = (obj1: Object, obj2: Object): boolean => {
  const stringifyValues = (obj: Object) => {
    return Object.fromEntries(
      Object.entries(obj).map(([key, value]) => [key, String(value)])
    );
  };
  const sortedObj1 = sortObjectKeys(stringifyValues(obj1));
  const sortedObj2 = sortObjectKeys(stringifyValues(obj2));
  return JSON.stringify(sortedObj1) === JSON.stringify(sortedObj2);
};

/**
 * Filters out specified keys from an object.
 *
 * @param object - The object to filter.
 * @param keysToFilter - The keys to filter out from the object.
 * @returns A new object without the specified keys.
 */
export const filterObjectFields = <T extends Object>(object: T, keysToFilter: (keyof T)[]): Partial<T> => {
  const filteredEntries = Object.entries(object).filter(([key]) => !keysToFilter.includes(key as keyof T));
  return Object.fromEntries(filteredEntries) as Partial<T>;
};
