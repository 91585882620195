import { AutosuggestProps, SpaceBetween, TableProps } from '@amzn/awsui-components-react';
import { ContactInformation, DataClassification, ItemMetadata } from 'src/models/AppContextModels';
import { getCurrentUTCTimeInISO } from 'src/utils/date-time-utilities';
import * as Yup from 'yup';
import { ContactFormValues } from './ContactsForm';
import { UserDisplay } from 'src/components/common/UserComponents';

const MAX_CONTACTS_ALLOWED = 5;
const ItemSchema = Yup.object().shape({
  key: Yup.string().required('Group name is required'),
  value: Yup.string()
    .matches(/^[a-zA-Z]+(?:,\s*[a-zA-Z]+)*$/, 'Only characters, commas, and optional spaces after commas are allowed')
    .test('csv-length', `Must be between 1 and ${MAX_CONTACTS_ALLOWED} comma-separated values`, (value) => {
      if (!value) return false;
      const valuesArray = value
        .split(',')
        .map((v) => v.trim())
        .filter((v) => v !== '');
      return valuesArray.length >= 1 && valuesArray.length <= MAX_CONTACTS_ALLOWED;
    })
    .required('Contacts are required')
});

export const ContactInformationSchema = Yup.object().shape({
  items: Yup.array().of(ItemSchema).min(1, 'At least one contact is required')
});

export const ContactMessages = {
  SUBMITTING: 'Submitting...',
  SUBMITTED_SUCCESSFULLY: 'Submitted successfully',
  SUBMIT_ERROR: 'Unable to submit'
};

/**
 * Returns the column definitions for the contacts table.
 * @returns The column definitions.
 */
export const Contacts_Header_Config = (): TableProps.ColumnDefinition<ContactInformation>[] => {
  return [
    {
      id: 'groupName',
      header: 'Group',
      cell: (item: ContactInformation) => item.group_name
    },
    {
      id: 'contacts',
      header: ' ',
      cell: (item: ContactInformation) => (
        <SpaceBetween size="m" direction="horizontal">
          <UserDisplay userAlias={item.primary_contact.contact_alias} variant="small" page="Contacts Widget" mainPage="Landing Page" />
          {item.other_contacts?.map((contact, index) => (
            <UserDisplay userAlias={contact.contact_alias} key={index} variant="small" page="Contacts Widget" mainPage="Landing Page" />
          ))}
        </SpaceBetween>
      )
    }
  ] as TableProps.ColumnDefinition<ContactInformation>[];
};

export const groupNames = () => {};

export const getContactAliasAsCSV = (contact: ContactInformation) => {
  const primaryContactALias = contact.primary_contact.contact_alias;
  const otherContactALias = contact.other_contacts.map((contact) => contact.contact_alias);
  return [primaryContactALias, ...otherContactALias].join(', ');
};

export const getGenericAutoSuggestionsForContacts = (): AutosuggestProps.OptionGroup[] => [
  {
    label: 'General Suggestions',
    options: [
      { label: 'Tech Team', value: 'Tech Team' },
      { label: 'Dev Team', value: 'Dev Team' },
      { label: 'PMO Team', value: 'PMO Team' }
    ]
  }
];

export const extractDataClassificationAsAutoSuggestions = (dataClassifications: DataClassification[]): AutosuggestProps.OptionGroup[] => {
  const dataClassificationOptions: AutosuggestProps.OptionGroup = {
    label: 'Business Groups',
    options: dataClassifications.map(({ data_classification_name }) => ({
      label: data_classification_name,
      value: data_classification_name
    }))
  };

  return [dataClassificationOptions, ...getGenericAutoSuggestionsForContacts()];
};

export const convertFormValuesToContactInformation = (
  values: ContactFormValues,
  originalContacts: ContactInformation[],
  alias: string
): { newContacts: ContactInformation[]; updatedContacts: ContactInformation[]; deletedContacts: ContactInformation[] } => {
  const newContacts: ContactInformation[] = [];
  const updatedContacts: ContactInformation[] = [];
  const deletedContacts: ContactInformation[] = [];

  // Track IDs of all form contacts to identify deletions
  const formContactIds = values.items.map((item) => item.id).filter((id) => id !== null) as number[];

  // Identify deleted contacts
  originalContacts.forEach((contact) => {
    if (!formContactIds.includes(contact.contact_id!)) {
      deletedContacts.push({
        ...contact,
        item_metadata: {
          ...contact.item_metadata,
          is_active: false,
          updated_at: getCurrentUTCTimeInISO(),
          updated_by: alias
        }
      });
    }
  });

  // Identify new and updated contacts
  values.items.forEach((item) => {
    const originalContact = originalContacts.find((contact) => contact.contact_id === item.id);
    const contactInfo: ContactInformation = {
      contact_id: item.id ?? null,
      group_name: item.key,
      primary_contact: { contact_alias: item.value.split(',')[0].trim(), contact_name: item.value.split(',')[0].trim() },
      other_contacts: item.value
        .split(',')
        .slice(1)
        .map((alias) => ({ contact_alias: alias.trim(), contact_name: alias.trim() })),
      item_metadata: {
        created_at: originalContact ? originalContact.item_metadata.created_at : getCurrentUTCTimeInISO(),
        created_by: originalContact ? originalContact.item_metadata.created_by : alias,
        updated_at: getCurrentUTCTimeInISO(),
        updated_by: alias,
        is_active: true
      } as ItemMetadata
    };

    if (item.id === null) {
      // New contact
      newContacts.push(contactInfo);
    } else if (originalContact) {
      const isUpdated =
        originalContact.group_name !== contactInfo.group_name ||
        originalContact.primary_contact.contact_alias !== contactInfo.primary_contact.contact_alias ||
        originalContact.other_contacts.length !== contactInfo.other_contacts.length ||
        originalContact.other_contacts.some((oc, index) => oc.contact_alias !== contactInfo.other_contacts[index].contact_alias);

      if (isUpdated) {
        updatedContacts.push(contactInfo);
      }
    }
  });

  return { newContacts, updatedContacts, deletedContacts };
};
