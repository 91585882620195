import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/store';
import { XptSecondaryNavBar } from './XptSecondaryNavBar';
import { XptTopNavigation } from './XptTopNavigation';

export const XPTGlobalTopNavigation: React.FC = () => {
  const { currentUserNotFound } = useSelector((state: RootState) => state.xptAccessAndAuthorizationStore);

  return (
    <>
      {currentUserNotFound && (
        <div id="h2">
          <XptTopNavigation />
        </div>
      )}
      {!currentUserNotFound && (
        <div id="h">
          <XptTopNavigation />
          <XptSecondaryNavBar />
        </div>
      )}
    </>
  );
};
