import { AppLayout, ContentLayout, Flashbar, Header, Link, SpaceBetween } from '@amzn/awsui-components-react';
import React, { useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useSelector } from 'react-redux';
import { ErrorFallback } from 'src/components/common/ErrorFallback';
import { XPTBreadcrumbs } from 'src/components/common/XptBreadcrumb';
import BusinessGroupSideNavigation from 'src/features/xpt-layout/XptBusinessGroupSideNavigation';
import { businessGroupBaseBreadcrumbs, currentBusinessGroupName } from '../business-group/businessGroupSelectors';
import { getPOTaggingTaggingBreadcrumbItems } from './POTaggingConfig';
import { POTaggingProvider, usePOTaggingContext } from './POTaggingContext';
import { POTaggingFilterSelection } from './POTaggingFilterSelection';
import { POTaggingGrid } from './POTaggingGrid';
import { PoTaggingInfoPanel } from './PoTaggingInfoPanel';

export const POTaggingContent: React.FC = () => {
  const businessGroupName = useSelector(currentBusinessGroupName);
  const businessGroupBaseBreadcrumb = useSelector(businessGroupBaseBreadcrumbs);
  const { flashbarItems } = usePOTaggingContext();

  const [toolsOpen, setToolsOpen] = useState(false);

  return (
    <AppLayout
      headerSelector="#h"
      navigation={<BusinessGroupSideNavigation />}
      tools={<PoTaggingInfoPanel />}
      toolsOpen={toolsOpen}
      onToolsChange={({ detail }) => setToolsOpen(detail.open)}
      breadcrumbs={<XPTBreadcrumbs items={getPOTaggingTaggingBreadcrumbItems(businessGroupBaseBreadcrumb, businessGroupName)} />}
      notifications={<Flashbar stackItems items={flashbarItems} />}
      maxContentWidth={Number.MAX_VALUE}
      contentType="default"
      content={
        <ContentLayout
          header={
            <Header
              info={
                <Link variant="info" onFollow={() => setToolsOpen(true)}>
                  Info
                </Link>
              }
            >
              {`PO Tagging`}
            </Header>
          }
        >
          <SpaceBetween size="xs" direction="vertical">
            <POTaggingFilterSelection />
            <POTaggingGrid />
          </SpaceBetween>
        </ContentLayout>
      }
    />
  );
};

export const POTagging: React.FC = () => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <POTaggingProvider>
        <POTaggingContent />
      </POTaggingProvider>
    </ErrorBoundary>
  );
};
