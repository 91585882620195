import { KatSpinner } from '@amzn/katal-react';
import React from 'react';
import { useSelector } from 'react-redux';
import { Status } from 'src/models/AuthContextModels';
import { RootState } from 'src/store/store';
import AccessRestricted from './AccessRestricted';

const AuthWrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { currentUserAccessLoadingStatus, currentUserNotFound } = useSelector((state: RootState) => state.xptAccessAndAuthorizationStore);

  if (currentUserAccessLoadingStatus === Status.Loading) {
    return <KatSpinner size="large" />;
  }

  if ((currentUserAccessLoadingStatus === Status.Completed || currentUserAccessLoadingStatus === Status.Failed) && currentUserNotFound) {
    return <AccessRestricted />;
  }

  return <>{children}</>;
};

export default AuthWrapper;
