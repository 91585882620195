import { Box, HelpPanel, SpaceBetween } from '@amzn/awsui-components-react';
import React from 'react';

export const PoTaggingInfoPanel: React.FC = () => {
  return (
    <>
      <HelpPanel header={<h2>PO Tagging</h2>}>
        <SpaceBetween size="l">
          <Box variant="p">This page allows users to review and update the tagging between Purchase Orders (POs) and xPT Line Item IDs.</Box>
          <ul>
            <li>
              <Box variant="p">Select the Month and the Cost Center to view POs</Box>
            </li>
            <li>
              <Box variant="p">Add or update tagging by selecting the appropriate value from the Line Item ID dropdown list</Box>
            </li>
            <li>
              <Box variant="p">You can copy & paste Line Item ID or use Excel upload to make bulk changes, similar to the Forecast Input page</Box>
            </li>
          </ul>
        </SpaceBetween>
      </HelpPanel>
    </>
  );
};
