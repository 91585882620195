import { Box, HelpPanel, SpaceBetween } from '@amzn/awsui-components-react';
import React from 'react';

export const ForecastTemplateInfoPanel: React.FC = () => {
  return (
    <HelpPanel header={<h3>Forecast Input</h3>}>
      <SpaceBetween size="l">
        <Box variant="p">This page allows users to add, update, and delete budget line items during an active planning cycle. </Box>

        <ul>
          <li>
            <Box variant="div">
              To view existing budgets, select the desired Planning Cycle, Cost Center, Product Line, Project and Account using the filters above the
              table.
              <ul>
                <li>
                  <Box variant="p">
                    To customize your view, you can expand or collapse Corp Segments and Business Segments as well as add or remove Columns and
                    Filters on the right side of the table
                  </Box>
                </li>
                <li>
                  <Box variant="p">To save your customized view, click the Actions button and the Save current view button</Box>
                </li>
              </ul>
            </Box>
          </li>

          <li>
            <Box variant="div">
              To add budgets directly to the table, click the Add button, complete all required fields, and click the Submit button
              <ul>
                <li>
                  <Box variant="p">
                    For the columns under Corp Segments, you can choose to select either the code or the description and the other column will
                    automatically populate.
                  </Box>
                </li>
                <li>
                  <Box variant="p">You can select multiple cells from an existing line and copy & paste them into the line you’re adding. </Box>
                </li>
                <li>
                  <Box variant="p">You can update the budget lines and click the Submit button when done to save the changes.</Box>
                </li>
              </ul>
            </Box>
          </li>

          <li>
            <Box variant="div">
              To upload budgets via Excel, click the Actions button and the Export to Excel button to download a copy of data in your current view.
              Next, click the Actions button and the Upload from Excel button. Follow the guidelines provided, make changes to your Excel file, save
              it and then upload it to xPT.
              <ul>
                <li>
                  <Box variant="p">To edit existing budget lines, only modify the yellow-shaded cells in the monthly input section.</Box>
                </li>
                <li>
                  <Box variant="p">
                    To add new budget lines, insert new rows in Excel and fill out all bolded column header fields in the Excel file
                  </Box>
                </li>
                <li>
                  <Box variant="p">
                    After uploading the Excel file, click the Submit button. If the Submit button is inactive, follow the error messages shown to
                    troubleshoot. Once all errors are resolved, the Submit button should become active.
                  </Box>
                </li>
              </ul>
            </Box>
          </li>
        </ul>
      </SpaceBetween>
    </HelpPanel>
  );
};
