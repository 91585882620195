import { logger } from 'src/analytics/KatalLogger';
import { CORP_SEGMENTS_SEQUENCE, CorpSegmentNames } from 'src/constants/corp-segment-constants';
import { CorpSegmentsEntity, MasterCorpSegments, SegmentHierarchy } from 'src/models/AppContextModels';
import { ForecastTemplateMasterCorpSegmentDropdownValues, OptionDropdown } from 'src/models/ForecastModels';
import { getFileFromS3URI } from 'src/utils/aws-s3-services';
import { localeCompareNullableSelectProps } from 'src/utils/generic-utilities';

export const getTransformedCorpSegments = async (
  businessGroupCorpSegments: CorpSegmentsEntity[],
  masterCorpSegmentsData: MasterCorpSegments[]
): Promise<ForecastTemplateMasterCorpSegmentDropdownValues[]> => {
  const dropdownPromises = masterCorpSegmentsData.map(async (masterCorpSegment) => {
    let corpSegmentData: SegmentHierarchy[] = [];
    if (masterCorpSegment.segment_name === CorpSegmentNames.COST_CENTER) {
      const costCenterSegment = businessGroupCorpSegments.find((segment) => segment.corp_segment_name === masterCorpSegment.segment_name)!;
      corpSegmentData = (await getFileFromS3URI(costCenterSegment.corp_segment_values_s3_path)) as unknown as SegmentHierarchy[];
      const filteredCostCenterHierarchy = filterOutMasterCorpSegmentsFromSelectedValues(
        masterCorpSegment.segment_hierarchy_data_from_s3,
        corpSegmentData
      );
      return convertToDropdownValues(filteredCostCenterHierarchy, masterCorpSegment.segment_name);
    } else {
      corpSegmentData = masterCorpSegment.segment_hierarchy_data_from_s3;
      return convertToDropdownValues(corpSegmentData, masterCorpSegment.segment_name);
    }
  });
  const dropdownValues = await Promise.all(dropdownPromises);
  return dropdownValues;
};

export const fetchCorpSegmentDataFromS3 = async (segment: MasterCorpSegments) => {
  try {
    const dataFromS3 = await getFileFromS3URI(segment.segment_hierarchy_s3_path);
    return {
      ...segment,
      segment_hierarchy_data_from_s3: dataFromS3 as unknown as SegmentHierarchy[]
    } as MasterCorpSegments;
  } catch (s3Error: any) {
    logger.error('Failed to fetch S3 data for segment:', { ...s3Error, segmentName: segment.segment_name });
    return {
      ...segment,
      segment_hierarchy_data_from_s3: []
    };
  }
};

export const convertToDropdownValues = (segments: SegmentHierarchy[], corpSegmentName: string): ForecastTemplateMasterCorpSegmentDropdownValues => {
  const dropdownValues: OptionDropdown[] = segments.map((segment) => {
    const lastHierarchyItem = segment.segment_hierarchy[segment.segment_hierarchy.length - 1] || ''; // Ensuring no undefined
    return {
      label: lastHierarchyItem,
      value: lastHierarchyItem,
      description: segment.segment_description
    };
  });

  dropdownValues.sort((a, b) => localeCompareNullableSelectProps(a, b));

  return {
    masterCorpSegmentDisplayName: corpSegmentName,
    masterCorpSegmentDropdownValues: dropdownValues
  };
};

/**
 * This function filters the master cost center data to include only those entries
 * that start with the hierarchy segments of any selected corporate segment, with an option
 * to include or exclude the exact parent hierarchies.
 *
 * @param masterCostCenter Array of all cost centers with hierarchical data.
 * @param selectedCorpSegments Array of selected corporate segments that act as filters.
 * @param includeParent Determines if the exact parent hierarchies should be included in the result.
 * @returns Filtered array of cost centers based on the selected corporate segment hierarchies.
 */
export const filterOutMasterCorpSegmentsFromSelectedValues = (
  masterCostCenter: SegmentHierarchy[],
  selectedCorpSegments: SegmentHierarchy[],
  includeParent: boolean = false
): SegmentHierarchy[] => {
  // Extract just the hierarchy arrays from selectedCorpSegments
  const selectedHierarchies = selectedCorpSegments.map((seg) => seg.segment_hierarchy);

  // Filter masterCostCenter based on whether their hierarchy matches any selected hierarchy prefix
  const filteredSegments = masterCostCenter.filter((segment) => {
    // Check if current segment's hierarchy starts with any of the selected segment hierarchies
    const isChildOrSame = selectedHierarchies.some(
      (selectedHierarchy) => segment.segment_hierarchy.join(',').startsWith(selectedHierarchy.join(',')) // Convert arrays to strings to compare
    );

    // Further refine to exclude exact matches if includeParent is false
    if (!includeParent) {
      return isChildOrSame && !selectedHierarchies.some((selectedHierarchy) => segment.segment_hierarchy.join(',') === selectedHierarchy.join(','));
    }

    return isChildOrSame;
  });
  return filteredSegments;
};

export const customCorpSegmentSort = (a: { corp_segment_name: string }, b: { corp_segment_name: string }) => {
  const indexA = CORP_SEGMENTS_SEQUENCE.indexOf(a.corp_segment_name as CorpSegmentNames);
  const indexB = CORP_SEGMENTS_SEQUENCE.indexOf(b.corp_segment_name as CorpSegmentNames);

  if (indexA !== -1 && indexB !== -1) {
    return indexA - indexB;
  }

  if (indexA !== -1) {
    return -1;
  }

  if (indexB !== -1) {
    return 1;
  }

  return a.corp_segment_name.localeCompare(b.corp_segment_name);
};

export const customBusinessSegmentSort = (a: { business_segment_name: string }, b: { business_segment_name: string }) => {
  return a.business_segment_name.localeCompare(b.business_segment_name);
};
