import { Alert, Button, Flashbar, Header, Link, SpaceBetween, TableProps } from '@amzn/awsui-components-react';
import React, { useEffect, useRef, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useSelector } from 'react-redux';
import { LoadingSpinner } from 'src/components/common/LoadingSpinner';
import { XptAppLayout } from 'src/components/common/xpt-app-layout/XptAppLayout';
import XptTable from 'src/components/common/xpt-table/XptTable';
import { XPTBreadcrumbs } from 'src/components/common/XptBreadcrumb';
import { SPLIT_PANEL_DEFAULT_SIZE, SPLIT_PANEL_MAX_SIZE } from 'src/constants/generic-constants';
import BusinessGroupSideNavigation from 'src/features/xpt-layout/XptBusinessGroupSideNavigation';
import { useFlashbar } from 'src/hooks/useFlashbar';
import { Status } from 'src/models/AuthContextModels';
import { BudgetTypeArray, ExpenseTypeArray, ExpenseTypeMappingEntity } from 'src/models/xPTMappingModels';
import { fetchExpenseTypeMappings } from 'src/store/slices/xPTMapperSlice';
import { RootState } from 'src/store/store';
import { useAppDispatch } from 'src/store/useAppDispatch';
import { getHeaderCounter } from 'src/utils/generic-utilities';
import { businessGroupBaseBreadcrumbs, currentBusinessGroupName } from '../businessGroupSelectors';
import { ExpenseTypeForm } from './ExpenseTypeForm';
import {
  getAllColumns,
  getExpenseTypeBreadcrumbItems,
  getExpenseTypeColumnDefs,
  getSortedUniqueExpenseAndBudgetTypesArray
} from './ExpenseTypeMappingConfig';
import { ExpenseTypeMappingsInfoPanel } from './ExpenseTypeMappingInfoPanel';
import { dateTimeComparator } from 'src/utils/date-time-utilities';
import { selectActiveExpenseTypeMappings } from 'src/store/selectors/xPTMapperSelector';
import { logUserClick } from 'src/analytics/KatalLogger';

export const ExpenseTypeMapping: React.FC = () => {
  const ENTITY_NAME = `Expense Type Mappings`;
  const appLayout = useRef<any>();
  const [toolsOpen, setToolsOpen] = useState(false);
  const [isSplitPanelOpen, setIsSplitPanelOpen] = useState<boolean>(false);
  const [splitPanelSize, setSplitPanelSize] = useState<number>(SPLIT_PANEL_DEFAULT_SIZE);

  const dispatch = useAppDispatch();
  const { expenseTypeStatus } = useSelector((state: RootState) => state.xPTMappingStore);
  const expenseTypeMappings = useSelector(selectActiveExpenseTypeMappings);

  const currentBusinessGroup = useSelector((state: RootState) => state.businessGroupStore.currentBusinessGroup);
  const businessGroupName = useSelector(currentBusinessGroupName);
  const businessGroupBaseBreadcrumb = useSelector(businessGroupBaseBreadcrumbs);

  const [expenseTypesAutoSuggestList, setExpenseTypeAutoSuggestList] = useState<ExpenseTypeArray>([]);
  const [budgetTypeAutoSuggestList, setBudgetTypeAutoSuggestList] = useState<BudgetTypeArray>([]);

  const isLoading = expenseTypeStatus === Status.Loading;
  const [tableInitialized, setTableInitialized] = useState(false);

  const [currentBusinessGroupExpenseTypeMappings, setCurrentBusinessGroupExpenseTypeMappings] = useState<ExpenseTypeMappingEntity[]>([]);
  const [selectedExpenseTypeMappings, setSelectedExpenseTypeMappings] = useState<ExpenseTypeMappingEntity[]>([]);
  const [columnDefinition, setColumnDefinition] = useState<TableProps.ColumnDefinition<ExpenseTypeMappingEntity>[]>([]);

  const { flashbarItems, displayFlashMessage, clearSpecificFlashMessage } = useFlashbar();

  useEffect(() => {
    if (expenseTypeStatus === Status.NotInitiated) {
      dispatch(fetchExpenseTypeMappings());
    }

    if (expenseTypeStatus === Status.Failed) {
      displayFlashMessage('Unable to load expense type mappings.', 'error', true);
    }
  }, [expenseTypeStatus]);

  useEffect(() => {
    if (expenseTypeStatus === Status.Completed) {
      setTableInitialized(false);
      setSelectedExpenseTypeMappings([]);
      setIsSplitPanelOpen(false);

      const { expenseTypes, budgetTypes } = getSortedUniqueExpenseAndBudgetTypesArray(expenseTypeMappings);

      setExpenseTypeAutoSuggestList(expenseTypes);
      setBudgetTypeAutoSuggestList(budgetTypes);

      // Will be displaying Current Data Classification Related.
      const _expenseTypeMappings: ExpenseTypeMappingEntity[] = expenseTypeMappings
        .filter(
          (item: ExpenseTypeMappingEntity) =>
            item.is_active && item.data_classification_id === currentBusinessGroup?.data_classification.data_classification_id
        )
        .sort((item1: ExpenseTypeMappingEntity, item2: ExpenseTypeMappingEntity) => dateTimeComparator(item1.updated_at, item2.updated_at));
      setCurrentBusinessGroupExpenseTypeMappings(_expenseTypeMappings);

      // Data is ready, will be updating Column Definitions
      setColumnDefinition(getExpenseTypeColumnDefs());

      setTableInitialized(true);
    }
  }, [expenseTypeStatus, expenseTypeMappings]);

  const reloadExpenseTypes = () => {
    logUserClick(`Refreshed expense type mappings`, 'Expense Type Mappings', businessGroupName);
    dispatch(fetchExpenseTypeMappings());
  };

  const addExpenseType = () => {
    setSelectedExpenseTypeMappings([]);
    setIsSplitPanelOpen(true);
  };

  useEffect(() => {
    if (selectedExpenseTypeMappings.length === 1) {
      setSelectedExpenseTypeMappings(selectedExpenseTypeMappings);
      setIsSplitPanelOpen(true);
    }
  }, [selectedExpenseTypeMappings]);

  // Clear selected user when split panel is closed
  useEffect(() => {
    if (!isSplitPanelOpen) {
      setSelectedExpenseTypeMappings([]);
    }
  }, [isSplitPanelOpen]);

  const ExpenseTypeTableHeader = () => (
    <Header
      actions={
        <SpaceBetween size="l" direction="horizontal">
          <Button iconName="refresh" onClick={reloadExpenseTypes}></Button>
          <Button iconName="add-plus" onClick={addExpenseType}>{`Add Expense Type`}</Button>
        </SpaceBetween>
      }
      info={
        <Link variant="info" onFollow={() => setToolsOpen(true)}>
          Info
        </Link>
      }
      counter={getHeaderCounter(selectedExpenseTypeMappings, currentBusinessGroupExpenseTypeMappings)}
    >
      {ENTITY_NAME}
    </Header>
  );

  return (
    <ErrorBoundary
      FallbackComponent={() => (
        <Alert
          type="error"
          dismissible={false}
          visible={true}
          header="Error"
          action={<Button onClick={() => window.location.reload()}>Reload</Button>}
        >
          {'Unable to load data, try reloading once'}
        </Alert>
      )}
    >
      <XptAppLayout
        ref={appLayout}
        headerSelector="#h"
        navigation={<BusinessGroupSideNavigation />}
        tools={<ExpenseTypeMappingsInfoPanel />}
        toolsOpen={toolsOpen}
        onToolsChange={({ detail }) => setToolsOpen(detail.open)}
        breadcrumbs={<XPTBreadcrumbs items={getExpenseTypeBreadcrumbItems(businessGroupBaseBreadcrumb, businessGroupName)} />}
        notifications={<Flashbar items={flashbarItems} stackItems />}
        stickyNotifications={true}
        maxContentWidth={Number.MAX_VALUE}
        contentType="table"
        content={
          <div className="xpt-app-layout-content">
            {tableInitialized ? (
              <XptTable
                xptTableVariant="full-page"
                xptTableHeader={<ExpenseTypeTableHeader />}
                loadingStatus={isLoading}
                entityName={ENTITY_NAME}
                allColumns={getAllColumns(ENTITY_NAME)}
                columnDefinitions={columnDefinition}
                allItems={currentBusinessGroupExpenseTypeMappings}
                selectionType="single"
                onRowClickSelect={true}
                selectedItems={selectedExpenseTypeMappings}
                setSelectedItems={async (selectedItems) => setSelectedExpenseTypeMappings(selectedItems)}
                page={ENTITY_NAME}
                mainPage={businessGroupName}
              />
            ) : (
              <LoadingSpinner />
            )}
          </div>
        }
        splitPanelSize={splitPanelSize}
        onSplitPanelResize={({ detail }) => setSplitPanelSize(detail.size > SPLIT_PANEL_MAX_SIZE ? SPLIT_PANEL_MAX_SIZE : detail.size)}
        splitPanelPreferences={{ position: 'side' }}
        onSplitPanelPreferencesChange={() => {}}
        onSplitPanelToggle={({ detail }) => {
          setIsSplitPanelOpen(detail.open);
        }}
        splitPanelOpen={isSplitPanelOpen}
        splitPanel={
          <ExpenseTypeForm
            expenseTypesAutoSuggestList={expenseTypesAutoSuggestList}
            budgetTypeAutoSuggestList={budgetTypeAutoSuggestList}
            selectedExpenseTypeMappings={selectedExpenseTypeMappings}
            closeTheSidePanel={() => setIsSplitPanelOpen(false)}
          />
        }
      />
    </ErrorBoundary>
  );
};
