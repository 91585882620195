import { TableProps } from '@amzn/awsui-components-react';
import { AdminBaseBreadcrumbs } from 'src/features/admin-console/AdminConsole';
import { DynamicColumnDetails } from 'src/models/XptTableModel';
import { APIStatisticsEntity } from '../models/KPIModels';

export const geAPIStatsBreadcrumbItems = () => {
  return [
    ...AdminBaseBreadcrumbs,
    {
      text: 'API Stats',
      href: '/admin-console/api-stats'
    }
  ];
};

export const getAPIStatsTableConfig = (entityName: string): DynamicColumnDetails => {
  return {
    columnInfo: {
      entityName: entityName,
      trackBy: 'API_Name', // Can use a unique field for tracking
      sortingColumn: 'TotalRequests',
      isDescending: true
    },
    data: [
      {
        fieldName: 'API_Name',
        displayName: 'API Name',
        canBeHiddenFromProperties: false,
        isShownByDefault: true,
        isFilterable: true,
        metadata: {
          type: 'string'
        }
      },
      {
        fieldName: 'TotalRequests',
        displayName: 'Total Requests',
        canBeHiddenFromProperties: false,
        isShownByDefault: true,
        isFilterable: true,
        metadata: {
          type: 'number'
        }
      },
      {
        fieldName: 'UniqueUsers',
        displayName: 'Unique Users',
        canBeHiddenFromProperties: false,
        isShownByDefault: true,
        isFilterable: true,
        metadata: {
          type: 'number'
        }
      },
      {
        fieldName: 'AvgResponseTime',
        displayName: 'Avg Response Time (ms)',
        canBeHiddenFromProperties: false,
        isShownByDefault: true,
        isFilterable: true,
        metadata: {
          type: 'number'
        }
      },
      {
        fieldName: 'MinResponseTime',
        displayName: 'Min Response Time (ms)',
        canBeHiddenFromProperties: false,
        isShownByDefault: true,
        isFilterable: true,
        metadata: {
          type: 'number'
        }
      },
      {
        fieldName: 'MaxResponseTime',
        displayName: 'Max Response Time (ms)',
        canBeHiddenFromProperties: false,
        isShownByDefault: true,
        isFilterable: true,
        metadata: {
          type: 'number'
        }
      },
      {
        fieldName: 'P95ResponseTime',
        displayName: 'P95 Response Time (ms)',
        canBeHiddenFromProperties: false,
        isShownByDefault: true,
        isFilterable: true,
        metadata: {
          type: 'number'
        }
      },
      {
        fieldName: 'SuccessRequests',
        displayName: 'Successful Requests',
        canBeHiddenFromProperties: false,
        isShownByDefault: true,
        isFilterable: true,
        metadata: {
          type: 'number'
        }
      },
      {
        fieldName: 'FailureRequests',
        displayName: 'Failed Requests',
        canBeHiddenFromProperties: false,
        isShownByDefault: true,
        isFilterable: true,
        metadata: {
          type: 'number'
        }
      },
      {
        fieldName: 'SuccessRate',
        displayName: 'Success Rate (%)',
        canBeHiddenFromProperties: false,
        isShownByDefault: true,
        isFilterable: true,
        metadata: {
          type: 'number'
        }
      }
    ]
  };
};

export const getAPIStatsHeader = (): TableProps.ColumnDefinition<APIStatisticsEntity>[] => {
  return [
    { id: 'API_Name', header: 'API', isRowHeader: true, cell: (item) => item.API_Name, sortingField: 'API_Name' },
    { id: 'TotalRequests', header: 'Total Req', cell: (item) => item.TotalRequests, sortingField: 'TotalRequests' },
    { id: 'UniqueUsers', header: 'Unique Users', cell: (item) => item.UniqueUsers, sortingField: 'UniqueUsers' },
    { id: 'AvgResponseTime', header: 'Avg RT (ms)', cell: (item) => Math.floor(item.AvgResponseTime), sortingField: 'AvgResponseTime' },
    { id: 'MinResponseTime', header: 'Min RT (ms)', cell: (item) => item.MinResponseTime, sortingField: 'MinResponseTime' },
    { id: 'MaxResponseTime', header: 'Max RT (ms)', cell: (item) => item.MaxResponseTime, sortingField: 'MaxResponseTime' },
    { id: 'P95ResponseTime', header: 'P95 RT (ms)', cell: (item) => Math.floor(item.P95ResponseTime), sortingField: 'P95ResponseTime' },
    { id: 'SuccessRequests', header: 'Success', cell: (item) => item.SuccessRequests, sortingField: 'SuccessRequests' },
    { id: 'FailureRequests', header: 'Failed', cell: (item) => item.FailureRequests, sortingField: 'FailureRequests' },
    { id: 'SuccessRate', header: 'Success %', cell: (item) => Math.floor(item.SuccessRate), sortingField: 'SuccessRate' }
  ];
};
