import { ColDef } from 'ag-grid-community';

export enum ReportTypes {
  FORECAST = 'Forecast',
  VARIANCE = 'Variance'
}

// Default widths for different types of columns in the grid
export enum ColumnWidths {
  DEFAULT = 120,
  LINE_ITEM_ID = 180,
  USER_ALIAS_COLUMN = 120,
  BUDGET_TYPE_COLUMN = 120,
  PLANNING_CYCLE_YEAR_COLUMN = 120,
  CORP_SEGMENT_CLOSED_COLUMN = 130,
  CORP_SEGMENT_COLUMN = 95,
  CORP_SEGMENT_DESCRIPTION_COLUMN = 180,
  BUSINESS_SEGMENT_CLOSED_COLUMN = 160,
  BUSINESS_SEGMENT_COLUMN = 160,
  TOTAL_LIFE_TIME_SPEND = 165,
  MONTH_COLUMN = 120,
  QUARTER_COLUMN = 130,
  YEAR_COLUMN = 130,
  LAST_UPDATED_AT = 190,
  VARIANCE_PERIOD_COLUMN = 170
}

export const forecastDataGridIcons = {
  // The key 'custom-stats' represents a custom icon for stats.
  'custom-stats': '<span class="ag-icon ag-icon-custom-stats"></span>'
};

export const XptReportGridFixedFields = {
  ScenarioSeqId: { value: 'scenario_seq_id', displayName: 'Scenario Seq Id' },
  XptLineItemSeqId: { value: 'xpt_line_item_seq_id', displayName: 'Line Item Seq Id' },
  XptLineItemId: { value: 'xpt_line_item_id', displayName: 'Line Item Id' },
  BudgetOwner: { value: 'budget_owner', displayName: 'Budget Owner' },
  BudgetType: { value: 'budget_type', displayName: 'Budget Type' },
  ScenarioYear: { value: 'scenario_year', displayName: 'Scenario Year' },
  UpdatedAt: { value: 'updated_at', displayName: 'Updated At' },
  UpdatedBy: { value: 'updated_by', displayName: 'Updated By' }
} as const;

export const ROW_GROUP_PANEL_SHOW: 'always' | 'onlyWhenGrouping' | 'never' = 'always';
export const GROUP_EXPANSION_LEVEL: number = -1;
export const GROUP_DISPLAY_TYPE = 'multipleColumns';
export const TOTAL_ROW_POSITION = 'bottom';

export const AUTO_COLUMN_GROUP_DEF_DEFAULT: ColDef = {
  cellRendererParams: {
    suppressCount: true,
    checkbox: false
  }
};

export const COLUMN_DEF_DEFAULT: ColDef = {
  resizable: true,
  sortable: true,
  autoHeaderHeight: true,
  filter: true,
  floatingFilter: false,
  filterParams: {
    applyMiniFilterWhileTyping: true
  },
  editable: false,
  enableRowGroup: true,
  enablePivot: true
};
