import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { logger } from 'src/analytics/KatalLogger';
import { getXptMetadata } from 'src/api/app-sync-services';
import { eAgGridThemes, eLocalStorageKeys } from 'src/constants/generic-constants';
import { Status } from 'src/models/AuthContextModels';
import { ParsedMetadata, XptMetadata } from 'src/models/XptGenericModels';
import { parseMetadata } from 'src/utils/xpt-metadata-utils';

// State interface definition for the Application level metadata.
interface AppMetadataState {
  themeClassName: eAgGridThemes;

  xptMetadataStatus: Status;
  xptMetadata: XptMetadata[];
  parsedMetadata: ParsedMetadata;
}

// Function to get the initial theme from localStorage or default to Quartz
const getInitialTheme = (): eAgGridThemes => {
  const savedTheme = localStorage.getItem(eLocalStorageKeys.Theme);
  return savedTheme === eAgGridThemes.Quartz_Dark ? eAgGridThemes.Quartz_Dark : eAgGridThemes.Quartz;
};

export const appMetadataInitialState: AppMetadataState = {
  themeClassName: getInitialTheme(),
  xptMetadataStatus: Status.NotInitiated,
  xptMetadata: [],
  parsedMetadata: {}
};

export const fetchXptMetadata = createAsyncThunk('xPTMapper/fetchXptMetadata', async () => {
  const response: XptMetadata[] = await getXptMetadata();
  return response;
});

const appMetadataSlice = createSlice({
  name: 'appMetadata',
  initialState: appMetadataInitialState,
  reducers: {
    toggleTheme: (state, action: PayloadAction<boolean>) => {
      const themeClassName = action.payload ? eAgGridThemes.Quartz_Dark : eAgGridThemes.Quartz;
      state.themeClassName = themeClassName;
      localStorage.setItem(eLocalStorageKeys.Theme, themeClassName);
    }
  },
  extraReducers: (builder) => {
    // Handle fetchExpenseTypes lifecycle actions
    builder.addCase(fetchXptMetadata.pending, (state) => {
      state.xptMetadataStatus = Status.Loading;
    });
    builder.addCase(fetchXptMetadata.fulfilled, (state, action: PayloadAction<XptMetadata[]>) => {
      state.xptMetadata = action.payload;
      try {
        state.parsedMetadata = parseMetadata(action.payload);
      } catch (error: any) {
        logger.error('Error parsing xpt metadata:', error);
        state.parsedMetadata = {};
      }
      state.xptMetadataStatus = Status.Completed;
    });
    builder.addCase(fetchXptMetadata.rejected, (state) => {
      state.xptMetadataStatus = Status.Failed;
    });
  }
});

export const { toggleTheme } = appMetadataSlice.actions;
export default appMetadataSlice.reducer;
