import { Box, HelpPanel, SpaceBetween } from '@amzn/awsui-components-react';
import React from 'react';

export const AdminPlanningCycleHelpPanel: React.FC = () => {
  return (
    <div>
      <HelpPanel header={<h3>Planning Cycle</h3>}>
        <SpaceBetween size="l">
          <Box variant="p">
            This page is accessible only to users with the <strong>Finance Admin</strong> roles.
          </Box>
          <Box variant="p">This feature allows Finance Admins to create, edit and close planning cycles.</Box>
          <ul>
            <li>
              <Box variant="p">To create a new planning cycle, click the Create planning cycle button and add at least one business group to it</Box>
            </li>
            <li>
              <Box variant="p">To edit a current planning cycle, click Edit, make updates, and click the Submit button when done</Box>
            </li>
            <li>
              <Box variant="p">
                To close a current planning cycle, click Edit, click the Close button for the desired business group, and click the Confirm Lock &
                Close button
              </Box>
            </li>
          </ul>
        </SpaceBetween>
      </HelpPanel>
    </div>
  );
};
