import { Box, HelpPanel, SpaceBetween } from '@amzn/awsui-components-react';
import React from 'react';

export const CapexInfoPanel: React.FC = () => {
  return (
    <HelpPanel header={<h2>Capex</h2>}>
      <SpaceBetween size="l">
        <Box variant="p">
          This page is accessible only to users with the <strong>Finance Admin</strong> roles.
        </Box>
        <Box variant="p">This feature allows Finance Admins to upload CapEx Actuals data, which is required for Push to Prophecy.</Box>
        <ul>
          <li>
            <Box variant="p">
              Click the Download Template button, make changes in the Excel file and save it, then click the Choose File button to upload it
            </Box>
          </li>
          <li>
            <Box variant="p">
              If the Upload button is inactive, follow the error messages shown to troubleshoot. Once all errors are resolved, the Upload button
              should become active.
            </Box>
          </li>
        </ul>
      </SpaceBetween>
    </HelpPanel>
  );
};
