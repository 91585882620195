import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import { Status } from 'src/models/AuthContextModels';
import {
  CorpSegmentFilterSelection,
  ForecastTemplateCorpSegmentDropdowns,
  ForecastTemplateDataState,
  ForecastTemplateDataValidationStatus,
  LineItemIdArchived
} from 'src/models/ForecastModels';
import { PlanningCycleEntity } from 'src/models/PlanningCycleModel';
import { ForecastTemplateDataStatuses } from '../forecast-utils/ForecastGridConstants';
import { INITIAL_VALIDATION_STATUS } from '../forecast-utils/ForecastTemplateDataValidations';
import { fetchLineItemIdsArchived } from './fetchLineItemIdsArchivedThunk';

export interface ForecastTemplateState {
  currentPlanningCycle: PlanningCycleEntity | null;
  currentCorpSegmentDropDownSelections: CorpSegmentFilterSelection | null;

  forecastTemplateDataLoading: boolean;
  forecastTemplateCompleteData: any[];
  forecastTemplateCorpSegmentDropdowns: ForecastTemplateCorpSegmentDropdowns[];

  forecastDataStatus: ForecastTemplateDataState;
  forecastDataSubmitClickCount: number;
  forecastDataSubmitting: boolean;
  forecastDataSubmitError: string;
  forecastDataValidationStatus: ForecastTemplateDataValidationStatus;
  lineItemIdsArchivedStatus: Status;
  lineItemIdsArchived: LineItemIdArchived[];
}

export const ForecastTemplateInitialState: ForecastTemplateState = {
  currentPlanningCycle: null,
  currentCorpSegmentDropDownSelections: null,

  forecastTemplateDataLoading: false,
  forecastTemplateCompleteData: [],
  forecastTemplateCorpSegmentDropdowns: [],

  forecastDataStatus: {
    dataStatus: ForecastTemplateDataStatuses.NotLoaded // Data is 'NotLoaded' at this point of time.
  },
  forecastDataSubmitClickCount: 0,
  forecastDataSubmitting: false,
  forecastDataSubmitError: '',
  forecastDataValidationStatus: INITIAL_VALIDATION_STATUS,
  lineItemIdsArchivedStatus: Status.NotInitiated,
  lineItemIdsArchived: []
};

const forecastTemplateSlice = createSlice({
  name: 'forecastTemplate',
  initialState: ForecastTemplateInitialState,
  reducers: {
    setCurrentPlanningCycle: (state, action: PayloadAction<PlanningCycleEntity | null>) => {
      state.currentPlanningCycle = action.payload;
    },
    setCurrentCorpSegmentDropDownSelections: (state, action: PayloadAction<CorpSegmentFilterSelection | null>) => {
      state.currentCorpSegmentDropDownSelections = action.payload as Draft<CorpSegmentFilterSelection>;
    },

    setForecastTemplateCompleteData: (state, action: PayloadAction<any[]>) => {
      state.forecastTemplateCompleteData = action.payload;
    },
    setForecastTemplateCorpSegmentDropdowns: (state, action: PayloadAction<ForecastTemplateCorpSegmentDropdowns[]>) => {
      state.forecastTemplateCorpSegmentDropdowns = action.payload as Draft<ForecastTemplateCorpSegmentDropdowns[]>;
    },
    setForecastTemplateDataStatus: (state, action: PayloadAction<ForecastTemplateDataState>) => {
      state.forecastDataStatus = action.payload;
    },
    setForecastTemplateDataLoading: (state, action: PayloadAction<boolean>) => {
      state.forecastTemplateDataLoading = action.payload;
    },
    setForecastDataSubmitClickCount: (state, action: PayloadAction<number>) => {
      state.forecastDataSubmitClickCount = action.payload;
    },
    setForecastDataSubmitting: (state, action: PayloadAction<boolean>) => {
      state.forecastDataSubmitting = action.payload;
    },
    setForecastDataSubmitError: (state, action: PayloadAction<string>) => {
      state.forecastDataSubmitError = action.payload;
    },
    setValidationStatus: (state, action: PayloadAction<ForecastTemplateDataValidationStatus>) => {
      state.forecastDataValidationStatus = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLineItemIdsArchived.pending, (state) => {
        state.lineItemIdsArchivedStatus = Status.Loading;
      })
      .addCase(fetchLineItemIdsArchived.fulfilled, (state, action: PayloadAction<LineItemIdArchived[]>) => {
        state.lineItemIdsArchivedStatus = Status.Completed;
        state.lineItemIdsArchived = action.payload;
      })
      .addCase(fetchLineItemIdsArchived.rejected, (state, action) => {
        state.lineItemIdsArchivedStatus = Status.Failed;
        state.lineItemIdsArchived = [];
        state.forecastDataSubmitError = action.payload as string;
      });
  }
});

export const {
  setCurrentPlanningCycle,
  setCurrentCorpSegmentDropDownSelections,

  setForecastTemplateCompleteData,
  setForecastTemplateCorpSegmentDropdowns,
  setForecastTemplateDataStatus,
  setForecastTemplateDataLoading,
  setForecastDataSubmitClickCount,
  setForecastDataSubmitting,
  setForecastDataSubmitError,
  setValidationStatus
} = forecastTemplateSlice.actions;

export { fetchLineItemIdsArchived };

export default forecastTemplateSlice.reducer;
