export const TRANSLATIONS_DIR_NAMESPACE = 'translations';

// This route is determined by translationOutputDir in configUtils
export const TRANSLATION_ROUTE = `/${TRANSLATIONS_DIR_NAMESPACE}`;

export const DEFAULT_LOCALE_EN_US = 'en-US';

export const APP_NAME = 'Expense Planning Tool';
export const APP_NAME_SHORT = 'xPT';

export enum eCacheKeys {
  UserAuthDetails = '-userAuthDetails'
}

export enum STAGES {
  DEV = 'dev',
  ALPHA = 'alpha',
  BETA = 'beta',
  PROD = 'prod'
}

export const PHONE_TOOL_URL = 'https://phonetool.amazon.com/users/';
export const USER_IMG_BASE_URL = 'https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=';

export const DEVELOPER_LDAP_GROUP = 'expense-planning-tool-dev';

// User types as individual constants
export const FINANCE_ADMIN = 'Finance Admin';
export const FINANCE_OWNER = 'Finance Owner';
export const BUDGET_LEADER = 'Budget Leader';
export const BUDGET_OWNER = 'Budget Owner';

// User types array for easy iteration or selection
export const USER_TYPES = [FINANCE_ADMIN, FINANCE_OWNER, BUDGET_LEADER, BUDGET_OWNER] as const;

// Type for user types
export type UserType = (typeof USER_TYPES)[number];

export enum eSecretManagerKeys {
  XPT_DEV_API_KEY = 'xpt-appsync-apikey',
  XPT_APPSYNC_API = 'xpt-appsync-endpoint',
  AD3_PAPI_API = 'xpt-PAPI'
}

export enum eLocalStorageKeys {
  Theme = 'theme',
  UserAuthDetails = 'userAuthDetails-v1',
  UserAccessDetails = 'UserAccessDetails',
  ForecastTemplateDetailsSelection = 'forecastTemplateDetailsSelection',
  XptHomePageWidgetsLayout = 'xptHomePageWidgetsLayout'
}

export enum eEntityStatus {
  Error = 'error',
  Warning = 'warning',
  Success = 'success',
  Stopped = 'stopped',
  Info = 'info',
  Pending = 'pending',
  InProgress = 'in-progress',
  Loading = 'loading',

  // For Scenario Status
  Open = 'Open',
  Closed = 'Closed',
  Locked = 'Locked',

  // generic purpose
  Active = 'Active',
  Inactive = 'Inactive',

  // scenario snapshot status
  Scheduled = 'scheduled'
}

export enum eErrorMessages {
  APP_INITIALIZATION_FAILED = 'An unexpected error occurred. We are working to resolve this.',
  GENERIC_ERROR = 'An unexpected error occurred. Please try again or contact support if the issue persists.',
  API_REQUEST_FAILED = 'Unable to submit the request. Please try again or contact the administrator.',
  API_REQUEST_IN_PROGRESS = 'The request is currently in progress.',
  API_REQUEST_SUCCESS = 'The request was submitted successfully.',
  NO_DATA_FOUND = 'No data found for the selected criteria.'
}

// 'success' | 'warning' | 'info' | 'error' | 'in-progress'
export enum NotificationType {
  Info = 'info',
  InProgress = 'in-progress',
  Error = 'error',
  Success = 'success'
}

export enum eAgGridThemes {
  Alpine = 'ag-theme-alpine',
  Alpine_Dark = 'ag-theme-alpine-dark',
  Alpine_Auto_Dark = 'ag-theme-alpine-auto-dark',
  Quartz = 'ag-theme-quartz',
  Quartz_Dark = 'ag-theme-quartz-dark',
  Quartz_Auto_Dark = 'ag-theme-quartz-auto-dark',
  Balham = 'ag-theme-balham',
  Balham_Dark = 'ag-theme-balham-dark',
  Balham_Auto_Dark = 'ag-theme-balham-auto-dark',
  Material = 'ag-theme-material'
}

export enum SpecialMetricTitles {
  HomePage = 'xPT-HomePage'
}

export const HEADER_TYPES = {
  ACTUAL: 'Actual',
  FORECAST: 'Forecast'
} as const;

export type HeaderType = (typeof HEADER_TYPES)[keyof typeof HEADER_TYPES];

export enum AuditType {
  All = 'All',
  Capex = 'capex_mapping'
}

export enum UserValidationMessages {
  UserIsValid = 'The user is valid.',
  UserIsInactive = 'The user is inactive.',
  UserAliasIsInvalid = 'The user alias is invalid.',
  UserAliasValidationNotInitiated = 'Alias Validation Not Initiated'
}

export enum OperationType {
  CRUD = 'crud',
  FILE_UPLOAD = 'file_upload'
}

export const SELECT_ALL_OPTION = {
  label: 'Select All',
  value: 'Select All'
};

export const SPLIT_PANEL_DEFAULT_SIZE: number = 320;
export const SPLIT_PANEL_MAX_SIZE: number = 520;
