import { createAsyncThunk } from '@reduxjs/toolkit';
import { logger } from 'src/analytics/KatalLogger';
import { CorpSegmentNames } from 'src/constants/corp-segment-constants';
import { eErrorMessages } from 'src/constants/generic-constants';
import XptMessages from 'src/constants/xpt-messages';
import { ForecastGridRowData, ForecastRowDataStructured, ForecastTemplateCorpSegmentDropdowns } from 'src/models/ForecastModels';
import { PlanningCycleEntity } from 'src/models/PlanningCycleModel';
import { RootState } from 'src/store/store';
import { getFileFromS3URI } from 'src/utils/aws-s3-services';
import { ForecastGridFixedFields, ForecastTemplateDataStatuses } from '../forecast-utils/ForecastGridConstants';
import { INITIAL_VALIDATION_STATUS } from '../forecast-utils/ForecastTemplateDataValidations';
import {
  flattenForecastRowDataStructured,
  forecastDataReadS3URI,
  generateAndProcessCorpSegmentFilterValues,
  getForecastTemplateHeaderInfo
} from '../forecast-utils/ForecastTemplateUtils';
import {
  setForecastDataSubmitClickCount,
  setForecastDataSubmitError,
  setForecastDataSubmitting,
  setForecastTemplateCompleteData,
  setForecastTemplateCorpSegmentDropdowns,
  setForecastTemplateDataLoading,
  setForecastTemplateDataStatus,
  setValidationStatus
} from './forecastTemplateSlice';

const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export const fetchForecastTemplateCompleteData = createAsyncThunk(
  'forecastTemplate/fetchForecastTemplateCompleteData',
  async (currentPlanningCycle: PlanningCycleEntity, { getState, dispatch, rejectWithValue }) => {
    dispatch(setForecastTemplateDataLoading(true));

    const state = getState() as RootState;
    const { userAccessForCurrentBusinessGroup, userCostCentersForCurrentBusinessGroup } = state.xptAccessAndAuthorizationStore!;

    let businessGroup = state.businessGroupStore.currentBusinessGroup;
    let attempts = 0;

    // Retry logic to wait for businessGroup to be defined
    while (!businessGroup && attempts < 5) {
      await delay(100); // Wait for 100ms before retrying
      businessGroup = state.businessGroupStore.currentBusinessGroup;
      attempts += 1;
    }

    if (!businessGroup || !userAccessForCurrentBusinessGroup) {
      const errorMsg = 'No business group selected';
      logger.error(errorMsg);
      dispatch(setForecastTemplateDataLoading(false));
      return rejectWithValue(errorMsg);
    }

    const dataClassificationId = businessGroup.data_classification.data_classification_id;
    const businessGroupShortDesc = businessGroup.data_classification.data_classification_short_description;

    if (!dataClassificationId || !currentPlanningCycle || !currentPlanningCycle.scenario_seq_id) {
      const errorMsg = 'No planning cycle selected to fetchForecastTemplateCompleteData';
      logger.error(errorMsg);
      dispatch(setForecastTemplateDataLoading(false));
      return rejectWithValue(errorMsg);
    }

    try {
      const forecastHeaderInfo = getForecastTemplateHeaderInfo(businessGroup, currentPlanningCycle);
      const optionalCorpSegmentsHeader = forecastHeaderInfo.corpSegmentOptionalFields;
      const s3URI = forecastDataReadS3URI(dataClassificationId, businessGroupShortDesc, currentPlanningCycle.scenario_seq_id);
      let forecastRowDataResponseFromS3: ForecastRowDataStructured[] = [];
      try {
        // Step 1: Loads the data from S3 for corresponding planning cycle
        forecastRowDataResponseFromS3 = (await getFileFromS3URI(s3URI)) as unknown as ForecastRowDataStructured[];
      } catch (error: any) {
        if (error.message === eErrorMessages.NO_DATA_FOUND) {
          dispatch(setForecastDataSubmitError(XptMessages.FORECAST_FETCH_FAILED_S3_NO_DATA));
        } else {
          dispatch(setForecastDataSubmitError(XptMessages.FORECAST_FETCH_FAILED_S3));
        }
      }

      // Step 2: Flattens, adds FE metadata fields to the data
      const flattenedData: ForecastGridRowData[] = flattenForecastRowDataStructured(forecastRowDataResponseFromS3, optionalCorpSegmentsHeader);

      const isBudgetOwner = userAccessForCurrentBusinessGroup.isBudgetOwner;
      const currentUserAlias = userAccessForCurrentBusinessGroup?.user_alias;

      // Step 3: Apply User Accessible Cost Centers filter.
      // For Budget Owner's they can see only their data and their CC's
      const authorizedForecastDataForCurrentUser: ForecastGridRowData[] = flattenedData.filter((row) => {
        if (isBudgetOwner) {
          return (
            currentUserAlias === row[ForecastGridFixedFields.BudgetOwner.value] &&
            userCostCentersForCurrentBusinessGroup.includes(row[CorpSegmentNames.COST_CENTER])
          );
        }
        // For all others, they can see their CC's
        else {
          return userCostCentersForCurrentBusinessGroup.includes(row[CorpSegmentNames.COST_CENTER]);
        }
      });

      logger.info(
        `Forecast data fetched successfully: for Business Group: ${businessGroupShortDesc} with Planning Cycle: ${
          currentPlanningCycle.scenario_year
        } (Seq ID: ${currentPlanningCycle.scenario_seq_id}). - Total records fetched from s3: ${
          flattenedData?.length || 0
        } and Authorized records for user ${userAccessForCurrentBusinessGroup?.user_alias}: ${authorizedForecastDataForCurrentUser?.length || 0}`
      );

      const corpSegmentFilters: ForecastTemplateCorpSegmentDropdowns[] = [];
      businessGroup.corp_segments
        .filter((corpSegment) => corpSegment.corp_segment_required)
        .forEach((corpSegment) => {
          const uniqueSortedValues = generateAndProcessCorpSegmentFilterValues(authorizedForecastDataForCurrentUser, corpSegment);
          const corpSegmentFilter: ForecastTemplateCorpSegmentDropdowns = {
            key: corpSegment.corp_segment_id,
            segmentKey: corpSegment.corp_segment_name_key,
            displayName: corpSegment.corp_segment_name,
            isRequired: corpSegment.corp_segment_required,
            isMultiSelect: true, // By default all Corp Segments are Multi Select
            loadingStatus: 'finished',
            fieldDropdownOptions: uniqueSortedValues
          };
          corpSegmentFilters.push(corpSegmentFilter);
        });

      dispatch(setForecastTemplateCorpSegmentDropdowns(corpSegmentFilters));
      dispatch(setForecastTemplateCompleteData(authorizedForecastDataForCurrentUser));
      dispatch(setValidationStatus(INITIAL_VALIDATION_STATUS));
      dispatch(setForecastTemplateDataLoading(false));
    } catch (error: any) {
      logger.error(XptMessages.FORECAST_FETCH_FAILED, error);
      dispatch(setForecastTemplateCorpSegmentDropdowns([]));
      dispatch(setForecastTemplateCompleteData([]));
      dispatch(setForecastTemplateDataLoading(false));
      dispatch(setValidationStatus(INITIAL_VALIDATION_STATUS));
      dispatch(setForecastDataSubmitError(XptMessages.FORECAST_FETCH_FAILED));
      return rejectWithValue(XptMessages.FORECAST_FETCH_FAILED);
    }
  }
);

export const clearForecastTemplateCompleteData = createAsyncThunk('forecastTemplate/clearForecastTemplateCompleteData', async (_, { dispatch }) => {
  logger.debug('clearing forecast template data');
  dispatch(setForecastTemplateCompleteData([]));
  dispatch(setForecastTemplateCorpSegmentDropdowns([]));

  dispatch(setForecastDataSubmitError(''));
  dispatch(setForecastTemplateDataStatus({ dataStatus: ForecastTemplateDataStatuses.NotLoaded }));
  dispatch(setForecastDataSubmitClickCount(0));
  dispatch(setForecastDataSubmitting(false));
  dispatch(setForecastTemplateDataLoading(false));
  dispatch(setValidationStatus(INITIAL_VALIDATION_STATUS));
});
