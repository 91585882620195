import { logger } from 'src/analytics/KatalLogger';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

interface ExcelFileOptions<T> {
  data: T[]; // Generic array to hold any kind of data
  sheetName: string;
  fileNameWithoutExtension: string;
}

/**
 * Generates and downloads an Excel file with dynamic data, sheet name, and file name.
 * Handles errors that might occur during the file generation or download process.
 * @param options - The options for the Excel file, including data, sheet name, and file name.
 */
export const generateExcelFile = <T extends object>({ data, sheetName, fileNameWithoutExtension }: ExcelFileOptions<T>): void => {
  try {
    logger.info(`Generating Excel file: ${fileNameWithoutExtension}.xlsx with sheet: ${sheetName}`);

    // Create a new workbook
    const workbook = XLSX.utils.book_new();

    // Convert data to worksheet
    const worksheet = XLSX.utils.json_to_sheet(data);

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);

    // Generate Excel file as a blob
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const dataBlob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });

    // Use file-saver to trigger the download
    saveAs(dataBlob, `${fileNameWithoutExtension}.xlsx`);

    logger.info(`Excel file generated and download initiated: ${fileNameWithoutExtension}.xlsx with sheet: ${sheetName}`);
  } catch (error: any) {
    logger.error(`Failed to generate Excel file: ${fileNameWithoutExtension}.xlsx. Error:`, error);
    throw error;
  }
};

/**
 * Reads the header row and data from an Excel file.
 *
 * @param {File} file - The Excel file to read.
 * @returns {Promise<{ headerRow: string[], fileData: any[] }>} - A promise that resolves with the header row and file data.
 */
export const readExcelFileWithHeader = (file: File, sheetName: string): Promise<{ headerRow: string[]; fileData: any[] }> => {
  return new Promise((resolve, reject) => {
    const reader: FileReader = new FileReader();

    reader.onload = (e: any) => {
      try {
        /* read workbook */
        const bstr: string = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

        const requiredSheet = wb.SheetNames.some((wbSheetName) => wbSheetName === sheetName);
        if (!requiredSheet) {
          reject(new Error(`Sheet '${sheetName}' not found in the uploaded file.`));
          return;
        }

        /* grab first sheet */
        const sheet: XLSX.WorkSheet = wb.Sheets[sheetName];

        let headerRow: string[] = [];
        let fileData: any[] = [];

        if (sheet['!ref']) {
          const range = XLSX.utils.decode_range(sheet['!ref']);
          let R = range.s.r;

          /* walk every column in the range */
          for (let C = range.s.c; C <= range.e.c; ++C) {
            const cell = sheet[XLSX.utils.encode_cell({ c: C, r: R })];

            /* find the cell in the first row */
            let hdr = 'UNKNOWN ' + C; // <-- replace with your desired default
            if (cell && cell.t) {
              hdr = XLSX.utils.format_cell(cell);
            }

            headerRow.push(hdr);
          }

          /* increment row to start reading data from second row */
          R += 1;

          /* update the range to start from the second row */
          range.s.r = R;

          /* convert sheet to JSON with manual headers */
          fileData = XLSX.utils.sheet_to_json(sheet, {
            raw: true, // Use raw values instead of formatted display values
            header: headerRow,
            range: range,
            defval: null
          });
        }

        resolve({ headerRow, fileData });
      } catch (error: any) {
        console.error(`Unable to read the file uploaded. Error: ${error?.message}`, error);
        reject(new Error('Unable to read the file uploaded.'));
      }
    };

    reader.onerror = (error: any) => {
      console.error(`FileReader error: ${error?.message}`, error);
      reject(new Error('FileReader error.'));
    };

    // reader.readAsBinaryString(file); - readAsBinaryString - This method is deprecated in favor of readAsArrayBuffer().
    reader.readAsArrayBuffer(file); // Using readAsArrayBuffer instead of readAsBinaryString
  });
};
