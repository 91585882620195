import { FlashbarProps, SelectProps } from '@amzn/awsui-components-react';
import { ColDef } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { logger } from 'src/analytics/KatalLogger';
import { currentBusinessGroupName } from 'src/features/business-group/businessGroupSelectors';
import { customCorpSegmentSort } from 'src/features/business-group/forecast-template/forecast-utils/CorpSegmentsUtils';
import { Status } from 'src/models/AuthContextModels';
import {
  PlanningCycleInfo,
  XptReportExportFileDetails,
  XptReportRowDataStructured,
  XptVarianceReportGridData,
  XptVarianceReportRowData
} from 'src/models/XptReportingModels';
import { RootState } from 'src/store/store';
import { ReportGrid } from '../XptReportGrid';
import { XptReportGridFixedFields } from '../XptReportGridConstants';
import { generateVarianceReportColumnDefinitions } from './XptVarianceReportColumnGenerator';
import { fetchCycleData, filterAndFlattenVarianceReportData, generateVarianceReport, ROLL_UP_PERIOD } from './XptVarianceReportUtils';
import { useAuth } from 'src/app/auth/AuthContextProvider';

const COLUMN_DEFINITION_DELAY_MS = 100;

interface XptVarianceReportGridProps {
  notificationMessage: (content: string, flashBarType: FlashbarProps.Type, isDismissible: boolean, messageId?: string) => void;
  reportGenerating: (isReportGenerating: boolean) => void;
  isGeneratingReport: boolean;
  currentPlanningCycleInfo: PlanningCycleInfo;
  comparisonPlanningCycleInfo: PlanningCycleInfo;
  selectedRollupPeriod: ROLL_UP_PERIOD;
  selectedPeriods: SelectProps.Options;
  exportFileDetails: XptReportExportFileDetails;
}

// Main component for rendering the variance report grid
export const XptVarianceReportGrid: React.FC<XptVarianceReportGridProps> = (props) => {
  const { userAlias } = useAuth();
  const currentBusinessGroup = useSelector((state: RootState) => state.businessGroupStore.currentBusinessGroup)!;
  const businessGroupName = useSelector(currentBusinessGroupName);

  const { userAccessForCurrentBusinessGroup, userCostCentersForCurrentBusinessGroup, userAccessForCurrentBusinessGroupStatus } = useSelector(
    (state: RootState) => state.xptAccessAndAuthorizationStore
  )!;
  const isBudgetOwner = userAccessForCurrentBusinessGroup?.isBudgetOwner || false;

  const dataClassificationShortDesc = currentBusinessGroup?.data_classification.data_classification_short_description;
  const gridStateKey = `UniqueGridStateKey-XptVarianceReport-${dataClassificationShortDesc}-v1`;
  const gridRef = useRef<AgGridReact>(null);

  const [columnDefinitions, setColumnDefinitions] = useState<ColDef[]>([]);
  const [xptReportData, setXptReportData] = useState<XptVarianceReportGridData[]>([]);

  const [generatingColumns, setGeneratingColumns] = useState<boolean>(false);

  const fetchVarianceReportData = async () => {
    const { currentPlanningCycleInfo, comparisonPlanningCycleInfo } = props;

    if (userAccessForCurrentBusinessGroup) {
      try {
        props.reportGenerating(true);

        logger.info(
          `Fetching variance report data for ${currentPlanningCycleInfo.planningCycleSelected.scenario_year} (isSnapshot: ${currentPlanningCycleInfo.isSnapshot}) & ${comparisonPlanningCycleInfo.planningCycleSelected.scenario_year} (isSnapshot: ${comparisonPlanningCycleInfo.isSnapshot})`
        );

        const corpSegmentOptionalFields = [...currentBusinessGroup.corp_segments]
          .sort(customCorpSegmentSort)
          .filter((corpSegment) => !corpSegment.corp_segment_required)
          .flatMap((segment) => [`${segment.corp_segment_name}`, `${segment.corp_segment_name} Description`]);

        // Applying Budget Owner validation
        const currentPlanningCycleAggregatedData: XptReportRowDataStructured[] = await fetchCycleData(
          currentPlanningCycleInfo,
          props.notificationMessage,
          props.selectedRollupPeriod,
          userAlias,
          isBudgetOwner
        );
        logger.info('Current PlanningCycle Aggregated Data length ' + currentPlanningCycleAggregatedData.length);

        // Applying Budget Owner validation
        const comparisonPlanningCycleAggregatedData: XptReportRowDataStructured[] = await fetchCycleData(
          comparisonPlanningCycleInfo,
          props.notificationMessage,
          props.selectedRollupPeriod,
          userAlias,
          isBudgetOwner
        );
        logger.info('Comparison PlanningCycle Aggregated Data length ' + comparisonPlanningCycleAggregatedData.length);

        const varianceReport: XptVarianceReportRowData[] = generateVarianceReport(
          currentPlanningCycleAggregatedData,
          comparisonPlanningCycleAggregatedData
        );
        logger.info('Variance Report Data length ' + varianceReport.length);

        // Applying CC validation
        const filteredAndAuthorized: XptVarianceReportGridData[] = filterAndFlattenVarianceReportData(
          varianceReport,
          corpSegmentOptionalFields,
          userCostCentersForCurrentBusinessGroup
        );
        logger.info('Final Variance Data length after applying CC validation ' + filteredAndAuthorized.length);

        setXptReportData(filteredAndAuthorized);
        gridRef?.current?.api.refreshCells({ force: true });
        props.reportGenerating(false);
      } catch (error: any) {
        logger.error(
          `Error while fetching variance report data for ${currentPlanningCycleInfo.planningCycleSelected.scenario_year} (isSnapshot: ${currentPlanningCycleInfo.isSnapshot}) & ${comparisonPlanningCycleInfo.planningCycleSelected.scenario_year} (isSnapshot: ${comparisonPlanningCycleInfo.isSnapshot})`,
          error
        );
        props.reportGenerating(false);
        props.notificationMessage('Unable to load variance report. Please try again later.', 'error', true);
      }
    }
  };

  useEffect(() => {
    if (userAccessForCurrentBusinessGroupStatus === Status.Completed) fetchVarianceReportData();
  }, [
    userAccessForCurrentBusinessGroup,
    userCostCentersForCurrentBusinessGroup,
    userAccessForCurrentBusinessGroupStatus,
    props.currentPlanningCycleInfo,
    props.comparisonPlanningCycleInfo,
    props.selectedRollupPeriod,
    props.selectedPeriods
  ]);

  const fetchVarianceReportColumnDefinitions = async () => {
    try {
      setGeneratingColumns(true);

      logger.info(
        `Fetching variance report col defs for ${props.currentPlanningCycleInfo.planningCycleSelected.scenario_year} (isSnapshot: ${props.currentPlanningCycleInfo.isSnapshot}) & ${props.comparisonPlanningCycleInfo.planningCycleSelected.scenario_year} (isSnapshot: ${props.comparisonPlanningCycleInfo.isSnapshot})`
      );

      const colDefs = await generateVarianceReportColumnDefinitions(
        currentBusinessGroup,
        props.currentPlanningCycleInfo,
        props.comparisonPlanningCycleInfo,
        props.selectedRollupPeriod,
        props.selectedPeriods
      );
      setColumnDefinitions(colDefs);
    } catch (error: any) {
      logger.error(
        `Error while fetching variance report col defs for ${props.currentPlanningCycleInfo.planningCycleSelected.scenario_year} (isSnapshot: ${props.currentPlanningCycleInfo.isSnapshot}) & ${props.comparisonPlanningCycleInfo.planningCycleSelected.scenario_year} (isSnapshot: ${props.comparisonPlanningCycleInfo.isSnapshot})`,
        error
      );
      props.notificationMessage('Unable to load variance report columns. Please try again later.', 'error', true);
    } finally {
      setGeneratingColumns(false);
    }
  };

  useEffect(() => {
    setTimeout(async () => {
      await fetchVarianceReportColumnDefinitions();
    }, COLUMN_DEFINITION_DELAY_MS);
  }, [xptReportData]);

  const refreshReport = async () => {
    await fetchVarianceReportData();
    setTimeout(async () => {
      await fetchVarianceReportColumnDefinitions();
    }, COLUMN_DEFINITION_DELAY_MS);
  };

  return (
    <ReportGrid
      gridRef={gridRef}
      columnDefs={columnDefinitions}
      rowData={xptReportData}
      uniqueIdField={XptReportGridFixedFields.XptLineItemSeqId.value}
      isLoading={props.isGeneratingReport || generatingColumns}
      localStorageKey={gridStateKey}
      refreshReport={refreshReport}
      exportFileDetails={props.exportFileDetails}
      notificationMessage={props.notificationMessage}
      showPivotModeOptionInPanel={true}
      enablePivotMode={false}
      page="Variance Report"
      mainPage={businessGroupName}
    />
  );
};
