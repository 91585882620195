import { createAsyncThunk } from '@reduxjs/toolkit';
import { listArchivedLineItemIdsS3URI } from '../forecast-utils/ForecastTemplateUtils';
import { getFileFromS3URI } from 'src/utils/aws-s3-services';
import { LineItemIdArchived } from 'src/models/ForecastModels';
import { logger } from 'src/analytics/KatalLogger';

/**
 * Fetches archived line item IDs from S3 based on data classification.
 * @param businessGroupShortDesc - The short description of the data classification.
 * @param dataClassificationId - The ID of the data classification.
 */
export const fetchLineItemIdsArchived = createAsyncThunk(
  'forecastTemplate/fetchLineItemIdsArchived',
  async ({ businessGroupShortDesc, dataClassificationId }: { businessGroupShortDesc: string; dataClassificationId: number }, { rejectWithValue }) => {
    try {
      const s3URI = listArchivedLineItemIdsS3URI(businessGroupShortDesc, dataClassificationId);
      const response = await getFileFromS3URI(s3URI); // Fetch the data from S3
      return response as unknown as LineItemIdArchived[]; // Return the fetched data
    } catch (error: any) {
      logger.error(`Unable to load complete set of line item IDs.`, error);
      throw error;
    }
  }
);
