import { Box, Button, ButtonDropdown, Header, SpaceBetween, Toggle } from '@amzn/awsui-components-react';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'src/store/store';
import { setShowDataWithPO } from './POTaggingSlice';
import { POTaggingStatusDisplay } from './POTaggingStatusDisplay';
import { poTaggingGridFileActions } from './POTaggingUtils';
import { logUserClick } from 'src/analytics/KatalLogger';
import { currentBusinessGroupName } from '../business-group/businessGroupSelectors';

export interface POTaggingGridHeaderProps {
  onClickOfSubmit: () => void;
  onClickOfFileActions: (id: string) => void;
  submitInProgress: boolean;
}

export const POTaggingGridHeader: React.FC<POTaggingGridHeaderProps> = ({ onClickOfSubmit, onClickOfFileActions, submitInProgress }) => {
  const dispatch = useDispatch<AppDispatch>();
  const { showAllData, isGridDirty } = useSelector((state: RootState) => state.poTaggingStore);
  const businessGroupName = useSelector(currentBusinessGroupName);

  return (
    <>
      <Header
        actions={
          <SpaceBetween size="s" direction="horizontal">
            <Box display="inline-block" variant="awsui-key-label" padding={{ top: 'xxs' }}>
              {'Show Data with Valid PO#'}
            </Box>
            <Box padding={{ top: 'xxs' }}>
              <Toggle
                onChange={({ detail }) => {
                  logUserClick('Toggled show all PO numbers', 'PO Tagging', businessGroupName);
                  dispatch(setShowDataWithPO(!detail.checked));
                }}
                checked={!showAllData}
              />
            </Box>
            <Button iconName="refresh" onClick={() => onClickOfFileActions('discard_all_changes')} />
            <ButtonDropdown
              variant="normal"
              onItemClick={({ detail }) => onClickOfFileActions(detail.id)}
              items={poTaggingGridFileActions(false)}
              disabled={submitInProgress}
            >
              {'Actions'}
            </ButtonDropdown>
            <Button variant="primary" disabled={!isGridDirty || submitInProgress} onClick={onClickOfSubmit}>
              {`Submit`}
            </Button>
          </SpaceBetween>
        }
      >
        <SpaceBetween size="s" direction="horizontal">
          {<POTaggingStatusDisplay />}
        </SpaceBetween>
      </Header>
    </>
  );
};
