import { HelpPanel, Box, SpaceBetween } from '@amzn/awsui-components-react';
import React from 'react';

export const ExpenseTypeMappingsInfoPanel: React.FC = () => {
  return (
    <HelpPanel header={<h3>Expense Type Mapping</h3>}>
      <SpaceBetween size="l">
        <Box variant="p">
          This page is accessible only to users with the <strong>Finance Admin</strong> or <strong>Finance Owner</strong> roles.
        </Box>

        <Box variant="p">This feature allows administrators to add, update or delete expense type mapping. </Box>
        <ul>
          <li>
            To add an expense type, click the Add Expense Type button, select a Budget Type from the dropdown, type the value in the Expense Type
            field, add an optional description, and click the Create Expense Type button.
          </li>
          <li>
            To update an expense type, select an expense type mapping record from the table, make updates, and click the Update Expense Type button.
          </li>
          <li>
            To delete an expense type, select an expense type mapping record from the table, change the Status toggle from Active to Inactive, and
            click the Update Expense Type button.
          </li>
        </ul>
      </SpaceBetween>
    </HelpPanel>
  );
};
