import { BreadcrumbGroupProps, TableProps } from '@amzn/awsui-components-react';
import { AdminBaseBreadcrumbs } from 'src/features/admin-console/AdminConsole';
import { DynamicColumnDetails } from 'src/models/XptTableModel';
import { formatUTCAsLocalReadable } from 'src/utils/date-time-utilities';
import { LogEntry } from '../models/KPIModels';

export const geUserInsightsBreadcrumbItems = (): BreadcrumbGroupProps.Item[] => {
  return [
    ...AdminBaseBreadcrumbs,
    {
      text: 'User Insights',
      href: '/admin-console/user-insights'
    }
  ];
};

export const getLogsTableConfig = (entityName: string): DynamicColumnDetails => {
  return {
    columnInfo: {
      entityName: entityName,
      trackBy: '',
      sortingColumn: 'Timestamp',
      isDescending: true
    },
    data: [
      {
        fieldName: 'Timestamp',
        displayName: 'Timestamp',
        canBeHiddenFromProperties: false,
        isShownByDefault: true,
        isFilterable: true,
        metadata: {
          type: 'dateTime'
        }
      },
      {
        fieldName: 'UserAlias',
        displayName: 'UserAlias',
        canBeHiddenFromProperties: false,
        isShownByDefault: true,
        isFilterable: true,
        metadata: {
          type: 'string'
        }
      },
      {
        fieldName: 'Name',
        displayName: 'Name',
        canBeHiddenFromProperties: false,
        isShownByDefault: true,
        isFilterable: true,
        metadata: {
          type: 'string'
        }
      }
    ]
  };
};

export const getLogsHeader = (): TableProps.ColumnDefinition<LogEntry>[] => {
  return [
    { id: 'Timestamp', header: 'Timestamp', cell: (item) => formatUTCAsLocalReadable(item.Timestamp) },
    { id: 'UserAlias', header: 'User Alias', cell: (item) => item.UserAlias },
    { id: 'Name', header: 'Name', cell: (item) => item.Name }
  ];
};
