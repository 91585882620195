import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { logger } from 'src/analytics/KatalLogger';
import { getLineItemIds, getPOTaggingMonthsAndCC } from 'src/api/app-sync-services';
import { eErrorMessages } from 'src/constants/generic-constants';
import { Status } from 'src/models/AuthContextModels';
import {
  CostCenterFilter,
  POCorpSegmentFilterDropdowns,
  POFilterDropdowns,
  POTaggingData,
  POTaggingEntity,
  POTaggingEntityRaw,
  PoTaggingLineItemDetails,
  PoTaggingLineItemDetailsUnParsed
} from 'src/models/POTaggingModel';
import { getFileFromS3URI } from 'src/utils/aws-s3-services';
import { sortDatesWithFormat_MMM_YY } from 'src/utils/date-time-utilities';
import { getActualsS3BucketName } from 'src/utils/xpt-s3-bucket-details';
import { extractFilterData, filterPOTaggingEntities, parseLineItemIds } from './POTaggingUtils';

interface ActualTaggingState {
  actualsFilterDataStatus: Status;
  actualsFilterDropdownValues: POFilterDropdowns; // Month & CC

  selectedActualMonth: string | null;
  selectedCostCenter: string | null;

  poTaggingLineItemDetailsStatus: Status;
  poTaggingLineItemDetails: PoTaggingLineItemDetails[];

  actualTaggingDataStatus: Status;
  actualTaggingData: POTaggingData; // PO Tagging data based on selectedActualMonth & selectedCostCenter

  showAllData: boolean;
  filtersSelected: POCorpSegmentFilterDropdowns; // product, project, account & JE Category
  filteredActualsTaggingData: POTaggingEntity[]; // filteredActualsTaggingData based on filtersSelected
  isGridDirty: boolean;
}

interface FetchActualTaggingDataParams {
  dataClassificationId: number;
  businessGroupShortDesc: string;
  selectedActualMonth: string;
  selectedCostCenter: string;
}

export const initialActualTaggingState: ActualTaggingState = {
  actualsFilterDataStatus: Status.NotInitiated,
  actualsFilterDropdownValues: {
    actual_months: [],
    cost_center: []
  },
  selectedActualMonth: null,
  selectedCostCenter: null,

  poTaggingLineItemDetailsStatus: Status.NotInitiated,
  poTaggingLineItemDetails: [],

  actualTaggingDataStatus: Status.NotInitiated,
  actualTaggingData: {
    data: [],
    filterData: {
      product_filter: [],
      project_filter: [],
      account_filter: [],
      je_category: []
    }
  },
  showAllData: false,
  filtersSelected: {
    product_filter: [],
    project_filter: [],
    account_filter: [],
    je_category: []
  },
  filteredActualsTaggingData: [],
  isGridDirty: false
};

// Fetches actuals filter dropdowns from the API
export const fetchActualsFilterDropdowns = createAsyncThunk(
  'actualTagging/fetchActualsFilterDropdowns',
  async (
    {
      dataClassificationId,
      userCostCentersForCurrentBusinessGroup
    }: { dataClassificationId: number; userCostCentersForCurrentBusinessGroup: string[] },
    { rejectWithValue }
  ) => {
    try {
      const actualsFilterDropdowns = await getPOTaggingMonthsAndCC(dataClassificationId);
      const poTaggingFilterCC = actualsFilterDropdowns?.cost_center || [];
      const filteredCC = poTaggingFilterCC.filter((cc) => userCostCentersForCurrentBusinessGroup.includes(cc.cost_center_code));
      return {
        actual_months: sortDatesWithFormat_MMM_YY(actualsFilterDropdowns?.actual_months || []),
        cost_center: filteredCC.sort((a: CostCenterFilter, b: CostCenterFilter) => a.cost_center_code.localeCompare(b.cost_center_code))
      } as POFilterDropdowns;
    } catch (error) {
      return rejectWithValue('Failed to fetch actuals filter dropdowns');
    }
  }
);

// Fetches PO Tagging Line Item Ids
export const fetchPOTaggingLineItemDetails = createAsyncThunk(
  'actualTagging/fetchPOTaggingLineItemDetails',
  async (dataClassificationId: number, { rejectWithValue }) => {
    try {
      const lineItemIds: PoTaggingLineItemDetailsUnParsed[] = await getLineItemIds(dataClassificationId);
      const lineItemIdsParsed: PoTaggingLineItemDetails[] = parseLineItemIds(lineItemIds);
      return lineItemIdsParsed;
    } catch (error) {
      return rejectWithValue('Failed to fetch line item ids');
    }
  }
);

export const fetchActualTaggingData = createAsyncThunk(
  'actualTagging/fetchActualTaggingData',
  async (params: FetchActualTaggingDataParams, { dispatch, getState, rejectWithValue }) => {
    const { selectedActualMonth, selectedCostCenter, businessGroupShortDesc, dataClassificationId } = params;

    if (!selectedActualMonth || !selectedCostCenter || !businessGroupShortDesc || !dataClassificationId) {
      return rejectWithValue('Invalid selections or missing business group data.');
    }

    dispatch(fetchPOTaggingLineItemDetails(dataClassificationId));

    const s3BucketName = getActualsS3BucketName().bucketName;
    const s3URI = `s3://${s3BucketName}/${businessGroupShortDesc}_${dataClassificationId}/${selectedActualMonth}/${selectedCostCenter}/query/list_actuals_data.json`;
    logger.info(`Fetching actuals tagging data from S3 for month ${selectedActualMonth} & cost center ${selectedCostCenter} with S3 URI: ${s3URI}`);

    try {
      const poTaggingData: POTaggingEntityRaw[] = (await getFileFromS3URI(s3URI)) as unknown as POTaggingEntityRaw[];
      const activePoTaggingData: POTaggingEntity[] = poTaggingData
        .filter((row) => row.is_active && row.actuals_item_id) // filtering only active and data with actuals_item_id
        .map((row) => {
          let xpt_line_item_id: number | null = null;

          if (row.xpt_line_item_id !== null) {
            const parsedId = Number(row.xpt_line_item_id);
            if (!isNaN(parsedId)) {
              xpt_line_item_id = parsedId;
            } else {
              logger.error(`Invalid xpt_line_item_id: "${row.xpt_line_item_id}" for actuals_item_id: "${row.actuals_item_id}"`);
            }
          }
          return {
            ...row,
            xpt_line_item_id
          };
        });

      // utility function to extract filter data
      const filterData = extractFilterData(activePoTaggingData);

      // Set initial filters with filterData
      // Initially, by default je_category should be selected with only "Purchase Invoices", but If the data doesn't have  "Purchase Invoices", then to select all by default.
      dispatch(
        setFiltersSelected({
          product_filter: filterData.product_filter,
          project_filter: filterData.project_filter,
          account_filter: filterData.account_filter,
          je_category: filterData.je_category.includes('Purchase Invoices') ? ['Purchase Invoices'] : filterData.je_category
        })
      );

      setTimeout(() => {
        dispatch(applyFilters());
      }, 10);

      return {
        data: activePoTaggingData,
        filterData
      };
    } catch (error: any) {
      logger.error(`Failed to fetch actual tagging data from S3 for month ${selectedActualMonth} & cost center ${selectedCostCenter}`, error);

      dispatch(
        setFiltersSelected({
          product_filter: [],
          project_filter: [],
          account_filter: [],
          je_category: []
        })
      );

      setTimeout(() => {
        dispatch(applyFilters());
      }, 10);

      if (error.message === eErrorMessages.NO_DATA_FOUND) {
        return rejectWithValue(eErrorMessages.NO_DATA_FOUND);
      }

      return rejectWithValue('Failed to fetch po tagging data');
    }
  }
);

const poTaggingSlice = createSlice({
  name: 'actualTagging',
  initialState: initialActualTaggingState,
  reducers: {
    setSelectedActualMonth(state, action: PayloadAction<string | null>) {
      state.selectedActualMonth = action.payload;
    },
    setSelectedCostCenter(state, action: PayloadAction<string | null>) {
      state.selectedCostCenter = action.payload;
    },
    clearActualsDataAndFilterDropdownValues(state) {
      state.actualTaggingData = {
        data: [],
        filterData: {
          product_filter: [],
          project_filter: [],
          account_filter: [],
          je_category: []
        }
      };
      state.filteredActualsTaggingData = [];
      state.actualTaggingDataStatus = Status.NotInitiated;
    },
    setFiltersSelected(state, action: PayloadAction<Partial<POCorpSegmentFilterDropdowns>>) {
      state.filtersSelected = { ...state.filtersSelected, ...action.payload };
    },
    applyFilters(state) {
      const { selectedCostCenter, selectedActualMonth, actualTaggingData, filtersSelected } = state;
      const filteredData = filterPOTaggingEntities(actualTaggingData.data, filtersSelected);
      logger.debug(
        `Filtered ${filteredData.length} out of ${actualTaggingData.data.length} total records for Month ${selectedActualMonth} & Cost Center ${selectedCostCenter}.`
      );
      state.filteredActualsTaggingData = filteredData;
    },
    setIsGridDirty(state, action: PayloadAction<boolean>) {
      state.isGridDirty = action.payload;
    },
    toggleShowOnlyDataWithPO: (state) => {
      state.showAllData = !state.showAllData;
    },
    setShowDataWithPO: (state, action: PayloadAction<boolean>) => {
      state.showAllData = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchActualsFilterDropdowns.pending, (state) => {
        state.actualsFilterDataStatus = Status.Loading;
      })
      .addCase(fetchActualsFilterDropdowns.fulfilled, (state, action: PayloadAction<POFilterDropdowns>) => {
        state.actualsFilterDataStatus = Status.Completed;
        state.actualsFilterDropdownValues = action.payload;
      })
      .addCase(fetchActualsFilterDropdowns.rejected, (state, action) => {
        state.actualsFilterDataStatus = Status.Failed;
        console.error(action.payload);
      })
      .addCase(fetchPOTaggingLineItemDetails.pending, (state) => {
        state.poTaggingLineItemDetailsStatus = Status.Loading;
      })
      .addCase(fetchPOTaggingLineItemDetails.fulfilled, (state, action: PayloadAction<PoTaggingLineItemDetails[]>) => {
        state.poTaggingLineItemDetails = action.payload;
        state.poTaggingLineItemDetailsStatus = Status.Completed;
      })
      .addCase(fetchPOTaggingLineItemDetails.rejected, (state, action) => {
        state.poTaggingLineItemDetailsStatus = Status.Failed;
        console.error(action.payload);
      })
      .addCase(fetchActualTaggingData.pending, (state) => {
        state.actualTaggingDataStatus = Status.Loading;
      })
      .addCase(fetchActualTaggingData.fulfilled, (state, action: PayloadAction<POTaggingData>) => {
        state.actualTaggingDataStatus = Status.Completed;
        state.actualTaggingData = action.payload;
        state.filteredActualsTaggingData = action.payload.data; // Initialize with all data
      })
      .addCase(fetchActualTaggingData.rejected, (state, action) => {
        state.actualTaggingDataStatus = Status.Failed;
        state.actualTaggingData = {
          data: [],
          filterData: {
            product_filter: [],
            project_filter: [],
            account_filter: [],
            je_category: []
          }
        };
        state.filteredActualsTaggingData = [];
        console.error(action.payload);
      });
  }
});

export const {
  setSelectedActualMonth,
  setSelectedCostCenter,
  clearActualsDataAndFilterDropdownValues,
  setFiltersSelected,
  applyFilters,
  setIsGridDirty,
  setShowDataWithPO,
  toggleShowOnlyDataWithPO
} = poTaggingSlice.actions;
export default poTaggingSlice.reducer;
