import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { logger } from 'src/analytics/KatalLogger';
import { getMasterSegments } from 'src/api/app-sync-services';
import { CorpSegmentNames, SegmentCategoryType } from 'src/constants/corp-segment-constants';
import {
  convertToDropdownValues,
  fetchCorpSegmentDataFromS3,
  filterOutMasterCorpSegmentsFromSelectedValues,
  getTransformedCorpSegments
} from 'src/features/business-group/forecast-template/forecast-utils/CorpSegmentsUtils';
import { BusinessGroupEntity, MasterBusinessSegments, MasterCorpSegments, SegmentHierarchy } from 'src/models/AppContextModels';
import { Status } from 'src/models/AuthContextModels';
import { CostCenterValuesPerBusinessGroup, ForecastTemplateMasterCorpSegmentDropdownValues, OptionDropdown } from 'src/models/ForecastModels';
import { getFileFromS3URI } from 'src/utils/aws-s3-services';
import { RootState } from '../store';

interface CorpSegmentState {
  masterCorpSegments: MasterCorpSegments[];
  masterCorpSegmentsStatus: Status;

  masterBusinessSegmentsStatus: Status;
  masterBusinessSegments: MasterBusinessSegments[];

  masterCostCenterDropdownValuesForAllBusinessGroups: CostCenterValuesPerBusinessGroup[];

  masterCorpSegmentDropdownValuesStatus: Status;
  masterCorpSegmentDropdownValues: ForecastTemplateMasterCorpSegmentDropdownValues[];
}

export const corpSegmentInitialState: CorpSegmentState = {
  masterCorpSegments: [],
  masterCorpSegmentsStatus: Status.NotInitiated,

  masterBusinessSegments: [],
  masterBusinessSegmentsStatus: Status.NotInitiated,

  masterCostCenterDropdownValuesForAllBusinessGroups: [],

  masterCorpSegmentDropdownValuesStatus: Status.NotInitiated,
  masterCorpSegmentDropdownValues: []
};

// Master Corp Segments from S3.
export const fetchCorpSegments = createAsyncThunk('corpSegment/fetchCorpSegments', async (_, { getState, rejectWithValue }) => {
  try {
    const masterSegmentsResponse = await getMasterSegments();

    const corpSegment: MasterCorpSegments[] = masterSegmentsResponse?.filter(
      (masterSegment) => masterSegment.segment_category === SegmentCategoryType.CORP
    ) as MasterCorpSegments[];

    const corpSegments: MasterCorpSegments[] = await Promise.all(corpSegment.map(fetchCorpSegmentDataFromS3));
    return corpSegments;
  } catch (error: any) {
    logger.error('Error fetching corporate segments:', error);
    return rejectWithValue('Failed to fetch corporate segments');
  }
});

// Master Business Segments from S3
export const fetchBusinessSegments = createAsyncThunk('corpSegment/fetchBusinessSegments', async (_, { rejectWithValue }) => {
  try {
    const masterSegmentsResponse = await getMasterSegments();

    const businessSegmentResponse = masterSegmentsResponse?.filter(
      (masterSegment) => masterSegment.segment_category === SegmentCategoryType.BUSINESS
    ) as MasterBusinessSegments[];

    return businessSegmentResponse;
  } catch (error: any) {
    logger.error('Error fetching business segments:', error);
    return rejectWithValue('Failed to fetch business segments');
  }
});

export const fetchCostCenterDropdownValuesOfAllBusinessGroups = createAsyncThunk(
  'forecastTemplate/fetchCostCenterDropdownValuesOfAllBusinessGroups',
  async (_, { getState }) => {
    const state = getState() as RootState;

    const businessGroups = state.businessGroupStore.businessGroups;
    const activeBusinessGroups = businessGroups.filter((group) => group.item_metadata.is_active);

    const masterCostCenterInfo = state.corpSegmentsStore.masterCorpSegments?.find(
      (corpSegment) => corpSegment.segment_name === CorpSegmentNames.COST_CENTER
    );

    if (!activeBusinessGroups || !masterCostCenterInfo) {
      return [];
    }

    const costCenterSelectedValuesOfAllBusinessGroups: CostCenterValuesPerBusinessGroup[] = [];

    for (const businessGroup of activeBusinessGroups) {
      try {
        const costCenterSegment = businessGroup.corp_segments.find((corpSegment) => corpSegment.corp_segment_name === CorpSegmentNames.COST_CENTER)!;
        const corpSegmentData = (await getFileFromS3URI(costCenterSegment?.corp_segment_values_s3_path)) as unknown as SegmentHierarchy[];
        const filteredCostCenterHierarchy = filterOutMasterCorpSegmentsFromSelectedValues(
          masterCostCenterInfo.segment_hierarchy_data_from_s3,
          corpSegmentData
        );

        const costCenters: string[] = convertToDropdownValues(
          filteredCostCenterHierarchy,
          masterCostCenterInfo.segment_name
        ).masterCorpSegmentDropdownValues.map((eachDropdownOption: OptionDropdown) => eachDropdownOption.label);

        costCenterSelectedValuesOfAllBusinessGroups.push({
          dataClassificationId: businessGroup.data_classification.data_classification_id!,
          costCenters: costCenters?.sort((a, b) => a.localeCompare(b))
        });
      } catch (error: any) {
        logger.error('Error fetching cost center data for business group:', {
          ...error,
          businessGroup: businessGroup.data_classification.data_classification_name
        });
      }
    }

    return costCenterSelectedValuesOfAllBusinessGroups;
  }
);

export const fetchMasterCorpSegmentDropdownValues = createAsyncThunk(
  'forecastTemplate/fetchMasterCorpSegmentDropdownValues',
  async ({ businessGroup }: { businessGroup: BusinessGroupEntity }, { getState }) => {
    const state = getState() as RootState;
    const masterCorpSegments = state.corpSegmentsStore.masterCorpSegments;

    if (!masterCorpSegments) {
      logger.warn('Master corp segments data is not available');
      return [];
    }

    const corpSegments = await getTransformedCorpSegments(businessGroup.corp_segments, masterCorpSegments);
    return corpSegments;
  }
);

const corpSegmentSlice = createSlice({
  name: 'corpSegment',
  initialState: corpSegmentInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      // fetchCorpSegments
      .addCase(fetchCorpSegments.pending, (state) => {
        state.masterCorpSegments = [];
        state.masterCorpSegmentsStatus = Status.Loading;
      })
      .addCase(fetchCorpSegments.fulfilled, (state, action) => {
        state.masterCorpSegments = action.payload;
        state.masterCorpSegmentsStatus = Status.Completed;
      })
      .addCase(fetchCorpSegments.rejected, (state) => {
        state.masterCorpSegments = [];
        state.masterCorpSegmentsStatus = Status.Failed;
      })

      // fetchBusinessSegments
      .addCase(fetchBusinessSegments.pending, (state) => {
        state.masterBusinessSegmentsStatus = Status.Loading;
      })
      .addCase(fetchBusinessSegments.fulfilled, (state, action) => {
        state.masterBusinessSegments = action.payload;
        state.masterBusinessSegmentsStatus = Status.Completed;
      })
      .addCase(fetchBusinessSegments.rejected, (state) => {
        state.masterBusinessSegmentsStatus = Status.Failed;
      })

      // fetchCostCenterDropdownValuesOfAllBusinessGroups
      .addCase(fetchCostCenterDropdownValuesOfAllBusinessGroups.fulfilled, (state, action) => {
        state.masterCostCenterDropdownValuesForAllBusinessGroups = action.payload;
      })
      .addCase(fetchCostCenterDropdownValuesOfAllBusinessGroups.rejected, (state) => {
        state.masterCostCenterDropdownValuesForAllBusinessGroups = [];
      })

      // fetchMasterCorpSegmentDropdownValues
      .addCase(fetchMasterCorpSegmentDropdownValues.pending, (state, action) => {
        state.masterCorpSegmentDropdownValues = [];
        state.masterCorpSegmentDropdownValuesStatus = Status.Loading;
      })
      .addCase(fetchMasterCorpSegmentDropdownValues.fulfilled, (state, action) => {
        state.masterCorpSegmentDropdownValues = action.payload;
        state.masterCorpSegmentDropdownValuesStatus = Status.Completed;
      })
      .addCase(fetchMasterCorpSegmentDropdownValues.rejected, (state) => {
        state.masterCorpSegmentDropdownValues = [];
        state.masterCorpSegmentDropdownValuesStatus = Status.Failed;
      });
  }
});

export default corpSegmentSlice.reducer;
