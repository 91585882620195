import { Box, HelpPanel, SpaceBetween } from '@amzn/awsui-components-react';
import React from 'react';

export const ProjectMappingInfoPanel: React.FC = () => {
  return (
    <HelpPanel header={<h2>Project Mapping</h2>}>
      <SpaceBetween size="l">
        <Box variant="p">
          This page is accessible only to users with the <strong>Finance Admin</strong> roles.
        </Box>
        <Box variant="p">This feature allows Finance Admins to update Project Description in xPT.</Box>
        <ul>
          <li>
            <Box variant="p">Click on the filter icon of the Project Code column to select the desired Project Code</Box>
          </li>
          <li>
            <Box variant="p">Enter the updated Project Description in the yellow-shaded cell and click the Submit button when done</Box>
          </li>
          <li>
            <Box variant="p">Note that updates made here are available in xPT only and will not be sent to FRAMES</Box>
          </li>
        </ul>
      </SpaceBetween>
    </HelpPanel>
  );
};
