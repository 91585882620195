import { Box, HelpPanel, SpaceBetween } from '@amzn/awsui-components-react';
import React from 'react';

export const UsefulLifeMappingsInfoPanel: React.FC = () => {
  return (
    <HelpPanel header={<h3>Useful Life Mapping</h3>}>
      <SpaceBetween size="l">
        <Box variant="p">
          This page is accessible only to users with the <strong>Finance Admin</strong> or <strong>Finance Owner</strong> roles.
        </Box>

        <Box variant="p">This feature allows administrators to add, update or delete useful life mapping.</Box>
        <ul>
          <li>
            To add a useful life, click the Add Useful Life button, select a Cost Center from the dropdown, select an Expense Type that is under
            CAPEX, enter the number of months in the Useful Life field, and click the Create Useful Life button.
          </li>
          <li>
            To update a useful life, select a useful life mapping record from the table, update the Useful Life field, and click the Update Useful
            Life button.
          </li>
          <li>
            To delete a useful life, select a useful life mapping record from the table, change the Status toggle from Active to Inactive, and click
            the Update Useful Life button.
          </li>
        </ul>
      </SpaceBetween>
    </HelpPanel>
  );
};
