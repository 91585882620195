import {
  Alert,
  Box,
  Container,
  ContentLayout,
  Flashbar,
  FormField,
  Header,
  Link,
  Multiselect,
  Select,
  SelectProps,
  SpaceBetween
} from '@amzn/awsui-components-react';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { logUserClick } from 'src/analytics/KatalLogger';
import { XptAppLayout } from 'src/components/common/xpt-app-layout/XptAppLayout';
import { XPTBreadcrumbs } from 'src/components/common/XptBreadcrumb';
import { useFlashbar } from 'src/hooks/useFlashbar';
import { PlanningCycleInfo, XptReportExportFileDetails } from 'src/models/XptReportingModels';
import { RootState } from 'src/store/store';
import { getMultiSelectPlaceHolderValue } from 'src/utils/generic-utilities';
import { businessGroupBaseBreadcrumbs, currentBusinessGroupName } from '../../business-group/businessGroupSelectors';
import { selectAllPlanningCyclesForCurrentGroup, selectScenarioSnapshotPerBusinessGroup } from '../../planning-cycle/planningCycleSelector';
import BusinessGroupSideNavigation from '../../xpt-layout/XptBusinessGroupSideNavigation';
import { ReportTypes } from '../XptReportGridConstants';
import { getCommonPeriodInfo, getPlanningCyclesDropdownOptions, getSelectedPlanningCycle, getXptReportFileName } from '../XptReportingUtils';
import { XptVarianceReportGrid } from './XptVarianceReportGrid';
import { XptVarianceReportInfoPanel } from './XptVarianceReportInfoPanel';
import { EMPTY_OPTION, getXptVarianceReportsBreadcrumbItems, ROLL_UP_PERIOD, RollUpPeriodOptions } from './XptVarianceReportUtils';

const LOCAL_STORAGE_PREFIX = 'XptVarianceReport-';

export const XptVarianceReport: React.FC = () => {
  const appLayout = useRef<any>();
  const [toolsOpen, setToolsOpen] = useState(false);

  const { userAccessForCurrentBusinessGroup } = useSelector((state: RootState) => state.xptAccessAndAuthorizationStore);
  const isFinanceAdminOrFinanceOwner = userAccessForCurrentBusinessGroup?.isFinanceAdminOrFinanceOwner || false;

  const currentBusinessGroup = useSelector((state: RootState) => state.businessGroupStore.currentBusinessGroup);
  const businessGroupName = useSelector(currentBusinessGroupName);
  const data_classification_id = currentBusinessGroup?.data_classification.data_classification_id;
  const dataClassificationShortDesc: string | undefined = currentBusinessGroup?.data_classification.data_classification_short_description;

  const businessGroupBaseBreadcrumb = useSelector(businessGroupBaseBreadcrumbs);
  const { flashbarItems, displayFlashMessage } = useFlashbar();

  const scenarios = useSelector((state: RootState) => state.planningCycleStore.scenarios);
  const planningCycles = useSelector(selectAllPlanningCyclesForCurrentGroup);
  const scenarioSnapshots = useSelector(selectScenarioSnapshotPerBusinessGroup);

  const [planningCycleOptions, setPlanningCycleOptions] = useState<SelectProps.OptionGroup[]>([]);
  const [periodOptions, setPeriodOptions] = useState<SelectProps.Options | SelectProps.OptionGroup[]>([]);

  const [selectedCurrentPlanningCycle, setSelectedCurrentPlanningCycleState] = useState<SelectProps.Option | null>(null);
  const [currentPlanningCycleInfo, setCurrentPlanningCycleInfo] = useState<PlanningCycleInfo>();
  const [exportFileDetails, setExportFileDetails] = useState<XptReportExportFileDetails>();

  const [selectedComparisonPlanningCycle, setSelectedComparisonPlanningCycleState] = useState<SelectProps.Option | null>(null);
  const [comparisonPlanningCycleInfo, setComparisonPlanningCycleInfo] = useState<PlanningCycleInfo>();

  const [selectedRollupPeriod, setSelectedRollupPeriod] = useState<SelectProps.Option | null>(null);
  const [selectedPeriods, setSelectedPeriods] = useState<SelectProps.Options>([]);

  // Temporary state to track selections for the period picker
  const [tempSelectedPeriods, setTempSelectedPeriods] = useState<SelectProps.Options>([]);

  const [isGeneratingReport, setIsGeneratingReport] = useState(false);

  useEffect(() => {
    const initialize = async () => {
      if (dataClassificationShortDesc) {
        setSelectedCurrentPlanningCycleState(getInitialState('selectedCurrentPlanningCycle', EMPTY_OPTION));
        setSelectedComparisonPlanningCycleState(getInitialState('selectedComparisonPlanningCycle', EMPTY_OPTION));
        setSelectedRollupPeriod(
          getInitialState('selectedRollupPeriod', RollUpPeriodOptions.find((period) => period.label === ROLL_UP_PERIOD.YEARLY) || EMPTY_OPTION)
        );
        const initialPeriods = getInitialState('selectedPeriods', []);
        setSelectedPeriods(initialPeriods);
        setTempSelectedPeriods(initialPeriods);
      }
    };

    initialize();
  }, [dataClassificationShortDesc]);

  const getInitialState = (key: string, defaultValue: SelectProps.Option | null | []) => {
    const storedValue = localStorage.getItem(`${LOCAL_STORAGE_PREFIX}${key}-${dataClassificationShortDesc}`);
    return storedValue ? JSON.parse(storedValue) : defaultValue;
  };

  useEffect(() => {
    // Determine applicable scenario snapshots based on user role
    // Only Finance Admin or Finance Owner can see snapshots
    const applicableScenarioSnapshots = isFinanceAdminOrFinanceOwner ? scenarioSnapshots : [];
    const planningCycleDropdownOptions: SelectProps.OptionGroup[] = getPlanningCyclesDropdownOptions(
      scenarios,
      planningCycles,
      applicableScenarioSnapshots
    );
    setPlanningCycleOptions(planningCycleDropdownOptions);
  }, [scenarios, planningCycles, scenarioSnapshots]);

  useEffect(() => {
    if (dataClassificationShortDesc && selectedCurrentPlanningCycle) {
      const { fileName, sheetName } = getXptReportFileName(
        dataClassificationShortDesc,
        selectedCurrentPlanningCycle?.label || '',
        ReportTypes.VARIANCE
      );
      setExportFileDetails({ fileName, sheetName });
    }
  }, [dataClassificationShortDesc, selectedCurrentPlanningCycle]);

  useEffect(() => {
    if (selectedCurrentPlanningCycle && dataClassificationShortDesc) {
      localStorage.setItem(
        `${LOCAL_STORAGE_PREFIX}selectedCurrentPlanningCycle-${dataClassificationShortDesc}`,
        JSON.stringify(selectedCurrentPlanningCycle)
      );
    }
  }, [selectedCurrentPlanningCycle, dataClassificationShortDesc]);

  useEffect(() => {
    if (selectedComparisonPlanningCycle && dataClassificationShortDesc) {
      localStorage.setItem(
        `${LOCAL_STORAGE_PREFIX}selectedComparisonPlanningCycle-${dataClassificationShortDesc}`,
        JSON.stringify(selectedComparisonPlanningCycle)
      );
    }
  }, [selectedComparisonPlanningCycle, dataClassificationShortDesc]);

  useEffect(() => {
    if (selectedRollupPeriod && dataClassificationShortDesc) {
      localStorage.setItem(`${LOCAL_STORAGE_PREFIX}selectedRollupPeriod-${dataClassificationShortDesc}`, JSON.stringify(selectedRollupPeriod));
    }
  }, [selectedRollupPeriod, dataClassificationShortDesc]);

  // Persist the final selection of periods on state change
  useEffect(() => {
    if (selectedPeriods.length > 0 && dataClassificationShortDesc) {
      localStorage.setItem(`${LOCAL_STORAGE_PREFIX}selectedPeriods-${dataClassificationShortDesc}`, JSON.stringify(selectedPeriods));
    }
  }, [selectedPeriods, dataClassificationShortDesc]);

  const getErrorText = () => {
    if (selectedCurrentPlanningCycle?.value && selectedComparisonPlanningCycle?.value) {
      return selectedCurrentPlanningCycle?.value === selectedComparisonPlanningCycle?.value ? `Should not be same as Planning Cycle` : '';
    } else {
      return '';
    }
  };

  useEffect(() => {
    if (!selectedCurrentPlanningCycle?.value || !data_classification_id || !dataClassificationShortDesc || !scenarioSnapshots || !planningCycles)
      return;
    const planningCycleInfo: PlanningCycleInfo = getSelectedPlanningCycle(
      selectedCurrentPlanningCycle,
      data_classification_id,
      dataClassificationShortDesc,
      scenarioSnapshots,
      planningCycles
    );
    setCurrentPlanningCycleInfo(planningCycleInfo);
  }, [selectedCurrentPlanningCycle, planningCycles, scenarioSnapshots]);

  useEffect(() => {
    if (!selectedComparisonPlanningCycle?.value || !data_classification_id || !dataClassificationShortDesc || !scenarioSnapshots || !planningCycles)
      return;
    const planningCycleInfo: PlanningCycleInfo = getSelectedPlanningCycle(
      selectedComparisonPlanningCycle,
      data_classification_id,
      dataClassificationShortDesc,
      scenarioSnapshots,
      planningCycles
    );
    setComparisonPlanningCycleInfo(planningCycleInfo);
  }, [selectedComparisonPlanningCycle, planningCycles, scenarioSnapshots]);

  useEffect(() => {
    if (!currentPlanningCycleInfo || !comparisonPlanningCycleInfo || !selectedRollupPeriod?.value) return;
    const commonPeriodInfo = getCommonPeriodInfo(
      currentPlanningCycleInfo.planningCyclePeriodInfo,
      comparisonPlanningCycleInfo.planningCyclePeriodInfo
    );

    if (selectedRollupPeriod?.value === ROLL_UP_PERIOD.QUARTERLY) {
      setPeriodOptions(commonPeriodInfo.year_quarter_group_by_year);
    } else {
      const selectAll: SelectProps.Options = [
        {
          label: 'Select All',
          value: 'Select All',
          options: commonPeriodInfo.year.map(
            (year) =>
              ({
                label: year.toString(),
                value: year.toString()
              } as SelectProps.OptionGroup)
          )
        }
      ];

      setPeriodOptions(selectAll);
    }
  }, [currentPlanningCycleInfo, comparisonPlanningCycleInfo, selectedRollupPeriod]);

  return (
    <>
      <XptAppLayout
        ref={appLayout}
        headerSelector="#h"
        navigation={<BusinessGroupSideNavigation />}
        tools={<XptVarianceReportInfoPanel />}
        toolsOpen={toolsOpen}
        onToolsChange={({ detail }) => setToolsOpen(detail.open)}
        breadcrumbs={<XPTBreadcrumbs items={getXptVarianceReportsBreadcrumbItems(businessGroupBaseBreadcrumb, businessGroupName)} />}
        notifications={<Flashbar stackItems items={flashbarItems} />}
        stickyNotifications={true}
        maxContentWidth={Number.MAX_VALUE}
        contentType="default"
        content={
          <ContentLayout
            disableOverlap
            header={
              <Header
                info={
                  <Link variant="info" onFollow={() => setToolsOpen(true)}>
                    Info
                  </Link>
                }
              >{`Variance Report`}</Header>
            }
          >
            <Container disableContentPaddings disableHeaderPaddings>
              <Box
                padding={{
                  top: 's',
                  right: 's',
                  bottom: 'm',
                  left: 's'
                }}
              >
                <SpaceBetween size="m" direction="horizontal">
                  <FormField label="Scenario 1" className="width-25-rem">
                    <Select
                      expandToViewport
                      disabled={isGeneratingReport}
                      options={planningCycleOptions}
                      selectedOption={selectedCurrentPlanningCycle}
                      onChange={({ detail }) => {
                        setSelectedPeriods([]);
                        setTempSelectedPeriods([]);
                        setSelectedCurrentPlanningCycleState(detail.selectedOption);
                        logUserClick(`Planning Cycle filter change`, 'Variance Report', businessGroupName);
                      }}
                    />
                  </FormField>

                  <FormField label="Scenario 2" errorText={getErrorText()} className="width-25-rem">
                    <Select
                      expandToViewport
                      disabled={isGeneratingReport}
                      options={planningCycleOptions}
                      selectedOption={selectedComparisonPlanningCycle}
                      onChange={({ detail }) => {
                        setSelectedPeriods([]);
                        setTempSelectedPeriods([]);
                        setSelectedComparisonPlanningCycleState(detail.selectedOption);
                      }}
                    />
                  </FormField>

                  <FormField label="Rollup Period" className="width-25-rem">
                    <Select
                      expandToViewport
                      disabled={isGeneratingReport}
                      options={RollUpPeriodOptions}
                      selectedOption={selectedRollupPeriod}
                      onChange={({ detail }) => {
                        setSelectedPeriods([]);
                        setTempSelectedPeriods([]);
                        setSelectedRollupPeriod(detail.selectedOption);
                        logUserClick(`Rollup period change`, 'Variance Report', businessGroupName);
                      }}
                    />
                  </FormField>

                  <FormField label="Period" className="width-25-rem">
                    <Multiselect
                      placeholder={getMultiSelectPlaceHolderValue(tempSelectedPeriods, 'Period')}
                      expandToViewport
                      hideTokens
                      disabled={isGeneratingReport}
                      options={periodOptions}
                      selectedOptions={tempSelectedPeriods} // Use the temporary state
                      onChange={({ detail }) => {
                        setTempSelectedPeriods(detail.selectedOptions); // Update temporary state
                      }}
                      onBlur={() => {
                        setSelectedPeriods(tempSelectedPeriods); // Set actual state onBlur
                      }}
                    />
                  </FormField>
                </SpaceBetween>
              </Box>
            </Container>
            {currentPlanningCycleInfo &&
            comparisonPlanningCycleInfo &&
            selectedRollupPeriod?.value &&
            selectedPeriods.length > 0 &&
            exportFileDetails ? (
              <Box padding={{ top: 's' }}>
                <XptVarianceReportGrid
                  notificationMessage={displayFlashMessage}
                  reportGenerating={setIsGeneratingReport}
                  isGeneratingReport={isGeneratingReport}
                  currentPlanningCycleInfo={currentPlanningCycleInfo}
                  comparisonPlanningCycleInfo={comparisonPlanningCycleInfo}
                  selectedRollupPeriod={selectedRollupPeriod.value as ROLL_UP_PERIOD}
                  selectedPeriods={selectedPeriods}
                  exportFileDetails={exportFileDetails}
                />
              </Box>
            ) : (
              <Box margin={{ top: 'l' }}>
                <Alert type="info">{`Make selections to generate report`}</Alert>
              </Box>
            )}
          </ContentLayout>
        }
      />
    </>
  );
};
