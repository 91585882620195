import { Button, Header, SpaceBetween, StatusIndicator, TableProps } from '@amzn/awsui-components-react';
import React, { useEffect, useState } from 'react';
import { logger, logUserClick } from 'src/analytics/KatalLogger';
import { getResources } from 'src/api/app-sync-services';
import { LoadingSpinner } from 'src/components/common/LoadingSpinner';
import XptWidgetTable from 'src/components/common/xpt-table/XptWidgetTable';
import { WidgetContainer } from '../WidgetContainer';
import { Resources } from 'src/models/AppContextModels';
import { ResourcesForm } from './ResourcesForm';
import { useAuth } from 'src/app/auth/AuthContextProvider';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/store';

interface ResourceWidgetProps {
  dataClassificationId?: number | null | undefined;
}

const ResourceWidget: React.FC<ResourceWidgetProps> = () => {
  const headerText = 'Resources';
  const { isDev } = useAuth();
  const { userAccessForCurrentBusinessGroup } = useSelector((state: RootState) => state.xptAccessAndAuthorizationStore);
  const isFinanceAdminOrFinanceOwner = userAccessForCurrentBusinessGroup?.isFinanceAdminOrFinanceOwner || false;

  const [resources, setResources] = useState<Resources[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const [showModal, setShowModal] = useState<boolean>(false);

  useEffect(() => {
    const loadResources = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const fetchedResources = await getResources();
        setResources(fetchedResources);
      } catch (err: any) {
        setResources([]);
        setError('Failed to load resources');
        logger.error('Failed to load resources', err);
      } finally {
        setIsLoading(false);
      }
    };

    loadResources();
  }, []);

  const onCancelCreateContact = () => {
    setShowModal(false);
  };

  const onConfirmingCreateContact = (updatedResources: Resources[]) => {
    setResources(updatedResources);
    setShowModal(false);
  };

  const widgetHeader = () => {
    return (
      <Header
        variant="h3"
        actions={
          <SpaceBetween size="s" direction="horizontal">
            {(isDev || isFinanceAdminOrFinanceOwner) && <Button onClick={() => setShowModal(true)}>{'Edit'}</Button>}
          </SpaceBetween>
        }
      >
        {headerText}
      </Header>
    );
  };

  return (
    <>
      <ResourcesForm
        showModal={showModal}
        onCancel={onCancelCreateContact}
        onConfirm={(updatedResources: Resources[]) => onConfirmingCreateContact(updatedResources)}
        currentResources={resources}
      />
      <WidgetContainer header={widgetHeader()}>
        {isLoading ? (
          <LoadingSpinner />
        ) : error ? (
          <StatusIndicator type="error"> {error}</StatusIndicator>
        ) : (
          <>
            <XptWidgetTable
              variant={'embedded'}
              loadingStatus={isLoading}
              allItems={resources}
              columnDefinitions={Resources_Header_Config()}
              hasPagination={false}
              itemsPerPage={5}
            />
          </>
        )}
      </WidgetContainer>
    </>
  );
};

export default ResourceWidget;

export const Resources_Header_Config = (): TableProps.ColumnDefinition<Resources>[] => {
  const onFollowEventOfClick = (item: Resources) => {
    logUserClick(`Clicked on resource: ${item.resource_name}`, 'Resource widget', 'Landing Page');
  };

  return [
    {
      id: 'resourceName',
      header: '',
      cell: (item: Resources) =>
        item.resource_link ? (
          <Button
            href={item.resource_link}
            iconAlign="right"
            iconName="external"
            target="_blank"
            variant="inline-link"
            onClick={() => onFollowEventOfClick(item)}
          >
            {item.resource_name}
          </Button>
        ) : (
          <span>{item.resource_name}</span>
        )
    }
  ] as TableProps.ColumnDefinition<Resources>[];
};
