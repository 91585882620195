import { createSelector } from '@reduxjs/toolkit';
import { logger } from 'src/analytics/KatalLogger';
import { ExpenseTypeMappingEntity } from 'src/models/xPTMappingModels';
import { RootState } from '../store';

// First, create a selector to get all expense type mappings
const selectAllExpenseTypeMappings = (state: RootState) => state.xPTMappingStore.expenseTypeMappings;

export const selectActiveExpenseTypeMappings = createSelector([selectAllExpenseTypeMappings], (allExpenseTypeMappings: ExpenseTypeMappingEntity[]) =>
  allExpenseTypeMappings.filter((expenseTypeMapping) => expenseTypeMapping.is_active)
);

/**
 * Utility function to get expense types from an array of ExpenseTypeMapping.
 * Adds "N/A" to the beginning of the list if it's not already included.
 */
const getExpenseTypes = (expenseTypeMappings: ExpenseTypeMappingEntity[]) => {
  const expenseTypes = expenseTypeMappings.map((et) => et.expense_type);
  return expenseTypes.includes('N/A') ? expenseTypes : ['N/A', ...expenseTypes];
};

/**
 * Selector to get the current business group from the state.
 */
const selectCurrentBusinessGroup = (state: RootState) => state.businessGroupStore.currentBusinessGroup;

/**
 * Selector to get all unique expense types from the state.
 */
export const selectActiveExpenseTypesIncludingNA = createSelector([selectActiveExpenseTypeMappings], getExpenseTypes);

/**
 * Selector to filter expense types based on the current business group's data classification.
 */
export const selectActiveExpenseTypesForCurrentGroup = createSelector(
  [selectCurrentBusinessGroup, selectActiveExpenseTypeMappings],
  (currentBusinessGroup, expenseTypeMappings) => {
    if (!currentBusinessGroup) return [];

    const classificationId = currentBusinessGroup.data_classification.data_classification_id;

    const filteredExpenseTypes = expenseTypeMappings.filter(
      (expenseType) => expenseType.is_active && expenseType.data_classification_id === classificationId
    );

    logger.info(
      `Filtered ${filteredExpenseTypes.length} expense types out of ${expenseTypeMappings.length} based on business group's data classification: ${currentBusinessGroup.data_classification.data_classification_short_description}.`
    );

    return getExpenseTypes(filteredExpenseTypes);
  }
);
