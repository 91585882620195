import { SideNavigation, SideNavigationProps } from '@amzn/awsui-components-react';
import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAuth } from 'src/app/auth/AuthContextProvider';
import { RootState } from 'src/store/store';
import { findActiveHref } from 'src/utils/navigation-utilities';

const BusinessGroupSideNavigation: React.FC = () => {
  const { userAccessForCurrentBusinessGroup } = useSelector((state: RootState) => state.xptAccessAndAuthorizationStore);

  const isFinanceAdminOrFinanceOwner = userAccessForCurrentBusinessGroup?.isFinanceAdminOrFinanceOwner || false;
  const isBudgetLeader = userAccessForCurrentBusinessGroup?.isBudgetLeader || false;
  const isBudgetOwner = userAccessForCurrentBusinessGroup?.isBudgetOwner || false;

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { businessGroupName } = useParams<{ businessGroupName: string }>();

  // This should be accessible to isFinanceAdminOrFinanceOwner
  const financeAdminOrLeaderNavigationItems: SideNavigationProps.Item[] = [
    {
      type: 'section',
      text: 'Finance Owner Tools',
      items: [
        { type: 'link', text: 'Access & Authorization', href: `/${businessGroupName}/access-authorization` },
        { type: 'link', text: 'Planning Cycle', href: `/${businessGroupName}/planning-cycle` },
        { type: 'link', text: 'Snapshots', href: `/${businessGroupName}/snapshots` },
        { type: 'link', text: 'Budget Owner Transfer', href: `/${businessGroupName}/budget-owner-transfer` },
        { type: 'link', text: 'Expense Type Mapping', href: `/${businessGroupName}/expense-type` },
        { type: 'link', text: 'Useful Life Mapping', href: `/${businessGroupName}/useful-life` }
      ]
    }
  ];

  // This should be accessible to isFinanceAdminOrFinanceOwner or isBudgetLeader or isBudgetOwner
  const budgetLeaderOrOwnerNavigationItems: SideNavigationProps.Item[] = [
    {
      type: 'section',
      text: 'Planning',
      items: [
        { type: 'link', text: 'Forecast Input', href: `/${businessGroupName}/forecast-input` },
        { type: 'link', text: 'PO Tagging', href: `/${businessGroupName}/po-tagging` }
      ]
    },
    {
      type: 'section',
      text: 'Reports',
      items: [
        {
          type: 'link',
          text: 'Forecast',
          href: `/${businessGroupName}/forecast-report`
        },
        {
          type: 'link',
          text: 'Variance',
          href: `/${businessGroupName}/variance-report`
        },
        {
          type: 'link',
          text: 'Actuals',
          href: `/${businessGroupName}/actuals-report`
        }
      ]
    }
  ];

  // If !isConsideredAsAdmin && !isBudgetOwner then don't show anything
  const sideNavigationItems = [
    ...(isFinanceAdminOrFinanceOwner ? financeAdminOrLeaderNavigationItems : []),
    ...(isFinanceAdminOrFinanceOwner || isBudgetLeader || isBudgetOwner ? budgetLeaderOrOwnerNavigationItems : [])
  ];

  const activeHref = findActiveHref(pathname, sideNavigationItems);

  // If no items should be displayed, return null
  if (sideNavigationItems.length === 0) {
    return null;
  }

  return (
    <SideNavigation
      activeHref={activeHref}
      header={{
        href: `/${businessGroupName}/forecast-input`,
        text: `${businessGroupName}`
      }}
      onFollow={(event) => {
        if (!event.detail.external) {
          event.preventDefault();
          navigate(event.detail.href);
        }
      }}
      items={sideNavigationItems}
    />
  );
};

export default BusinessGroupSideNavigation;
