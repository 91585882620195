import {
  Box,
  Button,
  FormField,
  Input,
  Modal,
  Multiselect,
  SelectProps,
  SpaceBetween,
  StatusIndicator,
  StatusIndicatorProps
} from '@amzn/awsui-components-react';
import { Form, Formik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import { useSelector } from 'react-redux';
import { updateAnnouncement } from 'src/api/app-sync-services';
import { useAuth } from 'src/app/auth/AuthContextProvider';
import { getActiveDataClassifications } from 'src/features/business-group/businessGroupSelectors';
import { Announcement } from 'src/models/AppContextModels';
import { Status } from 'src/models/AuthContextModels';
import { RootState } from 'src/store/store';
import {
  AnnouncementMessages,
  announcementSchema,
  extractDataClassificationOptions,
  formatAnnouncementData,
  getPublishToPlaceHolder
} from './AnnouncementUtils';
import { logUserClick } from 'src/analytics/KatalLogger';

interface AnnouncementsFormProps {
  showModal: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  announcements: Announcement[];
  selectedAnnouncementId: number | null;
}

export interface AnnouncementFormValues {
  data_classification: SelectProps.Option[];
  announcement_title: string;
  announcement_message: string;
}

export const AnnouncementsForm: React.FC<AnnouncementsFormProps> = ({ showModal, onCancel, onConfirm, announcements, selectedAnnouncementId }) => {
  const { userAlias: Alias } = useAuth();

  const businessGroupLoadingStatus = useSelector((state: RootState) => state.businessGroupStore.businessGroupLoadingStatus);
  const dataClassifications = useSelector(getActiveDataClassifications);

  const quillRef = useRef<ReactQuill | null>(null);

  const [currentDataClassificationsList, setCurrentDataClassificationsList] = useState<SelectProps.Options>([]);
  const [submissionStatus, setSubmissionStatus] = useState<string | null>(null);
  const [statusType, setStatusType] = useState<StatusIndicatorProps.Type | undefined>(undefined);

  useEffect(() => {
    if (businessGroupLoadingStatus === Status.Completed) {
      extractDataClassificationOptions(dataClassifications, setCurrentDataClassificationsList);
    }
  }, [businessGroupLoadingStatus, dataClassifications]);

  const [initialValues, setInitialValues] = useState<AnnouncementFormValues>({
    data_classification: [],
    announcement_title: '',
    announcement_message: ''
  });

  useEffect(() => {
    if (selectedAnnouncementId !== null) {
      const selectedAnnouncement = announcements.find((a) => a.announcement_id === selectedAnnouncementId);
      if (selectedAnnouncement) {
        setInitialValues({
          data_classification: selectedAnnouncement.data_classification.map((dc) => ({
            label: dc.data_classification_name,
            value: `${dc.data_classification_id}`
          })),
          announcement_title: selectedAnnouncement.announcement_title,
          announcement_message: selectedAnnouncement.announcement_message
        });
      }
    } else {
      setInitialValues({
        data_classification: [],
        announcement_title: '',
        announcement_message: ''
      });
    }
  }, [selectedAnnouncementId, announcements]);

  const handleCancel = () => {
    onCancel();
  };

  const handleSubmit = async (values: AnnouncementFormValues) => {
    setSubmissionStatus(AnnouncementMessages.SUBMITTING);
    setStatusType('in-progress');
    const selectedAnnouncement = announcements.find((a) => a.announcement_id === selectedAnnouncementId) || null;
    const formattedData = formatAnnouncementData(values, selectedAnnouncement, Alias);

    try {
      logUserClick(`Submitted new announcement`, 'Announcement Widget', 'Landing Page');
      await updateAnnouncement([formattedData]);
      setSubmissionStatus(AnnouncementMessages.SUBMITTED_SUCCESSFULLY);
      setStatusType('success');
      onConfirm();
    } catch (error) {
      setSubmissionStatus(AnnouncementMessages.SUBMIT_ERROR);
      setStatusType('error');
    }
  };

  return (
    <Formik initialValues={initialValues} validationSchema={announcementSchema} onSubmit={handleSubmit} enableReinitialize>
      {({ values, errors, touched, setFieldValue, dirty }) => (
        <Form id="announcementForm">
          <Modal
            onDismiss={handleCancel}
            visible={showModal}
            size="large"
            footer={
              <Box>
                <Box float="left">{submissionStatus && <StatusIndicator type={statusType}>{submissionStatus}</StatusIndicator>}</Box>
                <Box float="right">
                  <SpaceBetween direction="horizontal" size="xs">
                    <Button variant="link" onClick={handleCancel} disabled={statusType === 'in-progress'}>
                      Cancel
                    </Button>
                    <Button variant="primary" form="announcementForm" formAction="submit" disabled={statusType === 'in-progress' || !dirty}>
                      Submit
                    </Button>
                  </SpaceBetween>
                </Box>
              </Box>
            }
            header={selectedAnnouncementId ? 'Edit Announcement' : 'New Announcement'}
          >
            <Box className="announcement-box">
              <SpaceBetween size="m" direction="vertical" className="announcement-content">
                <SpaceBetween size="m" direction="vertical">
                  <SpaceBetween size="m" direction="horizontal">
                    {/* data_classification */}
                    <FormField
                      className="width-30-rem"
                      label="Publish to"
                      constraintText="Select the business groups that are applicable"
                      errorText={
                        touched.data_classification && typeof errors.data_classification === 'string' ? errors.data_classification : undefined
                      }
                    >
                      <Multiselect
                        selectedOptions={values.data_classification}
                        options={currentDataClassificationsList}
                        hideTokens
                        onChange={({ detail }) => setFieldValue('data_classification', detail.selectedOptions)}
                        placeholder={getPublishToPlaceHolder(values.data_classification, dataClassifications.length)}
                      />
                    </FormField>

                    {/* announcement_title  */}
                    <FormField
                      className="width-30-rem"
                      label="Announcement title"
                      constraintText=""
                      stretch
                      errorText={touched.announcement_title && errors.announcement_title}
                    >
                      <Input value={values.announcement_title} onChange={({ detail }) => setFieldValue('announcement_title', detail.value)} />
                    </FormField>
                  </SpaceBetween>

                  {/* announcement_message */}
                  <FormField label="Announcement Message" stretch errorText={touched.announcement_message && errors.announcement_message}>
                    <ReactQuill
                      ref={quillRef}
                      className="quill-editor"
                      theme="snow"
                      value={values.announcement_message}
                      onChange={(content) => setFieldValue('announcement_message', content)}
                    />
                  </FormField>
                </SpaceBetween>
              </SpaceBetween>
            </Box>
          </Modal>
        </Form>
      )}
    </Formik>
  );
};
