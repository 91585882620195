import { SelectProps } from '@amzn/awsui-components-react';
import { COST_CENTER_SEGMENT_NAME } from 'src/constants/corp-segment-constants';
import { CorpSegmentAccess, UserAccessEntity, UserAccessForm } from 'src/models/XptUsersModel';
import { getCurrentUTCTimeInISO } from 'src/utils/date-time-utilities';
import * as Yup from 'yup';

/**
 * Validation schema for the user access form.
 */
export const userAccessValidationSchema = Yup.object({
  user_alias: Yup.string().required('Alias is required'),
  user_name: Yup.string().required('Name is required'),
  user_type: Yup.string().required('User Type is required'),
  is_active: Yup.boolean().required('Status is required'),
  cost_centers: Yup.array().min(1, 'At least one cost center is required').required('Cost centers are required')
});

export const filterCostCenterOptions = (
  costCenterSegment: CorpSegmentAccess,
  costCenterOptionsAvailable: SelectProps.OptionGroup[]
): { valid: string[]; invalid: string[] } => {
  const availableOptions = costCenterOptionsAvailable.flatMap((option: SelectProps.OptionGroup) => option.options).map((option) => option.label);
  const valid = costCenterSegment.segment_value.filter((value) => availableOptions.includes(value));
  const invalid = costCenterSegment.segment_value.filter((value) => !availableOptions.includes(value));
  return { valid, invalid };
};

export const convertEntityToForm = (userAccessEntity: UserAccessEntity, costCenterOptionsAvailable: SelectProps.OptionGroup[]): UserAccessForm => {
  const {
    access_id,
    data_classification_id,
    user_alias,
    user_name,
    user_type,
    is_active,
    created_at,
    created_by,
    updated_at,
    updated_by,
    corp_segment_access
  } = userAccessEntity;

  const costCenterSegment = Array.isArray(corp_segment_access)
    ? corp_segment_access.find((segment) => segment.segment_name.toLowerCase() === COST_CENTER_SEGMENT_NAME)
    : null;

  let cost_centers: string[] = [];
  let not_applicable_cost_centers: string[] = [];

  if (costCenterSegment) {
    const { valid, invalid } = filterCostCenterOptions(costCenterSegment, costCenterOptionsAvailable);
    cost_centers = valid;
    not_applicable_cost_centers = invalid;
  }

  return {
    access_id,
    data_classification_id,
    user_alias,
    is_valid_user_alias: true,
    user_name,
    user_type,
    cost_centers,
    not_applicable_cost_centers,
    is_active,
    created_at,
    created_by,
    updated_at,
    updated_by
  };
};

export const convertFormToEntityValues = (userAlias: string, formValues: UserAccessForm, selectedUser: UserAccessEntity | null): UserAccessEntity => {
  const costCenterSegment = {
    segment_name: COST_CENTER_SEGMENT_NAME,
    segment_value: formValues.cost_centers.concat(formValues.not_applicable_cost_centers)
  };

  return {
    access_id: formValues.access_id,
    data_classification_id: formValues.data_classification_id,
    user_alias: formValues.user_alias,
    user_name: formValues.user_name,
    user_type: formValues.user_type,
    is_active: formValues.is_active,

    created_at: selectedUser ? formValues.created_at : getCurrentUTCTimeInISO(),
    created_by: selectedUser ? formValues.created_by : userAlias,
    updated_at: getCurrentUTCTimeInISO(),
    updated_by: userAlias,
    corp_segment_access: [costCenterSegment]
  } as UserAccessEntity;
};
