import { Box, Link, Popover } from '@amzn/awsui-components-react';
import React from 'react';

const MAX_VISIBLE_COST_CENTERS = 3;
const POPOVER_MAX_HEIGHT = 200; // in pixels

const CostCentersCell: React.FC<{ costCenters: string[] | null }> = ({ costCenters }) => {
  if (!costCenters || costCenters.length === 0) {
    return <span>-</span>;
  }

  const visibleContent = costCenters.slice(0, MAX_VISIBLE_COST_CENTERS).join(', ');
  const hasMore = costCenters.length > MAX_VISIBLE_COST_CENTERS;

  return (
    <Popover
      dismissButton={false}
      position="top"
      size="medium"
      triggerType="custom"
      content={
        <Box padding="m">
          <div style={{ maxHeight: `${POPOVER_MAX_HEIGHT}px`, overflowY: 'auto' }}>
            <ul style={{ padding: '0 20px 0 20px', margin: 0 }}>
              {costCenters.map((cc, index) => (
                <li key={index}>{cc}</li>
              ))}
            </ul>
          </div>
        </Box>
      }
    >
      <Link variant="primary" fontSize="body-s">
        {visibleContent}
        {hasMore && (
          <span style={{ marginLeft: '4px', color: 'var(--color-text-interactive-default)' }}>
            (+{costCenters.length - MAX_VISIBLE_COST_CENTERS} more...)
          </span>
        )}
      </Link>
    </Popover>
  );
};

export default CostCentersCell;
