import { BreadcrumbGroupProps, TableProps } from '@amzn/awsui-components-react';
import { UsefulLifeMappingEntity } from 'src/models/xPTMappingModels';
import { DynamicColumnDetails } from 'src/models/XptTableModel';
import { formatUTCAsLocalReadable, dateTimeComparator } from 'src/utils/date-time-utilities';
import * as Yup from 'yup';

export const getUsefulLifeBreadcrumbItems = (
  businessGroupBaseBreadcrumbs: BreadcrumbGroupProps.Item[],
  currentBusinessGroupName?: string
): BreadcrumbGroupProps.Item[] => {
  if (!currentBusinessGroupName) {
    return businessGroupBaseBreadcrumbs;
  }

  return [
    ...businessGroupBaseBreadcrumbs,
    {
      text: 'Useful Life',
      href: `/${currentBusinessGroupName}/useful-life`
    }
  ];
};

export const getUsefulLifeAllColumns = (entityName: string): DynamicColumnDetails => {
  return {
    columnInfo: {
      entityName: entityName,
      trackBy: 'useful_life_id',
      sortingColumn: 'updated_at',
      isDescending: true
    },
    data: [
      {
        fieldName: 'useful_life_id',
        displayName: 'Useful Life Id',
        canBeHiddenFromProperties: true,
        isShownByDefault: false,
        isFilterable: false,
        metadata: {
          type: 'number'
        }
      },
      {
        fieldName: 'data_classification_id',
        displayName: 'Data Classification Id',
        canBeHiddenFromProperties: true,
        isShownByDefault: false,
        isFilterable: false,
        metadata: {
          type: 'number'
        }
      },
      {
        fieldName: 'cost_center_code',
        displayName: 'Cost Center Code',
        canBeHiddenFromProperties: false,
        isShownByDefault: true,
        isFilterable: true,
        metadata: {
          type: 'string'
        }
      },
      {
        fieldName: 'expense_type',
        displayName: 'Expense Type',
        canBeHiddenFromProperties: false,
        isShownByDefault: true,
        isFilterable: true,
        metadata: {
          type: 'string'
        }
      },
      {
        fieldName: 'useful_life',
        displayName: 'Useful Life',
        canBeHiddenFromProperties: false,
        isShownByDefault: true,
        isFilterable: true,
        metadata: {
          type: 'number'
        }
      },
      {
        fieldName: 'updated_by',
        displayName: 'Updated By',
        canBeHiddenFromProperties: true,
        isShownByDefault: true,
        isFilterable: true,
        metadata: {
          type: 'string'
        }
      },
      {
        fieldName: 'updated_at',
        displayName: 'Updated At',
        canBeHiddenFromProperties: true,
        isShownByDefault: true,
        isFilterable: true,
        metadata: {
          type: 'dateTime'
        }
      }
    ]
  };
};

export const getUsefulLifeColumnDefs = (): TableProps.ColumnDefinition<UsefulLifeMappingEntity>[] => [
  {
    id: 'useful_life_id',
    header: 'Useful Life Id',
    cell: (item: UsefulLifeMappingEntity) => item.useful_life_id
  },
  {
    id: 'data_classification_id',
    header: 'Dat Classification Id',
    cell: (item: UsefulLifeMappingEntity) => item.data_classification_id
  },
  {
    id: 'cost_center_code',
    header: 'Cost Center Code',
    cell: (item: UsefulLifeMappingEntity) => item.cost_center_code,
    isRowHeader: true,
    minWidth: 100,
    sortingField: 'cost_center_code'
  },
  {
    id: 'expense_type',
    header: 'Expense Type',
    cell: (item: UsefulLifeMappingEntity) => item.expense_type,
    minWidth: 100,
    sortingField: 'expense_type'
  },
  {
    id: 'useful_life',
    header: 'Useful Life',
    cell: (item: UsefulLifeMappingEntity) => item.useful_life,
    minWidth: 100,
    sortingField: 'useful_life'
  },
  {
    id: 'updated_by',
    header: 'Updated By',
    cell: (item: UsefulLifeMappingEntity) => item.updated_by,
    minWidth: 120,
    sortingField: 'updated_by'
  },
  {
    id: 'updated_at',
    header: 'Updated At',
    cell: (item: UsefulLifeMappingEntity) => formatUTCAsLocalReadable(item.updated_at),
    minWidth: 120,
    sortingField: 'updated_at'
  }
];

export const usefulLifeMappingValidationSchema = (usefulLifeMappings: UsefulLifeMappingEntity[]) =>
  Yup.object()
    .shape({
      useful_life_id: Yup.number().nullable(true),

      data_classification_id: Yup.number().nullable().required('Data Classification ID is required'),

      cost_center_code: Yup.string().required('Cost center code is required'),

      expense_type: Yup.string().required('Expense type is required'),

      useful_life: Yup.number().nullable().required('Useful life is required')
    })
    .test('unique-combination', function (value) {
      const { cost_center_code, expense_type } = value;
      const isDuplicate = usefulLifeMappings
        .filter((mapping) => mapping.useful_life_id !== value.useful_life_id)
        .some((mapping) => mapping.cost_center_code === cost_center_code && mapping.expense_type === expense_type);

      if (isDuplicate) {
        return this.createError({
          path: 'cost_center_code',
          message: 'A mapping with the same cost center code and expense type already exists.'
        });
      }

      return true;
    });
