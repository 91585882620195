import { Alert, Button, ButtonDropdown, FlashbarProps } from '@amzn/awsui-components-react';
import React from 'react';

export const GridErrorAlert: React.FC = () => (
  <Alert type="error" dismissible={false} header="Error" action={<Button onClick={() => window.location.reload()}>Reload</Button>}>
    Unable to load grid data, please try reloading.
  </Alert>
);

interface PersistChangesButtonDropdownProps {
  isLoading: boolean;
  handleResetView: () => void;
  saveGridState: () => void;
  notificationMessage: (content: string, flashBarType: FlashbarProps.Type, isDismissible: boolean, messageId?: string) => void;
}

export const PersistChangesButtonDropdown: React.FC<PersistChangesButtonDropdownProps> = ({
  isLoading,
  handleResetView,
  saveGridState,
  notificationMessage
}) => (
  <ButtonDropdown
    items={[
      {
        id: 'reset-view',
        text: 'Reset to default view',
        disabled: isLoading
      }
    ]}
    onItemClick={(e) => {
      if (e.detail.id === 'reset-view') {
        handleResetView();
      }
    }}
    disabled={isLoading}
    mainAction={{
      text: 'Save current view',
      onClick: () => {
        saveGridState();
        notificationMessage('View saved successfully', 'success', true);
      },
      disabled: isLoading
    }}
    variant="normal"
  />
);
