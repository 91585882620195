import { Box, HelpPanel, Link, SpaceBetween } from '@amzn/awsui-components-react';
import React from 'react';

export const PlanningCycleNonAdminInfoPanel: React.FC = () => {
  return (
    <HelpPanel header={<h2>Planning Cycle</h2>}>
      <SpaceBetween size="l">
        <Box variant="p">
          This page is accessible only to users with the <strong>Finance Admin</strong> or <strong>Finance Owner</strong> roles.
        </Box>

        <Box variant="p">
          Administrators can set specific deadlines for Budget Leaders and Budget Owners. These deadlines must be scheduled prior to the overall
          Planning Cycle Window closure.
        </Box>

        <SpaceBetween size="xs">
          <h3>Prophecy Integration</h3>
          <Box variant="p">The "Push to Prophecy" toggle controls data synchronization:</Box>
          <ul>
            <li>
              <strong>Enabled:</strong> Allows Prophecy to retrieve data during its hourly scheduled job.
            </li>
            <li>
              <strong>Disabled:</strong> When you disable the “Push to Prophecy” button, we automatically take a snapshot of your data at that moment.
              This snapshot will be synced to Prophecy.
              <p>
                <strong>Note:</strong>There is also a feature in our application called “Snapshot,” which allows users to manually take snapshots of
                data. These two types of snapshots are different from each other.
              </p>
            </li>
          </ul>
          <Box variant="p">The "Last synced with Prophecy" field displays the timestamp of the most recent successful synchronization.</Box>
        </SpaceBetween>
      </SpaceBetween>
    </HelpPanel>
  );
};
