import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  getAllPlanningCycles,
  getAllPlanningCycleSnapshots,
  getForecastingPlanningCycles,
  getScenarios,
  planningCycleFormMutation
} from 'src/api/app-sync-services';
import { Status } from 'src/models/AuthContextModels';
import { ForecastPlanningCycleIds, MasterScenarios, PlanningCycleEntity, PlanningCycleSnapshot } from 'src/models/PlanningCycleModel';
import { getForecastS3BucketName } from 'src/utils/xpt-s3-bucket-details';

// State interface definition for the planning cycle feature.
interface PlanningCycleState {
  scenarios: MasterScenarios[];

  planningCyclesLoadingStatus: Status;
  planningCycles: PlanningCycleEntity[];

  forecastPlanningCyclesStatus: Status;
  forecastPlanningCycles: ForecastPlanningCycleIds[];

  planningCycleSnapshotsStatus: Status;
  planningCycleSnapshots: PlanningCycleSnapshot[];

  updateStatus: 'idle' | 'loading' | 'succeeded' | 'failed';
  updateError: string | null;
}

// Initial state for the planning cycle slice.
const initialState: PlanningCycleState = {
  scenarios: [],

  planningCyclesLoadingStatus: Status.NotInitiated,
  planningCycles: [],

  forecastPlanningCyclesStatus: Status.NotInitiated,
  forecastPlanningCycles: [],

  planningCycleSnapshotsStatus: Status.NotInitiated,
  planningCycleSnapshots: [],

  updateStatus: 'idle',
  updateError: null
};

// Async thunk for fetching planning cycles from the backend.
export const fetchPlanningCycles = createAsyncThunk('planningCycle/fetchPlanningCycles', async (_, { rejectWithValue }) => {
  try {
    const allPlanningCycles = await getAllPlanningCycles();
    const listPlanningCyclesMapped = allPlanningCycles.map((planningCycle: PlanningCycleEntity) => {
      return {
        ...planningCycle,
        scenario_year: `${planningCycle.scenario.planning_cycle_name}_${planningCycle.planning_cycle_year}`,
        export_s3_bucket: getForecastS3BucketName().bucketName
      };
    });
    return listPlanningCyclesMapped;
  } catch (error) {
    return rejectWithValue('Failed to fetch planning cycles');
  }
});

// Async thunk for fetching forecast planning cycles.
export const fetchForecastPlanningCycles = createAsyncThunk('planningCycle/fetchForecastPlanningCycles', async (_, { rejectWithValue }) => {
  try {
    const forecastPlanningCycles: ForecastPlanningCycleIds[] = await getForecastingPlanningCycles();
    return forecastPlanningCycles;
  } catch (error) {
    return rejectWithValue('Failed to fetch forecast planning cycles');
  }
});

// Async thunk for fetching scenarios related to planning cycles.
export const fetchScenarios = createAsyncThunk('planningCycle/fetchScenarios', async (_, { rejectWithValue }) => {
  try {
    const scenarios = await getScenarios();
    return scenarios;
  } catch (error) {
    return rejectWithValue('Failed to fetch scenarios');
  }
});

// Async thunk for fetching scenarios related to planning cycles.
export const fetchSnapshots = createAsyncThunk('planningCycle/fetchSnapshots', async (_, { rejectWithValue }) => {
  try {
    const planningCycleSnapshots: PlanningCycleSnapshot[] = await getAllPlanningCycleSnapshots();
    return planningCycleSnapshots;
  } catch (error) {
    return rejectWithValue('Failed to fetch scenarios');
  }
});

// Async thunk for planning cycle form submission
export const submitPlanningCycle = createAsyncThunk(
  'planningCycle/submitPlanningCycle',
  async (planningCycleForm: PlanningCycleEntity[], { rejectWithValue }) => {
    try {
      const response = await planningCycleFormMutation(planningCycleForm);
      return response;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data || 'Unable to update planning cycle');
    }
  }
);

const planningCycleSlice = createSlice({
  name: 'planningCycle',
  initialState,
  reducers: {
    // Place for reducers handling synchronous actions.
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPlanningCycles.pending, (state) => {
        state.planningCyclesLoadingStatus = Status.Loading;
      })
      .addCase(fetchPlanningCycles.fulfilled, (state, action) => {
        state.planningCycles = action.payload;
        state.planningCyclesLoadingStatus = Status.Completed;
      })
      .addCase(fetchPlanningCycles.rejected, (state) => {
        state.planningCyclesLoadingStatus = Status.Failed;
      })
      .addCase(fetchScenarios.fulfilled, (state, action) => {
        state.scenarios = action.payload;
      })
      .addCase(fetchScenarios.rejected, (state) => {})
      .addCase(fetchSnapshots.pending, (state) => {
        state.planningCycleSnapshotsStatus = Status.Loading;
      })
      .addCase(fetchSnapshots.fulfilled, (state, action) => {
        state.planningCycleSnapshots = action.payload;
        state.planningCycleSnapshotsStatus = Status.Completed;
      })
      .addCase(fetchSnapshots.rejected, (state) => {
        state.planningCycleSnapshots = [];
        state.planningCycleSnapshotsStatus = Status.Failed;
      })
      .addCase(submitPlanningCycle.pending, (state) => {
        state.updateStatus = 'loading';
      })
      .addCase(submitPlanningCycle.fulfilled, (state, action) => {
        const updatedPlanningCycles: PlanningCycleEntity[] = action.payload;
        state.updateStatus = 'succeeded';
      })
      .addCase(submitPlanningCycle.rejected, (state, action: PayloadAction<any>) => {
        state.updateStatus = 'failed';
        state.updateError = action.payload;
      })
      .addCase(fetchForecastPlanningCycles.pending, (state) => {
        state.forecastPlanningCyclesStatus = Status.Loading;
      })
      .addCase(fetchForecastPlanningCycles.fulfilled, (state, action) => {
        state.forecastPlanningCycles = action.payload;
        state.forecastPlanningCyclesStatus = Status.Completed;
      })
      .addCase(fetchForecastPlanningCycles.rejected, (state) => {
        state.forecastPlanningCyclesStatus = Status.Failed;
      });
  }
});

export default planningCycleSlice.reducer;
