import { Box, Container, ContentLayout, Flashbar, FormField, Header, Link, Select, SelectProps, SpaceBetween } from '@amzn/awsui-components-react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { XptAppLayout } from 'src/components/common/xpt-app-layout/XptAppLayout';
import { XPTBreadcrumbs } from 'src/components/common/XptBreadcrumb';
import { CorpSegmentNames } from 'src/constants/corp-segment-constants';
import { SELECT_ALL_OPTION } from 'src/constants/generic-constants';
import { getUserCostCenters } from 'src/features/business-group/access-authorization/redux/AccessAuthorizationUtils';
import {
  businessGroupBaseBreadcrumbs,
  currentBusinessGroupName,
  currentBusinessGroupShortDesc
} from 'src/features/business-group/businessGroupSelectors';
import BusinessGroupSideNavigation from 'src/features/xpt-layout/XptBusinessGroupSideNavigation';
import { useFlashbar } from 'src/hooks/useFlashbar';
import { Status } from 'src/models/AuthContextModels';
import { RootState } from 'src/store/store';

import { getLocalStorageItem, setLocalStorageItem } from 'src/hooks/useLocalStorage';
import { getXptActualsReportsBreadcrumbItems } from './XptActualsReportConfig';
import { XptActualsReportGrid } from './XptActualsReportGrid';
import { XptActualsReportInfoPanel } from './XptActualsReportInfoPanel';
import { logUserClick } from 'src/analytics/KatalLogger';

export const XptActualsReport: React.FC = () => {
  const appLayout = useRef<any>();
  const { flashbarItems, displayFlashMessage } = useFlashbar();

  const { userAccessForCurrentBusinessGroup, userAccessForCurrentBusinessGroupStatus } = useSelector(
    (state: RootState) => state.xptAccessAndAuthorizationStore
  )!;

  const businessGroupBaseBreadcrumb = useSelector(businessGroupBaseBreadcrumbs);
  const businessGroupName = useSelector(currentBusinessGroupName);
  const businessGroupShortDesc = useSelector(currentBusinessGroupShortDesc);

  const localStoragePrefix = `actuals_report_filters`;
  const actualsFilterCostCenterKey = `${localStoragePrefix}_${businessGroupShortDesc}_cc`;

  const { masterCorpSegmentDropdownValues, masterCorpSegmentDropdownValuesStatus } = useSelector((state: RootState) => state.corpSegmentsStore);
  const [toolsOpen, setToolsOpen] = useState(false);

  const [costCenterOptions, setCostCenterOptions] = useState<SelectProps.OptionGroup[]>([]);
  const [selectedCostCenter, setSelectedCostCenter] = useState<SelectProps.Option | null>(null);

  const initializeCostCenters = useCallback(() => {
    if (
      businessGroupShortDesc &&
      userAccessForCurrentBusinessGroupStatus === Status.Completed &&
      masterCorpSegmentDropdownValuesStatus === Status.Completed &&
      userAccessForCurrentBusinessGroup
    ) {
      const userCCs = getUserCostCenters(userAccessForCurrentBusinessGroup);
      const masterCostCenterDropdowns = masterCorpSegmentDropdownValues.find(
        (corpSegment) => corpSegment.masterCorpSegmentDisplayName === CorpSegmentNames.COST_CENTER
      );
      const authorizedCostCenterDropdowns =
        masterCostCenterDropdowns?.masterCorpSegmentDropdownValues.filter((segmentDropdown) => userCCs.includes(segmentDropdown.value)) || [];

      setCostCenterOptions([{ ...SELECT_ALL_OPTION, options: authorizedCostCenterDropdowns }]);
      return authorizedCostCenterDropdowns;
    }
    return [];
  }, [
    businessGroupShortDesc,
    userAccessForCurrentBusinessGroupStatus,
    masterCorpSegmentDropdownValuesStatus,
    userAccessForCurrentBusinessGroup,
    masterCorpSegmentDropdownValues
  ]);

  const initializeSelectedCostCenter = useCallback(
    (authorizedCostCenterDropdowns: SelectProps.Options) => {
      const storedCostCenter = getLocalStorageItem<SelectProps.Option | null>(actualsFilterCostCenterKey);
      if (storedCostCenter && authorizedCostCenterDropdowns.some((cc) => cc.value === storedCostCenter.value)) {
        return storedCostCenter;
      } else if (authorizedCostCenterDropdowns.length > 0) {
        const newSelectedCostCenter = authorizedCostCenterDropdowns[0];
        setLocalStorageItem(actualsFilterCostCenterKey, newSelectedCostCenter);
        return newSelectedCostCenter;
      }
      return null;
    },
    [actualsFilterCostCenterKey]
  );

  useEffect(() => {
    const authorizedCostCenterDropdowns = initializeCostCenters();
    const initialSelectedCostCenter = initializeSelectedCostCenter(authorizedCostCenterDropdowns);
    setSelectedCostCenter(initialSelectedCostCenter);
  }, [initializeCostCenters, initializeSelectedCostCenter]);

  const onCostCenterChange = useCallback(
    (selectedOption: SelectProps.Option) => {
      logUserClick(`Cost Center filter change`, 'Actuals Report', businessGroupName);
      setSelectedCostCenter(selectedOption);
      setLocalStorageItem(actualsFilterCostCenterKey, selectedOption);
    },
    [actualsFilterCostCenterKey]
  );

  return (
    <>
      <XptAppLayout
        ref={appLayout}
        headerSelector="#h"
        navigation={<BusinessGroupSideNavigation />}
        tools={<XptActualsReportInfoPanel />}
        toolsOpen={toolsOpen}
        onToolsChange={({ detail }) => setToolsOpen(detail.open)}
        breadcrumbs={<XPTBreadcrumbs items={getXptActualsReportsBreadcrumbItems(businessGroupBaseBreadcrumb, businessGroupName)} />}
        notifications={<Flashbar stackItems items={flashbarItems} />}
        stickyNotifications={true}
        maxContentWidth={Number.MAX_VALUE}
        contentType="default"
        content={
          <ContentLayout
            disableOverlap
            header={
              <Header
                info={
                  <Link variant="info" onFollow={() => setToolsOpen(true)}>
                    Info
                  </Link>
                }
              >{`Actuals Report`}</Header>
            }
          >
            <Container disableHeaderPaddings disableContentPaddings>
              <Box padding={{ top: 's', right: 's', bottom: 'm', left: 's' }}>
                <SpaceBetween size="m" direction="horizontal">
                  <FormField label="Cost Centers" className="width-25-rem">
                    <Select
                      options={costCenterOptions}
                      selectedOption={selectedCostCenter}
                      onChange={({ detail }) => onCostCenterChange(detail.selectedOption)}
                      expandToViewport
                      filteringType="auto"
                      errorText={masterCorpSegmentDropdownValuesStatus === Status.Failed ? 'Cost Center loading failed' : undefined}
                      loadingText={masterCorpSegmentDropdownValuesStatus === Status.Loading ? 'Cost Centers loading' : undefined}
                      placeholder={costCenterOptions.length === 0 ? 'No cost centers available' : 'Select a cost center'}
                    />
                  </FormField>
                </SpaceBetween>
              </Box>
            </Container>

            <Box padding={{ top: 's' }}>
              <XptActualsReportGrid selectedCostCenter={selectedCostCenter} notifyFlashMessage={displayFlashMessage} />
            </Box>
          </ContentLayout>
        }
      />
    </>
  );
};
