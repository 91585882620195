import { Alert, Box, SpaceBetween } from '@amzn/awsui-components-react';
import React from 'react';

interface POTaggingUploadGuidelinesProps {}

const POTaggingUploadGuidelines: React.FC<POTaggingUploadGuidelinesProps> = () => {
  return (
    <SpaceBetween size="xs" direction="vertical">
      <Box variant="h4">Excel Upload Guidelines</Box>
      <Alert type="warning">Important: Do not modify column headers in the template. Doing so may result in upload failure.</Alert>
      <Box variant="p">
        <strong>Editable Content:</strong> Only modify the 'Line Item ID' column. All other columns should remain unchanged.
      </Box>
      <Box variant="p">
        <strong>File Usage:</strong> After making changes and uploading the Excel file, you cannot reuse the same file for subsequent uploads. Always
        download the latest version of the file for any new modifications.
      </Box>
      <Box variant="p">
        <strong>Best Practice:</strong> For each upload session:
        <ol>
          <li>Download a fresh copy of the Excel file</li>
          <li>Make necessary changes</li>
          <li>Upload the modified file</li>
        </ol>
      </Box>
      <Box variant="p">Following these guidelines ensures data integrity and prevents potential errors in the upload process.</Box>
    </SpaceBetween>
  );
};

export default POTaggingUploadGuidelines;
