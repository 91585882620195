import { Box, BoxProps, Button, Popover, SpaceBetween } from '@amzn/awsui-components-react';
import React from 'react';
import { useSelector } from 'react-redux';
import { LastSyncProphecyEntity } from 'src/models/PlanningCycleModel';
import { RootState } from 'src/store/store';
import { formatUTCAsLocalReadable } from 'src/utils/date-time-utilities';
import useLockStatus from './hooks/useLockStatus';

const ForecastTemplateStatusIndicator: React.FC = () => {
  const { userAccessForCurrentBusinessGroup } = useSelector((state: RootState) => state.xptAccessAndAuthorizationStore);
  const { currentPlanningCycle } = useSelector((state: RootState) => state.forecastTemplateStore);

  const isFinanceAdminOrFinanceOwner = userAccessForCurrentBusinessGroup?.isFinanceAdminOrFinanceOwner || false;
  const lockStatus = useLockStatus(currentPlanningCycle, isFinanceAdminOrFinanceOwner);

  if (!currentPlanningCycle) return null;

  return (
    <SpaceBetween size="m" direction="horizontal">
      <LastSyncPopover lastSyncData={currentPlanningCycle.last_synced_to_prophecy} />
      <ScenarioButton scenarioName={currentPlanningCycle.scenario?.planning_cycle_name} scenarioYear={currentPlanningCycle.scenario_year} />
      <LockStatusPopover lockStatus={lockStatus} />
    </SpaceBetween>
  );
};

interface ScenarioButtonProps {
  scenarioName?: string;
  scenarioYear?: string;
}

const ScenarioButton: React.FC<ScenarioButtonProps> = ({ scenarioName, scenarioYear }) => <Button>{`Scenario: ${scenarioYear || 'N/A'}`}</Button>;

interface LastSyncPopoverProps {
  lastSyncData: LastSyncProphecyEntity[] | null;
}

const LastSyncPopover: React.FC<LastSyncPopoverProps> = ({ lastSyncData }) => (
  <Popover
    dismissButton={false}
    position="top"
    size="large"
    triggerType="custom"
    content={
      <Box padding="s">
        {lastSyncData && lastSyncData.length > 0 ? (
          lastSyncData.map((item, index) => (
            <div key={index}>
              <Box color={'text-status-success'}>
                <strong>{item.load_type}</strong>: {formatUTCAsLocalReadable(item.last_synced_to_prophecy)}
              </Box>
            </div>
          ))
        ) : (
          <div>No sync data available</div>
        )}
      </Box>
    }
  >
    <Button>Last sync with Prophecy</Button>
  </Popover>
);

interface ScenarioButtonProps {
  scenarioName?: string;
}

interface LockStatusPopoverProps {
  lockStatus: ReturnType<typeof useLockStatus>;
}

const LockStatusPopover: React.FC<LockStatusPopoverProps> = ({ lockStatus }) => {
  const { isCycleLocked, buttonText, popOverText, statusType } = lockStatus;

  const iconName = isCycleLocked ? 'lock-private' : 'unlocked';

  const buttonClassName = `${statusType}-status-button`;
  const textColor: BoxProps.Color = `text-status-${statusType || 'info'}`;

  return (
    <Popover dismissButton={false} position="top" size="large" triggerType="custom" content={<Box color={textColor}>{popOverText}</Box>}>
      <Button iconAlign="left" className={buttonClassName} iconName={iconName}>
        {buttonText}
      </Button>
    </Popover>
  );
};

export default ForecastTemplateStatusIndicator;
