/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from '../API';
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const createBusinessGroup = /* GraphQL */ `mutation CreateBusinessGroup($dataClassificationMDJson: BusinessGroupInput) {
  createBusinessGroup(dataClassificationMDJson: $dataClassificationMDJson) {
    data_classification {
      data_classification_id
      data_classification_name
      data_classification_short_description
      data_classification_description
      
    }
    corp_segments {
      corp_segment_id
      corp_segment_name
      corp_segment_name_key
      corp_segment_data_type
      corp_segment_required
      corp_segment_default_value {
        segment_hierarchy
        segment_description
        
      }
      corp_segment_values_s3_path
      
    }
    business_segments {
      business_segment_id
      is_id_column
      business_segment_name
      business_segment_name_key
      business_segment_data_type
      business_segment_values_s3_path
      
    }
    status
    item_metadata {
      is_active
      created_by
      created_at
      updated_by
      updated_at
      
    }
    
  }
}
` as GeneratedMutation<APITypes.CreateBusinessGroupMutationVariables, APITypes.CreateBusinessGroupMutation>;
export const createPlanningCycles = /* GraphQL */ `mutation CreatePlanningCycles($planningCycleJson: [PlanningCycleInput]) {
  createPlanningCycles(planningCycleJson: $planningCycleJson) {
    scenario_seq_id
    planning_cycle_year
    baseline_scenario {
      baseline_scenario_seq_id
      baseline_scenario_name
      
    }
    scenario {
      scenario_id
      scenario_name
      planning_cycle_name
      
    }
    is_active
    actuals_start_month_id
    actuals_end_month_id
    forecast_start_month_id
    forecast_end_month_id
    prophecy_forecast_start_month_id
    prophecy_forecast_end_month_id
    prophecy_actuals_start_month_id
    prophecy_actuals_end_month_id
    data_classification {
      data_classification_id
      data_classification_name
      data_classification_short_description
      data_classification_description
      
    }
    created_at
    created_by
    updated_at
    updated_by
    planning_cycle_open_date
    planning_cycle_lock_date
    budget_owner_lock_date
    forecast_data_available
    is_prophecy_enabled
    last_synced_to_prophecy {
      load_type
      last_synced_to_prophecy
      
    }
    prophecy_created_at
    prophecy_created_by
    
  }
}
` as GeneratedMutation<APITypes.CreatePlanningCyclesMutationVariables, APITypes.CreatePlanningCyclesMutation>;
export const createScenarioSnapshot = /* GraphQL */ `mutation CreateScenarioSnapshot($scenarioSnapshotJson: ScenarioSnapshotInput) {
  createScenarioSnapshot(scenarioSnapshotJson: $scenarioSnapshotJson) {
    scenario_name
    scenario_snapshot_id
    scenario_snapshot_name
    scenario_snapshot_note
    scenario_seq_id
    planning_cycle_name
    planning_cycle_year
    scenario_snapshot_time
    created_at
    updated_at
    created_by
    updated_by
    status
    
  }
}
` as GeneratedMutation<APITypes.CreateScenarioSnapshotMutationVariables, APITypes.CreateScenarioSnapshotMutation>;
export const updateForecastDetails = /* GraphQL */ `mutation UpdateForecastDetails($forecastDetailsJson: ForecastDetailsInput) {
  updateForecastDetails(forecastDetailsJson: $forecastDetailsJson) {
    numberOfRecordsUpdated
    
  }
}
` as GeneratedMutation<APITypes.UpdateForecastDetailsMutationVariables, APITypes.UpdateForecastDetailsMutation>;
export const createOrUpdateContactDetails = /* GraphQL */ `mutation CreateOrUpdateContactDetails($input: [ContactDetailsInput]) {
  createOrUpdateContactDetails(input: $input) {
    numberOfRecordsUpdated
    
  }
}
` as GeneratedMutation<APITypes.CreateOrUpdateContactDetailsMutationVariables, APITypes.CreateOrUpdateContactDetailsMutation>;
export const createOrUpdateResourcesDetails = /* GraphQL */ `mutation CreateOrUpdateResourcesDetails($input: [ResourcesDetailsInput]) {
  createOrUpdateResourcesDetails(input: $input) {
    numberOfRecordsUpdated
    
  }
}
` as GeneratedMutation<APITypes.CreateOrUpdateResourcesDetailsMutationVariables, APITypes.CreateOrUpdateResourcesDetailsMutation>;
export const createOrUpdateAnnouncementDetails = /* GraphQL */ `mutation CreateOrUpdateAnnouncementDetails($input: [AnnouncementDetailsInput]) {
  createOrUpdateAnnouncementDetails(input: $input) {
    numberOfRecordsUpdated
    
  }
}
` as GeneratedMutation<APITypes.CreateOrUpdateAnnouncementDetailsMutationVariables, APITypes.CreateOrUpdateAnnouncementDetailsMutation>;
export const updatePOActualsDetails = /* GraphQL */ `mutation UpdatePOActualsDetails($input: POActualsDetailsInput) {
  updatePOActualsDetails(input: $input) {
    numberOfRecordsUpdated
    
  }
}
` as GeneratedMutation<APITypes.UpdatePOActualsDetailsMutationVariables, APITypes.UpdatePOActualsDetailsMutation>;
export const createOrUpdateCapexActualsDetails = /* GraphQL */ `mutation CreateOrUpdateCapexActualsDetails($input: CapexActualsDetailsInput) {
  createOrUpdateCapexActualsDetails(input: $input) {
    numberOfRecordsUpdated
    total_expense_amount
    
  }
}
` as GeneratedMutation<APITypes.CreateOrUpdateCapexActualsDetailsMutationVariables, APITypes.CreateOrUpdateCapexActualsDetailsMutation>;
export const createOrUpdateUsefulLifeDetails = /* GraphQL */ `mutation CreateOrUpdateUsefulLifeDetails($input: [UsefulLifeDetailsInput]) {
  createOrUpdateUsefulLifeDetails(input: $input) {
    numberOfRecordsUpdated
    
  }
}
` as GeneratedMutation<APITypes.CreateOrUpdateUsefulLifeDetailsMutationVariables, APITypes.CreateOrUpdateUsefulLifeDetailsMutation>;
export const createOrUpdateExpenseTypeDetails = /* GraphQL */ `mutation CreateOrUpdateExpenseTypeDetails($input: [ExpenseTypeDetailsInput]) {
  createOrUpdateExpenseTypeDetails(input: $input) {
    numberOfRecordsUpdated
    
  }
}
` as GeneratedMutation<APITypes.CreateOrUpdateExpenseTypeDetailsMutationVariables, APITypes.CreateOrUpdateExpenseTypeDetailsMutation>;
export const createOrUpdateCommodityDetails = /* GraphQL */ `mutation CreateOrUpdateCommodityDetails($input: [CommodityDetailsInput]) {
  createOrUpdateCommodityDetails(input: $input) {
    numberOfRecordsUpdated
    
  }
}
` as GeneratedMutation<APITypes.CreateOrUpdateCommodityDetailsMutationVariables, APITypes.CreateOrUpdateCommodityDetailsMutation>;
export const updateBudgetOwnership = /* GraphQL */ `mutation UpdateBudgetOwnership($input: BudgetOwnershipTransferInput) {
  updateBudgetOwnership(input: $input) {
    numberOfRecordsUpdated
    
  }
}
` as GeneratedMutation<APITypes.UpdateBudgetOwnershipMutationVariables, APITypes.UpdateBudgetOwnershipMutation>;
export const createExportForecastDetails = /* GraphQL */ `mutation CreateExportForecastDetails($input: ForecastDetailsInput) {
  createExportForecastDetails(input: $input) {
    numberOfRecordsUpdated
    
  }
}
` as GeneratedMutation<APITypes.CreateExportForecastDetailsMutationVariables, APITypes.CreateExportForecastDetailsMutation>;
export const updateAndExportProjectDetails = /* GraphQL */ `mutation UpdateAndExportProjectDetails($input: ProjectDetailsInput) {
  updateAndExportProjectDetails(input: $input) {
    numberOfRecordsUpdated
    
  }
}
` as GeneratedMutation<APITypes.UpdateAndExportProjectDetailsMutationVariables, APITypes.UpdateAndExportProjectDetailsMutation>;
export const createOrUpdateUserAccess = /* GraphQL */ `mutation CreateOrUpdateUserAccess($input: [UserAccessInput]) {
  createOrUpdateUserAccess(input: $input) {
    numberOfRecordsUpdated
    
  }
}
` as GeneratedMutation<APITypes.CreateOrUpdateUserAccessMutationVariables, APITypes.CreateOrUpdateUserAccessMutation>;
