import { Box, Button, Container, Header, Modal, SpaceBetween } from '@amzn/awsui-components-react';
import 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { logger } from 'src/analytics/KatalLogger';
import { LoadingSpinner } from 'src/components/common/LoadingSpinner';
import { CorpSegmentNames } from 'src/constants/corp-segment-constants';
import { SegmentHierarchy } from 'src/models/AppContextModels';
import { RootState } from 'src/store/store';
import { getHeaderCounter, isDefinedAndNotEmptyObject } from 'src/utils/generic-utilities';
import { flattenTheSegmentHierarchyData, removeLeafNodes, segmentHierarchyComparator } from './OnboardingFormUtils';
import { selectMasterCorpSegments } from 'src/store/selectors/corpSegmentSelectors';

export interface SelectedSegmentDetails {
  isDefaultValue: boolean;
  isReadOnly: boolean;
  modalHeader: string;
  selectionType: 'single' | 'multiple';
  groupingColumnHeaderName: string;
  segmentS3Path: string | null;
  segmentHierarchialData: SegmentHierarchy[];
}

interface SegmentSelectionModalProps {
  showModal: boolean;
  onCancel: () => void;
  onConfirmOfSegmentHierarchy: (isDefaultValue: boolean, groupingColumn: string, selectedSegments: SegmentHierarchy[]) => void;
  selectedSegmentDetails: SelectedSegmentDetails;
}

const CorpSegmentHierarchySelectionModal: React.FC<SegmentSelectionModalProps> = ({
  showModal,
  onCancel,
  onConfirmOfSegmentHierarchy,
  selectedSegmentDetails
}) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const [modalSize, setModalSize] = useState<'large' | 'max'>('large');

  const themeClassName = useSelector((state: RootState) => state.xptAppMetadataStore.themeClassName);

  const gridRef = useRef<AgGridReact>(null);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [corpSegmentHierarchialMasterData, setCorpSegmentHierarchialMasterData] = useState<SegmentHierarchy[]>([]);
  const [selectedRowData, setSelectedRowData] = useState<SegmentHierarchy[]>([]);

  const masterCorpSegments = useSelector(selectMasterCorpSegments);

  const columnDefs = useMemo(() => {
    if (!selectedSegmentDetails || !isDefinedAndNotEmptyObject(selectedSegmentDetails)) {
      return [];
    }

    const segmentHierarchyColumnDef = {
      field: 'segment_hierarchy',
      headerName: 'Segment',
      filter: true,
      floatingFilter: true,
      checkboxSelection: true,
      valueFormatter: (params: any) => {
        return typeof params?.value === 'string' ? params?.value : params?.value[params?.value?.length - 1] || '';
      }
    };

    const commonColumnDef = [
      segmentHierarchyColumnDef,
      { field: 'segment_description', headerName: 'Description', filter: true, floatingFilter: true }
    ];

    if (selectedSegmentDetails?.selectionType === 'multiple') {
      return commonColumnDef.slice(1); // Remove segment_hierarchy column for 'multiple' selection type
    } else {
      return commonColumnDef;
    }
  }, [selectedSegmentDetails]);

  const defaultColDef = useMemo(
    () => ({
      flex: 1,
      filterParams: {
        applyMiniFilterWhileTyping: true
      }
    }),
    []
  );

  const autoGroupColumnDef = useMemo(
    () => ({
      headerName: selectedSegmentDetails?.groupingColumnHeaderName || '',
      cellRendererParams: {
        suppressCount: true,
        checkbox: selectedSegmentDetails?.isReadOnly ? false : true
      },
      filter: true,
      floatingFilter: true
    }),
    [selectedSegmentDetails]
  );

  const getDataPath = useCallback((data: any) => data?.segment_hierarchy, []);

  useEffect(() => {
    initializeDataFromS3();
  }, [selectedSegmentDetails]);

  const initializeDataFromS3 = async () => {
    try {
      if (selectedSegmentDetails?.segmentS3Path) {
        setDataLoaded(false);

        const segmentHierarchyData = masterCorpSegments.find(
          (masterSegment) => masterSegment.segment_name === selectedSegmentDetails.groupingColumnHeaderName
        )?.segment_hierarchy_data_from_s3!;

        if (selectedSegmentDetails?.selectionType === 'single') {
          const flattenData = flattenTheSegmentHierarchyData(segmentHierarchyData);
          setCorpSegmentHierarchialMasterData(flattenData);
        } else {
          if (selectedSegmentDetails.modalHeader === CorpSegmentNames.COST_CENTER) {
            logger.info('Cost Center selected -> ' + JSON.stringify(selectedSegmentDetails?.segmentHierarchialData));
            const onlyParentNodeData = removeLeafNodes(segmentHierarchyData);
            setCorpSegmentHierarchialMasterData(onlyParentNodeData);
          } else {
            setCorpSegmentHierarchialMasterData(segmentHierarchyData);
          }
        }
        setSelectedRowData(selectedSegmentDetails?.segmentHierarchialData);
        setDataLoaded(true);
      }
    } catch (error: any) {
      logger.error(`Unable to load segment hierarchy. `, { error: error });
      setDataLoaded(true);
    }
  };

  const onGridReady = () => {
    if (!selectedSegmentDetails?.isReadOnly) {
      gridRef.current?.api.forEachNode((node) => {
        const isMatch = selectedSegmentDetails?.segmentHierarchialData.some((selectedSegment) =>
          segmentHierarchyComparator(selectedSegment, node.data)
        );
        node.setSelected(isMatch || false);
      });
    }

    gridRef.current?.api.refreshCells();
  };

  const toggleExpandCollapse = () => {
    if (isExpanded) {
      gridRef.current?.api.collapseAll();
    } else {
      gridRef.current?.api.expandAll();
    }
    setIsExpanded(!isExpanded);
  };

  const toggleModalSize = () => {
    setModalSize((prevSize) => (prevSize === 'max' ? 'large' : 'max'));
  };

  const handleCancel = () => {
    resetState();
    onCancel();
  };

  const handleConfirm = () => {
    resetState();
    onConfirmOfSegmentHierarchy(
      selectedSegmentDetails?.isDefaultValue || false,
      selectedSegmentDetails?.groupingColumnHeaderName || '',
      selectedRowData
    );
  };

  const onSelectionChanged = useCallback(() => {
    setSelectedRowData(gridRef.current?.api.getSelectedRows() || []);
  }, []);

  const resetState = () => {
    setCorpSegmentHierarchialMasterData([]);
    setSelectedRowData([]);
    setDataLoaded(false);
  };

  const isTreeData = selectedSegmentDetails?.selectionType === 'multiple';
  const isReadOnly = selectedSegmentDetails?.isReadOnly;

  return (
    <Modal
      className="segment-hierarchy-modal"
      onDismiss={handleCancel}
      visible={showModal}
      footer={
        <Box float="right" textAlign="center">
          <SpaceBetween direction="horizontal" size="xs" alignItems="center">
            <Button variant="normal" onClick={handleCancel}>
              {!selectedSegmentDetails?.isReadOnly ? 'Cancel' : 'Close'}
            </Button>
            {!selectedSegmentDetails?.isReadOnly && (
              <Button variant="primary" onClick={handleConfirm}>
                Confirm Selection
              </Button>
            )}
          </SpaceBetween>
        </Box>
      }
      header={
        <Header counter={getHeaderCounter(selectedRowData, corpSegmentHierarchialMasterData)}>{`${selectedSegmentDetails?.modalHeader}`}</Header>
      }
      size={modalSize}
    >
      <Container className="segment-hierarchy-modal-content" disableContentPaddings>
        {!dataLoaded && <LoadingSpinner />}
        {dataLoaded && (
          <SpaceBetween direction="vertical" size="xxxs">
            <div className="modal-container">
              <Box float="right" margin={{ top: 's', bottom: 's', horizontal: 's' }}>
                <SpaceBetween direction="horizontal" size="xs">
                  {isTreeData && (
                    <Button variant="normal" iconName={isExpanded ? 'treeview-collapse' : 'treeview-expand'} onClick={toggleExpandCollapse}>
                      {isExpanded ? 'Collapse All' : 'Expand All'}
                    </Button>
                  )}
                  <Button variant="normal" iconName={modalSize === 'max' ? 'shrink' : 'expand'} onClick={toggleModalSize}>
                    {modalSize === 'max' ? 'Restore' : 'Maximize'}
                  </Button>
                </SpaceBetween>
              </Box>
            </div>
            <div className={`modal-grid ${themeClassName}`}>
              <AgGridReact
                ref={gridRef}
                onGridReady={onGridReady}
                rowData={corpSegmentHierarchialMasterData}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                autoGroupColumnDef={autoGroupColumnDef}
                treeData={isTreeData}
                animateRows={true}
                groupDefaultExpanded={-1}
                rowSelection={isReadOnly || !isTreeData ? undefined : 'multiple'}
                groupSelectsChildren={!isReadOnly && isTreeData}
                suppressRowClickSelection={true}
                getDataPath={getDataPath}
                onSelectionChanged={onSelectionChanged}
                suppressDragLeaveHidesColumns
              />
            </div>
          </SpaceBetween>
        )}
      </Container>
    </Modal>
  );
};

export default CorpSegmentHierarchySelectionModal;
