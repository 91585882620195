import { TableProps } from '@amzn/awsui-components-react';
import { logger } from 'src/analytics/KatalLogger';
import { MonthlyUniqueUsersPayloadEntity, UserMetricsRaw } from 'src/models/AppContextModels';
import { formatDateString } from 'src/utils/date-time-utilities';

export const DATE_INITIAL_FORMAT = 'YYYY-MM-DD';
export const DATE_DISPLAY_FORMAT = 'MMM YYYY';

export const uniqueUsersPerMonthTableColumnDefinition = (): TableProps.ColumnDefinition<MonthlyUniqueUsersPayloadEntity>[] => {
  const columns: TableProps.ColumnDefinition<MonthlyUniqueUsersPayloadEntity>[] = [
    {
      id: 'log_date',
      header: 'Month',
      cell: (item: MonthlyUniqueUsersPayloadEntity) => formatDateString(item.log_date, DATE_INITIAL_FORMAT, DATE_DISPLAY_FORMAT)
    },
    {
      id: 'distinct_users_count',
      header: 'Unique Users',
      cell: (item: MonthlyUniqueUsersPayloadEntity) => item.distinct_users_count
    }
  ];
  return columns;
};

export enum MetricsName {
  UserActivity = 'user_activity',
  MonthlyUniqueUsers = 'monthly_unique_users',
  MonthlyUniqueUsersByPage = 'monthly_unique_users_by_page'
}

export const parseUserMetrics = (userMetricsResponse: UserMetricsRaw[]) => {
  return userMetricsResponse.map((metric) => {
    let parsedPayload;
    try {
      parsedPayload = JSON.parse(metric.metrics_payload);
    } catch (error: any) {
      logger.error('Unable to parse user metrics payload:', error);
      parsedPayload = null;
    }
    return {
      ...metric,
      metrics_payload: parsedPayload
    };
  });
};
