import { logger } from 'src/analytics/KatalLogger';
import { ParsedMetadata, SupportTicketTemplates, XptMetadata } from 'src/models/XptGenericModels';
import { parseAWSJSONToJsonObject } from './generic-utilities';

export const parseMetadata = (metadata: XptMetadata[]): ParsedMetadata => {
  const parsed: ParsedMetadata = {};

  metadata.forEach((item) => {
    switch (item.key) {
      case 'xpt_tt_templates':
        try {
          parsed.xpt_tt_templates = parseAWSJSONToJsonObject(item.value) as SupportTicketTemplates;
        } catch (error: unknown) {
          if (error instanceof Error) {
            logger.error('Error parsing xpt_tt_templates', error);
          } else {
            logger.error('Error parsing xpt_tt_templates', new Error(String(error)));
          }
        }
        break;
      // Add more cases here for other keys
      default:
        logger.warn(`Unknown metadata key: ${item.key}`);
    }
  });
  return parsed;
};
