import { HelpPanel } from '@amzn/awsui-components-react';
import React from 'react';
import { APP_NAME } from 'src/constants/generic-constants';

export const APP_DESCRIPTION = {
  intro: `Expense Planning Tool (xPT) is the centralized platform for planning and managing non-headcount expenses. It enables collaborative bottom-up budget modeling by business teams and finance teams. It also serves as the source of truth for reporting and analyzing actuals vs forecast at the line item level. Seamlessly integrated with Prophecy for automated budget loading, xPT empowers finance users with enhanced control over planning, reporting, access management, and calendaring functionalities.`,
  intro_archived: `Expense Planning Tool (xPT) is DaS Finance's new Non-HC OPEX Planning Tool. Serving as a centralized platform, xPT automates the entire end-to-end planning process, from collecting inputs from budget owners to facilitating reviews and providing consolidated roll-up views with detailed commentary for quick analysis.`,
  integrationAndUse_archived: `Seamlessly integrated with Prophecy for automated budget loading, xPT empowers DaS Finance users with enhanced control over planning, reporting, security management, and calendaring functionalities.`,
  features_archived: `It is utilized across Software, Hardware, Operations, and Device Managed Marketing spend, xPT consolidates forecast data into a singular view, delivering invaluable insights for informed decision-making. Additional features include scenario analysis, real-time comparisons of actuals versus forecasts, integration with PO data, and customizable viewing options, ensuring a tailored experience for every user.`
};

const XptHomePageToolPanel: React.FC = () => {
  return (
    <HelpPanel header={<h2>{APP_NAME}</h2>}>
      <div>
        <p>{APP_DESCRIPTION.intro}</p>
      </div>
    </HelpPanel>
  );
};

export default XptHomePageToolPanel;
