import { AppLayout, Box, Button, Container, ContentLayout, Link, Modal, SpaceBetween } from '@amzn/awsui-components-react';
import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { logUserClick } from 'src/analytics/KatalLogger';
import { selectSupportTicketTemplates } from 'src/app/AppMetadataSelector';
import { useAuth } from 'src/app/auth/AuthContextProvider';
import peccyImage from 'src/images/peccy-permissions-gate.png';
import { FixedTicket, SupportTicketTemplates } from 'src/models/XptGenericModels';
import { sendSNSNotification } from 'src/utils/aws-sns-service';

// Constants moved to separate object for better maintainability
const MESSAGES = {
  HEADER: "Oops! It looks like Peccy couldn't find your access permission. 🦙🛑",
  SUBHEADER: 'Submit a request to gain entry!',
  BUTTON_TEXT: 'Submit an Access Request',
  MODAL_HEADER: 'Support Info',
  MODAL_TITLE: 'Access Requests',
  NO_REQUESTS: 'No access request templates available',
  CLOSE: 'Close'
} as const;

interface AccessModalProps {
  visible: boolean;
  onCancel: () => void;
}

// Separate component for modal content to improve readability
const AccessRequestLinks: React.FC<{ templates?: SupportTicketTemplates; userAlias?: string }> = ({ templates, userAlias }) => {
  if (!templates?.accessRequests?.length) {
    return <Box>{MESSAGES.NO_REQUESTS}</Box>;
  }

  const onClickOfLink = (request: FixedTicket) => {
    logUserClick(`User ${userAlias} requested access for ${request.name}`, 'Access Restricted', 'Landing Page');
    sendSNSNotification(`Access Requested`, `User ${userAlias} requested access for ${request.name}`, 'info');
  };

  return (
    <SpaceBetween size="m">
      {templates.accessRequests.map((request, index) => (
        <Box key={`${request.name}-${index}`}>
          <Link href={request.url} external onFollow={() => onClickOfLink(request)}>
            {request.name}
          </Link>
        </Box>
      ))}
    </SpaceBetween>
  );
};

export const AccessModal: React.FC<AccessModalProps> = ({ visible, onCancel }) => {
  const { userAlias } = useAuth();
  const supportTemplates = useSelector(selectSupportTicketTemplates);

  return (
    <Modal
      visible={visible}
      onDismiss={onCancel}
      header={MESSAGES.MODAL_HEADER}
      footer={
        <Box float="right">
          <SpaceBetween size="s" direction="horizontal">
            <Button variant="normal" onClick={onCancel}>
              {MESSAGES.CLOSE}
            </Button>
          </SpaceBetween>
        </Box>
      }
    >
      <Container>
        <h2>{MESSAGES.MODAL_TITLE}</h2>
        <AccessRequestLinks templates={supportTemplates} userAlias={userAlias} />
      </Container>
    </Modal>
  );
};

export const AccessRestricted: React.FC = () => {
  const [showAccessModal, setShowAccessModal] = useState(false);

  const handleModalClose = useCallback(() => {
    setShowAccessModal(false);
  }, []);

  const handleAccessRequest = useCallback(() => {
    setShowAccessModal(true);
  }, []);

  return (
    <>
      <AccessModal visible={showAccessModal} onCancel={handleModalClose} />
      <AppLayout
        headerSelector="#h2"
        navigationHide={true}
        toolsHide={true}
        contentType="dashboard"
        content={
          <div className="xpt-no-access-layout-content">
            <ContentLayout>
              <Box margin={{ top: 'xl', left: 'l', right: 'l' }} padding={{ left: 'l', right: 'l' }}>
                <SpaceBetween size="l" className="access-restricted-container">
                  <Box variant="h2">{MESSAGES.HEADER}</Box>
                  <Box variant="h4">{MESSAGES.SUBHEADER}</Box>
                  <Button ariaLabel="Request access bug (opens new tab)" onClick={handleAccessRequest}>
                    {MESSAGES.BUTTON_TEXT}
                  </Button>
                  <img src={peccyImage} alt="Peccy Permissions Gate" className="access-restricted-image" />
                </SpaceBetween>
              </Box>
            </ContentLayout>
          </div>
        }
      />
    </>
  );
};

export default AccessRestricted;
