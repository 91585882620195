import { HelpPanel } from '@amzn/awsui-components-react';
import React from 'react';

export const OnboardingHelpPanel = () => {
  return (
    <HelpPanel header={<h3>Onboarding Business Group</h3>}>
      <p>
        This page allows you to onboard a Business Group to the xPT Platform. It is accessible only to developers. The onboarding process is divided
        into three main sections:
      </p>
      <ol>
        <li>Data Classification</li>
        <li>Corp Segments</li>
        <li>Business Segments</li>
      </ol>

      <h4>Data Classification</h4>
      <p>
        Please provide a unique name and description for the Data Classification. The name should be a short, unique identifier, typically a 2-3
        character code that is used in the database to differentiate line item IDs. The description should provide a full explanation to give context
        about the Business Group.
      </p>

      <h4>Corp Segments</h4>
      <p>
        A Corp Segment consists of four fields: <strong>Required</strong>, <strong>Segment</strong>, <strong>Default Value</strong>, and{' '}
        <strong>Applicable Values</strong>.
      </p>
      <ul>
        <li>
          <strong>Required</strong>: Indicates whether the segment is a mandatory field for Forecast Input.
        </li>
        <li>
          <strong>Segment</strong>: The name of the segment.
        </li>
        <li>
          <strong>Default Value</strong>: The default value for non-mandatory fields, which is used for sending to upstream applications.
        </li>
        <li>
          <strong>Applicable Values</strong>: The list of parent nodes for the segment. Currently, only the "Cost Center" segment allows selection,
          while all values are applicable by default for the other segments.
        </li>
      </ul>

      <h4>Business Segments</h4>
      <p>
        Business Segments have three fields: <strong>ID Field</strong>, <strong>Segment Name</strong>, and <strong>Data Type</strong>.
      </p>
      <ul>
        <li>
          <strong>ID Field</strong>: Determines whether this segment will be considered in the line item ID generation.
        </li>
        <li>
          <strong>Segment Name</strong>: The name of the business segment.
        </li>
        <li>
          <strong>Data Type</strong>: The type of data used by this segment. For dropdown fields, the Business Group must submit the corresponding
          data to the dev team for integration into Forecast Input.
        </li>
      </ul>
    </HelpPanel>
  );
};
