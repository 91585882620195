import { SelectProps } from '@amzn/awsui-components-react';
import moment from 'moment-timezone';
import { logger } from 'src/analytics/KatalLogger';
import { BusinessGroupEntity, DropdownModel } from 'src/models/AppContextModels';
import { AdminPlanningCycleBusinessGroupsForm, AdminPlanningCycleForm, NonAdminPlanningCycleForm } from 'src/models/PlanningCycleFormModel';
import { PlanningCycleEntity, PlanningCycleFlatTable } from 'src/models/PlanningCycleModel';
import {
  convertPacificTimeToUTC,
  convertToUserLocalAndSplitDateTime,
  formatUTCAsLocalReadable,
  getCurrentUTCTimeInISO,
  getReadableFormatOfMonthDateYear,
  getReadableFormatOfMonthYear,
  getRemainingTimeDetails,
  getYearWithOffset,
  isDateInThePast
} from 'src/utils/date-time-utilities';
import { compareNullableNumbers } from 'src/utils/generic-utilities';
import { getForecastS3BucketName } from 'src/utils/xpt-s3-bucket-details';
import { getBusinessGroupByName } from '../business-group/BusinessGroupUtils';

export enum ReportingFilterTags {
  PLANNING_CYCLE = `Planning Cycle`,
  SNAPSHOT = `Snapshot`
}

/**
 * Transforms a PlanningCycle object into a selection option format suitable for dropdown menus.
 *
 * @param {PlanningCycleEntity | null | undefined} cycle - The PlanningCycle object to transform.
 * @returns {SelectProps.Option} An object representing a dropdown option with label and value properties.
 */
export const toPlanningCycleSelectOption = (cycle: PlanningCycleEntity | null | undefined): SelectProps.Option => {
  const defaultLabel = 'Select a cycle';
  return {
    label: cycle ? `${cycle.scenario.scenario_name}` : defaultLabel,
    value: cycle ? `${cycle.scenario_seq_id}` : '',
    description: cycle?.scenario.planning_cycle_name || ''
  };
};

/**
 *
 * @param {SelectProps.Option | null | undefined} option - A dropdown option
 * @param {PlanningCycleEntity[]} cycles - Planning Cycles
 * @returns {PlanningCycleEntity} Planning Cycle from selected Dropdown
 */
export const fromPlanningCycleSelectOption = (option: SelectProps.Option | null | undefined, cycles: PlanningCycleEntity[]): PlanningCycleEntity =>
  cycles.find((cycle) => String(cycle.scenario_seq_id) === option?.value)!;

/**
 * Converts "PlanningCycle" to "AdminPlanningCycleForm"
 * @param planningCycles[] Planning Cycles
 * @returns AdminPlanningCycleForm
 */
export const convertToAdminPlanningCycleForm = (planningCycles: PlanningCycleEntity[]): AdminPlanningCycleForm => {
  const scenario: DropdownModel = {
    label: planningCycles[0]?.scenario.scenario_name,
    value: `${planningCycles[0]?.scenario.scenario_id}`
  };

  const planningCycleDropdown: DropdownModel = {
    label: planningCycles[0]?.scenario.planning_cycle_name,
    value: `${planningCycles[0]?.scenario.planning_cycle_name}`
  };

  const business_groups: AdminPlanningCycleBusinessGroupsForm[] = planningCycles.map((planningCycle) => ({
    scenario_seq_id: planningCycle?.scenario_seq_id,

    data_classification: {
      label: planningCycle.data_classification.data_classification_name,
      value: `${planningCycle.data_classification.data_classification_id}`
    },

    baseline_scenario: {
      label: `${planningCycle.baseline_scenario?.baseline_scenario_name || ''}`, // Handle historical data where baseline_scenario_name might be null by using an empty string as fallback
      value: `${planningCycle.baseline_scenario?.baseline_scenario_seq_id || ''}`
    },

    actuals_date_range: {
      type: 'absolute',
      startDate: planningCycle.actuals_start_month_id || '',
      endDate: planningCycle.actuals_end_month_id || '',
    },

    budget_owner_lock_date: planningCycle.budget_owner_lock_date,

    forecast_date_range: {
      type: 'absolute',
      startDate: planningCycle.forecast_start_month_id || '',
      endDate: planningCycle.forecast_end_month_id || '',
    },

    prophecy_actuals_date_range: {
      type: 'absolute',
      startDate: planningCycle.prophecy_actuals_start_month_id || '',
      endDate: planningCycle.prophecy_actuals_end_month_id || ''
    },

    prophecy_forecast_date_range: {
      type: 'absolute',
      startDate: planningCycle.prophecy_forecast_start_month_id || '',
      endDate: planningCycle.prophecy_forecast_end_month_id || ''
    },

    planning_cycle_date_time_range: {
      type: 'absolute',
      startDate: planningCycle.planning_cycle_open_date,
      endDate: planningCycle.planning_cycle_lock_date
    },

    forecast_data_available: planningCycle.forecast_data_available,

    is_prophecy_enabled: planningCycle.is_prophecy_enabled,
    last_synced_to_prophecy: planningCycle.last_synced_to_prophecy,
    prophecy_created_at: planningCycle.prophecy_created_at,
    prophecy_created_by: planningCycle.prophecy_created_by,

    is_active: planningCycle.is_active,
    created_at: planningCycle.created_at,
    created_by: planningCycle.created_by,
    updated_at: planningCycle.updated_at,
    updated_by: planningCycle.updated_by
  }));

  return {
    scenario,
    scenario_year: planningCycles[0]?.scenario_year,
    planning_cycle_year: { value: `${planningCycles[0]?.planning_cycle_year}`, label: `${planningCycles[0]?.planning_cycle_year}` },
    planning_cycle_name: planningCycleDropdown,
    business_groups
  };
};

/**
 * Converts "AdminPlanningCycleForm" to "PlanningCycle"
 * note: scenario_year is a custom field that AdminPlanningCycleForm has. Do not include while submitting
 * @param form AdminPlanningCycleForm
 * @param userAlias userAlias
 * @param businessGroups BusinessGroupEntity[]
 * @returns PlanningCycle
 */
export const convertToPlanningCycle = (form: AdminPlanningCycleForm, userAlias: string, businessGroups: BusinessGroupEntity[]) => {
  const parsedPlanningCycle = form.business_groups.map((businessGroupForm) => {
    const _businessGroups: BusinessGroupEntity | undefined = getBusinessGroupByName(businessGroupForm.data_classification.label, businessGroups);
    const formAction = businessGroupForm.scenario_seq_id ? 'Edit' : 'Create'; // To Determine if this group is being added or edited based on the scenario_seq_id

    if (_businessGroups?.data_classification) {
      const baselineScenarioSeqId = businessGroupForm.baseline_scenario.value ? Number(businessGroupForm.baseline_scenario.value) || null : null;
      const mutationObject = {
        scenario_seq_id: businessGroupForm.scenario_seq_id,
        planning_cycle_year: +form.planning_cycle_year.label,

        scenario: {
          scenario_id: +form.scenario.value,
          scenario_name: form.scenario.label,
          planning_cycle_name: form.planning_cycle_name.label
        },

        baseline_scenario: {
          baseline_scenario_name: businessGroupForm.baseline_scenario.label || '',
          baseline_scenario_seq_id: baselineScenarioSeqId
        },
        data_classification: _businessGroups.data_classification,

        actuals_start_month_id: businessGroupForm.actuals_date_range.startDate,
        actuals_end_month_id: businessGroupForm.actuals_date_range.endDate,

        forecast_start_month_id: businessGroupForm.forecast_date_range.startDate,
        forecast_end_month_id: businessGroupForm.forecast_date_range.endDate,

        prophecy_actuals_start_month_id: businessGroupForm.prophecy_actuals_date_range.startDate,
        prophecy_actuals_end_month_id: businessGroupForm.prophecy_actuals_date_range.endDate,

        prophecy_forecast_start_month_id: businessGroupForm.prophecy_forecast_date_range.startDate,
        prophecy_forecast_end_month_id: businessGroupForm.prophecy_forecast_date_range.endDate,

        is_prophecy_enabled: businessGroupForm.is_prophecy_enabled,
        last_synced_to_prophecy: businessGroupForm.last_synced_to_prophecy,
        prophecy_created_at: businessGroupForm.prophecy_created_at,
        prophecy_created_by: businessGroupForm.prophecy_created_by,

        forecast_data_available: businessGroupForm.forecast_data_available,
        export_s3_bucket: getForecastS3BucketName().bucketName,

        planning_cycle_open_date: convertPacificTimeToUTC(businessGroupForm.planning_cycle_date_time_range.startDate),
        budget_owner_lock_date: businessGroupForm.budget_owner_lock_date ? convertPacificTimeToUTC(businessGroupForm.budget_owner_lock_date) : null,
        planning_cycle_lock_date: convertPacificTimeToUTC(businessGroupForm.planning_cycle_date_time_range.endDate),

        is_active: businessGroupForm.is_active,
        created_at: formAction === 'Create' ? getCurrentUTCTimeInISO() : businessGroupForm.created_at,
        created_by: formAction === 'Create' ? userAlias : businessGroupForm.created_by,
        updated_at: getCurrentUTCTimeInISO(),
        updated_by: userAlias
      };
      return mutationObject;
    } else {
      logger.error(`Business group not found for: ${businessGroupForm.data_classification.label}`);
      return null;
    }
  });

  return parsedPlanningCycle?.filter((cycle) => cycle !== null) as PlanningCycleEntity[];
};

/**
 * Converting Planning Cycle to Non Admin (BL) Planning Cycle form
 * @param planningCycle
 * @returns NonAdminPlanningCycleForm
 */
export const convertPlanningCycleAPIToNonAdminPlanningCycleForm = (planningCycle: PlanningCycleEntity): NonAdminPlanningCycleForm => {
  return {
    scenario_seq_id: planningCycle.scenario_seq_id,
    scenario_year: planningCycle.scenario_year,
    planning_cycle_date_time_range: {
      type: 'absolute',
      startDate: planningCycle.planning_cycle_open_date,
      endDate: planningCycle.planning_cycle_lock_date
    },
    datePickerValue: planningCycle.budget_owner_lock_date ? convertToUserLocalAndSplitDateTime(planningCycle.budget_owner_lock_date).date : '',
    timePickerValue: planningCycle.budget_owner_lock_date ? convertToUserLocalAndSplitDateTime(planningCycle.budget_owner_lock_date).time : '',
    budget_owner_lock_date: planningCycle.budget_owner_lock_date || '',
    last_updated_at: planningCycle.updated_at,
    last_updated_by: planningCycle.updated_by,

    is_prophecy_enabled: planningCycle.is_prophecy_enabled,
    prophecy_created_at: planningCycle.prophecy_created_at,
    prophecy_created_by: planningCycle.prophecy_created_by,
    last_synced_to_prophecy: planningCycle.last_synced_to_prophecy
  };
};

/**
 * Converting BL Planning Cycle form to Planning Cycle.
 * Also, scenario_year is removing as it belongs to only frontend. It is not part of the API
 * @param planningCycle
 * @returns NonAdminPlanningCycleForm
 */
export const convertNonAdminPlanningCycleFormToAPI = (
  currentPlanningCycle: PlanningCycleEntity,
  planningCycleForm: NonAdminPlanningCycleForm,
  userAlias: string
): PlanningCycleEntity => {
  // Destructure scenario_year out and capture the rest of the properties
  const { scenario_year, ...restCurrentPlanningCycle } = currentPlanningCycle;

  let prophecy_created_at = null;
  let prophecy_created_by = null;

  if (currentPlanningCycle.is_prophecy_enabled !== planningCycleForm.is_prophecy_enabled) {
    prophecy_created_at = getCurrentUTCTimeInISO();
    prophecy_created_by = userAlias;
  } else {
    prophecy_created_at = currentPlanningCycle.prophecy_created_at;
    prophecy_created_by = currentPlanningCycle.prophecy_created_by;
  }

  return {
    ...restCurrentPlanningCycle,
    is_prophecy_enabled: planningCycleForm.is_prophecy_enabled,
    prophecy_created_at: prophecy_created_at,
    prophecy_created_by: prophecy_created_by,

    last_synced_to_prophecy: planningCycleForm.last_synced_to_prophecy,
    export_s3_bucket: getForecastS3BucketName().bucketName,
    budget_owner_lock_date: convertPacificTimeToUTC(planningCycleForm.budget_owner_lock_date),
    updated_at: getCurrentUTCTimeInISO(),
    updated_by: userAlias
  } as PlanningCycleEntity;
};

export const planningCycleWindowText = (planningCycle: PlanningCycleEntity, isAdmin: boolean): string => {
  const lockDate = isAdmin && planningCycle.budget_owner_lock_date ? planningCycle.budget_owner_lock_date : planningCycle.planning_cycle_lock_date;
  return `${getReadableFormatOfMonthDateYear(planningCycle.planning_cycle_open_date)} - ${formatUTCAsLocalReadable(lockDate)}`;
};

/**
 * Converts PlanningCycle to PlanningCycleFlatTable for Widgets & other table with default Property Filtering
 */
export const convertPlanningsToFlat = (planningCycles: PlanningCycleEntity[], isAdmin: boolean): PlanningCycleFlatTable[] => {
  return planningCycles.map((planningCycle) => {
    const { isLocked, buttonText, statusType } = calculateLockDates(planningCycle, isAdmin);

    return {
      scenario_seq_id: planningCycle.scenario_seq_id,
      scenario_name: planningCycle.scenario.scenario_name,
      scenario_year: planningCycle.scenario_year,
      data_classification_id: planningCycle.data_classification.data_classification_id,
      data_classification_name: planningCycle.data_classification.data_classification_name,
      actuals_date_range: getActualsDateRange(planningCycle),
      forecast_date_range: getForecastDateRange(planningCycle),
      budget_owner_lock_date: planningCycle.budget_owner_lock_date ? formatUTCAsLocalReadable(planningCycle.budget_owner_lock_date) : null,
      planning_cycle_window: planningCycleWindowText(planningCycle, isAdmin),

      // planning_cycle_bo_lock_status: planningCycle.budget_owner_lock_date
      //   ? isDateInThePast(planningCycle.budget_owner_lock_date)
      //     ? 'Locked'
      //     : 'Unlocked'
      //   : null,
      // planning_cycle_lock_status: isDateInThePast(planningCycle.planning_cycle_lock_date) ? 'Locked' : 'Unlocked',

      isLocked: isLocked,
      planningCycleLockStatusMessage: buttonText,
      statusType: statusType,

      is_active: planningCycle.is_active,
      created_at: planningCycle.created_at,
      created_by: planningCycle.created_by,
      updated_at: planningCycle.updated_at,
      updated_by: planningCycle.updated_by
    };
  });
};

export const getActualsDateRange = (cycle: PlanningCycleEntity): string => {
  return `${getReadableFormatOfMonthYear(cycle.actuals_start_month_id || '')} - ${getReadableFormatOfMonthYear(cycle.actuals_end_month_id || '')}`;
};

export const getForecastDateRange = (cycle: PlanningCycleEntity): string => {
  return `${getReadableFormatOfMonthYear(cycle.forecast_start_month_id || '')} - ${getReadableFormatOfMonthYear(cycle.forecast_end_month_id || '')}`;
};

export const isValidScenarioYear = (scenarioYear: string, planningCycles: PlanningCycleEntity[]) => {
  return planningCycles.some((planningCycle) => planningCycle.scenario_year === scenarioYear);
};

// Filters planning cycles by scenario year, aiding in narrowing down cycles for a given year.
export const selectPlanningCyclesByScenarioYear = (scenarioYear: string, planningCycles: PlanningCycleEntity[]) => {
  return planningCycles.filter((planningCycle) => planningCycle.scenario_year === scenarioYear);
};

// Factory selector for finding a planning cycle based on scenario year and data classification name.
export const selectPlanningCycleByScenarioYearAndDataClassification = (
  scenarioYear: string,
  dataClassificationName: string,
  planningCycles: PlanningCycleEntity[]
) => {
  return planningCycles.find(
    (cycle) => cycle.scenario_year === scenarioYear && cycle.data_classification.data_classification_name === dataClassificationName
  );
};

// Selector for filtering planning cycles by data classification ID.
export const selectPlanningCyclesByDataClassificationId = (dataClassificationId: number, activePlanningCycles: PlanningCycleEntity[]) => {
  return activePlanningCycles.filter((cycle) => cycle.data_classification.data_classification_id === dataClassificationId);
};

export const findPlanningCyclesByScenarioSeqId = (scenarioSeqId: number, activePlanningCycles: PlanningCycleEntity[]) => {
  return activePlanningCycles.find((cycle) => cycle.scenario_seq_id === scenarioSeqId);
};

// Selector for filtering planning cycles by data classification Name.
export const selectPlanningCyclesByDataClassificationName = (dataClassificationName: string, planningCycles: PlanningCycleEntity[]) => {
  return planningCycles.filter((cycle) => cycle.data_classification.data_classification_name === dataClassificationName);
};

export const atLeastOnePlanningCycleIsActive = (dataClassificationId: number, activePlanningCycles: PlanningCycleEntity[]): boolean => {
  return activePlanningCycles.some((cycle) => cycle.data_classification.data_classification_id === dataClassificationId);
};

/**
 * Retrieves baseline scenario options for the dropdown selection.
 * Returns planning cycles from previous year onwards for a specific business group.
 * Planning cycles are sorted by scenario sequence ID in descending order.
 *
 * @param groupName - Business group name to filter planning cycles
 * @param allPlanningCycles - Complete list of available planning cycles
 * @returns Array of select options with scenario year as label and sequence ID as value
 */
// Updated Oct 13, 2024: Include planning cycles from previous year onwards per team discussion
export const getBaseLineScenarioOptions = (groupName: string, allPlanningCycles: PlanningCycleEntity[]): SelectProps.Option[] => {
  const previousYear = getYearWithOffset(-1);

  return allPlanningCycles
    .filter(
      ({ data_classification, planning_cycle_year }) =>
        data_classification.data_classification_name === groupName && planning_cycle_year >= previousYear
    )
    .sort((a, b) => compareNullableNumbers(a.scenario_seq_id, b.scenario_seq_id, 'desc'))
    .map(({ scenario_seq_id, scenario_year }) => ({
      label: `${scenario_year}`,
      value: `${scenario_seq_id}`
    }));
};

export const getPlanningCycleYearOptions = (): SelectProps.Option[] => {
  const currentYear = getYearWithOffset();
  const nextYear = getYearWithOffset(1);

  return [
    { label: currentYear.toString(), value: currentYear.toString() },
    { label: nextYear.toString(), value: nextYear.toString() }
  ];
};

// Helper function to calculate lock dates and status
export const calculateLockDates = (planningCycle: PlanningCycleEntity, isAdmin: boolean) => {
  // Get opening date in local time
  const openLockDateInLocalTime = moment.utc(planningCycle.planning_cycle_open_date).local().format();

  // Determine the appropriate lock date based on admin status
  const lockDateKey = isAdmin ? 'planning_cycle_lock_date' : 'budget_owner_lock_date';
  const lockDateUTC = planningCycle[lockDateKey] || planningCycle.planning_cycle_lock_date;
  const closingDateInLocalTime = moment.utc(lockDateUTC).local().format();

  // Determine if the cycle is locked
  const now = moment();
  const isLocked = now.isBefore(openLockDateInLocalTime) || now.isAfter(closingDateInLocalTime);

  let buttonText = '';
  let popOverText = '';
  let statusType: 'info' | 'success' | 'error' = 'info';

  if (now.isBefore(openLockDateInLocalTime)) {
    // Before opening date
    buttonText = getRemainingTimeDetails(openLockDateInLocalTime, 'to open');
    popOverText = `Opening on: ${formatUTCAsLocalReadable(openLockDateInLocalTime)}`;
    statusType = 'info';
  } else if (now.isBetween(openLockDateInLocalTime, closingDateInLocalTime)) {
    // Between opening and closing dates
    buttonText = getRemainingTimeDetails(closingDateInLocalTime, 'left');
    popOverText = `Closing on: ${formatUTCAsLocalReadable(closingDateInLocalTime)}`;
    statusType = 'success';
  } else {
    // After closing date
    buttonText = 'Locked';
    popOverText = `Closed on: ${formatUTCAsLocalReadable(closingDateInLocalTime)}`;
    statusType = 'error';
  }

  return { openLockDateInLocalTime, closingDateInLocalTime, isLocked, buttonText, popOverText, statusType };
};
