import { CognitoAccessToken, CognitoIdToken, CognitoUserSession } from 'amazon-cognito-identity-js';
import { Auth } from 'aws-amplify';
import moment from 'moment-timezone';

interface TokenExpirationResult {
  idToken: string;
  accessToken: string;
}

export const checkTokenExpiration = async (): Promise<TokenExpirationResult | null> => {
  try {
    const session: CognitoUserSession = await Auth.currentSession();

    const idToken: CognitoIdToken = session.getIdToken();
    const accessToken: CognitoAccessToken = session.getAccessToken();

    const formatExpiration = (expirationTime: number) => {
      return moment(expirationTime * 1000)
        .local()
        .format('YYYY-MM-DD HH:mm:ss');
    };

    return {
      idToken: formatExpiration(idToken.getExpiration()),
      accessToken: formatExpiration(accessToken.getExpiration())
    };
  } catch (error) {
    console.error('Error checking token expiration:', error);
    return null;
  }
};
