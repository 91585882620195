import { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const useUnsavedChangesWarning = (isModified: boolean) => {
  const navigate = useNavigate();
  const location = useLocation();
  const lastLocationRef = useRef(location);
  const [isNavigating, setIsNavigating] = useState(false);

  const confirmNavigation = useCallback(() => {
    if (isModified && !isNavigating) {
      setIsNavigating(true);
      const result = window.confirm('You have unsaved changes. Are you sure you want to leave?');
      setIsNavigating(false);
      return result;
    }
    return true;
  }, [isModified, isNavigating]);

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (isModified) {
        const message = 'You have unsaved changes. Are you sure you want to leave?';
        event.preventDefault();
        event.returnValue = message;
        return message;
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isModified]);

  useEffect(() => {
    if (isModified && location !== lastLocationRef.current && !isNavigating) {
      const confirmed = confirmNavigation();
      if (!confirmed) {
        navigate(lastLocationRef.current);
      } else {
        lastLocationRef.current = location;
      }
    } else {
      lastLocationRef.current = location;
    }
  }, [location, isModified, confirmNavigation, navigate, isNavigating]);

  useEffect(() => {
    const originalPushState = window.history.pushState;
    const originalReplaceState = window.history.replaceState;

    window.history.pushState = function (data: any, title: string, url?: string | null) {
      if (confirmNavigation()) {
        return originalPushState.call(this, data, title, url);
      }
    };

    window.history.replaceState = function (data: any, title: string, url?: string | null) {
      if (confirmNavigation()) {
        return originalReplaceState.call(this, data, title, url);
      }
    };

    return () => {
      window.history.pushState = originalPushState;
      window.history.replaceState = originalReplaceState;
    };
  }, [confirmNavigation]);
};

export default useUnsavedChangesWarning;
