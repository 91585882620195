import { configureStore } from '@reduxjs/toolkit';
import appMetadataReducer from '../app/AppMetadataSlice';
import corpSegmentReducer from '../store/slices/corpSegmentSlice';
import xPTMapperReducer from '../store/slices/xPTMapperSlice';
import accessAndAuthorizationReducer from '../features/business-group/access-authorization/redux/userAuthorizationSlice';
import businessGroupReducer from '../features/business-group/businessGroupSlice';
import planningCycleReducer from '../features/planning-cycle/planningCycleSlice';
import forecastTemplateReducer from '../features/business-group/forecast-template/redux/forecastTemplateSlice';
import poTaggingReducer from '../features/po-tagging/POTaggingSlice';

export const makeStore = () =>
  configureStore({
    reducer: {
      xptAppMetadataStore: appMetadataReducer,
      xptAccessAndAuthorizationStore: accessAndAuthorizationReducer,
      corpSegmentsStore: corpSegmentReducer,
      businessGroupStore: businessGroupReducer,

      xPTMappingStore: xPTMapperReducer,
      planningCycleStore: planningCycleReducer,
      forecastTemplateStore: forecastTemplateReducer,
      poTaggingStore: poTaggingReducer
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false
      }),
    devTools: process.env.NODE_ENV === 'dev'
  });

export const store = makeStore();

// Types for RootState and AppDispatch are useful for TypeScript support throughout the app, especially for type-safe useSelector and useDispatch hooks.
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
